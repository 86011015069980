import React, { useEffect, useRef, useState } from 'react'
import Sidebar from '../../../template/sidebar/Sidebar'
import HomeNavbar from '../../../template/navbar/HomeNavbar'
import AdminUserBanner from '../../../template/banner/AdminUserBanner'
import { HiAdjustments } from "react-icons/hi";
import * as XLSX from 'xlsx/xlsx.mjs';
import { FaFileDownload, FaCalculator, FaUpload, FaPercent } from 'react-icons/fa';
import { PiHandCoinsFill } from "react-icons/pi";
import { Button } from 'keep-react'
import MyDropdown from '../../../components/dropdown/MyDropdown';
import MyModal from '../../../components/dialog/MyModal';
import ViewExcellUpload from '../../../components/ressources/ViewExcellUpload';
import { useParams } from 'react-router-dom';
import { NewTarif } from '../../../api/ressources/TarisAssurances';
import { failNotification, succesNotification } from '../../../components/notifications/Notifications';
import { useFormik } from 'formik';
import CalculatriceTarifAuto from '../../../components/ressources/CalculatriceTarifAuto';
import { useProfile } from '../../../UserDataStock/Profils/Profile';

const TarifsAutomobileList = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState('Selectionner les Places');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [data, setData] = useState([]);
    const assurId = useParams();
    const {position, token, userDetails } = useProfile();
  

    const formik1 = useFormik({ 
      initialValues: {
        comCourtier: "",

      }, 
      onSubmit: async (values, { setSubmitting }) => {
        setIsLoading(true);
        try {
          // const response = await NewTarif(values, assurId.id);
          // succesNotification('Tarif ajouté avec succès');
          // setIsLoading(false);
          // setSubmitting(false)

          } catch (error) {
            // failNotification('Erreur lors de l\'ajout du tarif');
            // setIsLoading(false);
            // setSubmitting(false)
          }
      }
    })

    const formik2 = useFormik({ 
      initialValues: {
        comRevendeur: "",

      }, 
      onSubmit: async (values, { setSubmitting }) => {
        setIsLoading(true);
        try {
          // const response = await NewTarif(values, assurId.id);
          // succesNotification('Tarif ajouté avec succès');
          // setIsLoading(false);
          // setSubmitting(false)

          } catch (error) {
            // failNotification('Erreur lors de l\'ajout du tarif');
            // setIsLoading(false);
            // setSubmitting(false)
          }
      }
    })


    
    const handledrawer = () => {
      setOpenDrawer(!openDrawer);
    };

    const handleItemClick = (item) => {
      setSelectedItem(item);
    };

    const handleExcellFileUpload = (event) => {
      const file = event.target.files[0];

      if (file) {
        handleSaveToDatabase(file);
        setSelectedFile(file);
        setModalOpen(true);
        fileInputRef.current.value = null;
      } else {
        console.error('No file selected');
      }
    };

    const handlExcelLoad = () => { 
      fileInputRef.current.click();
    };

    const formdata = (data) => { 
      setIsLoading(true);
      var addTarif ={ 
        nbPlaces: data?.Nbplace,
        assuranceId: assurId.id,
        file: selectedFile
      }
     

      NewTarif (addTarif, token , position)
      .then(function(result) {
        console.log(result);
        if (result.status === 201) {
          succesNotification(result.data.message);
          setModalOpen(false); 
        } else {
          failNotification(result.data.message);
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(function() {
        setIsLoading(false);
      });
    
    }

    const handleSaveToDatabase = (file) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          setData(jsonData);
        };
        reader.readAsArrayBuffer(file);
      }
    };

    const handleOpenClose = () => {
      setIsOpen(!isOpen);
    };
 
  
    return (
      <div className='flex h-screen'>
        <Sidebar isOpen={isOpen} onToggle={handleOpenClose}/> 
        <div  className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
        <HomeNavbar isOpen={isOpen} />
        <main className="p-6 mt-16">
            <AdminUserBanner /> 


            {/* dialog de visualisation des tables importe */}
            <MyModal 
              content={<ViewExcellUpload data={data} handleSave={formdata} isLoading={isLoading} />} 
              isOpen={modalOpen} 
              closeModal={() => setModalOpen(false)} 
            />

            {/* le drawer de la machine a calculer */}
            <CalculatriceTarifAuto drawOpen={openDrawer} closeDrawer={handledrawer}  /> 

          

            <div className='flex justify-end  pb-4'>
              <div className='flex space-x-2 py-2 px-4 mr-5 bg-purple-300 text-white items-center rounded-lg cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700 hover:text-white'
                onClick={()=>alert('Hello Everyone')}>
                <FaFileDownload className='w-8 h-7' />
              </div>
    

              <div className='flex space-x-2 py-2 px-4 mr-5 bg-purple-300 text-white items-center rounded-lg cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700 hover:text-white'
                onClick={handlExcelLoad}>
                <FaUpload className='w-8 h-7' />
                <input
                  type='file'
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleExcellFileUpload}
                />
              </div>
              
              <div className='flex space-x-2 py-2 px-4 mr-5 bg-purple-300 text-white items-center rounded-lg cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700 hover:text-white'
                onClick={()=>setOpenDrawer(true)}>
                <FaCalculator className='w-8 h-7' />
              </div>
            </div>

            <div className='space-y-6'>
              <div className='flex justify-between w-full p-5 border-teal-500 border-[0.2px] shadow-md rounded-xl bg-white items-center '>
                <div className='flex-col justify-center'>
                  <div className='flex justify-start '>
                    <div className='flex justify-center items-center space-x-2'>
                      <HiAdjustments size={30} className='text-teal-700' /> 
                      <span className='text-xl font-semibold text-teal-700'>Tarification </span>
                    </div>
                  </div>
                  <div>
                    <span className='font-semibold'>Véhicules Affaires et promenades - AP</span>
                  </div>
                </div>
                <MyDropdown selectedItem={selectedItem} handleItemClick={handleItemClick} /> 
              </div>

              <div className='grid grid-cols-4 gap-4 '>

                <div className=' col-span-2 p-5 border-teal-500 border-[0.2px] shadow-md rounded-xl bg-white items-center'>
                  <div className='flex justify-between pb-4'>
                    <div className='flex justify-start items-center space-x-2 '>
                      <PiHandCoinsFill size={30} className='text-teal-700' />
                      <h1 className='font-semibold '>Mode de commissionnement du courtier</h1>
                    </div> 
                    <div className='flex space-x-1'>
                      <button className='text-white bg-yellow-500 hover:bg-yellow-600 px-4 py-2 rounded-lg' disabled>
                        {selectedItem}
                      </button>
                      <Button  size='xs'>
                        Enregistrer
                      </Button>
                    </div>
                  </div>
                  <hr className='pb-4' /> 
                  <div>
                    <div className='bg-slate-200 p-4 rounded-md'>
                      <span className='font-semibold text-sm'>Pourcentage de la Commission %</span>
                    </div>
                    <form onSubmit={formik1.handleSubmit} method='post'>
                      <div className="grid grid-cols-8 px-14 py-6 space-y-4 row-auto">
                         <div className='col-span-8'>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <FaPercent className="w-5 h-5 text-gray-400" />
                                </span>
                                <input 
                                    autoComplete='off'
                                    id='Nbplace' 
                                    name='Nbplace'
                                    onChange={formik1.handleChange}
                                    value={formik1.values.comCourtier}
                                    type="number"
                                    placeholder="Saisissez la commission" 
                                    className='pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                                />
                            </div>
                         </div>
                      </div>
                    </form>

                  </div>
                </div>

                <div className=' col-span-2 p-5 border-teal-500 border-[0.2px] shadow-md rounded-xl bg-white items-center'>
                  <div className='flex justify-between pb-4'>
                    <div className='flex justify-start items-center space-x-2 '>
                      <PiHandCoinsFill size={30} className='text-teal-700' />
                      <h1 className='font-semibold '>Mode de commissionnement du revendeur</h1>
                    </div> 
                    <div className='flex space-x-1'>
                      <button className='text-white bg-yellow-500 hover:bg-yellow-600 px-4 py-2 rounded-lg' disabled>
                        {selectedItem}
                      </button>
                      <Button  size='xs'>
                        Enregistrer
                      </Button>
                    </div>
                  </div>
                  <hr className='pb-4' /> 
                  <div>
                    <div className='bg-slate-200 p-4 rounded-md'>
                      <span className='font-semibold text-sm'>Pourcentage de la Commission %</span>
                    </div>
                    <form onSubmit={formik2.handleSubmit} method='post'>
                      <div className="grid grid-cols-8 px-14 py-6 space-y-4 row-auto">
                         <div className='col-span-8'>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <FaPercent className="w-5 h-5 text-gray-400" />
                                </span>
                                <input 
                                    autoComplete='off'
                                    id='Nbplace' 
                                    name='Nbplace'
                                    onChange={formik2.handleChange}
                                    value={formik2.values.comRevendeur}
                                    type="number"
                                    placeholder="Saisissez la commission" 
                                    className='pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                                />
                            </div>
                         </div>
                      </div>
                    </form>

                  </div>
                </div>

              </div>
            </div>
          </main>
        </div>
      </div>
    )
}

export default TarifsAutomobileList;
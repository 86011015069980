import axios from "axios"

export async function Authentification(data){ 
    try{ 
        const response = await axios({ 
            method: 'POST',
            url: `${import.meta.env.VITE_BACKLOG}/auth/login`,
            headers: { 
                'Content-Type': 'application/json',
                'Accept': '*/*'
            },
            data: data
        })
        return response;

    } catch (error) { 
        if(error.response){ 
            return (error.response)
        } else if (error.request) { 
            console.log(error.request)
        } else { 
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}
import React, { useEffect, useState } from 'react';
import Sidebar from '../../template/sidebar/Sidebar';
import HomeNavbar from '../../template/navbar/HomeNavbar';
import AdminUserBanner from '../../template/banner/AdminUserBanner';
import { motion } from "framer-motion";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import VendeursContratDatatable from '../../components/datatables/voyagesDatatable/VendeursContratsDatatable';
import { useProfile } from '../../UserDataStock/Profils/Profile';
import { getAllDistributeurs } from '../../api/distributeurs/Distributeurs';
import SelectDistributeurs from '../../components/inputs/SelectDIstributeurs';
import { GetAllPointVentes } from '../../api/pointsdeventes/PointsDeVente';
import SelectPDV from '../../components/inputs/SelectPDV';
import { GetAllContratsVoyageByDB, GetAllContratsVoyageByPV } from '../../api/voyages/DevisEtContratsVoyage';

const ContratsVoyageList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const { position, token, userDetails } = useProfile();
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [lesDistributeurs, setLesDistributeurs] = useState([]);
    const [lesPointsDeVente, setLesPointsDeVente] = useState([]);
    const [selectedDistributeur, setSelectedDistributeur] = useState(null);
    const [numData, setNumData] = useState(0);
    const idpvInitial = userDetails?.pointDeVenteId;
    const [idpvSelected, setIdpvSelected] = useState(idpvInitial);
    const [isLoadingDistributeurs, setIsLoadingDistributeurs] = useState(false);
    const [isLoadingPDV, setIsLoadingPDV] = useState(false); 

    
    // Fonction pour charger les contrats
    const loadContratsVoyage = async () => {
        if (!token || !position) {
            console.error('Token ou position manquant');
            return;
        }

        try {
            setIsLoading(true);
            let donnes;
            if (selectedDistributeur) {
                donnes = await GetAllContratsVoyageByDB(token, position, selectedDistributeur);
            } else {
                donnes = await GetAllContratsVoyageByPV(token, position, idpvInitial);
            }
            

            if (!donnes) {
                setRowData([]);
                setNumData(0);
                return;
            }

            const formattedData = donnes.map(data => ({
                id: data.contrat.ref,
                modifc: data.contrat.edited,
                date: data.contrat.dateContrat || data.contrat.dateDevis, 
                policeNo: data.contrat.ref,
                souscripteur: {
                    nom: `${data.subscriber.prenom} ${data.subscriber.nom}`,
                    telephone: data.subscriber.telephone,
                    email: data.subscriber.email,
                    social: data.subscriber.raisonSociale
                },
                createdByUser: {
                    vendeur: `${data.createdByUser.prenom} ${data.createdByUser.nom}`
                },
                voyage: {
                    depart: data.voyage.dateDepart,
                    retour: data.voyage.dateRetour
                },
                formule:{
                    libelle: data.formule.name
                },
                decomposition: {
                    primeNette: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.primeNette, 0),
                    accessoires: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.accessoire, 0),
                    taxes: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.taxes, 0),
                    primeTTC: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.primeTTC, 0),
                    montantTotal: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.montantTotal, 0),
                },
                assureur:{
                    nom: data.assureur.libelle,
                },
                etat: data.contrat.isPaid ? 'recouvré' : 'non recouvré',
                pdv: data.pointDeVente.libelle              
            }));
            setRowData(formattedData);
            setNumData(donnes.length);
        } catch (error) {
            console.error('Erreur lors du chargement des contrats:', error);
            setRowData([]);
            setNumData(0);
        } finally {
            setIsLoading(false);
        }
    };

   // Premier montage et changement de distributeur
    useEffect(() => {
        if (token && position) {
            loadContratsVoyage();
        }
    }, [token, position, selectedDistributeur]);

    
    // Chargement des distributeurs
    async function loadDistributeurs() {
        if (!token || !position) return;
    
        try {
            setIsLoadingDistributeurs(true);
            const response = await getAllDistributeurs(token, position);
            setLesDistributeurs(response || []);
        } catch (err) {
            console.error('Erreur chargement distributeurs:', err);
            setLesDistributeurs([]);
        } finally {
            setIsLoadingDistributeurs(false);
        }
    }

    useEffect(() => {
        loadDistributeurs();
    }, [token, position]);

    const filteredData = rowData.filter((row) => {
        const lowerSearchText = searchText.toLowerCase();
        return (
            row.id?.toLowerCase().includes(lowerSearchText) ||
            row.date?.toLowerCase().includes(lowerSearchText) ||
            row.policeNo?.toLowerCase().includes(lowerSearchText) ||
            row.souscripteur?.nom?.toLowerCase().includes(lowerSearchText) ||
            row.souscripteur?.telephone?.toLowerCase().includes(lowerSearchText) ||
            row.souscripteur?.email?.toLowerCase().includes(lowerSearchText) ||
            row.souscripteur?.social?.toLowerCase().includes(lowerSearchText) ||
            row.createdByUser?.vendeur?.toLowerCase().includes(lowerSearchText) ||
            row.voyage?.depart?.toLowerCase().includes(lowerSearchText) ||
            row.voyage?.retour?.toLowerCase().includes(lowerSearchText) ||
            row.formule?.libelle?.toLowerCase().includes(lowerSearchText) ||
            row.assureur?.nom?.toLowerCase().includes(lowerSearchText) ||
            row.etat?.toLowerCase().includes(lowerSearchText) ||
            row.pdv?.toLowerCase().includes(lowerSearchText)
        );
    });

    return (
        <div className='flex h-screen'>
            <Sidebar isOpen={isOpen} onToggle={() => setIsOpen(!isOpen)} />
            <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
                <HomeNavbar isOpen={isOpen} />
                <main className="p-6 mt-16 h-screen">
                    <AdminUserBanner />
                    <div className="flex justify-between items-center mb-4">
                        <div className='flex flex-col md:flex-row items-center gap-4'>
                            <h2 className="text-xl font-bold text-white bg-purple-700 px-4 py-2 rounded-md whitespace-nowrap">
                                Liste des Contrats voyage
                                <span className="ml-3">({numData || 0})</span>
                            </h2>
                            {userDetails.role !== "Vendeur" && (
                                <>
                                    <div className="w-full sm:w-[200px] md:w-[220px] lg:w-[250px] xl:w-[300px]">
                                        <SelectDistributeurs 
                                            distributeurs={lesDistributeurs} 
                                            onSelect={(distributeur) => setSelectedDistributeur(distributeur.id)}
                                            isLoading={isLoadingDistributeurs}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        
                        <div className='relative w-full sm:w-[200px] md:w-[220px] lg:w-[250px] xl:w-[300px]'>
                            <input
                                type="text"
                                placeholder="Rechercher un contrat..."
                                className="w-full px-4 py-2 rounded-lg border border-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    
                    <motion.div
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
                        exit={{ y: 100, opacity: 0 }}
                        className='border rounded-lg '
                    >
                        <VendeursContratDatatable data={filteredData} chargement={isLoading}  />
                    </motion.div>
                </main>
            </div>
        </div>
    );
};


export default ContratsVoyageList;


import { Drawer, DrawerContent } from 'keep-react'
import React, { useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FaWallet } from 'react-icons/fa'
import espece from "/src/images/espece.png";
import orange from "/src/images/orange.png";
import wave from "/src/images/wave.png";
import free from "/src/images/free.png";
import ChoiceDialog from '../dialog/ChoiceDialog';
import { useNavigate } from 'react-router-dom';


const AutoPaiement = ({drawOpen, closeDrawer}) => {


    const [ouvert, setOuvert] = useState(false);
    const [selectedMobileMoney, setSelectedMobileMoney] = useState(null);
    const [useDefaultNumber, setUseDefaultNumber] = useState(true);
    const navigate = useNavigate();
    const ContratVoyage = () => {navigate(`/Voyages/Contrats/8`)}

    const mobileMoney = [
        { id: 1, nom: "Orange Money", img: orange },
        { id: 2, nom: "Wave", img: wave },
        { id: 3, nom: "Free Money", img: free }
    ];

    const handleSelectPaymentMethod = (method) => {
        setSelectedMobileMoney(method);
        setOuvert(true);
    };

    return (
        <>
            <ChoiceDialog
                isOpen={ouvert}
                closeModal={() => setOuvert(false)}
                content={(
                    <div className='flex items-center'>
                        {selectedMobileMoney && (
                            <motion.div 
                            className='flex justify-center items-center'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            >
                            <div className='flex items-center'>
                                <img 
                                className='w-28 h-24 rounded-lg shadow-md'
                                src={selectedMobileMoney.img} 
                                alt={selectedMobileMoney.nom} 
                                />
                                <h1 className='ml-4 font-AbrilFatface text-lg'>{selectedMobileMoney.nom}</h1>
                            </div>
                            <motion.div
                                className='flex flex-col items-center ml-4'
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                <h1 className='text-center text-md mt-4'>
                                {useDefaultNumber 
                                    ? "Voulez-vous effectuer le paiement avec ce numéro"
                                    : "Mettez le numéro pour effectuer le paiement"}
                                </h1>
                                <input 
                                type='text' 
                                className={`mt-4 p-2 rounded-lg w-full ${useDefaultNumber ? 'bg-gray-300' : 'bg-white'}`} 
                                value={useDefaultNumber ? selectedMobileMoney.numero : ''} 
                                disabled={useDefaultNumber} 
                                onChange={(e) => setSelectedMobileMoney({ ...selectedMobileMoney, numero: e.target.value })}
                                />
                                {useDefaultNumber ? (
                                <div className='flex space-x-4 mt-4'>
                                    <button 
                                    className='bg-red-500 text-white py-2 px-4 rounded-lg'
                                    onClick={() => setUseDefaultNumber(false)}
                                    >
                                    Non
                                    </button>
                                    <button 
                                    className='bg-green-500 text-white py-2 px-4 rounded-lg'
                                    onClick={() => alert('Paiement effectué')}
                                    >
                                    Oui
                                    </button>
                                </div>
                                ) : (
                                <button 
                                    className='bg-blue-500 text-white py-2 px-4 rounded-lg mt-4'
                                    onClick={() => alert('Paiement effectué')}
                                >
                                    Payer
                                </button>
                                )}
                            </motion.div>
                            </motion.div>
                        )}
                    </div>
                )}
            />

            <Drawer isOpen={drawOpen} position='right'>
                <DrawerContent className='w-3/12 border bg-white shadow-md'>
                    <AiFillCloseCircle 
                        className='m-5 text-gray-500 cursor-pointer text-3xl'
                        onClick={closeDrawer}
                    />
                    <div className='bg-purple-600 mx-8 rounded-lg flex justify-center items-center text-white'>
                        <FaWallet size={25}  /> 
                        <h1 className=' text-lg font-semibold p-5'>Les Moyens de Payements</h1>
                    </div>
                    <div className='flex-col justify-center items-center border mx-8 my-4 p-4 rounded-lg bg-white shadow-md'>
                        <h1 className='font-semibold text-center font-ConcertOne text-xl text-teal-600'>Payer en espèces</h1>
                        <div className='flex justify-center items-center mt-4 transform transition-transform hover:scale-105 cursor-pointer'
                        onClick={ContratVoyage}
                        >
                            <img
                                src={espece}
                                alt="espece"
                                className="h-24 w-28 rounded-md border-[1.5px] shadow-sm p-1"
                            /> 
                        </div>
                        <hr className='mt-5 mb-5 h-2' />
                        <h1 className='font-semibold text-center font-ConcertOne text-xl text-teal-600'>Payer par Mobile Money</h1>
                        <div className='flex justify-between items-center mt-4'>
                            {mobileMoney.map((method) => (
                                <div
                                    key={method.id}
                                    className='transform transition-transform hover:scale-105 cursor-pointer'
                                    onClick={() => handleSelectPaymentMethod(method)}
                                >
                                    <img
                                        src={method.img}
                                        alt={method.nom}
                                        className="h-24 w-28 rounded-md border-[1.5px] shadow-sm p-2"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </DrawerContent>
            </Drawer>
        </>
    );
  };
export default AutoPaiement

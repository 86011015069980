import { motion } from "framer-motion";
import { FaInstagram, FaTwitter, FaFacebook, FaLinkedin, FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";
import logo from '/src/images/Diokotech.png';

const Footer = () => {
  const socialLinks = [
    { label: "Facebook", icon: FaFacebook, url: "https://facebook.com/diokotech" },
    { label: "Instagram", icon: FaInstagram, url: "https://instagram.com/diokotech" },
    { label: "Twitter", icon: FaTwitter, url: "https://twitter.com/diokotech" },
    { label: "LinkedIn", icon: FaLinkedin, url: "https://linkedin.com/company/diokotech" },
  ];

  const contactInfo = [
    { icon: FaMapMarkerAlt, text: "lot n°16 Cité CCBM, Ouest-Foire 3e étage, Dakar 12000," },
    { icon: FaPhone, text: "+221 33 827 20 05" },
    { icon: FaEnvelope, text: "contact@diokotech.com" },
  ];

  return (
    <motion.footer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-gradient-to-r from-purple-900 to-purple-700 text-white"
    >
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Section À propos */}
          <div className="space-y-6">
            <div className="bg-white p-4 rounded-lg inline-block">
              <img src={logo} alt="Diokotech Logo" className="h-12" />
            </div>
            <p className="text-sm leading-relaxed">
              Diokotech est une entreprise leader dans les solutions technologiques innovantes, 
              dédiée à transformer le futur numérique de l'Afrique.
            </p>
          </div>

          {/* Section Contact */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold mb-4">Contactez-nous</h3>
            {contactInfo.map((item, index) => (
              <div key={index} className="flex items-center space-x-3">
                <item.icon className="w-5 h-5 text-purple-300" />
                <span className="text-sm">{item.text}</span>
              </div>
            ))}
          </div>

          {/* Section Newsletter */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold">Newsletter</h3>
            <p className="text-sm">Restez informé de nos dernières actualités</p>
            <div className="flex flex-col space-y-2">
              <input
                type="email"
                placeholder="Votre email"
                className="px-4 py-2 rounded-lg text-purple-600 border border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-300"
              />
              <button className="px-4 py-2 bg-purple-500 hover:bg-purple-400 transition-colors rounded-lg font-semibold">
                S'abonner
              </button>
            </div>
          </div>

          {/* Section Réseaux sociaux */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold">Suivez-nous</h3>
            <div className="flex space-x-4">
              {socialLinks.map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:transform hover:scale-110 transition-transform"
                >
                  <social.icon className="w-8 h-8 text-purple-300 hover:text-white" />
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Barre de copyright */}
        <div className="border-t border-purple-600 mt-8 pt-8 text-center">
          <p className="text-sm">
            © {new Date().getFullYear()} Diokotech SA. Tous droits réservés.
          </p>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
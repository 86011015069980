import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useProfile } from '../../UserDataStock/Profils/Profile';
import AccessDenied from '../../template/errors/AccesDenied';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { userDetails } = useProfile();
  const location = useLocation();
  if (!userDetails || userDetails === null || userDetails === undefined || Object.keys(userDetails).length === 0) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (allowedRoles && !allowedRoles.includes(userDetails.role)) {
    return <AccessDenied />;
  }

  return children;
};

export default ProtectedRoute;
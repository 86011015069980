import React, { useState } from 'react'
import Sidebar from '../../../template/sidebar/Sidebar'
import HomeNavbar from '../../../template/navbar/HomeNavbar'
import AdminUserBanner from '../../../template/banner/AdminUserBanner'

const TarifsMotocycletteList = () => {

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenClose = () => {
        setIsOpen(!isOpen);
    };

  return (
    <div className='flex h-screen'>
      <Sidebar isOpen={isOpen} onToggle={handleOpenClose}/> 
      <div  className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
       <HomeNavbar isOpen={isOpen} />
          <main className="p-6 mt-16">
            <AdminUserBanner  /> 
              <div>
                hello guys
              </div>
          </main>
      </div>
    </div>
  )
}

export default TarifsMotocycletteList

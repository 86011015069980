import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react'

const MyDropdown = ({ selectedItem, handleItemClick }) => {

    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelection = (item) => {
        handleItemClick(item);
        setIsOpen(false);
    };

   
  return (
        <div className="relative inline-block text-left">
        <button
            className="text-white bg-yellow-500 hover:bg-yellow-600 px-4 py-2 rounded-lg"
            onClick={toggleDropdown}
        >
            {selectedItem}
        </button>
        <AnimatePresence>
            {isOpen && (
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="origin-top-left absolute right-16 w-28  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                <div
                    className="px-4 py-2 rounded-lg hover:bg-purple-700 hover:text-white cursor-pointer"
                    onClick={() => handleSelection('3 places')}
                >
                    3 places
                </div>
                <div
                    className="px-4 py-2 rounded-lg hover:bg-purple-700 hover:text-white cursor-pointer"
                    onClick={() => handleSelection('5 places')}
                >
                    5 places
                </div>
                <div
                    className="px-4 py-2 rounded-lg hover:bg-purple-700 hover:text-white cursor-pointer"
                    onClick={() => handleSelection('12 places')}
                >
                    12 places
                </div>
                </div>
            </motion.div>
            )}
        </AnimatePresence>
        </div>
  )
}

export default MyDropdown


const FormatNumero = ({numero}) => {
  if (typeof numero !== 'string') {
    return 'Invalid input'; // or any other appropriate error handling
  }

  // Rest of the function remains the same
  const indicatif = numero.slice(0, 3);
  const resteNumero = numero.slice(3);



  let format;
  switch (indicatif) {
    case '221': 
      format = `(+${indicatif})-${resteNumero.slice(0, 2)}-${resteNumero.slice(2, 5)}-${resteNumero.slice(5, 7)}-${resteNumero.slice(7)}`;
      break;
    case '223': 
      format = `(+${indicatif})-${resteNumero.slice(0, 2)}-${resteNumero.slice(2, 4)}-${resteNumero.slice(4, 6)}-${resteNumero.slice(6)}`;
      break;
    case '224': 
      format = `(+${indicatif})-${resteNumero.slice(0, 2)}-${resteNumero.slice(2, 4)}-${resteNumero.slice(4, 6)}-${resteNumero.slice(6)}`;
      break;
    case '225': 
      format = `(+${indicatif})-${resteNumero.slice(0, 2)}-${resteNumero.slice(2, 4)}-${resteNumero.slice(4, 6)}-${resteNumero.slice(6)}`;
      break;
    // Ajouter d'autres cas pour d'autres indicatifs si nécessaire
    default:
      format = numero; // Retourne le numéro original si l'indicatif n'est pas reconnu
      break;
  }

  return format;
};

export default FormatNumero

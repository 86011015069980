import { Popover, PopoverContent, PopoverTrigger } from 'keep-react'
import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { FaEdit, FaPercentage } from 'react-icons/fa'
import { RxDotsHorizontal } from 'react-icons/rx'
import {  FcFile,FcSimCardChip, FcTimeline, FcGenealogy, FcDocument, FcFullTrash, FcBusinessman, FcMoneyTransfer } from "react-icons/fc";
import DeleteModal from '../dialog/DeleteModal';
import { DeleteAssureur, UpAssureur } from '../../api/ressources/CompagniesAssurances';
import { failNotification, succesNotification } from '../notifications/Notifications';
import MyModal from '../dialog/MyModal';
import UpdateAssureur from '../forms/assureurs/UpdateAssureur';
import ChoiceDialog from '../dialog/ChoiceDialog';
import { GiCommercialAirplane } from "react-icons/gi";
import { IoCarSportSharp } from 'react-icons/io5';
import { FaMotorcycle } from "react-icons/fa6";
import { useNavigate, useLocation } from "react-router-dom";
import { useProfile } from '../../UserDataStock/Profils/Profile';


const OptionsAssureurs = ({active, confirmation }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [Selected, setSelected] = useState(active);
  const [UpdateModale, setUpdateModale] = useState(false);
  const [opTarif, setOpTarif] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {position, token, userDetails } = useProfile();

  const navigate = useNavigate();
  const location = useLocation();

  const Auto = () => {navigate(`${location.pathname}/Tarifs_Automobile/${Selected.id}`)}
  const Voyage = () => {navigate(`${location.pathname}/Tarif_Voyage/${Selected.id}`)}
  const Moto = () => {navigate(`${location.pathname}/Tarifs_Motocyclette/${Selected.id}`)}

   const deleOneAssureur =()=>{
        DeleteAssureur(Selected.id, token, position)  
        .then(function(result){
          console.log(result)
        if(result.status === 204){
            succesNotification("Assureur supprimé avec succès");
            setIsOpen(false)
            confirmation(prevStatus => prevStatus + 1)
        } else{
            failNotification(result.data.message);
        }
        })
        .catch(function (error){
        console.log(error)
        })
   }

   const formData = (data) =>{ 

        const idAs = data.id;
        setIsLoading(true)
        var updateInsurer={ 
            code: data?.code,
            libelle: data?.libelle,
            description: data?.description,
            email: data?.email,
            logo: data?.logo,
            tel_fixe: data?.fixnumber,
            tel_mobile: data?.mobileNumber,
            code_courtier: data?.codeCourtier,
            addedBy: data?.addedBy
        }

        UpAssureur(idAs, updateInsurer, token, position)
        .then(function(result){
            if(result.status === 200){
                setSelected({
                    ...Selected,
                    ...updateInsurer
                });
                succesNotification(result.data.message);
                setUpdateModale(false)
                confirmation(prevStatus => prevStatus + 1)
            } else { 
                failNotification(result.data.message);
            }
        })
        .catch(function(error){ 
            console.log(error)
        })
        .finally(function() { 
            setIsLoading(false);
        })
    } 

    const itemVariants = {
        hover: { y: -10, transition: { duration: 0.3 } },
      };

  return (
    <>
         {/* update dialog */}
        <MyModal 
        isOpen={UpdateModale} 
        closeModal={()=>setUpdateModale(false)}
        content={<UpdateAssureur data={Selected} isLoading={isLoading} handleUpdate={formData}/> }/> 
        {/* delete dialog */}
        <DeleteModal isOpen={isOpen} id={Selected.id} libelle={Selected.libelle} closeModal={()=>setIsOpen(false)} type={"l'Assureur"} titre={"Assureur"} choix={deleOneAssureur} />
        {/* choix tarif modal */}
        <ChoiceDialog isOpen={opTarif} closeModal={()=> setOpTarif(false)} content={(
            <div className="flex flex-col justify-center items-center w-full p-4">
            <div className="text-center">
              <h1 className="font-bold text-xl text-purple-700">
                Sélectionner Le Type d'Assurance
              </h1>
              <hr className="border-t-2 border-purple-700 mt-2" />
            </div>
        
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-center mt-8">
              <motion.div
                className="p-2 cursor-pointer"
                variants={itemVariants}
                whileHover="hover"
              >
                <div className="flex flex-col items-center space-y-3 hover:text-purple-700" onClick={Auto}>
                  <IoCarSportSharp size={40} />
                  <p className='text-center'>Assurance Automobile</p>
                </div>
              </motion.div>
        
              <motion.div
                className="p-2 cursor-pointer"
                variants={itemVariants}
                whileHover="hover"
              >
                <div className="flex flex-col items-center space-y-3 hover:text-purple-700" onClick={Voyage}>
                  <GiCommercialAirplane size={40} />
                  <p className='text-center'>Assurance Voyage</p>
                </div>
              </motion.div>
        
              <motion.div
                className="p-2 cursor-pointer"
                variants={itemVariants}
                whileHover="hover"
              >
                <div className="flex flex-col items-center space-y-3 hover:text-purple-700" onClick={Moto}>
                  <FaMotorcycle size={40} />
                  <p className='text-center'>Assurance Motocyclette</p>
                </div>
              </motion.div>
            </div>
          </div>
        )
        } /> 

        <div className='flex justify-between p-1'>
        <div></div>
        <Popover placement='left-start'>
            <PopoverTrigger
            className='p-2  rounded-md cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700  hover:text-white '>
            <RxDotsHorizontal   />
            </PopoverTrigger>
            <PopoverContent className="max-w-xs z-20 rounded-lg  shadow-md border bg-[#faf0e6] ">
                    <motion.div 
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className='flex-col justify-center p-2 space-y-2'
                    >

                        <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7'
                        onClick={()=>setUpdateModale(true)}
                        >
                            <div className='flex items-center space-x-2 '>
                            <FaEdit className='text-green-500'  /> 
                            <button>Modifier</button>
                            </div>
                            <div></div>
                        </div>

                        <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7 '>
                            <div className='flex items-center space-x-2  '>
                                <FcBusinessman  /> 
                            <button>Membres</button>
                            </div>
                            <div className='bg-purple-700 rounded-lg border border-white'>
                                <span className='text-white p-1 '>02</span>
                            </div>
                        </div>

                        <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7  '>
                            <div className='flex items-center space-x-2 '>
                            <FcFile /> 
                                <button>Attestations</button>
                            </div>
                            <div className='bg-purple-700 rounded-lg border border-white'>
                                <span className='text-white p-1 '>100</span>
                            </div>
                        </div>

                        <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7 '>
                            <div className='flex items-center space-x-2 '>
                            <FcSimCardChip /> 
                            <button>Cartes Brune</button>
                            </div>
                            <div className='bg-purple-700 rounded-lg border border-white'>
                                <span className='text-white p-1 '>16</span>
                            </div>
                        </div>

                        <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7  '>
                            <div className='flex items-center space-x-2 '>
                            <FcGenealogy /> 
                            <button>Branches</button>
                            </div>
                            <div></div>
                        </div>

                        <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7  '>
                            <div className='flex items-center space-x-2 '>
                            <FcTimeline  /> 
                            <button>Garanties</button>
                            </div>
                            <div></div>
                        </div>

                        <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7'
                        onClick={()=>setOpTarif(true)} 
                        >
                            <div className='flex items-center space-x-2 '>
                            <FcMoneyTransfer /> 
                            <button>Tarifs</button>
                            </div>
                            <div></div>
                        </div>

                        <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7  '>
                            <div className='flex items-center space-x-2 '>
                            <FcDocument  /> 
                            <button>Livrables</button>
                            </div>
                            <div></div>
                        </div>

                        <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7'>
                            <div className='flex items-center space-x-2 '>
                            <FaPercentage className='text-green-500' /> 
                            <button>Commissions</button>
                            </div>
                            <div></div>
                        </div>

                        <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7'
                        onClick={() =>setIsOpen(true)}
                        >
                            <div className='flex items-center space-x-2 '>
                            <FcFullTrash  /> 
                            <button>Supprimer</button>
                            </div>
                            <div></div>
                        </div>

                    </motion.div>
            </PopoverContent>
        </Popover>
    </div>
    </>
  )
 
}

export default OptionsAssureurs

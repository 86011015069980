import React from 'react';
import { motion } from "framer-motion";
import { HiMiniXMark } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';

const variant = {
  close: {
    x: -300,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  },
  open: {
    x: 0,
    opacity: 100,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  }
};

const NavigationLink = ({ name, onClick, children }) => (
  <div
    className='flex items-center gap-2 p-2 rounded-md cursor-pointer hover:bg-neutral-700'
    onClick={onClick}
  >
    {children}
    <span className='text-white'>{name}</span>
  </div>
);

const SousCategoriesNavigation = ({ selectedCategories, setSelectedCategories, isOpen, submenuItems, searchTerm, setSearchTerm }) => {
  const navigate = useNavigate();

  return (
    <motion.nav
      variants={variant}
      initial="close"
      animate="open"
      exit="close"
      className={`h-full flex flex-col gap-8 w-64 fixed bg-[#8203b1] ml-0 sous-categories-nav z-50 ${
        isOpen ? 'left-64' : 'left-20'
      } border-r border-neutral-800 p-5`}
    >
      <div className='flex flex-row w-full justify-between place-items-center'>
        <h1 className='tracking-wide text-neutral-100 text-lg'>{selectedCategories}</h1>
        <button onClick={() => setSelectedCategories(null)}>
          <HiMiniXMark className='w-12 text-white stroke-neutral-400'/>
        </button>
      </div>

      <input
        placeholder='Recherche'
        type='text'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className='px-3 py-2 tracking-wide rounded-lg bg-neutral-600 text-neutral-100'
      />

      <div className='flex flex-col gap-3'>
        {submenuItems?.map((item, index) => (
          <NavigationLink
            key={index}
            name={item.name}
            onClick={() => {
              navigate(item.link);
              setSelectedCategories(null);
            }}
          >
            <div className='text-white'>{item.icon}</div>
          </NavigationLink>
        ))}
      </div>
    </motion.nav>
  );
};

export default SousCategoriesNavigation;
import { Skeleton, SkeletonLine } from 'keep-react'
import React from 'react'

const AussureurSkeleton = () => {
  return Array(4).fill(0).map((_, index) => (
    <Skeleton key={index} className="col-span-6 lg:col-span-3 py-4 border rounded-2xl shadow-md px-4 bg-white">
      {/* Header with menu */}
      <div className='flex justify-end p-1'>
        <SkeletonLine className="h-8 w-8 rounded-full" />
      </div>

      {/* Logo and company info */}
      <div className='flex'>
        <div className='w-24 h-24 rounded-md'>
          <SkeletonLine className="h-full w-full rounded-md" />
        </div>
        <div className='p-4 flex-1'>
          <SkeletonLine className="h-6 w-3/4 mb-2" />
          <SkeletonLine className="h-4 w-1/2" />
        </div>
      </div>

      {/* Contact details */}
      <div className='py-6 space-y-4'>
        <div className='flex justify-between items-center'>
          <SkeletonLine className="h-4 w-1/4" />
          <SkeletonLine className="h-4 w-1/2" />
        </div>
        <div className='flex justify-between items-center'>
          <SkeletonLine className="h-4 w-1/3" />
          <SkeletonLine className="h-4 w-1/2" />
        </div>
        <div className='flex justify-between items-center'>
          <SkeletonLine className="h-4 w-1/3" />
          <SkeletonLine className="h-4 w-1/2" />
        </div>
      </div>
      
      <hr className='my-4' />

      {/* Stats */}
      <div className='flex justify-between pt-4'>
        <div className='w-1/2'>
          <SkeletonLine className="h-12 w-12 mb-2 rounded-full" />
          <SkeletonLine className="h-3 w-20 mb-1" />
          <SkeletonLine className="h-4 w-16" />
        </div>
        <div className='w-1/2 flex justify-end'>
          <div>
            <SkeletonLine className="h-12 w-12 mb-2 rounded-full" />
            <SkeletonLine className="h-3 w-20 mb-1" />
            <SkeletonLine className="h-4 w-24" />
          </div>
        </div>
      </div>
    </Skeleton>
  ));
};

export default AussureurSkeleton
import React from 'react'
import VendeurHomeList from '../../layout/home/VendeurHomeList'
import { motion } from 'framer-motion';

const Home = () => {

  // const test = useProfile();
  // // console.log('position:'+test.position)
  // console.log('token:'+test.token)
  // console.log('user:'+test.userDetails)
  return (
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      >
        <VendeurHomeList />
      </motion.div>
  )
}

export default Home

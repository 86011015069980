import React, { useEffect, useRef, useState } from 'react'
import { RiImageAddFill } from "react-icons/ri";
import { useFormik } from "formik"
import no from "/src/images/no.jpg?url";
import { motion } from 'framer-motion';
import PhoneInput from 'react-phone-input-2'
import { GrTextAlignLeft } from "react-icons/gr";
import { FaCheckCircle, FaEnvelope, FaGlobeAfrica, FaPen , FaQrcode } from 'react-icons/fa';
import { Button, Spinner } from 'keep-react';
import { AssureurValidation } from '../../../utils/FormValidation';


const UpdateAssureur = ({handleUpdate, isLoading, data}) => {

    const validate = AssureurValidation;
    const [imagePreview, setImagePreview] = useState(null);
    const fileInputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFocused1, setIsFocused1] = useState(false);

    const pays = [
        { id: 1, label: 'Senegal', flag: 'SEN' },
        { id: 2, label: 'Côte d\'Ivoire', flag: 'CIV' },
        { id: 3, label: 'Guinée Conakry', flag: 'GUI' },
        { id: 4, label: 'Mali', flag: 'MLI' },
    ]

    const formik = useFormik({ 
        initialValues: { 

            code:"",
            libelle:"", 
            description:"",
            email:"",
            logo:"",
            fixnumber:"",
            mobileNumber:"",
            codeCourtier:"",
            modedBy: "",
            country_id: "",
        },
        validate,
        onSubmit: handleUpdate
    })

    useEffect(()=>{ 
        if(data){
            formik.resetForm(); // Réinitialiser le formulaire
            formik.setFieldValue("id", data?.id || "")
            formik.setFieldValue("code", data?.code || "")
            formik.setFieldValue("libelle", data?.libelle || "")
            formik.setFieldValue("description", data?.description || "")
            formik.setFieldValue("email", data?.email || "")
            formik.setFieldValue("logo", data?.logo || "")
            formik.setFieldValue("fixnumber", data?.tel_fixe || "")
            formik.setFieldValue("mobileNumber", data?.tel_mobile || "")
            formik.setFieldValue("codeCourtier", data?.code_courtier || "")
            formik.setFieldValue("country_id", String(data?.countryId) || "")
            
            setImagePreview(data?.logo || null)
        }
    }, [data, formik.resetForm]) // Ajouter formik.resetForm comme dépendance

    const handleImageChange = (event) => { 
        const file = event.target.files[0];
        if(file){ 
            formik.setFieldValue('logo', file);
            const reader = new  FileReader();
            reader.onloadend = () => { 
                setImagePreview(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    const handleClickImageUpload = () => { 
        fileInputRef.current.click();
    };

  return (
    <form onSubmit={formik.handleSubmit} method='post' encType="multipart/form-data" className='w-full'>

         {/* le logo */}
         <div className='flex items-center justify-center mt-2 mb-4'>
            <div>
                <div className=" ml-32 cursor-pointer bottom-5 shadow-md  " >
                  <span onClick={handleClickImageUpload}>
                      <RiImageAddFill className="hover:text-purple-800" size={25} />
                  </span>
                </div>
                {imagePreview ? (
                    <div className='w-32 h-32 rounded-md shadow-md bg-white border-[0.5px]'>
                        <img src={imagePreview} alt="Preview" className='p-2 object-cover items-center' />
                    </div>
                ) : (
                    <div className='w-32 h-32 rounded-md shadow-md bg-white border-[0.5px]'>
                        <img src={no} alt="Preview" className='p-2 object-cover items-center' />
                    </div>
                )}
              </div>
              <input type='file' accept="image/*"  id='logo' style={{ display: "none" }} ref={fileInputRef} onChange={handleImageChange} />
        </div>
        <div className="italic text-md text-center text-red-500">{formik.touched.logo && formik.errors.logo ?
         (<motion.div 
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -100, opacity: 0 }}
            transition={{ duration: 0.5 }}
        >{formik.errors.logo}</motion.div>) : null}
        </div>
        <hr /> 
        <div className='grid grid-cols-8 gap-4 px-14 py-2 row-auto'>
            <div className='col-span-4'>
                <label htmlFor="libelle" className="block text-gray-700 font-semibold mb-1 pl-2">Libelle</label>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FaPen className="w-5 h-5 text-gray-400 " />
                        </span>
                        <input 
                        autoComplete='off'
                        id="libelle" 
                        name='libelle'
                        onChange={formik.handleChange}
                        value={formik.values.libelle}
                        onBlur={formik.handleBlur}
                        type="text" 
                        placeholder="Saisissez le Libelle " 
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.libelle && formik.touched.libelle ? 'border-red-500': ''}`}
                        />
                    </div>
            </div>
            

            <div className='col-span-4'>
                <label htmlFor="country_id" className="block text-gray-700 font-semibold mb-1 pl-2">Pays</label>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FaGlobeAfrica className="w-5 h-5 text-gray-400 " />
                        </span>
                        <select
                          autoComplete='off'
                          id='country_id' 
                          name='country_id'
                          onChange={formik.handleChange}
                          value={formik.values.country_id}
                          onBlur={formik.handleBlur}
                          className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.country_id && formik.touched.country_id ? 'border-red-500': ''}`}
                        >
                            <option value="" label="Sélectionner le pays"  />
                            {pays.map((option) => (
                                <option key={option.id} value={option.id}>{option.label}</option>
                            ))}
                        </select>
                    </div>
            </div>
            {formik.touched.libelle && formik.errors.libelle ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-4 text-red-500"
                >{formik.errors.libelle}
                </motion.div>) : null
            }
            
            {formik.touched.country_id && formik.errors.country_id ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-4 text-red-500"
                >{formik.errors.country_id}
                </motion.div>) : null
            }
            
            <div className='col-span-4'>
                <label htmlFor="code identification" className="block text-gray-700 font-semibold mb-1 pl-2">Code d'identification</label>
                <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FaQrcode className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input 
                    autoComplete='off'
                    id='code' 
                    name='code'
                    onChange={formik.handleChange}
                    value={formik.values.code}
                    onBlur={formik.handleBlur}
                    type="number"
                    placeholder="Saisissez le Code " 
                    className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.code && formik.touched.code ? 'border-red-500': ''}`}
                    />
                </div>
            </div>

            <div className='col-span-4'>
                <label htmlFor="code courtier" className="block text-gray-700 font-semibold mb-1 pl-2">Code courtier</label>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FaQrcode className="w-5 h-5 text-gray-400 " />
                        </span>
                        <input 
                        autoComplete='off'
                        id='codeCourtier' 
                        name='codeCourtier'
                        onChange={formik.handleChange}
                        value={formik.values.codeCourtier}
                        onBlur={formik.handleBlur}
                        type="number"
                        placeholder="Saisissez le Code courtier " 
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.codeCourtier && formik.touched.codeCourtier ? 'border-red-500': ''}`}
                        />
                    </div>
            </div>
            
            {formik.touched.code && formik.errors.code ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-4 text-red-500"
                >{formik.errors.code}
                </motion.div>) : null
            }
            
            {formik.touched.codeCourtier && formik.errors.codeCourtier ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-4 text-red-500"
                >{formik.errors.codeCourtier}
                </motion.div>) : null
            }
           
            <div className='col-span-8'>
                <label htmlFor="email" className="block text-gray-700 font-semibold mb-1 pl-2">Adresse électronique</label>
                <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FaEnvelope className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input 
                    autoComplete='off'
                    id="email" 
                    name='email'
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    type="email" 
                    placeholder="Saisissez l'email" 
                    className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.email && formik.touched.email ? 'border-red-500': ''}`}
                    />
                </div>
            </div>
            {formik.touched.email && formik.errors.email ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-8 text-red-500"
                >{formik.errors.email }
                </motion.div>) : null
            }

            <div className='col-span-4'>
                <label htmlFor="fixnumber" className="block text-gray-700 font-semibold mb-1 pl-2">Téléphone Fixe</label>
                    <div className="relative">
                        <PhoneInput
                            country={'sn'}
                            onlyCountries={['sn', 'gn','ml', 'ci']}
                            masks={{ 
                                sn: '-..-...-..-..',
                                gn: '-..-...-...',
                                ml: '-..-...-...',
                                ci: '-..-..-..-..'
                            }}
                            autoComplete='off'
                            id="fixnumber"
                            name='fixnumber'
                            value={formik.values.fixnumber}
                            onChange={(value) => {
                                formik.setFieldValue('fixnumber', value);
                            }}
                            onBlur={() => {
                                formik.handleBlur('fixnumber');
                                setIsFocused(false);
                            }}
                            placeholder=" Numero du Fix"
                            inputStyle={{
                                'fontFamily':'serif',
                                'width': '100%',
                                'height': '2.5rem',
                                'color': '#444',
                                ...(isFocused && {
                                    outline: 'none',
                                    boxShadow: '0 0 0 2px #3b82f6',
                                    borderColor: 'transparent'
                                  
                                })
                            }}
                            onFocus={() => setIsFocused(true)}
                        />
                    </div>
            </div>
        
            <div className='col-span-4'>
                <label htmlFor="mobileNumber" className="block text-gray-700 font-semibold mb-1 pl-2">Téléphone Mobile</label>
                
                    <div className="relative">
                        <PhoneInput
                            country={'sn'}
                            onlyCountries={['sn', 'fr']}
                            masks={{ sn: '-..-...-..-..', fr: '(...) ..-..-..' }}
                            autoComplete='off'
                            id="mobileNumber"
                            name='mobileNumber'
                            value={formik.values.mobileNumber}
                            onChange={(value) => {
                                formik.setFieldValue('mobileNumber', value);
                            }}
                            onBlur={() => {
                                formik.handleBlur('mobileNumber');
                                setIsFocused1(false);
                            }}
                            placeholder=" Numero mobile"
                            inputStyle={{
                                'fontFamily':'serif',
                                'width': '100%',
                                'height': '2.5rem',
                                'color': '#444',
                                ...(isFocused1 && {
                                    outline: 'none',
                                    boxShadow: '0 0 0 2px #3b82f6',
                                    borderColor: 'transparent'
                                  
                                }),
                                // ...(!formik.touched.mobileNumber && !formik.errors.fixnumber) ? { border: '#ef4444' } : null
                            }}
                            onFocus={() => setIsFocused1(true)}
                           
                        />
                    </div>
            </div>
            
            {formik.touched.fixnumber && formik.errors.fixnumber ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-4 text-red-500"
                >{formik.errors.fixnumber}
                </motion.div>) : null
            }
            {formik.touched.mobileNumber && formik.errors.mobileNumber? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-4 text-red-500"
                >{formik.errors.mobileNumber}
                </motion.div>) : null
            }

            <div className='col-span-8'>
                <label htmlFor="description" className="block text-gray-700 font-semibold mb-1 pl-2">Recommandations</label>
                
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <GrTextAlignLeft className="w-5 h-5 text-gray-400 " />
                        </span>
                        <textarea
                        autoComplete='off'
                        id='description'
                        name='description'
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        onBlur={formik.handleBlur}
                        placeholder="Saisissez la description " 
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.description && formik.touched.description ? 'border-red-500': ''}`}
                        />
                    </div>
            </div>
            {formik.touched.description && formik.errors.description ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-8 text-red-500"
                >{formik.errors.description}
                </motion.div>) : null
            }
        </div>
        <div className='flex  justify-center item-center py-2'>
                <Button type='submit' size="md" disabled={isLoading}>
                    <span className="pr-2">
                        {
                            isLoading ? (<Spinner color="info" size="md" />) : (<FaCheckCircle size={18} />)
                        }
                    </span>
                    {
                        isLoading ? "En Cours d'enregistrement..." : 'Sauvegarde'
                    }
                </Button>
        </div>
    </form>
  )
}

export default UpdateAssureur

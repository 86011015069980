import car from '/public/icons/auto.svg'
export const products = [
    {
      id: 1,
      name: "Product A",
      price: 29.99,
      stock: 100
    },
    {
      id: 2,
      name: "Product B",
      price: 49.99,
      stock: 50
    },
    {
      id: 3,
      name: "Product C",
      price: 19.99,
      stock: 200
    },
    {
      id: 4,
      name: "Product D",
      price: 99.99,
      stock: 10
    }
  ];
  
  export const orders = [
    {
      id: '001',
      customer: 'John Doe',
      status: 'Pending'
    },
    {
      id: '002',
      customer: 'Jane Smith',
      status: 'Shipped'
    },
    {
      id: '003',
      customer: 'Alice Johnson',
      status: 'Delivered'
    },
    {
      id: '004',
      customer: 'Bob Brown',
      status: 'Cancelled'
    }
  ];

  export const Data = [
        { id: 1, prenom:"Moustapha" ,nom: "Sambe", telephone: "+221775896320", voiture: "Toyota x6", matricule: "SN-466-DK", montant: "74.5863", status: "valide", expiration: "22/10/2024"  },
        { id: 2, prenom:"Moustapha" ,nom: "Sambe", telephone: "+221775896320", voiture: "Toyota x6", matricule: "SN-466-DK", montant: "74.5863", status: "expire bientot", expiration: "17/07/2024"  },
        { id: 3, prenom:"Moustapha" ,nom: "Sambe", telephone: "+221775896320", voiture: "Toyota x6", matricule: "SN-466-DK", montant: "74.5863", status: "valide", expiration: "22/10/2024"  },
        { id: 4, prenom:"Moustapha" ,nom: "Sambe", telephone: "+221775896320", voiture: "Toyota x6", matricule: "SN-466-DK", montant: "74.5863", status: "expiree", expiration: "22/06/2024"  },
        { id: 5, prenom:"Moustapha" ,nom: "Sambe", telephone: "+221775896320", voiture: "Toyota x6", matricule: "SN-466-DK", montant: "74.5863", status: "valide", expiration: "22/10/2024"  },
        { id: 6, prenom:"Moustapha" ,nom: "Sambe", telephone: "+221775896320", voiture: "Toyota x6", matricule: "SN-466-DK", montant: "74.5863", status: "expiree", expiration: "22/06/2024"  },
        { id: 7, prenom:"Moustapha" ,nom: "Sambe", telephone: "+221775896320", voiture: "Toyota x6", matricule: "SN-466-DK", montant: "74.5863", status: "valide", expiration: "22/10/2024"  },
        { id: 8, prenom:"Moustapha" ,nom: "Sambe", telephone: "+221775896320", voiture: "Toyota x6", matricule: "SN-466-DK", montant: "74.5863", status: "expire bientot", expiration: "15/07/2024"  },
        { id: 9, prenom:"Moustapha" ,nom: "Sambe", telephone: "+221775896320", voiture: "Toyota x6", matricule: "SN-466-DK", montant: "74.5863", status: "valide", expiration: "22/10/2024"  },
        { id: 10, prenom:"Moustapha" ,nom: "Sambe", telephone: "+221775896320", voiture: "Toyota x6", matricule: "SN-466-DK", montant: "74.5863", status: "expiree", expiration: "22/60/2024"  },
        { id: 11, prenom:"Moustapha" ,nom: "Sambe", telephone: "+221775896320", voiture: "Toyota x6", matricule: "SN-466-DK", montant: "74.5863", status: "valide", expiration: "22/10/2024"  },
  ];

  

  

  
  export default orders;
import React, { useState } from 'react'
import Sidebar from '../../template/sidebar/Sidebar';
import HomeNavbar from '../../template/navbar/HomeNavbar';
import { motion } from 'framer-motion';
import AdminUserBanner from '../../template/banner/AdminUserBanner';
import AsPic from "/src/images/Auto.png";
import contrat from '/src/images/contrat.json';
import good from "/src/images/good.json";
import Lottie from 'lottie-react';
import CheckForm from '../../components/forms/automobiles/CheckForm';
import ChoiceDialog from '../../components/dialog/ChoiceDialog';
import { useNavigate, useLocation } from 'react-router-dom';

const SouscriptionAutoList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showContrat, setShowContrat] = useState(false);
    const [createContrat, setCreateContrat] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const newContrat = () => {navigate(`${location.pathname}/Nouveau`)}
    const renewContrat = () => {navigate(`${location.pathname}/Renouvellement`)}


    const response = true;

    const handleOpenClose = () => {
      setIsOpen(!isOpen);
    };

    const formCheck = (data) => { 
      setIsLoading(true)
      var verifMat={ 
        matricule: data?.matricule
      }

      if (response === true){ 
        console.log(verifMat);
        setShowContrat(true);

      } else{ 
        setCreateContrat(true)

      }
    } 

    

  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 2 }}
     className='flex h-screen'>
      <Sidebar isOpen={isOpen}  onToggle={handleOpenClose}/> 
      <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
        <HomeNavbar isOpen={isOpen} />
            <main className="p-6 mt-16">
              <AdminUserBanner />

              {/* Dialogue de redirection vers les contrats existants  */}
              <ChoiceDialog 
                isOpen={showContrat} 
                closeModal={()=>setShowContrat(false)} 
                content={<div className='flex flex-col items-center'>
                  <Lottie 
                    animationData={contrat}
                    loop={true}
                    autoPlay={true}
                    className='w-48 h-48'
                  />
                  <h1 className="text-teal-700 font-bold text-2xl mt-4 mb-2">
                    Contrat Existant
                  </h1>
                  <div className='text-center mb-4 '>
                    <div className='text-md text-lg'><strong className='text-red-500'>Attention </strong>nous avons trouvé que cette <strong>Matricule</strong> existe déjà dans notre base de données <br />et qu’il a déjà eu une souscription de <strong>Contrat</strong> au nom de <strong className='text-red-500'>Ousmane Badji</strong><br/>
                        S’agit-il de la même personne qui veux souscrire a l’assurance ?<br />
                        si <strong>OUI </strong>cliquez sur <strong className='font-bold text-teal-600'>Renouveler</strong> si <strong>NON</strong> cliquez sur <strong className='text-teal-600'>Créer</strong> un nouveau Contrat.
                    </div>
                  </div>
                  <div className="flex space-x-10 mt-4">
                    <button
                      className="bg-teal-500 text-white font-bold py-2 px-4 rounded-lg shadow hover:bg-teal-600  transform transition duration-300 hover:scale-110"
                      onClick={renewContrat}
                    >
                      Renouveller
                    </button>
                    <button
                      className="bg-teal-500 text-white font-bold py-2 px-4 rounded-lg shadow hover:bg-teal-600 transform transition duration-300 hover:scale-110"
                      onClick={newContrat}
                    >
                      Créer 
                    </button>
                  </div>
                  
                </div>}  
              /> 

              {/* Dialogue de redirection vers la creation des contrats */}
              <ChoiceDialog 
                isOpen={createContrat}
                closeModal={()=>setCreateContrat(false)}
                content={<div className='flex flex-col items-center'>
                  <Lottie 
                    animationData={good}
                    loop={true}
                    autoPlay={true}
                    className='w-48 h-48'
                  /> 
                  <h1 className='text-teal-700 font-bold text-2xl mt-4 mb-2'>
                    Contrat Inexistant
                  </h1>
                  <div className='text-center mb-4 '>
                    <div className='text-md text-lg'>
                     Aucun <strong>Contrat</strong> n’a été trouvé liant ce dernier a cette <strong>Matricule</strong>.<br />
                     Vous pouvez créer un nouveau contrat.
                    </div>
                  </div>
                  <div className="flex mt-4">
                    <button
                      className="bg-teal-500 text-white font-bold py-2 px-4 rounded-lg shadow hover:bg-teal-600 transform transition duration-300 hover:scale-110"
                      onClick={newContrat}
                    >
                      Créer 
                    </button>
                  </div>
                </div>}
              >
              </ChoiceDialog>

              <div className='w-full flex bg-white rounded-xl shadow-sm items-center'>
                <div className='w-2/4 h-2/4 px-10 rounded-lg'>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1, duration: 2 }}>
                    <img src={AsPic} alt="image" className='w-full h-full objectcover'/>
                </motion.div>
                </div>
                <div className='flex ml-36 '>
                  <div >
                    <motion.h1 
                      initial={{ y: -100, opacity: 0 }} 
                      animate={{ y: 0, opacity: 1 }} 
                      transition={{ delay: 1, duration: 2}}
                      className='font-bold text-3xl py-4 underline '
                    >
                      Recommendations
                    </motion.h1>
                        <motion.div 
                          className='w-96'
                          initial={{ x: 100, opacity: 0 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ type: 'tween', delay: 1, duration: 2}}
                        >
                        <span className='text-xl font-semibold text-teal-600'>Bienvenue dans notre parcours assurance auto, Attention ! il est indispensable de vous munir de votre carte grise et de votre pièce d'identité afin de poursuivre le parcours.</span>
                      </motion.div>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{delay: 2, duration: 2}}
                      >
                        <CheckForm handleCheck={formCheck} isLoading={isLoading} />
                      </motion.div>
                  </div>
                </div>
              </div>
           </main>
      </div>
    </motion.div>
  )
}

export default SouscriptionAutoList

import React, { useState } from 'react'
import Sidebar from '../../template/sidebar/Sidebar'
import { motion } from 'framer-motion';
import HomeNavbar from '../../template/navbar/HomeNavbar'
import AdminUserBanner from '../../template/banner/AdminUserBanner'
import SouscriptionsAuto from '../../components/forms/automobiles/SouscriptionsAuto'

const NouveauAutoList = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenClose = () => {
        setIsOpen(!isOpen);
      };

  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
     className='flex h-screen'>
       <Sidebar isOpen={isOpen} onToggle={handleOpenClose}/> 
            <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
                <HomeNavbar isOpen={isOpen} />
                    <main className="p-6 mt-16">
                        <AdminUserBanner />
                        <div className='flex w-full'>
                            <SouscriptionsAuto /> 
                        </div>
                    </main>
            </div>
    </motion.div>
  )
}

export default NouveauAutoList

import axios from "axios"

//////// ajout des tarifs ///////////////////


////////////  Tarif Automobile //////////////
export async function NewTarif(data, token, position){ 
    try{ 
        const response = await axios({ 
        method: 'POST',
        url: `${import.meta.env.VITE_BACKLOG}/tarifs/upload`,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `${token}`,
            'countryCode': `${position?.location?.country_code3 || ''}`
        },
        data: data
    })
    return response ;


    } catch(error){ 
        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

///////////// Tarif Voyages //////////////////

export async function NewTarifVoyage(data, token, position) {
    try{
        const response = await axios({ 
            method: 'POST',
            url: `${import.meta.env.VITE_BACKLOG}/tarifs/upload/voyage`,
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: data
        })
        return response;
    } catch (error){ 

        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

export async function NewTarifVoyageFormule(data, token, position){ 
    try{
        const response = await axios({ 
            method: 'POST',
            url: `${import.meta.env.VITE_BACKLOG}/formules/voyage`,
            headers:{
                'Content-Type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data:data
            
        })
        return response;
    } catch (error){ 

        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

export async function NewTarifVoyageFormuleGn(data, token){ 
    try{
        const response = await axios({ 
            method: 'POST',
            url: `${import.meta.env.VITE_GUINEE}/upload/voyage`,
            headers:{
                'Content-Type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `${token}`,
                
            },
            data:data
        })
        return response;
    } catch (error){ 

        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}
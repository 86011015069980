import axios from "axios";




export async function AddUser(data, token, position){ 
    try { 
        const response = await axios({ 
            method: 'POST',
            url: `${import.meta.env.VITE_BACKLOG}/users`,
            headers:{ 
                'Content-Type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: data
        })
        return response;
    } catch (error) { 
        if(error.response){ 
            return (error.response)
        } else if (error.request) { 
            console.log(error.request)
        } else { 
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

export async function UpdateUser(id, data, token, position){ 
    try{ 
        const response = await axios({ 
            method: 'PUT',
            url: `${import.meta.env.VITE_BACKLOG}/users/${id}`,
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: data
        }) 
        return response;
    } catch (error){ 

        if(error.response){ 
            return (error.response)
        } else if (error.request) { 
            console.log(error.request)
        } else { 
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

export async function GetAllUsers(token, position){ 
    try{ 
        const response = await axios({ 
            method: 'GET',
            url: `${import.meta.env.VITE_BACKLOG}/users`,
            headers: { 
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        })
        return response.data;
    }catch (error){ 

        if(error.response){ 
            return (error.response)
        } else if (error.request) { 
            console.log(error.request)
        } else { 
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}


export async function DeleteUser(id, token, position){ 
    try{ 
        const response = await axios({ 
            method: "DELETE",
            url: `${import.meta.env.VITE_BACKLOG}/users/${id}`,
            headers: { 
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        })
        return response.data;
    } catch (error){ 

        if(error.response){ 
            return (error.response)
        } else if (error.request) { 
            console.log(error.request)
        } else { 
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}


export async function  NewPassword(id, data, token, position){ 
    try{
        const response = await axios({ 
            method: 'POST',
            url: `${import.meta.env.VITE_BACKLOG}/users/set-password/${id}`,
            headers:{ 
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: data
        })
        return response.data;

    } catch (error) { 

        if(error.response){ 
            return (error.response)
        } else if (error.request) { 
            console.log(error.request)
        } else { 
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}
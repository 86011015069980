import React, { useState, useEffect } from 'react';
import { FcSurvey } from "react-icons/fc";
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { FaCar, FaUser, FaMoneyBillWave, FaCheck, FaCalendarAlt, FaMapMarkerAlt, FaEnvelope, FaPhone, FaFileContract } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const FormStep4 = ({ onNext, onPrev, isLastStep }) => {

    const [stepData, setStepData] = useState({});
    const navigate = useNavigate();
    const Devis = () => {navigate(`/Automobiles/Devis/2`)}

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem('formData'));
        if (data) {
            setStepData(data);
        }
    }, []);

    // console.log(stepData)

    const step1Data = stepData.step1Data || {};
    const step2Data = stepData.step2Data || {};
    const step3Data = stepData.step3Data || {}; 

    return (
        <motion.div 
            className='bg-gray-50 p-8 rounded-xl border shadow-xl max-w-4xl mx-auto'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <h2 className="text-2xl mb-4 font-semibold text-purple-700">4. Résumé</h2>

            <div className="space-y-6">
                {/* Card 1: Informations sur l'assuré */}
                <motion.div 
                    className="bg-white p-6 rounded-lg shadow-md border transition transform hover:scale-105 hover:shadow-lg"
                    whileHover={{ scale: 1.02 }}
                >
                    <h3 className="text-xl font-semibold text-gray-700 mb-4 flex items-center">
                        <FaUser className="text-blue-500 mr-2" /> Informations sur l'assuré
                    </h3>
                    <p><strong>Type de personne :</strong> {step2Data.type}</p>
                    <p><strong>Genre :</strong> {step2Data.genre}</p>
                    <p><strong>Nom :</strong> {step2Data.nom}</p>
                    <p><strong>Prénom :</strong> {step2Data.prenom}</p>
                    <p><strong>Activité :</strong> {step2Data.activite}</p>
                    <p><strong>Téléphone mobile :</strong> <FaPhone className="text-green-500 mr-1 inline-block" /> {step2Data.telephone_mobile}</p>
                    <p><strong>Adresse e-mail :</strong> <FaEnvelope className="text-red-500 mr-1 inline-block" /> {step2Data.adresse_email}</p>
                    <p><strong>Adresse :</strong> <FaMapMarkerAlt className="text-yellow-500 mr-1 inline-block" /> {step2Data.adresse}</p>
                    <p><strong>Vérification d'identité :</strong> <FaCheck className="text-green-500 inline-block" /></p>
                </motion.div>

                {/* Card 2: Informations sur le souscripteur */}
                <motion.div 
                    className="bg-white p-6 rounded-lg shadow-md border transition transform hover:scale-105 hover:shadow-lg"
                    whileHover={{ scale: 1.02 }}
                >
                    <h3 className="text-xl font-semibold text-gray-700 mb-4 flex items-center">
                        <FaUser className="text-purple-500 mr-2" /> Informations sur le souscripteur
                    </h3>
                    <p><strong>Genre :</strong> {step3Data.genre}</p>
                    <p><strong>Nom :</strong> {step3Data.nom}</p>
                    <p><strong>Prénom :</strong> {step3Data.prenom}</p>
                    <p><strong>Activité :</strong> {step3Data.activite}</p>
                    <p><strong>Téléphone mobile :</strong> <FaPhone className="text-green-500 mr-1 inline-block" /> {step3Data.telephone_mobile}</p>
                    <p><strong>Adresse e-mail :</strong> <FaEnvelope className="text-red-500 mr-1 inline-block" /> {step3Data.adresse_email}</p>
                    <p><strong>Adresse :</strong> <FaMapMarkerAlt className="text-yellow-500 mr-1 inline-block" /> {step3Data.adresse}</p>
                    <p><strong>Vérification d'identité :</strong> <FaCheck className="text-green-500 inline-block" /></p>
                </motion.div>

                {/* Card 3: Informations sur le véhicule */}
                <motion.div 
                    className="bg-white p-6 rounded-lg shadow-md border transition transform hover:scale-105 hover:shadow-lg"
                    whileHover={{ scale: 1.02 }}
                >
                    <h3 className="text-xl font-semibold text-gray-700 mb-4 flex items-center">
                        <FaCar className="text-indigo-500 mr-2" /> Informations sur le véhicule
                    </h3>
                    <p><strong>Catégorie :</strong> {step1Data.categorie}</p>
                    <p><strong>Sous-catégorie :</strong> {step1Data.sousCategorie}</p>
                    <p><strong>Matricule :</strong> {step1Data.matricule}</p>
                    <p><strong>Énergie :</strong> {step1Data.energie}</p>
                    <p><strong>Valeur à neuf :</strong> {step1Data.valeur_a_neuf} FCFA</p>
                    <p><strong>Valeur vénale :</strong> {step1Data.valeur_venal} FCFA</p>
                    <p><strong>Marque du véhicule :</strong> {step1Data.marque_du_vehicule}</p>
                    <p><strong>Modèle du véhicule :</strong> {step1Data.modele_du_vehicule}</p>
                    <p><strong>Puissance fiscale :</strong> {step1Data.puissance_fiscal} CV</p>
                    <p><strong>Nombre de places :</strong> {step1Data.nombre_de_place}</p>
                </motion.div>

                {/* Card 4: Informations sur le contrat */}
                <motion.div 
                    className="bg-white p-6 rounded-lg shadow-md border transition transform hover:scale-105 hover:shadow-lg"
                    whileHover={{ scale: 1.02 }}
                >
                    <h3 className="text-xl font-semibold text-gray-700 mb-4 flex items-center">
                        <FaFileContract className="text-indigo-500 mr-2" /> Informations sur le contrat
                    </h3>
                    <p><strong>Durée du contrat :</strong> {step1Data.duree_du_contrat} mois</p>
                    <p><strong>Date d'effet :</strong> <FaCalendarAlt className="text-blue-500 mr-1 inline-block" /> {step1Data.date_effet}</p>
                    <p><strong>Date de fin :</strong> <FaCalendarAlt className="text-blue-500 mr-1 inline-block" /> {step1Data.date_fin}</p>
                    <p><strong>Garanties souscrites :</strong> {step1Data.garanties && step1Data.garanties.join(', ')}</p>
                    <p><strong>Prime nette :</strong> <FaMoneyBillWave className="text-green-500 mr-1 inline-block" /> {step1Data.prime_nette} FCFA</p>
                    <p><strong>Accessoires :</strong> {step1Data.accessoire} FCFA</p>
                    <p><strong>FGA :</strong> {step1Data.FGA} FCFA</p>
                    <p><strong>Taxes :</strong> {step1Data.taxes} FCFA</p>
                    <p><strong>Prime TTC :</strong> {step1Data.prime_TTC} FCFA</p>
                    <p><strong>Commission :</strong> {step1Data.commission} FCFA</p>
                    <p><strong>Montant total :</strong> <FaMoneyBillWave className="text-green-500 mr-1 inline-block" /> {step1Data.montant_total} FCFA</p>
                </motion.div>
            </div>

            {/* Boutons de navigation */}
            <div className="flex justify-between mt-8">
                <button 
                    onClick={onPrev} 
                    className="flex items-center space-x-3 bg-blue-100 text-blue-500 py-2 px-4 rounded transform transition duration-300 hover:scale-110 hover:shadow-md"
                >
                    <IoIosArrowDropleftCircle size={20} />
                    <span>Précédent</span>
                </button>
                <button 
                    onClick={()=>Devis()} 
                    className="flex space-x-3 items-center bg-blue-500 text-white py-2 px-4 rounded transform transition duration-300 hover:scale-110 hover:shadow-md"
                >
                    <span>{isLastStep ? 'Enregistrer' : 'Suivant'}</span>
                </button>
            </div>
        </motion.div>
    );
};

export default FormStep4;

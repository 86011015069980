import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoNotificationsOutline } from "react-icons/io5";
import { FaRegMessage } from "react-icons/fa6";
import { FiChevronDown } from "react-icons/fi";
import profile from '/src/images/default.png';
import { useProfile } from '../../UserDataStock/Profils/Profile';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../../UserDataStock/slice/UserSlice';
import { succesNotification } from '../../components/notifications/Notifications';

const HomeNavbar = ({ isOpen }) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [avatar, setAvatar] = useState('');
  const { position, token, userDetails } = useProfile();

  useEffect(() => {
    if (userDetails?.image) {
      setAvatar(userDetails.image);
    } else {
      setAvatar(profile);
    }
  }, [userDetails?.image]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  

  const handleLogout = () => {
    dispatch(clearUser());
    navigate('/');
    succesNotification("Vous vous êtes déconnecté avec succès");
  };

  return (
    <nav className={`fixed top-0 z-50 transition-all duration-300 ${isOpen ? 'left-64' : 'left-20'} right-0`}>
      <div className="bg-white/80 backdrop-blur-md shadow-lg">
        <div className="mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0">
              <h1 className="text-xl font-bold text-purple-700">YESHA</h1>
            </div>

            {/* Navigation Items */}
            <div className="flex items-center space-x-8">
              {/* Notifications */}
              <div className="relative">
                <motion.button
                  whileHover={{ scale: 1.1, backgroundColor: "#f3f4f6" }}
                  whileTap={{ scale: 0.95 }}
                  className="relative p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  onClick={() => {
                    setShowNotifications(!showNotifications);
                    setShowMessages(false);
                    setShowProfileMenu(false);
                  }}
                >
                  <IoNotificationsOutline className="w-6 h-6 text-gray-600" />
                  <span className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full"></span>
                </motion.button>
                
                <AnimatePresence>
                  {showNotifications && (
                    <motion.div
                      initial={{ opacity: 0, y: 10, scale: 0.95 }}
                      animate={{ opacity: 1, y: 0, scale: 1 }}
                      exit={{ opacity: 0, y: 10, scale: 0.95 }}
                      transition={{ duration: 0.2, ease: "easeOut" }}
                      className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-xl py-2 border border-gray-100"
                    >
                      <div className="px-4 py-2 font-semibold border-b">Notifications</div>
                      <div className="max-h-64 overflow-y-auto scrollbar-thin scrollbar-thumb-purple-500 scrollbar-track-purple-100">
                        <div className="px-4 py-2 hover:bg-gray-50 transition-colors duration-200">
                          <p className="text-sm text-gray-600">Aucune notification</p>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* Messages */}
              <div className="relative">
                <motion.button
                  whileHover={{ scale: 1.1, backgroundColor: "#f3f4f6" }}
                  whileTap={{ scale: 0.95 }}
                  className="relative p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  onClick={() => {
                    setShowMessages(!showMessages);
                    setShowNotifications(false);
                    setShowProfileMenu(false);
                  }}
                >
                  <FaRegMessage className="w-5 h-5 text-gray-600" />
                  <span className="absolute top-1 right-1 w-2 h-2 bg-green-500 rounded-full"></span>
                </motion.button>

                <AnimatePresence>
                  {showMessages && (
                    <motion.div
                      initial={{ opacity: 0, y: 10, scale: 0.95 }}
                      animate={{ opacity: 1, y: 0, scale: 1 }}
                      exit={{ opacity: 0, y: 10, scale: 0.95 }}
                      transition={{ duration: 0.2, ease: "easeOut" }}
                      className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-xl py-2 border border-gray-100"
                    >
                      <div className="px-4 py-2 font-semibold border-b">Messages</div>
                      <div className="max-h-64 overflow-y-auto scrollbar-thin scrollbar-thumb-purple-500 scrollbar-track-purple-100">
                        <div className="px-4 py-2 hover:bg-gray-50 transition-colors duration-200">
                          <p className="text-sm text-gray-600">Aucun message</p>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* Profile Menu */}
              <div className="relative">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    setShowProfileMenu(!showProfileMenu);
                    setShowNotifications(false);
                    setShowMessages(false);
                  }}
                  className="flex items-center space-x-3 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                >
                  <img 
                    src={avatar} 
                    alt="Profile"
                    className="w-8 h-8 rounded-full object-cover border-2 border-purple-500"
                  />
                  <FiChevronDown 
                    className={`w-4 h-4 text-gray-600 transition-transform duration-200 ${
                      showProfileMenu ? 'rotate-180' : ''
                    }`} 
                  />
                </motion.button>

                <AnimatePresence>
                  {showProfileMenu && (
                    <motion.div
                      initial={{ opacity: 0, y: 10, scale: 0.95 }}
                      animate={{ opacity: 1, y: 0, scale: 1 }}
                      exit={{ opacity: 0, y: 10, scale: 0.95 }}
                      transition={{ duration: 0.2, ease: "easeOut" }}
                      className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-xl py-2 border border-gray-100"
                    >
                      <motion.button 
                        whileHover={{ backgroundColor: "#faf5ff" }}
                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-purple-700"
                      >
                        Statistiques
                      </motion.button>
                      <motion.button 
                        whileHover={{ backgroundColor: "#faf5ff" }}
                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-purple-700"
                      >
                        Mon Compte
                      </motion.button>
                      <hr className="my-1" />
                      <motion.button 
                        whileHover={{ backgroundColor: "#fee2e2" }}
                        onClick={handleLogout}
                        className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50"
                      >
                        Déconnexion
                      </motion.button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HomeNavbar;
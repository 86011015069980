import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { FaCar , FaCalendarDay } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { AiOutlineApartment } from "react-icons/ai";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { TbNumber123 } from "react-icons/tb";
import { MdLocalGasStation } from "react-icons/md";
import { GrCertificate } from "react-icons/gr";
import { BsHourglassSplit, BsPatchCheckFill } from "react-icons/bs";
import { RiShieldCheckFill } from "react-icons/ri";
import loadpic from "/src/images/loader.json";
import { motion } from 'framer-motion';
import loader from "/src/images/loader.json";
import { IoIosArrowDroprightCircle, IoIosFlash } from "react-icons/io";
import { MdOutlineAirlineSeatReclineNormal } from "react-icons/md";
import moment from 'moment';
import { GetAllAssureur } from '../../../api/ressources/CompagniesAssurances';
import { getTarification } from '../../../api/automobiles/TarifsAndFormules';
import Lottie from 'lottie-react';
import FormatMontant from '../../../utils/FormatMontant';
import { ContratFormValidation } from '../../../utils/FormValidation';
import { useProfile } from '../../../UserDataStock/Profils/Profile';

const FormStep1 = ({onNext,data}) => {

  const [today, setToday] = useState(moment().format('YYYY-MM-DD'));
  const [selectedInsurer, setSelectedInsurer] = useState(null);
  const [insurers, setInsurers] = useState([]);
  const [waiting, setWaiting] = useState(true);
  const [loading, setLoading] = useState(false);
  const [tarification, setTarification] = useState([]);
  const [selectedTarification, setSelectedTarification] = useState(null);
  const {position, token, userDetails } = useProfile();


  const toggleSelection = (insurer) => {
    if (selectedInsurer === insurer) {
      setSelectedInsurer(null);
      setSelectedTarification(null);
      formik.setValues({
        ...formik.values,
        assureur_id: "",
        prime_nette: 0,
        accessoire: 0,
        FGA: 0,
        taxes: 0,
        carte_brun: 0,
        prime_TTC: 0,
        commission: 0,
        montant_total: 0
      });
    } else {
      const selectedTarif = tarification.find(t => t.id === insurer.id);
  
      setSelectedInsurer(insurer);
      setSelectedTarification(selectedTarif);
  
      formik.setValues({
        ...formik.values,
        assureur_id: insurer.id,
        prime_nette: selectedTarif.tarifs.primeNette,
        accessoire: selectedTarif.tarifs.accessoire,
        FGA: selectedTarif.tarifs.FGA,
        taxes: selectedTarif.tarifs.taxes,
        carte_brun: selectedTarif.tarifs.carteBrune,
        prime_TTC: selectedTarif.tarifs.primeTTC,
        commission: selectedTarif.tarifs.fraisDeService,
        montant_total: selectedTarif.tarifs.montantTotal
      });
    }
  };



  async function Assureurs() {
    try {
      let response = await GetAllAssureur(token, position);
      setInsurers(response);
      setWaiting(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function getTarif(data) { 
    setLoading(true);

    var donnes={
          puissance: data?.puissance_fiscal,
          duree: data?.duree_du_contrat,
          nbPlaces: data?.nombre_de_place,
          garanties: data?.garanties,
    }
    
    try {
      const result = await getTarification(donnes, token, position);
      
      if (result.status === 200 && result.data && result.data.length > 0) { 
        setTarification(result.data);
      } else {
        setTarification([]);  // Réinitialiser les tarifications si la réponse est vide ou null
        setSelectedTarification(null); // Réinitialiser la sélection si la réponse est vide ou null
      }
    } catch (error) {
      console.log(error);
      setTarification([]); // Réinitialiser les tarifications en cas d'erreur
      setSelectedTarification(null); // Réinitialiser la sélection en cas d'erreur
    } finally {
      setLoading(false);
    }
  }



  useEffect(() => { 
    Assureurs();
  },[]);



  function refactor(value) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  const handleMoneyChange = (e) => {
    const formattedValue = refactor(e.target.value.replace(/\./g, ''));
    formik.setFieldValue(e.target.name, formattedValue);
  };


  const formik = useFormik({
    initialValues: { 
      categorie: "", 
      sousCategorie: "",
      matricule: "SN-458-DK",
      energie: "",
      valeur_a_neuf: "",
      valeur_venal: "",
      marque_du_vehicule: "",
      modele_du_vehicule: "",
      puissance_fiscal: "",
      nombre_de_place: "",
      duree_du_contrat: "",
      date_effet: "",
      date_fin: "",
      garanties: ["RC"],
      assureur_id: "", 
      prime_nette: 0, 
      accessoire: 0, 
      FGA: 0, 
      taxes: 0, 
      carte_brun: 0, 
      prime_TTC: 0, 
      commission: 0, 
      montant_total: 0 
    },
    validate: ContratFormValidation,
    onSubmit: (values) => {
      const savedData = JSON.parse(sessionStorage.getItem('formData')) || {};
      savedData.step1Data = values;
      sessionStorage.setItem('formData', JSON.stringify(savedData));
      onNext(values); 
    }
  });



  // a revoir le code 
  // useEffect(() => {
  //   if (data) {
  //     formik.setValues({ ...data, garanties: data.garanties || [] });
  //   }
  // }, [data]);


  useEffect(() => {
    const { puissance_fiscal, duree_du_contrat, nombre_de_place, garanties } = formik.values;
    if (puissance_fiscal && duree_du_contrat && nombre_de_place && garanties.length > 0) {
      const data = {
        puissance_fiscal,
        duree_du_contrat,
        nombre_de_place,
        garanties
      };
      getTarif(data);
    }
  }, [formik.values.puissance_fiscal, formik.values.duree_du_contrat, formik.values.nombre_de_place, formik.values.garanties]);


  useEffect(() => {
    if (selectedInsurer && tarification.length > 0) {
      const newTarification = tarification.find(t => t.id === selectedInsurer.id);
      setSelectedTarification(newTarification);
    }
  }, [tarification, selectedInsurer]);

  const garantiesOptions = [
    { id: '1', label: 'RC (Responsabilité civile)', disabled: true, init: "RC"},
    { id: '4', label: 'PT (Personnes transportées )', init: "PT"},
    { id: '2', label: 'DR (Défense et Recours)', init: "DR"},
    { id: '5', label: 'BDG (Bris de glace )', init: "BDG"},
    { id: '7', label: 'VOL', init: "VOL"},
    { id: '8', label: 'INC (Incendie)', init: "INC"}
    
];
  const categoriesOptions = [
    { id: '1', label: 'CAT 1 (Promenade et Affaire)' },
    { id: '2', label: 'CAT 2 (TPC)' },
    { id: '3', label: 'CAT 3 (TPM)' },
    { id: '4', label: 'CAT 4 (TPV)' },
    { id: '5', label: 'CAT 6 (Garagiste)' },
    { id: '6', label: 'CAT 7 (Vehicule Auto-Ecole)' },
    { id: '7', label: 'CAT 8 (Location sans Chauffeur)' },
    { id: '8', label: 'Flotte TPV' }
  ];
  const sousCategoriesOptions = [
    { id: '1', label: 'Vehicule de Tourisme double commande' },
    { id: '2', label: 'Vehicule de Tourisme sans double commande' },
    { id: '3', label: 'Vehicule utilitaire double commande' },
    { id: '4', label: 'Vehicule utilitaire sans double commande' },
  ];
  const energiesOptions = [
    { id: '1', label: 'Essence' },
    { id: '2', label: 'Diesel' },
    { id: '3', label: 'Électrique' },
    { id: '4', label: 'Hybride' }
  ];

  const marquesOptions = [
    { id: '1', label: 'Toyota' },
    { id: '2', label: 'BMW' },
    { id: '3', label: 'Mercedes' },
    { id: '4', label: 'Ford' },
    { id: '5', label: 'Audi' }
  ];


  const puissancesFiscalesOptions = [
    { id: '1', label: '4 CV', nbr: 4 },
    { id: '2', label: '6 CV', nbr: 6},
    { id: '3', label: '8 CV', nbr: 8 },
    { id: '4', label: '12 CV', nbr: 12},
    { id: '4', label: '14 CV', nbr: 14},
    { id: '4', label: '16 CV', nbr: 16},
    { id: '4', label: '18 CV', nbr: 18},
    { id: '4', label: '20 CV', nbr: 20}
  ];
  
  const nombreDePlacesOptions = [
    { id: '2', label: '2 places' },
    { id: '4', label: '4 places' },
    { id: '5', label: '5 places' },
    { id: '7', label: '8 places' }
  ];
  
  

  // Utiliser useEffect pour mettre à jour la date_fin lorsque duree_du_contrat ou date_effet changent
  useEffect(() => {
    if (formik.values.date_effet && formik.values.duree_du_contrat) {
      const dateEffet = moment(formik.values.date_effet, 'YYYY-MM-DD');
      const duree = parseInt(formik.values.duree_du_contrat, 10);
  
      // Ajouter la durée à la date d'effet
      let dateFin = dateEffet.add(duree, 'months');
      // Soustraire un jour de la date de fin
      dateFin = dateFin.subtract(1, 'days').format('YYYY-MM-DD');
      formik.setFieldValue('date_fin', dateFin);
    }
  }, [formik.values.date_effet, formik.values.duree_du_contrat]);

  

  return (
    <div className='bg-white p-8 rounded-xl border shadow-md'>
      <h2 className="text-2xl mb-4 font-semibold text-purple-700"> 1. Contrat</h2>
      <div className='flex space-x-16'>
        {/* le formulaire */}
          <form onSubmit={formik.handleSubmit} method='post' className='w-full'>
            
              <div className="grid grid-cols-8 gap-4 py-2 row-auto">
                <div className="col-span-8">
                  <div className="flex justify-center items-center space-x-4 text-purple-700 py-2">
                    <FaCar size={22} />
                    <span className="text-2xl font-semibold">Les Informations du Vehicule</span>
                  </div>
                </div>

                <div className="col-span-4">
                  <label htmlFor="categories" className="block text-gray-700 font-semibold mb-1 pl-2">Categories</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <BiCategory className="w-5 h-5 text-gray-400" />
                    </span>
                    <select
                      autoComplete="off"
                      id="categorie"
                      name="categorie"
                      onChange={formik.handleChange}
                      value={formik.values.categorie}
                      onBlur={formik.handleBlur}
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.categorie && formik.touched.categorie ? 'border-red-500' : ''}`}
                    >
                      <option value="" label="Sélectionner la catégorie du véhicule" />
                      {categoriesOptions.map((option) => (
                        <option key={option.id} value={option.label}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="h-6">
                    {formik.touched.categorie && formik.errors.categorie && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.categorie}
                      </motion.div>
                    )}
                  </div>
                </div>

                <div className="col-span-4">
                  <label htmlFor="sousCategorie" className="block text-gray-700 font-semibold mb-1 pl-2">Sous-Categories</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <AiOutlineApartment className="w-5 h-5 text-gray-400" />
                    </span>
                    <select
                      autoComplete="off"
                      id="sousCategorie"
                      name="sousCategorie"
                      onChange={formik.handleChange}
                      value={formik.values.sousCategorie}
                      onBlur={formik.handleBlur}
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.sousCategorie && formik.touched.sousCategorie ? 'border-red-500' : ''}`}
                    >
                      <option value="" label="Sélectionner une sous-categorie du véhicule" />
                      {sousCategoriesOptions.map((option) => (
                        <option key={option.id} value={option.label}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="h-6">
                    {formik.touched.sousCategorie && formik.errors.sousCategorie && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.sousCategorie}
                      </motion.div>
                    )}
                  </div>
                </div>

                <div className="col-span-4">
                  <label htmlFor="matricule" className="block text-gray-700 font-semibold mb-1 pl-2">Matricule</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <TbNumber123 className="w-5 h-5 text-gray-400" />
                    </span>
                    <input
                      disabled={true}
                      autoComplete="off"
                      id="matricule"
                      name="matricule"
                      onChange={formik.handleChange}
                      value={formik.values.matricule}
                      onBlur={formik.handleBlur}
                      type="text"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.matricule && formik.touched.matricule ? 'border-red-500' : ''}`}
                    />
                  </div>
                  <div className="h-6">
                    {formik.touched.matricule && formik.errors.matricule && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.matricule}
                      </motion.div>
                    )}
                  </div>
                </div>

                <div className="col-span-4">
                  <label htmlFor="energie" className="block text-gray-700 font-semibold mb-1 pl-2">Energies</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <MdLocalGasStation className="w-5 h-5 text-gray-400" />
                    </span>
                    <select
                      autoComplete="off"
                      id="energie"
                      name="energie"
                      onChange={formik.handleChange}
                      value={formik.values.energie}
                      onBlur={formik.handleBlur}
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.energie && formik.touched.energie ? 'border-red-500' : ''}`}
                    >
                      <option value="" label="Sélectionner le type de consommation du véhicule" />
                      {energiesOptions.map((option) => (
                        <option key={option.id} value={option.label}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="h-6">
                    {formik.touched.energie && formik.errors.energie && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.energie}
                      </motion.div>
                    )}
                  </div>
                </div>

                <div className="col-span-4">
                  <label htmlFor="valeur_a_neuf" className="block text-gray-700 font-semibold mb-1 pl-2">Valeur à neuf</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <HiOutlineCurrencyDollar className="w-5 h-5 text-gray-400" />
                    </span>
                    <input
                      autoComplete="off"
                      id="valeur_a_neuf"
                      name="valeur_a_neuf"
                      onChange={handleMoneyChange}
                      value={formik.values.valeur_a_neuf}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder="Saisissez le montant"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.valeur_a_neuf && formik.touched.valeur_a_neuf ? 'border-red-500' : ''}`}
                    />
                  </div>
                  <div className="h-6">
                    {formik.touched.valeur_a_neuf && formik.errors.valeur_a_neuf && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.valeur_a_neuf}
                      </motion.div>
                    )}
                  </div>
                </div>

                <div className="col-span-4">
                  <label htmlFor="valeur_venal" className="block text-gray-700 font-semibold mb-1 pl-2">Valeur vénal</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <HiOutlineCurrencyDollar className="w-5 h-5 text-gray-400" />
                    </span>
                    <input
                      autoComplete="off"
                      id="valeur_venal"
                      name="valeur_venal"
                      onChange={handleMoneyChange}
                      value={formik.values.valeur_venal}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder="Saisissez le montant"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.valeur_venal && formik.touched.valeur_venal ? 'border-red-500' : ''}`}
                    />
                  </div>
                  <div className="h-6">
                    {formik.touched.valeur_venal && formik.errors.valeur_venal && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.valeur_venal}
                      </motion.div>
                    )}
                  </div>
                </div>

                <div className="col-span-4">
                  <label htmlFor="marque_du_vehicule" className="block text-gray-700 font-semibold mb-1 pl-2">Marque du véhicule</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <FaCar className="w-5 h-5 text-gray-400" />
                    </span>
                    <select
                      autoComplete="off"
                      id="marque_du_vehicule"
                      name="marque_du_vehicule"
                      onChange={formik.handleChange}
                      value={formik.values.marque_du_vehicule}
                      onBlur={formik.handleBlur}
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.marque_du_vehicule && formik.touched.marque_du_vehicule ? 'border-red-500' : ''}`}
                    >
                      <option value="" label="Sélectionner la marque du véhicule" />
                      {marquesOptions.map((option) => (
                        <option key={option.id} value={option.label}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="h-6">
                    {formik.touched.marque_du_vehicule && formik.errors.marque_du_vehicule && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.marque_du_vehicule}
                      </motion.div>
                    )}
                  </div>
                </div>

                <div className="col-span-4">
                  <label htmlFor="modele_du_vehicule" className="block text-gray-700 font-semibold mb-1 pl-2">Modèle du véhicule</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <FaCar className="w-5 h-5 text-gray-400" />
                    </span>
                    <input
                      autoComplete="off"
                      id="modele_du_vehicule"
                      name="modele_du_vehicule"
                      onChange={formik.handleChange}
                      value={formik.values.modele_du_vehicule}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder="Saisissez le modèle du véhicule"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.modele_du_vehicule && formik.touched.modele_du_vehicule ? 'border-red-500' : ''}`}
                    />
                  </div>
                  <div className="h-6">
                    {formik.touched.modele_du_vehicule && formik.errors.modele_du_vehicule && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.modele_du_vehicule}
                      </motion.div>
                    )}
                  </div>
                </div>

                <div className="col-span-4">
                  <label htmlFor="puissance_fiscal" className="block text-gray-700 font-semibold mb-1 pl-2">Puissance fiscale</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <IoIosFlash className="w-5 h-5 text-gray-400" />
                    </span>
                    <select
                      autoComplete="off"
                      id="puissance_fiscal"
                      name="puissance_fiscal"
                      onChange={formik.handleChange}
                      value={formik.values.puissance_fiscal}
                      onBlur={formik.handleBlur}
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.puissance_fiscal && formik.touched.puissance_fiscal ? 'border-red-500' : ''}`}
                    >
                      <option value="" label="Sélectionner le nombre de chevaux du véhicule" />
                      {puissancesFiscalesOptions.map((option, index) => (
                        <option key={`${option.id}-${index}`} value={option.nbr}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="h-6">
                    {formik.touched.puissance_fiscal && formik.errors.puissance_fiscal && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.puissance_fiscal}
                      </motion.div>
                    )}
                  </div>
                </div>

                <div className="col-span-4">
                  <label htmlFor="nombre_de_place" className="block text-gray-700 font-semibold mb-1 pl-2">Nombre de places</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <MdOutlineAirlineSeatReclineNormal className="w-5 h-5 text-gray-400" />
                    </span>
                    <select
                      autoComplete="off"
                      id="nombre_de_place"
                      name="nombre_de_place"
                      onChange={formik.handleChange}
                      value={formik.values.nombre_de_place}
                      onBlur={formik.handleBlur}
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.nombre_de_place && formik.touched.nombre_de_place ? 'border-red-500' : ''}`}
                    >
                      <option value="" label="Sélectionner le nombre de places du véhicule" />
                      {nombreDePlacesOptions.map((option) => (
                        <option key={option.id} value={option.id}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="h-6">
                    {formik.touched.nombre_de_place && formik.errors.nombre_de_place && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.nombre_de_place}
                      </motion.div>
                    )}
                  </div>
                </div>

                <div className="col-span-8">
                  <div className="flex justify-center items-center space-x-4 text-purple-700 py-2">
                    <GrCertificate size={22} />
                    <span className="text-2xl font-semibold">Les Informations du Contrat</span>
                  </div>
                </div>

                <div className="col-span-8">
                  <label htmlFor="duree_du_contrat" className="block text-gray-700 font-semibold mb-1 pl-2">Durée du contrat (en mois)</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <BsHourglassSplit className="w-5 h-5 text-gray-400" />
                    </span>
                    <select
                      autoComplete="off"
                      id="duree_du_contrat"
                      name="duree_du_contrat"
                      onChange={formik.handleChange}
                      value={formik.values.duree_du_contrat}
                      onBlur={formik.handleBlur}
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.duree_du_contrat && formik.touched.duree_du_contrat ? 'border-red-500' : ''}`}
                      >
                        <option value="" label="Sélectionner la durée" />
                        <option value="1" label="1 mois" />
                        <option value="3" label="3 mois" />
                        <option value="6" label="6 mois" />
                        <option value="12" label="12 mois" />
                      </select>
                    </div>
                    <div className="h-6">
                      {formik.touched.duree_du_contrat && formik.errors.duree_du_contrat && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className="italic text-md text-red-500"
                        >
                          {formik.errors.duree_du_contrat}
                        </motion.div>
                      )}
                    </div>
                  </div>
                
                  <div className="col-span-4">
                    <label htmlFor="date_effet" className="block text-gray-700 font-semibold mb-1 pl-2">Date d’effet</label>
                    <div className="relative">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FaCalendarDay className="w-5 h-5 text-gray-400" />
                      </span>
                      <input
                        autoComplete="off"
                        id="date_effet"
                        name="date_effet"
                        onChange={formik.handleChange}
                        value={formik.values.date_effet || ''}
                        onBlur={formik.handleBlur}
                        type="date"
                        min={today}
                        onClick={(e) => e.target.showPicker()}
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.date_effet && formik.touched.date_effet ? 'border-red-500' : ''}`}
                      />
                    </div>
                    <div className="h-6">
                      {formik.touched.date_effet && formik.errors.date_effet && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className="italic text-md text-red-500"
                        >
                          {formik.errors.date_effet}
                        </motion.div>
                      )}
                    </div>
                  </div>
                
                  <div className="col-span-4">
                    <label htmlFor="date_fin" className="block text-gray-700 font-semibold mb-1 pl-2">Date d’échéance</label>
                    <div className="relative">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FaCalendarDay className="w-5 h-5 text-gray-400" />
                      </span>
                      <input
                        autoComplete="off"
                        id="date_fin"
                        name="date_fin"
                        value={formik.values.date_fin}
                        readOnly
                        type="date"
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.date_fin && formik.touched.date_fin ? 'border-red-500' : ''}`}
                      />
                    </div>
                    <div className="h-6">
                      {formik.touched.date_fin && formik.errors.date_fin && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className="italic text-md text-red-500"
                        >
                          {formik.errors.date_fin}
                        </motion.div>
                      )}
                    </div>
                  </div>
                
                  <div className="col-span-8">
                    <div className="flex justify-center items-center space-x-36 py-5">
                      <div className="flex flex-col justify-center items-center">
                        <span className="text-teal-600 font-semibold text-xl">Les Garanties</span>
                        <RiShieldCheckFill className="w-28 h-28 text-teal-600" />
                      </div>
                      <div>
                        {garantiesOptions.map((option) => (
                          <div key={option.id} className="flex items-center mt-4 font-semibold">
                            <input
                              id={option.id}
                              name="garanties"
                              type="checkbox"
                              value={option.init}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              checked={Array.isArray(formik.values.garanties) && formik.values.garanties.includes(option.init)}
                              disabled={option.disabled}
                              className={`mr-2 w-6 h-6 border cursor-pointer border-gray-300 rounded text-blue-600 bg-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.garanties && formik.touched.garanties ? 'border-red-500' : ''}`}
                            />
                            <label htmlFor={option.id} className="text-teal-700">{option.label}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="h-6">
                      {formik.touched.garanties && formik.errors.garanties && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className="italic text-md text-red-500"
                        >
                          {formik.errors.garanties}
                        </motion.div>
                      )}
                    </div>
                  </div>
              </div>
    
              <button 
                type="submit" 
                className={`flex space-x-3 items-center bg-blue-500 text-white py-2 px-4 rounded transform transition duration-300 hover:scale-110 ${(!formik.isValid || !selectedInsurer) ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={!formik.isValid || !selectedInsurer}
              >
                <span>Suivant</span>
                <IoIosArrowDroprightCircle size={20} />
              </button>

          </form>

        {/* la vue des informations en foncttion de l'assureur choisie  */}
        <div className='w-4/6 mt-24 p-2 h-screen border-[0.5px] rounded-2xl shadow-sm sticky top-0 overflow-y-auto'>
          <div className='container mx-auto p-4'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
              {waiting ? (
                <div className='flex justify-center items-center ml-36 h-full w-full'>
                  <Lottie 
                    animationData={loader}
                    loop={true}
                    autoplay={true}
                  />
                </div>
              ) : (
                <>
                  {insurers.map((insurer) => (
                    <div
                      key={insurer.id}
                      className={`relative border rounded-lg p-4 shadow-lg cursor-pointer ${selectedInsurer === insurer ? 'border-blue-500' : ''}`}
                      onClick={() => toggleSelection(insurer)}
                    >
                      {selectedInsurer === insurer && (
                        <BsPatchCheckFill className='absolute top-2 right-2 text-blue-500' />
                      )}
                      <div className="flex justify-center items-center mb-2 ">
                        <img
                          src={insurer.logo}
                          alt="logo"
                          className='w-20 h-20 rounded border-[1.5px] p-1 shadow-md'
                        />
                      </div>
                      <div className='text-center mt-1 font-AbrilFateface text-teal-600'>
                        Montant:
                      </div>
                      <div className='text-center font-semibold text-lg font-AbrilFateface text-teal-600'>
                        <FormatMontant montant={
                          tarification.find(t => t.id === insurer.id)?.tarifs.montantTotal || 0
                        } /> 
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>

            <div className='flex-col border rounded-lg md:w-2/3 lg:w-2/3 border-teal-600 p-4 h-auto mt-5 mx-auto'>
                <h2 className='text-lg text-center mb-4 font-semibold text-teal-600'>
                  Décomposition de la prime de: 
                </h2>
                <div className='text-center text-xl text-teal-600 font-bold mb-4'>
                  {selectedInsurer?.libelle || 'Sélectionner un Assureur'}
                </div>
                <hr className='w-full h-2' />
                <div className='space-y-3 mt-4'>
                  <div className='flex justify-between items-center'>
                    <div className='font-semibold text-teal-600'>Prime nette :</div>
                    <div>
                      <FormatMontant montant={selectedTarification ? selectedTarification.tarifs.primeNette : 0} />
                    </div>
                  </div>
                  <div className='flex justify-between items-center'>
                    <div className='font-semibold text-teal-600'>Accessoire :</div>
                    <div>
                      <FormatMontant montant={selectedTarification ? selectedTarification.tarifs.accessoire : 0} />
                    </div>
                  </div>
                  <div className='flex justify-between items-center'>
                    <div className='font-semibold text-teal-600'>FGA :</div>
                    <div>
                      <FormatMontant montant={selectedTarification ? selectedTarification.tarifs.FGA : 0} />
                    </div>
                  </div>
                  <div className='flex justify-between items-center'>
                    <div className='font-semibold text-teal-600'>Taxes :</div>
                    <div>
                      <FormatMontant montant={selectedTarification ? selectedTarification.tarifs.taxes : 0} />
                    </div>
                  </div>
                  <div className='flex justify-between items-center'>
                    <div className='font-semibold text-teal-600'>Carte brune :</div>
                    <div>
                      <FormatMontant montant={selectedTarification ? selectedTarification.tarifs.carteBrune : 0} />
                    </div>
                  </div>
                </div>
                <hr className='w-full h-2' />
                <div className='space-y-3 mt-4'>
                  <div className='flex justify-between items-center'>
                    <div className='font-semibold text-teal-600'>Prime TTC :</div>
                    <div>
                      <FormatMontant montant={selectedTarification ? selectedTarification.tarifs.primeTTC : 0} />
                    </div>
                  </div>
                  <div className='flex justify-between items-center'>
                    <div className='font-semibold text-teal-600'>Commission :</div>
                    <div>
                      <FormatMontant montant={selectedTarification ? selectedTarification.tarifs.fraisDeService : 0} />
                    </div>
                  </div>
                </div>
                <hr className='w-full h-2' />
                <div className='space-y-3 mt-4'>
                  <div className='flex justify-between items-center'>
                    <div className='font-semibold text-teal-600'>TOTAL :</div>
                    <div>
                      <FormatMontant montant={selectedTarification ? selectedTarification.tarifs.montantTotal : 0} />
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default FormStep1

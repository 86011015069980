import React, { useState } from 'react';
import { motion } from 'framer-motion';
import HomeNavbar from '../../template/navbar/HomeNavbar';
import AssureurBanner from '../../template/banner/AssureurBanner';
import Sidebar from '../../template/sidebar/Sidebar';
import logopdf from "/src/images/pflogo.png"
import { NewPDFAttestationVoyage, NewPDFConditionVoyage } from '../../api/pdf/AssureurAttestation';
import { NewPDFFactureVoyage } from '../../api/pdf/DevisFacturePdf';
import { useParams } from 'react-router-dom';

const ContratVoyage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    attestation: false,
    condition: false,
    facture: false
  });
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const idContrat = useParams();

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  const openAttestationPdfInModal = () => {
    setLoadingStates(prev => ({ ...prev, attestation: true }));
    NewPDFAttestationVoyage(idContrat)
      .then((result) => {
        if (result.status === 200) {
          const url = URL.createObjectURL(result.data);
          setPdfUrl(url);
          setShowModal(true);
        } else {
          console.error("Erreur lors de la récupération du PDF");
        }
      })
      .catch((error) => {
        console.error("Erreur lors du chargement du PDF :", error);
      })
      .finally(() => {
        setLoadingStates(prev => ({ ...prev, attestation: false }));
      });
  };

  const openConditionPdfInModal = () => {
    setLoadingStates(prev => ({ ...prev, condition: true }));
    NewPDFConditionVoyage(idContrat)
      .then((result) => {
        if (result.status === 200) {
          const url = URL.createObjectURL(result.data);
          setPdfUrl(url);
          setShowModal(true);
        } else {
          console.error("Erreur lors de la récupération du PDF");
        }
      })
      .catch((error) => {
        console.error("Erreur lors du chargement du PDF :", error);
      })
      .finally(() => {
        setLoadingStates(prev => ({ ...prev, condition: false }));
      });
  };

  const openFacturePdfInModal = () => {
    setLoadingStates(prev => ({ ...prev, facture: true }));
    NewPDFFactureVoyage(idContrat)
      .then((result) => {
        if (result.status === 200) {
          const url = URL.createObjectURL(result.data);
          setPdfUrl(url);
          setShowModal(true);
        } else {
          console.error("Erreur lors de la récupération du PDF");
        }
      })
      .catch((error) => {
        console.error("Erreur lors du chargement du PDF :", error);
      })
      .finally(() => {
        setLoadingStates(prev => ({ ...prev, facture: false }));
      });
  };

  const closeModal = () => {
    setShowModal(false);
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      setPdfUrl(null);
    }
  };

  const Loader = () => (
    <div className="flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );

  return (
    <div className='flex h-screen'>
      <Sidebar isOpen={isOpen} onToggle={handleOpenClose} />
      <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
        <HomeNavbar isOpen={isOpen} />
        <main className="p-6 mt-16">
          <AssureurBanner />
          <div className="min-h-screen bg-gray-100 flex flex-col items-center py-8">
            <h1 className="text-2xl font-semibold text-gray-800 mb-8">
              Impression des livrables contrat #{idContrat.id}
            </h1>
            <div className="flex space-x-6 mt-10">
              {/* Carte Attestation */}
              <motion.div
                whileHover={{ scale: 1.05, boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)" }}
                className="w-56 bg-white rounded-lg shadow-md p-4 flex flex-col items-center hover:bg-gray-50 transition duration-200 cursor-pointer"
                onClick={openAttestationPdfInModal}
              >
                <div className="text-center flex flex-col items-center">
                  {loadingStates.attestation ? (
                    <Loader />
                  ) : (
                    <>
                      <img src={logopdf} alt="PDF icon" className="w-24 h-32 mb-3 mx-auto" />
                      <p className="text-gray-500 text-xs uppercase font-semibold">Attestations</p>
                    </>
                  )}
                </div>
                <p className="mt-2 text-gray-800 text-sm font-medium">{idContrat.id}</p>
              </motion.div>

              {/* Carte Conditions */}
              <motion.div
                whileHover={{ scale: 1.05, boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)" }}
                className="w-56 bg-white rounded-lg shadow-md p-4 flex flex-col items-center hover:bg-gray-50 transition duration-200 cursor-pointer"
                onClick={openConditionPdfInModal}
              >
                <div className="text-center flex flex-col items-center">
                  {loadingStates.condition ? (
                    <Loader />
                  ) : (
                    <>
                      <img src={logopdf} alt="PDF icon" className="w-24 h-32 mb-3 mx-auto" />
                      <p className="text-gray-500 text-xs uppercase font-semibold">Conditions particulières</p>
                    </>
                  )}
                </div>
                <p className="mt-2 text-gray-800 text-sm font-medium">{`${idContrat.id}/0`}</p>
              </motion.div>

              {/* Carte Facture */}
              <motion.div
                whileHover={{ scale: 1.05, boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)" }}
                className="w-56 bg-white rounded-lg shadow-md p-4 flex flex-col items-center hover:bg-gray-50 transition duration-200 cursor-pointer"
                onClick={openFacturePdfInModal}
              >
                <div className="text-center flex flex-col items-center">
                  {loadingStates.facture ? (
                    <Loader />
                  ) : (
                    <>
                      <img src={logopdf} alt="PDF icon" className="w-24 h-32 mb-3 mx-auto" />
                      <p className="text-gray-500 text-xs uppercase font-semibold">Factures</p>
                    </>
                  )}
                </div>
                <p className="mt-2 text-gray-800 text-sm font-medium">{idContrat.id}</p>
              </motion.div>
            </div>
          </div>
        </main>

        {/* Modal */}
        {showModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          >
            <div className="bg-white w-3/4 h-3/4 rounded-lg shadow-lg overflow-hidden">
              <div className="flex justify-between items-center p-4 border-b">
                <h2 className="text-xl font-semibold">Aperçu du PDF</h2>
                <button onClick={closeModal} className="text-red-500 font-bold">
                  <span className='p-3 hover:bg-red-500 hover:text-white rounded-md border-red-500 border'>
                    Fermer
                  </span>
                </button>
              </div>
              <div className="p-4 h-full">
                {pdfUrl ? (
                  <iframe src={pdfUrl} title="PDF Preview" className="w-full h-full" />
                ) : (
                  <p>Chargement du PDF...</p>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ContratVoyage;
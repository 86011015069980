export const Pays = { 
    sn:{
        regions:[
            {id: 1, nom: "Dakar"},
            {id: 2, nom: "Diourbel"},
            {id: 3, nom: "Fatick"},
            {id: 4, nom: "Kaffrine"},
            {id: 5, nom: "Kaolack"},
            {id: 6, nom: "Kédougou"},
            {id: 7, nom: "Kolda"},
            {id: 8, nom: "Louga"},
            {id: 9, nom: "Matam"},
            {id: 10, nom: "Saint-Louis"},
            {id: 11, nom: "Sédhiou"},
            {id: 12, nom: "Tambacounda"},
            {id: 13, nom: "Thiès"},
            {id: 14, nom: "Ziguinchor"},
        ]
    },
    ml:{
        regions: [
            {id: 1, nom: "Bamako"},
            {id: 2, nom: "Kayes"},
            {id: 3, nom: "Koulikoro"},
            {id: 4, nom: "Ségou"},
            {id: 5, nom: "Mopti"},
            {id: 6, nom: "Sikasso"},
            {id: 7, nom: "Tombouctou"},
            {id: 8, nom: "Gao"},
            {id: 9, nom: "Kidal"},
            {id: 10, nom: "Ménaka"},
            {id: 11, nom: "Taoudénit"}
        ]
    },
    gn:{
        regions: [
            {id: 1, nom: "Boké"},
            {id: 2, nom: "Kindia"},
            {id: 3, nom: "Labé"},
            {id: 4, nom: "Mamou"},
            {id: 5, nom: "Faranah"},
            {id: 6, nom: "Kankan"},
            {id: 7, nom: "Nzérékoré"},
            {id: 8, nom: "Kérouané"},
            {id: 9, nom: "Conakry"},
        ]
    },
    ci:{ 
        regions: [
            {id: 1, nom: "Abidjan"},
            {id: 2, nom: "Yamoussoukro"},
            {id: 3, nom: "Lacs"},
            {id: 4, nom: "Comoé"},
            {id: 5, nom: "Denguélé"},
            {id: 6, nom: "Gôh-Djiboua"},
            {id: 7, nom: "Lagunes"},
            {id: 8, nom: "Montagnes"},
            {id: 9, nom: "Sassandra"},
            {id: 10, nom: "Marahoué"},
            {id: 11, nom: "Savanes"},
            {id: 12, nom: "Bas-Sassandra"},
            {id: 13, nom: "Vallée du Bandama"},
            {id: 14, nom: "Woroba"},
            {id: 15, nom: "Zanzan"},
        ]
    },
}
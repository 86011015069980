import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react'
import FormatNumero from '../../../utils/FormatNumero';
import { FaRegFilePdf } from 'react-icons/fa';
import { NewPDFFactureVoyage } from '../../../api/pdf/DevisFacturePdf';

const VendeursFacturesDatatable = ({data , chargement}) => {

  const [isFirstMount, setIsFirstMount] = useState(true);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const Loader = () => (
    <div className="flex items-center justify-center">
      <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFirstMount(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const getRowClass = useCallback(() => {
    return isFirstMount ? 'fade-in' : '';
  }, [isFirstMount]);

  const closeModal = () => {
    setShowModal(false);
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      setPdfUrl(null);
    }
  };

  moment.locale('fr');
  const formatDate = (dateString, format = 'DD MMMM YYYY') => {
    if (!dateString) return 'N/A';
    return moment(dateString).isValid() ? moment(dateString).format(format) : 'Date invalide';
  };

  

  const cellStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Centre horizontalement
    textAlign: 'center', // Texte centré (au cas où)
    height: '100%',
    padding: '0.5rem',
  };

  const defaultColDef = {
    sortable: true,
    resizable: true,
    headerClass: 'ag-header-center',
    cellStyle: cellStyle
  };

  const ActionRenderer = (params) => {
    const rowId = params.data.id;
    
    const handleClick = () => {
      setLoading(true);
      NewPDFFactureVoyage({id: rowId})
        .then((result) => {
          if (result.status === 200) {
            const url = URL.createObjectURL(result.data);
            setPdfUrl(url);
            setShowModal(true);
          } else {
            console.error("Erreur lors de la récupération du PDF");
          }
        })
        .catch((error) => {
          console.error("Erreur lors du chargement du PDF :", error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    return (
      <div>
        <button
          onClick={handleClick}
          className='p-2 rounded-lg bg-blue-100 hover:bg-blue-200 transition-colors'
          disabled={loading}
        >
          {loading ? (
            <Loader />
          ) : (
            <FaRegFilePdf className="text-blue-600" size={18} />
          )}
        </button>
      </div>
    );
  };



  const columnDefs = [
    {
      headerName: "Date souscription",
      field: "date",
      cellRenderer: (params) => (
        <div className="font-medium">
          {params.value}
        </div>
      ),
      flex: 1
    },
    {
      headerName: 'Police N°',
      field: 'policeNo',
      flex: 1
    },
    {
      headerName: 'Souscripteur',
      field: 'souscripteur',
      flex: 1.5,
      cellRenderer: params => (
        <div className="">
          <div className=" text-purple-600 font-bold text-center underline underline-offset-1 ">{params.value.nom}</div>
          <div className="text-sm font-medium text-gray-500">📞 : <FormatNumero numero={params.value.telephone} />  </div>
          <div className="text-sm font-medium text-gray-500">✉️ : {params.value.email}</div>
        </div>
      )
    },
    {
      headerName: 'Paiement',
      field: 'contrat',
      flex: 1,
      cellRenderer: params => (
        <div className="-space-y-4">
          <div className='font-bold text-gray-500 text-center uppercase'>{params.value}</div>
        </div>
      )
    },
    {
      headerName: 'Montant Total',
      field: 'decomposition',
      flex: 1,
      cellRenderer: params => (
        <div className="">
          <div className='text-md font-bold text-purple-600'>{params.value.montantTotal.toLocaleString()}</div>
        </div>
      )
    },
    {
      headerName: 'Point de Vente',
      field: 'pdv',
      flex: 1,
      cellRenderer: params => (
        <div>
          <div className="font-medium text-gray-500 text-center">{params.value} </div>
        </div>
      )
    },
    {
      headerName: 'PDF',
      field: 'actions',
      width: 80,
      cellRenderer: ActionRenderer,
      cellRendererParams: {
        popup: true, 
      }
    }
  ];

  const mobileColumnDefs = [
    {
      headerName: "Infos",
      field: "souscripteur",
      cellRenderer: (params) => (
        <div className="flex flex-col p-2">
          <div className="font-bold text-purple-600">{params.value.nom}</div>
          <div className="text-sm">Police N°: {params.data.policeNo}</div>
          <div className="text-sm font-medium">
            <FormatNumero numero={params.value.telephone} />
          </div>
          <div className="text-gray-500">{params.data.contrat}</div>
          <div className="font-bold text-purple-600">
            {params.data.decomposition.montantTotal.toLocaleString()} FCFA
          </div>
        </div>
      ),
      flex: 1
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      cellRenderer: ActionRenderer
    }
  ];
  
  // 2. Configuration tablette
  const tabletColumnDefs = [
    {
      headerName: "Client",
      field: "souscripteur",
      cellRenderer: (params) => (
        <div className="flex flex-col">
          <div className="font-bold text-purple-600">{params.value.nom}</div>
          <div className="text-sm">N° {params.data.policeNo}</div>
        </div>
      ),
      flex: 2
    },
    {
      headerName: "Paiement",
      field: "contrat",
      cellRenderer: (params) => (
        <div className="font-medium text-gray-600">
          {params.value}
        </div>
      ),
      flex: 1
    },
    {
      headerName: "Montant",
      field: "decomposition",
      cellRenderer: (params) => (
        <div className="font-bold text-purple-600">
          {params.value.montantTotal.toLocaleString()} FCFA
        </div>
      ),
      flex: 1
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      cellRenderer: ActionRenderer
    }
  ];
  
  // 3. Ajouter la configuration responsive
  const getGridConfig = () => {
    const width = window.innerWidth;
    if (width <= 640) {
      return {
        columnDefs: mobileColumnDefs,
        rowHeight: 140
      };
    } else if (width <= 1024) {
      return {
        columnDefs: tabletColumnDefs,
        rowHeight: 100
      };
    }
    return {
      columnDefs: columnDefs,
      rowHeight: 100
    };
  };
  
  // 4. Ajouter l'état et l'effet pour la gestion du responsive
  const [gridConfig, setGridConfig] = useState(getGridConfig());
  
  useEffect(() => {
    const handleResize = () => {
      setGridConfig(getGridConfig());
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
    
  return (
    <div className="w-full h-full">
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <div className="bg-white w-3/4 h-3/4 rounded-lg shadow-lg overflow-hidden">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-semibold">Aperçu du PDF</h2>
              <button onClick={closeModal} className="text-red-500 font-bold">
                <span className='p-3 hover:bg-red-500 hover:text-white rounded-md border-red-500 border'>
                  Fermer
                </span>
              </button>
            </div>
            <div className="p-4 h-full">
              {pdfUrl ? (
                <iframe src={pdfUrl} title="PDF Preview" className="w-full h-full" />
              ) : (
                <p>Chargement du PDF...</p>
              )}
            </div>
          </div>
        </motion.div>
      )}
      <div className="ag-theme-alpine w-full h-[660px]">
      <AgGridReact
          columnDefs={gridConfig.columnDefs}
          rowData={data}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={10}
          rowHeight={gridConfig.rowHeight}
          animateRows={true}
          getRowClass={getRowClass}
          rowClass="hover:bg-gray-50"
          loading={chargement}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Chargement des données...</span>'
          }
          overlayNoRowsTemplate={
            '<span class="ag-overlay-no-rows-center">Aucune donnée disponible</span>'
          }
        />
      </div>
    </div>
  )
}

export default VendeursFacturesDatatable

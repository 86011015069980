import React, { useEffect } from 'react';
import { Modal } from 'keep-react';
import { motion } from 'framer-motion';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdDelete } from "react-icons/md";

const DeleteModal = ({ isOpen, closeModal, libelle, type , titre, choix}) => {

    
    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            closeModal();
          }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

  return (
    <Modal isOpen={isOpen}>
      <motion.div
      className="fixed inset-0 flex items-center justify-center "
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="relative bg-white rounded-3xl p-6 shadow-lg w-11/12 max-w-lg">
        <AiFillCloseCircle
          className="absolute top-4 right-4 text-gray-500 cursor-pointer text-2xl"
          onClick={closeModal}
        />
        <div className="flex flex-col items-center">
          <MdDelete size={40} className="text-red-500" />
          <h1 className="text-red-500 font-bold text-2xl mt-4 mb-2">
            Suppression d'un {titre}
          </h1>
          <div className="text-center mb-4">
            <div>Attention cette action est irréversible.<br/>Voulez-vous vraiment supprimer {type} </div>
            <span className="text-lg font-bold block mt-1">{libelle}</span>
          </div>
          <div className="flex space-x-4 mt-4">
            <button
              className="bg-red-500 text-white font-bold py-2 px-4 rounded-lg shadow hover:bg-red-600 transition"
              onClick={() => choix(true)}
            >
              Confirmer
            </button>
            <button
              className="bg-gray-300 text-white font-bold py-2 px-4 rounded-lg shadow hover:bg-gray-400 transition"
              onClick={closeModal}
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </motion.div>
    </Modal>
  )
}

export default DeleteModal


import React, { useState } from 'react';
import { Drawer, DrawerContent } from 'keep-react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { FaWallet } from 'react-icons/fa';
import { motion } from 'framer-motion';
import espece from '/src/images/espece.png';
import orange from '/src/images/orange.png';
import wave from '/src/images/wave.png';
import free from '/src/images/free.png';
import { succesNotification } from '../notifications/Notifications';
import { NewVoyagePaiementEspece } from '../../api/paiement/Paiement';
import { useNavigate } from 'react-router-dom';
import { useProfile } from '../../UserDataStock/Profils/Profile';

const VoyagePaiement = ({ drawOpen, closeDrawer, idref }) => {
  const [selectedMobileMoney, setSelectedMobileMoney] = useState(null);
  const [useDefaultNumber, setUseDefaultNumber] = useState(true);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const {position, token, userDetails } = useProfile();

  const mobileMoney = [
    { id: 1, nom: 'Orange Money', img: orange },
    { id: 2, nom: 'Wave', img: wave },
    { id: 3, nom: 'Free Money', img: free },
  ];

  const handleSelectPaymentMethod = (method) => {
    setSelectedMobileMoney(method);
    alert('Disponible bientôt');
  };

    const handlePaiementEspeceVoyage = () =>{ 
        setLoading(true);
        NewVoyagePaiementEspece(idref, token, position)
        .then((result) => { 
          // console.log(result)
            if(result.status === 200){ 
                succesNotification(result.data.message);
                navigate(`/Voyages/Contrats/${result.data.contratRef}`);
            }
        })
        .catch((error) => { 
            console.error("Erreur lors du paiement en espece :", error);
        })
        .finally(() => { 
            setLoading(false);
        })
    }

  return (
    <Drawer isOpen={drawOpen} position="right" onClose={closeDrawer}>
      <DrawerContent className="w-full md:w-3/12 bg-white shadow-lg">
        {/* <AiFillCloseCircle
          className="text-gray-300 text-3xl absolute top-4 right-4 cursor-pointer hover:text-white"
          onClick={closeDrawer}
        /> */}
        <div className="bg-gradient-to-r from-purple-500 to-purple-700 p-6 text-center rounded-b-lg shadow-md">
          <FaWallet size={30} className="text-white mx-auto" />
          <h1 className="text-xl font-bold text-white mt-2">Paiement</h1>
        </div>
        <div className="p-6 space-y-8">
          {/* Section Espèce */}
          <div className="text-center">
            <h2 className="text-lg font-semibold text-gray-800 mb-2">Paiement en espèces</h2>
            <motion.div
              className=" cursor-pointer"
              whileHover={{ scale: 1.05 }}
              onClick={handlePaiementEspeceVoyage}
            >
              <img
                src={espece}
                alt="Espece"
                className="h-24 w-28 mx-auto rounded-lg shadow-md border border-gray-200"
              />
            </motion.div>
            <motion.h1
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: loading ? 0 : -20, opacity: loading ? 1 : 0 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
                className='font-semibold text-center text-xl mt-4 text-teal-600'
                >
                {loading ? "En cours de validation..." : ""}
            </motion.h1>
          </div>
          <hr className="border-gray-300" />
          {/* Section Mobile Money */}
          <div className="text-center">
            <h2 className="text-lg font-semibold text-gray-800">Paiement Mobile Money</h2>
            <div className="flex justify-center gap-4 mt-4">
              {mobileMoney.map((method) => (
                <motion.div
                  key={method.id}
                  className="cursor-pointer"
                  whileHover={{ scale: 1.1 }}
                  onClick={() => handleSelectPaymentMethod(method)}
                >
                  <img
                    src={method.img}
                    alt={method.nom}
                    className="h-24 w-28 rounded-lg shadow-md border border-gray-200"
                  />
                  <p className="text-sm font-medium text-gray-700 mt-2">{method.nom}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default VoyagePaiement;


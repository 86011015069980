import React from 'react';
import animationData from '/src/images/error_404.json';
import Lottie from 'lottie-react';

const ServerError = () => {
    return (
        <div className="relative flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-screen h-screen">
                <Lottie 
                    animationData={animationData}
                    loop={true}
                    autoPlay={true}
                    className="w-full h-full"
                />
                <div className="absolute bottom-0 w-full text-center p-4 bg-gray-100 bg-opacity-75">
                    <h1 className="text-3xl font-bold text-gray-800">Oops! Quelque chose s'est mal passé.</h1>
                    <p className="text-lg text-gray-600 mt-4">Nous rencontrons des difficultés pour charger la page demandée. Veuillez réessayer ultérieurement.</p>
                </div>
            </div>
        </div>
    );
};

export default ServerError;

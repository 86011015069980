import axios from "axios";

export async function NewPDFAttestationVoyage (data){
    try{
        const response = await axios({ 
            method : 'POST',
            url: `${import.meta.env.VITE_BACKLOG}/pdf/AttestationWafa`,
            headers: {
                'Content-Type': 'application/json' ,
                'Accept': '*/*',
                // 'Authorization': `${token}`,
                // 'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: data,
            responseType: 'blob'  
        })
        return response;
    } catch (error){ 
        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }

}

export async function NewPDFConditionVoyage (data) { 
    try{ 
        const response = axios({ 
            method : 'POST',
            url: `${import.meta.env.VITE_BACKLOG}/pdf/conditions-particulieres`,
            headers: {
                 'Content-Type': 'application/json' ,
                 'Accept': '*/*',
                //  'Authorization': `${token}`,
                //  'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: data,
            responseType: 'blob'
        })
        return response;
    } catch (error){ 

        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
} 
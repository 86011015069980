import { useEffect, useState } from 'react';
import Information from '../../template/information/Information';
import Footer from '../../template/footer/Footer';
import Navbar from '../../template/navbar/Navbar';
import { Modal } from 'keep-react';
import { AiFillCloseCircle } from "react-icons/ai";
import { AnimatePresence, motion } from 'framer-motion';
import LoginForm from '../../components/forms/login/LoginForm';
import { useLocalisation } from '../../utils/localisation';
import { Authentification } from '../../api/auth/Connexion';
import { useDispatch } from 'react-redux';
import { setPosition, setToken, setUserDetails } from '../../UserDataStock/slice/UserSlice';
import animationData from '/src/images/loader.json';
import Lottie from 'lottie-react';
import SetPasswordForm from '../../components/forms/login/SetPasswordForm';
import { failNotification, succesNotification } from '../../components/notifications/Notifications';
import { NewPassword } from '../../api/admin/Utlisateurs';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  // États
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLottieVisible, setIsLottieVisible] = useState(true);
  const [data, setData] = useState({});
  const [checkpassword, setCheckpassword] = useState(false);
  const [isLocationAuthorized, setIsLocationAuthorized] = useState(false);
  const [componentsReady, setComponentsReady] = useState(false);
  
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const position = useLocalisation();

  // Chargement des composants
  useEffect(() => {
    const loadComponents = async () => {
      try {
        await Promise.all([
          new Promise(resolve => setTimeout(resolve, 2000)), // Délai minimal pour l'animation
          Information.preload?.(),
          Footer.preload?.(),
          Navbar.preload?.(),
          LoginForm.preload?.(),
          SetPasswordForm.preload?.()
        ]);
        setComponentsReady(true);
      } catch (error) {
        console.error('Erreur de chargement des composants:', error);
      }
    };
    loadComponents();
  }, []);

  // Gestion de l'animation de chargement
  useEffect(() => {
    if (componentsReady) {
      const timer = setTimeout(() => {
        setIsLottieVisible(false);
        setIsLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [componentsReady]);

  // Gestion de la géolocalisation
  const requestLocationPermission = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setIsLocationAuthorized(true);
          setIsOpen(true);
        },
        (error) => {
          failNotification("Vous devez autoriser l'accès à votre position pour utiliser l'application");
          setIsLocationAuthorized(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    } else {
      failNotification("La géolocalisation n'est pas supportée par votre navigateur");
    }
  };

  const handleOpenModal = () => {
    if (!isLocationAuthorized) {
      requestLocationPermission();
    } else {
      setIsOpen(true);
    }
  };

  // Gestion de l'authentification
  const formLogin = (data) => {
    setIsLoading(true);
    const User = {
      identifiant: data?.email,
      password: data?.password,
    };
  
    Authentification(User)
      .then((result) => {
        if (result.status === 200) {
          const userData = result.data;
          
          // Vérification si l'utilisateur est bloqué
          if (userData.user.isBlocked) {
            failNotification("Utilisateur bloqué. Veuillez contacter l'administrateur.");
            return;
          }
  
          // Vérification si le mot de passe n'est pas défini
          if (!userData.user.isPasswordSet) {
            setData(userData);
            setCheckpassword(true);
            return;
          }
  
          // Si tout est OK, on connecte l'utilisateur
          handleSuccessfulLogin(userData);
        } else {
          failNotification("Utilisateur ou mots de passe incorrect");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de l'authentification:", error);
        failNotification("Une erreur s'est produite lors de la connexion");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSuccessfulLogin = (userData) => {
    dispatch(setToken(userData.token));
    dispatch(setUserDetails(userData.user));
    if (position) {
      dispatch(setPosition(position));
    }
    navigate('/Accueil');
    succesNotification(`Soyez le Bienvenue M(r).${userData.user.prenom} ${userData.user.nom} sur Yesha`);
  };

  const passwordUpdate = (donnes) => {
    setIsLoading(true);
    const idU = data.user?.id;
    const defPass = {
      password: donnes?.password
    };
  
    NewPassword(idU, defPass)
      .then((result) => {
        handleSuccessfulLogin(data);
        setCheckpassword(false);
        succesNotification(result.message);
      })
      .catch((error) => {
        console.error(error);
        failNotification("Une erreur s'est produite lors de la mise à jour du mot de passe");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Gestion du modal
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Animations
  const formVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  // Rendu conditionnel
  if (isLottieVisible) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Lottie 
          animationData={animationData}
          loop={true}
          autoPlay={true}
          className="w-1/2 h-1/2"
        />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Navbar onOpenModal={handleOpenModal} />
      <Modal isOpen={isOpen}>
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={formVariants}
          transition={{ duration: 0.3 }}
          className="w-screen"
        >
          <AiFillCloseCircle
            className="absolute top-10 right-10 text-white cursor-pointer text-[30px]"
            onClick={closeModal}
          />

          <AnimatePresence mode="wait">
            {!checkpassword ? (
              <motion.div
                key="loginForm"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={formVariants}
                transition={{ duration: 0.3 }}
              >
                <LoginForm handleLogin={formLogin} check={isLoading} />
              </motion.div>
            ) : (
              <motion.div
                key="setPasswordForm"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={formVariants}
                transition={{ duration: 0.3 }}
              >
                <SetPasswordForm handleSetPassword={passwordUpdate} />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </Modal>
      <Information />
      <Footer />
    </motion.div>
  );
};

export default Login;
import React, { useState, useRef } from 'react';
import { FaPlaneArrival } from 'react-icons/fa6';
import DropdownPortal from '../dropdown/DropdownPortal';

const PaysAriv = ({ formik, paysOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const selectRef = useRef(null);
  const searchInputRef = useRef(null);

  const handleOptionClick = (id, nom) => {
    formik.setFieldValue('destinationId', id);
    formik.setFieldValue('destination', nom);
    setIsOpen(false);
    setSearchTerm('');
    // Scroll vers le bas après la sélection
    setTimeout(() => {
      window.scrollTo({
        top: selectRef.current.offsetTop + 300,
        behavior: 'smooth'
      });
    }, 0);
  };

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      // Maintenir la position du scroll actuelle
      const currentScroll = window.scrollY;
      setTimeout(() => {
        searchInputRef.current?.focus();
        window.scrollTo({
          top: currentScroll,
          behavior: 'instant'
        });
      }, 0);
    }
  };

  return (
    <div className='w-full'>
      <label className="block text-gray-700 font-semibold mb-1 pl-2">
        Pays de Destination
      </label>
      <div className="relative" ref={selectRef}>
        <div
          className={`pl-10 pr-4 py-2 border ${
            isOpen ? 'border-purple-500' : 'border-gray-300'
          } rounded-lg w-full text-gray-700 cursor-pointer`}
          onClick={handleDropdownClick}
        >
          <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
            <FaPlaneArrival className='w-5 h-5 text-gray-400' />
          </span>
          {formik.values.destination || "Choisir un pays"}
        </div>

        <DropdownPortal isOpen={isOpen} anchorRef={selectRef}>
          <div className="bg-white border border-gray-300 rounded-lg shadow-lg">
            <input
              type="text"
              placeholder="Rechercher un pays..."
              className="pl-3 pr-4 py-2 border-b w-full"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              ref={searchInputRef}
            />
            <div className="max-h-60 overflow-y-auto">
              {Array.isArray(paysOptions) && paysOptions
                .filter(option => 
                  option.nom.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(searchTerm.toLowerCase())
                )
                .map(option => (
                  <div
                    key={option.id}
                    onClick={() => handleOptionClick(option.id, option.nom)}
                    className={`px-4 py-2 cursor-pointer hover:bg-purple-500 hover:text-white`}
                  >
                    {option.nom}
                  </div>
                ))}
            </div>
          </div>
        </DropdownPortal>
      </div>
    </div>
  );
};

export default PaysAriv;
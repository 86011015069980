import { AgGridReact } from 'ag-grid-react'
import React from 'react'
import FormatNumero from '../../../../utils/FormatNumero'
import { MdDelete } from 'react-icons/md'
import { RiEdit2Fill } from 'react-icons/ri'
import { FaUser } from 'react-icons/fa6'
import { useLocation, useNavigate } from 'react-router-dom'

const PointsDeVentesDatatable = ({data, chargement}) => {


    const navigate = useNavigate();
    const location = useLocation();

    const vendeurs = (id) => {navigate(`${location.pathname}/${id}/Vendeurs`)}

    const RaisonCellRender = ({value}) => { 
        return (
            <div className='flex justify-start pl-2 items-center'>
                <span className='font-semibold text-purple-500 mt-4'>{value}</span>
            </div>
        )
    }

    const VilleCellRender = ({value}) => { 
        return (
            <div className='flex justify-start pl-2 items-center'>
                <span className='font-semibold text-purple-500 mt-4'>{value}</span>
            </div>
        )
    }

    const ContactCellRender = ({ value }) => {
        const { mobile, fixe, email } = value;
        return (
            <div className='flex-col justify-center pl-2 items-start'>
                
                <div className='flex-col font-semibold mt-2'>
                    <div className='flex justify-start items-center space-x-2'>
                        <span className='text-sm'>Email: </span>
                        <span className='text-sm font-semibold text-purple-500'><FormatNumero numero={email}/></span>
                    </div>
                    <div className='flex justify-start items-center space-x-2'>
                        <span className='text-sm'>Fix: </span>
                        <span className='text-sm font-semibold text-purple-500'><FormatNumero numero={fixe}/></span>
                    </div>
                    <div className='flex justify-normal items-center space-x-2'>
                        <span className='text-sm'>Mobile: </span>
                        <span className='text-sm font-semibold text-purple-500'><FormatNumero numero={mobile}/></span>
                    </div>
                </div> 
            </div>
        );
    };



    const ActionComponent = params => {
        const {id, libelle, rccm, type, mobile, fixe, email, ville, quartier, rue, latitude, longitude} = params.data
        return (
            <div className='flex text-center justify-center items-center space-x-3 mt-4'>

                <button className='p-2 rounded-lg border bg-fuchsia-100 hover:bg-purple-600 hover:text-white'
                    onClick={()=>alert('editer')}
                >
                    <RiEdit2Fill  size={20}/>
                </button>

                <button className='p-2 rounded-lg border bg-fuchsia-100 hover:bg-purple-600 hover:text-white'
                    onClick={()=>alert('supprimer')}
                >
                    <MdDelete size={20}/> 
                </button>

                <button className='p-2 rounded-lg border bg-fuchsia-100 hover:bg-purple-600 hover:text-white'
                    onClick={()=>vendeurs(id)}
                >
                    <FaUser  size={20} />
                </button>

            </div>
        )
    }
    
    

    const header = [
        {
            field: "Raison Social",
            valueGetter: params => params.data.libelle,
            cellRenderer: RaisonCellRender,
            headerCheckboxSelection: true,
            flex: 1 
        },
        {
            field: "Ville",
            valueGetter: params => params.data.ville,
            cellRenderer: VilleCellRender,
            flex: 1
        },
        {
            field: "Contact",
            valueGetter: params => ({
                mobile: params.data.mobile,
                fixe: params.data.fixe,
                email: params.data.email
            }),
            cellRenderer: ContactCellRender,
            flex: 2  // Contact pourrait avoir besoin de plus d'espace
        },
        {
            field: "Volume",
            flex: 1
        },
        {
            field: "Montant",
            flex: 1
        },
        {
            field: "Etat",
            flex: 1
        },
        { 
            field: "Action",
            cellRenderer: ActionComponent,
            flex: 1
        }
    ]



  return (
    <div className="ag-theme-quartz " style={{height: 500}}>
        <AgGridReact 
        rowData={data}
        rowSelection='multiple'
        columnDefs={header}
        rowHeight={80}
        animateRows={true}
        pagination={true}
        getRowClass={() => 'fade-in'} 
        loading={chargement}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Chargement des données...</span>'
          }
          overlayNoRowsTemplate={
            '<span class="ag-overlay-no-rows-center">Aucune donnée disponible</span>'
          }
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 20, 30]}
        domLayout='autoHeight'
        /> 
    </div>
  )
}

export default PointsDeVentesDatatable

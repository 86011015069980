import React from 'react'
import { FcIdea } from 'react-icons/fc'

const AssureurBanner = () => {
  return (
    <fieldset className="w-full p-4 bg-cyan-100 mb-5 shadow-md border rounded-lg">
    <legend className="flex items-center">
      <h2 className=" flex items-baseline text-xl font-semibold text-purple-600 ml-4 p-1 bg-gray-50 rounded-lg border">
        <FcIdea size={20} className='mr-1'/> 
        Notices
      </h2>
    </legend>
    <div className="mb-2">
    Bienvenue à cette section réserve a l’enregistrement des Assureurs. Veuillez remplir correctement les différents champs décrit ci-dessous. Ces informations seront utilisées plus tard.
    </div>
  </fieldset>
  )
}

export default AssureurBanner

import React from 'react'
import { Area, AreaChart, ChartTooltip, CustomTooltip, ResponsiveContainer, XAxis, YAxis } from 'keep-react'

const VendeursCharts = () => {
  const data = [
    {
      name: 'Jan',
      automobile: 340,
      voyage: 200,
    },
    {
      name: 'Feb',
      automobile: 300,
      voyage: 180,
    },
    {
      name: 'Mar',
      automobile: 170,
      voyage: 300,
    },
    {
      name: 'Apr',
      automobile: 190,
      voyage: 230,
    },
    {
      name: 'May',
      automobile: 450,
      voyage: 100,
    },
    {
      name: 'Jun',
      automobile: 400,
      voyage: 200,
    },
    {
      name: 'Jul',
      automobile: 250,
      voyage: 220,
    },
    {
      name: 'Aug',
      automobile: 320,
      voyage: 240,
    },
    {
      name: 'Sep',
      automobile: 280,
      voyage: 70,
    },
  ]

  return (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="automobile" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#48d986" stopOpacity={0.3} />
            <stop offset="95%" stopColor="#48d986" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="voyage" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#1B4DFF" stopOpacity={0.3} />
            <stop offset="95%" stopColor="#1B4DFF" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area type="natural" dataKey="automobile" stroke="#48d986" fillOpacity={1} fill="url(#automobile)" />
        <Area type="natural" dataKey="voyage" stroke="#1B4DFF" fillOpacity={1} fill="url(#voyage)" />

        <XAxis
          className="text-body-4 font-medium text-metal-600"
          dataKey="name"
          stroke="#8897AE"
          strokeWidth={0.5}
          dy={12}
        />
        <YAxis
          className="text-body-4 font-medium text-metal-600"
          dx={-12}
          color='#8897AE'
          dataKey="automobile"
          stroke="#8897AE"
          strokeWidth={0.5}
        />

        <ChartTooltip content={<CustomTooltip />} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default VendeursCharts
import moment from 'moment'
export const AssureurValidation = (values) => { 
  let errors = {}; 
  const requiredError = " * Veuillez Remplir ce Champ";
  const invalidEmailError = " * Adresse Email Invalide";
  const requirePicture = " * Veuillez Selectionnez un Image"

  if(!values.code) errors.code = requiredError;
  if(!values.libelle) errors.libelle = requiredError;
  if(!values.description) errors.description = requiredError;
  if(!values.logo) errors.logo = requirePicture;
  if (!values.email) errors.email = requiredError;
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) errors.email = invalidEmailError;
  if (!values.fixnumber) errors.fixnumber = requiredError;
  if (!values.mobileNumber) errors.mobileNumber = requiredError;
  if (!values.codeCourtier) errors.codeCourtier = requiredError;
  if (!values.country_id) errors.country_id = requiredError;

  // Validation conditionnelle pour secu2 et secu4
  if (values.country_id === "3") {
      if (!values.secu2) errors.secu2 = requiredError;
      if (!values.secu4) errors.secu4 = requiredError;
  }

  return errors;
}

export const RoleValidation = (values) => { 
    let errors = {}; 
    const requiredError = " * Veuillez Remplir ce Champ";

    if(!values.libelle) errors.libelle = requiredError;
    return errors;
}

export const PlaceValidation = (values) => { 
    let errors = {};
    const requiredError = " * Veuillez Remplir ce Champ";
    
    if(!values.Nbplace) errors.Nbplace = requiredError;
    return errors;
} 

export const MatriculeValidation = (values) => { 
    let errors = {};
    const requiredError = " * Veuillez Saisir le Matricule de la Voiture";
    
    if(!values.matricule) errors.matricule = requiredError;
    return errors;
}

export const UtilisateurValidation  = (values) => { 
    let errors = {};
    
    const requiredError = " * Veuillez Remplir ce Champ";
    const invalidEmailError = " * Adresse Email Invalide";
    // const requirePicture = " * Veuillez Selectionnez un Image"

    if(!values.prenom) errors.prenom = requiredError;
    if(!values.nom) errors.nom = requiredError;
    if(!values.username) errors.username = requiredError;
    if(!values.mobile) errors.mobile = requiredError;
    if(!values.role) errors.role = requiredError;
    // if(!values.image) errors.image = requirePicture;
    if (!values.email) errors.email = requiredError;
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) errors.email = invalidEmailError;
    return errors;
}

export const VendeursValidation = (values) => { 
    let errors = {};
    
    const requiredError = " * Veuillez Remplir ce Champ";
    const invalidEmailError = " * Adresse Email Invalide";

    if(!values.prenom) errors.prenom = requiredError;
    if(!values.nom) errors.nom = requiredError;
    if(!values.username) errors.username = requiredError;
    if(!values.mobile) errors.mobile = requiredError;
    if (!values.email) errors.email = requiredError;
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) errors.email = invalidEmailError;
    return errors;
}

export const AssureVoyageValidation = (values) => {
  const errors = {};

  // Validation pour `departId`
  if (!values.departId) {
    errors.departId = "Le lieu de départ est requis.";
  } else if (!Number.isInteger(values.departId)) {
    errors.departId = "Le lieu de départ doit être un nombre entier.";
  }

  // Validation pour `destination`
  if (!values.destination) {
    errors.destination = "La destination est requise.";
  }

  // Validation pour `voyagers`
  if (values.voyagers.length === 0) {
    errors.voyagers = "Au moins un voyageur est requis.";
  } else {
    const voyagersErrors = [];
    
    values.voyagers.forEach((voyager, index) => {
      const voyagerError = {};
      
      if (!voyager.assure) {
        voyagerError.assure = `Le nom de l'assuré ${index + 1} est requis.`;
      }
      
      if (!voyager.dateNaissance) {
        voyagerError.dateNaissance = `La date de naissance est requise.`;
      } else {
        const age = moment().diff(moment(voyager.dateNaissance, 'YYYY-MM-DD'), 'years');
        if (age > 75) {
          voyagerError.dateNaissance = "L'âge maximum autorisé est de 75 ans";
        }
      }
      
      if (Object.keys(voyagerError).length > 0) {
        voyagersErrors[index] = voyagerError;
      }
    });

    if (voyagersErrors.length > 0) {
      errors.voyagers = voyagersErrors;
    }
  }

  // Validation pour `duree`
  if (!values.duree) {
    errors.duree = "La durée du voyage est requise.";
  } else if (values.duree <= 0) {
    errors.duree = "La durée doit être supérieure à zéro.";
  }

  // Validation pour `dateDepart`
  if (!values.dateDepart) {
    errors.dateDepart = "La date de départ est requise.";
  } else if (moment(values.dateDepart).isBefore(moment(), 'day')) {
    errors.dateDepart = "La date de départ ne peut pas être antérieure à aujourd'hui.";
  }

  // Validation pour `dateRetour`
  if (values.verif === '1' && !values.dateRetour) {
    errors.dateRetour = "La date de retour est requise.";
  } else if (values.dateRetour && moment(values.dateDepart).isAfter(values.dateRetour)) {
    errors.dateRetour = "La date de retour doit être après la date de départ.";
  }

  // Validation pour `nbVoyager`
  if (!values.nbVoyager) {
    errors.nbVoyager = "Le nombre de voyageurs est requis.";
  } else if (values.nbVoyager <= 0) {
    errors.nbVoyager = "Le nombre de voyageurs doit être supérieur à zéro.";
  }

  // Validation pour `verif`
  if (!['1', '2'].includes(values.verif)) {
    errors.verif = "Sélectionnez si la date de retour est connue.";
  }

  // Validation pour `selectedTarification`
  if (!values.selectedTarification) {
    errors.selectedTarification = "Une tarification doit être sélectionnée.";
  }

  return errors;
};


export const AssureVoyageModificationValidation = (values) => { 
  const errors = {
    souscripteur: {} // Initialiser un objet pour les erreurs du souscripteur
  };

  // Validation des voyageurs
  values.voyage.voyageurs.forEach((voyageur, index) => {
    // Vérification des champs requis
    if (!voyageur.nom) {
      errors[`voyage.voyageurs[${index}].nom`] = 'Le nom est obligatoire';
    }
    if (!voyageur.prenom) {
      errors[`voyage.voyageurs[${index}].prenom`] = 'Le prénom est obligatoire';
    }
    if (!voyageur.genre) {
      errors[`voyage.voyageurs[${index}].genre`] = 'Le genre est obligatoire';
    }
    if (!voyageur.dateNaissance) {
      errors[`voyage.voyageurs[${index}].dateNaissance`] = 'La date de naissance est obligatoire';
    } else {
      // Vérification de l'âge maximum de 75 ans
      const age = moment().diff(moment(voyageur.dateNaissance), 'years');
      if (age > 75) {
        errors[`voyage.voyageurs[${index}].dateNaissance`] = "L'âge ne doit pas dépasser 75 ans";
      }
    }
    if (!voyageur.tel) {
      errors[`voyage.voyageurs[${index}].tel`] = 'Le téléphone est obligatoire';
    }
    if (!voyageur.email) {
      errors[`voyage.voyageurs[${index}].email`] = "L'email est obligatoire";
    }
    if (!voyageur.passport) {
      errors[`voyage.voyageurs[${index}].passport`] = 'Le numéro de passeport est obligatoire';
    }
    if (!voyageur.dateExp) {
      errors[`voyage.voyageurs[${index}].dateExp`] = "La date d'expiration du passeport est obligatoire";
    }
  });

   // Validation du souscripteur
   if (!values.souscripteur.type) {
    errors.souscripteur.type = "Le type de souscripteur est requis";
  }

  // Validations communes pour tous les types
  if (!values.souscripteur.tel) {
    errors.souscripteur.tel = "Le numéro de téléphone est requis";
  }

  if (!values.souscripteur.email) {
    errors.souscripteur.email = "L'email est requis";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.souscripteur.email)) {
    errors.souscripteur.email = "Format d'email invalide";
  }

  if (!values.souscripteur.adresse) {
    errors.souscripteur.adresse = "L'adresse est requise";
  }

  // Validations spécifiques pour le type particulier
  if (values.souscripteur.type === 'particulier') {
    if (!values.souscripteur.prenom) {
      errors.souscripteur.prenom = "Le prénom est requis";
    }
    
    if (!values.souscripteur.nom) {
      errors.souscripteur.nom = "Le nom est requis";
    }
    
    if (!values.souscripteur.genre) {
      errors.souscripteur.genre = "Le genre est requis";
    }
  }

  // Validations spécifiques pour le type entreprise
  if (values.souscripteur.type === 'entreprise') {
    if (!values.souscripteur.raison) {
      errors.souscripteur.raison = "La raison sociale est requise";
    }
  }

  if (!values.souscripteur.domaine) {
    errors.souscripteur.domaine = "Le Domaine d'activité est requise";
  }

  // Ne retourner errors que s'il y a des erreurs
  return Object.keys(errors.souscripteur).length > 0 || Object.keys(errors).length > 1 ? errors : {};
}


export const ContratFormValidation = (values) => {
    let errors = {};
    
    const requiredError = " * Veuillez Remplir ce Champ";
    
    
    if (!values.categorie) errors.categorie = requiredError;
    if (!values.sousCategorie) errors.sousCategorie = requiredError;
    if (!values.matricule) errors.matricule = requiredError;  
    if (!values.energie) errors.energie = requiredError;
    if (!values.valeur_a_neuf) errors.valeur_a_neuf = requiredError;
    if (!values.valeur_venal) errors.valeur_venal = requiredError;
    if (!values.marque_du_vehicule) errors.marque_du_vehicule = requiredError;
    if (!values.modele_du_vehicule) errors.modele_du_vehicule = requiredError;
    if (!values.puissance_fiscal) errors.puissance_fiscal = requiredError;
    if (!values.nombre_de_place) errors.nombre_de_place = requiredError;
    if (!values.duree_du_contrat) errors.duree_du_contrat = requiredError;
    if (!values.date_effet) errors.date_effet = requiredError;

    return errors;
}

// export const AssureFormValidation = (values) => { 
//     let errors = {};

//     const requiredError = " * Veuillez Remplir ce Champ";
//     const checkerror = " * Veuillez Selecionner un Choix";

//     if (!values.genre) errors.genre = requiredError;
//     if (!values.nom) errors.nom = requiredError;
//     if (!values.prenom) errors.prenom = requiredError;
//     if (!values.activite) errors.activite = requiredError;
//     if (!values.telephone_mobile) errors.telephone_mobile = requiredError;
//     if (!values.adresse_email) errors.adresse_email = requiredError;
//     if (!values.raison_social) errors.raison_social = requiredError;
//     if (!values.adresse) errors.adresse = requiredError;
//     if (!values.verif) errors.verif = checkerror;

//     return errors;
// }
// FormValidation.js

// export const SetPasswordValidation = (values) => { 
//   const errors = {}

//   // Validation du mot de passe
//   if (!values.password) {
//     errors.password = 'Mot de passe requis';
//   } else {
//     const strength = validatePasswordStrength(values.password);
//     setPasswordStrength(strength);

//     if (values.password.length < 8) {
//       errors.password = 'Le mot de passe doit comporter au moins 8 caractères';
//     } else if (!/[A-Z]/.test(values.password)) {
//       errors.password = 'Le mot de passe doit contenir au moins une majuscule';
//     } else if (!/[0-9]/.test(values.password)) {
//       errors.password = 'Le mot de passe doit contenir au moins un chiffre';
//     } else if (!/[^A-Za-z0-9]/.test(values.password)) {
//       errors.password = 'Le mot de passe doit contenir un caractère spécial';
//     }
//   }

  
//   if (!values.confirmPassword) {
//     errors.confirmPassword = 'Confirmation du mot de passe requise';
//   } else if (values.password !== values.confirmPassword) {
//     errors.confirmPassword = 'Les mots de passe ne correspondent pas';
//   }

//   return errors;
// }

export const AssureFormValidation = (values) => {
    const errors = {};
  
    if (!values.verif) {
      errors.verif = "Ce champ est obligatoire.";
    }
  
    if (!values.type) {
      errors.type = "Le type de client est obligatoire.";
    }
  
    if (values.type === "1") { // Personne physique
      if (!values.genre) errors.genre = "Le genre est obligatoire.";
      if (!values.nom) errors.nom = "Le nom est obligatoire.";
      if (!values.prenom) errors.prenom = "Le prénom est obligatoire.";
    } else if (values.type === "2") { // Personne morale
      if (!values.raison_social) errors.raison_social = "La raison sociale est obligatoire.";
    }
  
    if (!values.telephone_mobile) {
      errors.telephone_mobile = "Le téléphone mobile est obligatoire.";
    } else {
      // Vous pouvez ajouter une validation de format si nécessaire
      const phoneRegex = /^\d{9,15}$/; // Exemple de regex pour numéro de téléphone
      if (!phoneRegex.test(values.telephone_mobile)) {
        errors.telephone_mobile = "Le numéro de téléphone est invalide.";
      }
    }
  
    if (!values.adresse_email) {
      errors.adresse_email = "L'adresse email est obligatoire.";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!emailRegex.test(values.adresse_email)) {
        errors.adresse_email = "L'adresse email est invalide.";
      }
    }
  
    if (!values.adresse) {
      errors.adresse = "L'adresse est obligatoire.";
    }
  
    if (values.type === "1" && !values.activite) {
      errors.activite = "L'activité est obligatoire.";
    }
  
    if (values.type === "2" && !values.activite) {
      errors.activite = "L'activité est obligatoire.";
    }
  
    return errors;
  };

  export const VoyageSusbcriber = (values) => {
    let errors = {};
    
    // Validation du type de souscripteur
    if (!values.type) {
      errors.type = "Le type de souscripteur est requis";
    }
  
    // Validations communes pour tous les types
    if (!values.telephone) {
      errors.telephone = "Le numéro de téléphone est requis";
    }
    
    if (!values.email) {
      errors.email = "L'email est requis";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Format d'email invalide";
    }
  
    if (!values.adresse) {
      errors.adresse = "L'adresse est requise"; 
    }
  
    if (!values.domaine) {
      errors.domaine = "Le domaine est requis";
    }
  
    // Validations spécifiques pour le type Particulier
    if (values.type === 'Particulier') {
      if (!values.prenom) {
        errors.prenom = "Le prénom est requis";
      }
      if (!values.nom) { 
        errors.nom = "Le nom est requis";
      }
      if (!values.genre) {
        errors.genre = "Le genre est requis";
      }
      if (!values.dateNaissance) {
        errors.dateNaissance = "La date de naissance est requise";
      }
    }
  
    // Validations spécifiques pour le type Entreprise
    if (values.type === 'Entreprise') {
      if (!values.raison_social) {
        errors.raison_social = "La raison sociale est requise";
      }
    }
  
    return errors;
  };

  export const VoyageurValidation = (values) => {
    const errors = { voyagers: [] };
  
    values.voyagers.forEach((voyager, index) => {
      const voyagerErrors = {};
  
      if (!voyager.prenom) {
        voyagerErrors.prenom = "Le prénom est obligatoire.";
      }
      if (!voyager.nom) {
        voyagerErrors.nom = "Le nom est obligatoire.";
      }
      if (!voyager.genre) {
        voyagerErrors.genre = "Le genre est obligatoire.";
      }
      if (!voyager.dateNaissance) {
        voyagerErrors.dateNaissance = "La date de naissance est obligatoire.";
      }
      if(!voyager.adresse){ 
        voyagerErrors.adresse = "L'adresse est obligatoire.";
      }
      if (!voyager.telephone) {
        voyagerErrors.telephone = "Le téléphone est obligatoire.";
      }
      if (!voyager.email) {
        voyagerErrors.email = "L'email est obligatoire.";
      }
      if (!voyager.passeport) {
        voyagerErrors.passeport = "Le numéro de passeport est obligatoire.";
      }
      if (!voyager.expirationPasseport) {
        voyagerErrors.expirationPasseport = "La date d'expiration du passeport est obligatoire.";
      }
      if(!voyager.delivrancePasseport) { 
        voyagerErrors.delivrancePasseport = "La date de délivrance du passeport est obligatoire.";
      }
  
      // Ajoutez les erreurs pour ce voyageur uniquement si des erreurs existent
      if (Object.keys(voyagerErrors).length > 0) {
        errors.voyagers[index] = voyagerErrors;
      }
    });
  
    // S'il n'y a pas d'erreurs de voyageurs, retirez la clé voyagers
    if (errors.voyagers.length === 0) {
      delete errors.voyagers;
    }
  
    return errors;
  };
  

  // export const VoyageurValidation = (values) => {
  //   const errors = { voyagers: [] };
  
  //   values.voyagers.forEach((voyager, index) => {
  //     const voyagerErrors = {};
  
  //     if (!voyager.prenom) {
  //       voyagerErrors.prenom = "Le prénom est obligatoire.";
  //     }
  //     if (!voyager.nom) {
  //       voyagerErrors.nom = "Le nom est obligatoire.";
  //     }
  //     if (!voyager.genre) {
  //       voyagerErrors.genre = "Le genre est obligatoire.";
  //     }
  //     if (!voyager.dateNaissance) {
  //       voyagerErrors.dateNaissance = "La date de naissance est obligatoire.";
  //     }
  //     if (!voyager.telephone) {
  //       voyagerErrors.telephone = "Le téléphone est obligatoire.";
  //     }
  //     if (!voyager.email) {
  //       voyagerErrors.email = "L'email est obligatoire.";
  //     }
  //     if (!voyager.passeport) {
  //       voyagerErrors.passeport = "Le numéro de passeport est obligatoire.";
  //     }
  //     if (!voyager.expirationPasseport) {
  //       voyagerErrors.expirationPasseport = "La date d'expiration du passeport est obligatoire.";
  //     }
  
  //     if (Object.keys(voyagerErrors).length > 0) {
  //       errors.voyagers[index] = voyagerErrors;
  //     }
  //   });
  
  //   return errors;
  // };
  
  

import Sidebar from '../../template/sidebar/Sidebar';
import HomeNavbar from '../../template/navbar/HomeNavbar';
import { motion } from "framer-motion";
import AdminUserBanner from '../../template/banner/AdminUserBanner';
import React, { useState, useEffect } from 'react';
import AdminDevisDatatable from '../../components/datatables/voyagesDatatable/AdminDevisDatatable';
import { useProfile } from '../../UserDataStock/Profils/Profile';
import { GetAllDevisVoyageByPV } from '../../api/voyages/DevisEtContratsVoyage';


const DevisVoyageList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [isloading, setIsLoading] = useState(true);
    const [numData , setNumData] = useState(0);
    const handleOpenClose = () => setIsOpen(!isOpen);
    const { position, token, userDetails } = useProfile();
    const idpv = userDetails?.pointDeVenteId;
    useEffect(() => {
        const mesDevis = async () => { 
            try { 
                const datas = await GetAllDevisVoyageByPV(token, position, idpv);
                // console.log(datas)
                setIsLoading(true)
                setNumData(datas.length);

                const formattedData = datas.map(data => ({
                    id: data.contrat.ref,
                    date: data.contrat.dateDevis,
                    formule: data.formule.name, 
                    client: {
                        nom: `${data.subscriber.prenom} ${data.subscriber.nom}`,
                        telephone: data.subscriber.telephone,
                        email: data.subscriber.email
                    },
                    dateDepart: data.voyage.dateDepart,
                    dateRetour: data.voyage.dateRetour,
                    primeTTC: data.contrat.DetailsContrats[0].primeTTC,
                    assureur: data.assureur.libelle
                }));
                setRowData(formattedData);
                setFilteredData(formattedData);
            } catch (error) { 
                console.error('Erreur Impossible de recuperer les devis:', error);
            } finally {
                setIsLoading(false);
            }
        };
        mesDevis();
    }, [token, position, idpv]);

    useEffect(() => {
        const filtered = rowData.filter(item => 
            item.date.includes(searchText) ||
            item.formule.toLowerCase().includes(searchText.toLowerCase()) ||
            item.client.nom.toLowerCase().includes(searchText.toLowerCase()) ||
            item.client.telephone.includes(searchText) ||
            item.client.email.toLowerCase().includes(searchText.toLowerCase()) ||
            item.dateDepart.includes(searchText) ||
            item.dateRetour.includes(searchText) ||
            item.primeTTC.toString().includes(searchText) ||
            item.assureur.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchText, rowData]);

    return (
        <div className="flex h-screen">
            <Sidebar isOpen={isOpen} onToggle={handleOpenClose} />
            <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
                <HomeNavbar isOpen={isOpen} />
                <main className="p-6 mt-16 h-screen">
                    <AdminUserBanner />
                    <div className="flex justify-between items-baseline mb-2 mt-4">
                        <h2 className="text-xl font-bold text-white bg-purple-700 px-4 py-2 rounded-md">
                            Liste des Devis voyage
                            <span className="ml-3">({numData || 0})</span>
                        </h2>
                        <div className='flex justify-end items-center mx-8 mb-4'>
                            <div className='relative w-64'>
                                <input
                                    type="text"
                                    placeholder="Rechercher un devis..."
                                    className="w-full px-4 py-2 rounded-lg border border-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <motion.div
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }} 
                        exit={{ y: 100, opacity: 0 }}
                        className='border rounded-lg'
                    >
                        <AdminDevisDatatable data={filteredData} chargement={isloading} /> 
                    </motion.div>
                </main>
            </div>
        </div>
    );
};

export default DevisVoyageList;
import React, { useState, useEffect } from 'react';
import { GrCertificate } from "react-icons/gr";
import { BsFillClipboard2CheckFill } from "react-icons/bs";
import { motion } from 'framer-motion';
import { FaUser, FaUserShield } from 'react-icons/fa';
import FormStep1 from './Step1';
import FormStep2 from './Step2';
import FormStep3 from './Step3';
import FormStep4 from './Step4';

const steps = [
  { component: FormStep1, icon: <GrCertificate size={25} />, label: '1 Contrat' },
  { component: FormStep2, icon: <FaUserShield size={25} />, label: '2 Assuré' },
  { component: FormStep3, icon: <FaUser size={25} />, label: '3 Souscripteur' },
  { component: FormStep4, icon: <BsFillClipboard2CheckFill size={25} />, label: '4 Résumé' },
];

const SouscriptionsAuto = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    step1Data: {},
    step2Data: {},
    step3Data: {},
    step4Data: {},
  });

  useEffect(() => {
    const savedData = JSON.parse(sessionStorage.getItem('formData'));
    if (savedData) {
      setFormData(savedData);
    }
  }, []);

  // const handleNext = (data) => {
    
  //   if (currentStep === 0 && !data.champObligatoire) {
  //     alert("Veuillez remplir tous les champs obligatoires.");
  //     return;
  //   }
  
  //   const newFormData = { ...formData, [`step${currentStep + 1}Data`]: data };
  //   setFormData(newFormData);
  //   sessionStorage.setItem('formData', JSON.stringify(newFormData));
  //   setCurrentStep(currentStep + 1);
  // };

  const handleNext = (data) => {
    console.log('Données reçues dans handleNext :', data);

    const newFormData = { ...formData, [`step${currentStep + 1}Data`]: data };
    setFormData(newFormData);
    sessionStorage.setItem('formData', JSON.stringify(newFormData));
    setCurrentStep(currentStep + 1);
  };


  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const CurrentComponent = steps[currentStep].component;

  return (
    <div className="w-11/12 mx-auto bg-white p-14 rounded-3xl">
      <ProgressBar currentStep={currentStep} />
      <motion.div
        key={currentStep}
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100 }}
        transition={{ duration: 0.5 }}
      >
        <CurrentComponent
          onNext={handleNext}
          onPrev={handlePrev}
          data={formData[`step${currentStep + 1}Data`]}  
          isLastStep={currentStep === steps.length - 1}
        />
      </motion.div>
    </div>
  );
};

const ProgressBar = ({ currentStep }) => {
  const progress = (currentStep / (steps.length - 1)) * 100;

  return (
    <div className="mb-8">
      <div className="relative flex justify-between items-center mt-4 ">
        {steps.map((step, index) => (
          <div key={index} className="flex flex-col items-center relative z-10 ">
            <motion.div
              className={`flex items-center justify-center w-20 h-20 rounded-full p-2 transition-transform duration-500 
                ${index <= currentStep ? 'bg-purple-600 text-white scale-110' : 'bg-gray-300 text-gray-600'}`}
            >
              <div className="z-10 flex items-center justify-center w-full h-full">
                {step.icon}
              </div>
            </motion.div>
            <span className={`mt-4 font-semibold text-lg ${index <= currentStep ? 'text-purple-600' : 'text-gray-500'}`}>
              {step.label}
            </span>
          </div>
        ))}
        <motion.div
          className="absolute top-1/3 left-0 h-2 bg-purple-600 "
          style={{ width: `${progress}%`, transform: 'translateY(-50%)' }}
          initial={{ width: 0 }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.5 }}
        />
      </div>
    </div>
  );
};

export default SouscriptionsAuto;
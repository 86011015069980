import React, { useEffect, useState } from 'react'
import Sidebar from '../../template/sidebar/Sidebar'
import HomeNavbar from '../../template/navbar/HomeNavbar';
import AdminUserBanner from '../../template/banner/AdminUserBanner';
import EditContrat from '../../components/forms/voyages/EditContrat';
import { useNavigate, useParams } from 'react-router-dom';
import { useProfile } from '../../UserDataStock/Profils/Profile';
import { GetOneVoyageContrat, ModifContratVoyage } from '../../api/voyages/DevisEtContratsVoyage';
import { succesNotification } from '../../components/notifications/Notifications';

const ContratsVoyageModifie = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [getContrat, setGetContrat] = useState([]);
    const [loading, setLoading] = useState(false);
    const [chargement, setChargement] = useState(false);
    const handleOpenClose = () => setIsOpen(!isOpen);
    const { position, token, userDetails } = useProfile();
    const idContrat = useParams().id;
    const navigate = useNavigate();


    async function fetchContrat() {
        try {
            const response = await GetOneVoyageContrat(idContrat,token, position);
            setLoading(true)
            setGetContrat(response);
        } catch (error) {
            console.error("Erreur lors de la récupération des contrats de voyage :", error);
        }
    }

    useEffect(() => {
        fetchContrat();
    }, [token, position]);

    const FormUpdate = async (data) => { 
        try {
            setChargement(true);
            console.log(data);
            const response = await ModifContratVoyage(idContrat, token, position, data);
            console.log(response);
            if (response.status === 200) {
                succesNotification(response.data.message);
                navigate('/Voyages/Contrats');
            } else {
                console.error("Erreur lors de la modification du contrat");
            }
        } catch (error) {
            console.error("Erreur lors de la modification du contrat :", error);
        } finally {
            setChargement(false);
        }
    }

  return (
    <div className='flex h-screen'>
        <Sidebar isOpen={isOpen} onToggle={handleOpenClose}/>
        <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
            <HomeNavbar isOpen={isOpen} /> 
            <main className='p-6 mt-16 h-screen'>
                <AdminUserBanner />
                 <div className="flex items-center justify-between">
                    <h1 className="text-2xl font-semibold text-gray-800 ">Modifications du Contrat #<span className='font-bold text-red-600'>{idContrat}</span></h1>
                </div>
                <EditContrat contractData={getContrat} onUpdate={FormUpdate} chargement={chargement}  /> 
            </main>
        </div>
    </div>
  )
}

export default ContratsVoyageModifie

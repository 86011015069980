import axios from "axios";


export async function getTarification(data, token, position) { 
    try{ 
        const response = await axios({ 
            method: 'POST',
            url: `${import.meta.env.VITE_BACKLOG}/tarification`,
            headers: { 
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: data
        })
        return response;
    } catch(error){ 
    
        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}
import { BsBuildingFill, BsDiagram3Fill } from 'react-icons/bs';
import { MdManageAccounts } from 'react-icons/md';
import { FaStoreAlt } from 'react-icons/fa';
import { BiSupport } from 'react-icons/bi';
import { TbUserShield } from 'react-icons/tb';
import { FaFileContract, FaFileInvoice, FaFileInvoiceDollar, FaFileSignature, FaPersonChalkboard, FaShieldHalved, FaUser } from "react-icons/fa6";
export const submenus = {
    
    Automobile: [
      {
        name: "Souscriptions",
        icon: <FaFileSignature size={20} />,
        link: '/Automobiles/Souscriptions'
      },
      {
        name: "Devis",
        icon: <FaFileInvoice size={20} />,
        link: '/Automobiles/Devis'
      },
      {
        name: "Contrats",
        icon: <FaFileContract size={20} />,
        link: '/Automobiles/Contrats'
      },
      {
        name: "Factures",
        icon: <FaFileInvoiceDollar size={20} />,
        link: '/Automobiles/Factures'
      },
    ],
    Moto:[
        {
          name: "Souscriptions",
          icon: <FaFileSignature size={20} />,
          link: '/Motocyclettes/Souscriptions'
        },
        {
          name: "Devis",
          icon: <FaFileInvoice size={20} />,
          link: '/Motocyclettes/Devis'
        },
        {
          name: "Contrats",
          icon: <FaFileContract size={20} />,
          link: '/Motocyclettes/Contrats'
        },
        {
          name: "Factures",
          icon: <FaFileInvoiceDollar size={20} />,
          link: '/Motocyclettes/Factures'
        }
    ],
    Retrait: [
      {
        name: "Souscriptions",
        icon: <FaFileSignature size={20} />,
        link: '#'
      },
      {
        name: "Factures",
        icon: <FaFileInvoiceDollar size={20} />,
        link: '#'
      }
    ],
    Voyage: [
      {
        name: "Souscriptions",
        icon: <FaFileSignature size={20} />,
        link: '/Voyages/Souscriptions'
      },
      {
        name: "Devis",
        icon: <FaFileInvoice size={20} />,
        link: '/Voyages/Devis'
      },
      {
        name: "Contrats",
        icon: <FaFileContract size={20} />,
        link: '/Voyages/Contrats'
      },
      {
        name: "Factures",
        icon: <FaFileInvoiceDollar size={20} />,
        link: '/Voyages/Factures'
      },
    ],
    Ressources: [
      {
        name: "Assureurs",
        icon: <BsBuildingFill size={20} />,
        link: '/Ressources/Assureurs'
      },
      {
        name: "Distributeurs",
        icon: <BsDiagram3Fill size={20} />,
        link: '/Ressources/Distributeurs'
      },
      {
        name: "Manageurs",
        icon: <MdManageAccounts size={20} />,
        link: '/Ressources/Manageurs'
      },
      {
        name: "Vendeurs",
        icon: <FaPersonChalkboard size={20} />,
        link: '/Ressources/Vendeurs'
      },
      {
        name: "Points de Ventes",
        icon: <FaStoreAlt size={20} />,
        link: '/Ressources/PointsDeVentes'
      },
      {
        name: "Commerciaux",
        icon: <BiSupport size={20} />,
        link: '/Ressources/Commerciaux'
      },
    ],
    Configurations: [
      {
        name: "Utilisateurs",
        icon: <FaUser size={20} />,
        link: '/Administrateurs/Utilisateurs'
      },
      
    ]
};
import React, { useEffect, useState } from 'react'
import { Pays } from '../../../utils/City';
import { useFormik } from 'formik';
import { FaCheckCircle, FaCity, FaEnvelope, FaPen, FaStreetView } from 'react-icons/fa';
import { BsPersonWorkspace } from 'react-icons/bs';
import { GiArchiveRegister, GiVillage } from 'react-icons/gi';
import { FaUserPen } from 'react-icons/fa6';
import PhoneInput from 'react-phone-input-2';
import { MdPlace } from 'react-icons/md';
import { Button, Spinner } from 'keep-react';

const UpdateDistributeurs = ({handleUpdate, isLoading, data, formdata}) => {

    const [isFocused, setIsFocused] = useState(false);
    const [isFocused1, setIsFocused1] = useState(false);
    const [typePoint, setTypePoint] = useState(data);

    const flags = "sn";
    const regions = Pays[flags].regions;

    const formik = useFormik({ 
        initialValues: { 
            raison: "",
            rccm: "",
            adresse: "",
            email: "",
            mobile: "",
            gerant: "",
            fixe: "",
            ville: "",
            rue: "",
            quartier: "",
            activiteId: ""
        },
        onSubmit: handleUpdate
    })

    useEffect(()=>{ 
        if(formdata){ 
            formik.setValues("raison", formdata?.id || "")
            formik.setValues("rccm", formdata?.rccm || "")
            formik.setValues("adresse", formdata?.adresse || "")
            formik.setValues("email", formdata?.email || "")
            formik.setValues("mobile", formdata?.mobile || "")
            formik.setValues("gerant", formdata?.gerant || "")
            formik.setValues("fixe", formdata?.fixe || "")
            formik.setValues("ville", formdata?.ville || "")
            formik.setValues("rue", formdata?.rue || "")
            formik.setValues("quartier", formdata?.quartier || "")
            formik.setValues("activiteId", formdata?.activiteId || "")
        }
    }, [formdata])

  return (
        <form onSubmit={formik.handleSubmit} method='post' className='w-full'>
            <div className='h-[700px] overflow-y-auto'>
                <h1 className='flex justify-center text-2xl text-center font-semibold text-purple-600 underline mb-2 '>Les informations nécessaires  </h1>
                <div className='grid grid-cols-8 gap-4 p-5 py-2 row-auto'>
                    <div className='col-span-8'>
                        <label htmlFor="raison" className="block text-gray-700 font-semibold mb-1 pl-2">Raison sociale</label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <FaPen className="w-5 h-5 text-gray-400 " />
                                </span>
                                <input 
                                autoComplete='off'
                                id="raison" 
                                name='raison'
                                onChange={formik.handleChange}
                                value={formik.values.raison}
                                onBlur={formik.handleBlur}
                                type="text" 
                                placeholder="Nom commercial" 
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.raison && formik.touched.raison ? 'border-red-500': ''}`}
                                />
                            </div>
                    </div>
                    <div className='col-span-4'>
                        <label htmlFor="activiteId" className="block text-gray-700 font-semibold mb-1 pl-2">Activté principale</label>
                        <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <BsPersonWorkspace className="w-5 h-5 text-gray-400 " />
                            </span>
                            <select
                            autoComplete='off'
                            id="activiteId" 
                            name="activiteId"
                            onChange={formik.handleChange}
                            value={formik.values.activiteId}
                            onBlur={formik.handleBlur}
                            className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.activiteId && formik.touched.activiteId ? 'border-red-500': ''}`}
                            > 
                            <option value="" label="Type d'activite" />
                            {typePoint.map(option => (
                                <option key={option.id} value={option.id}>{option.libelle}</option>
                            ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-span-4'>
                        <label htmlFor="rccm" className="block text-gray-700 font-semibold mb-1 pl-2">Registre de Commerce </label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <GiArchiveRegister className="w-5 h-5 text-gray-400 " />
                                </span>
                                <input 
                                autoComplete='off'
                                id="rccm" 
                                name='rccm'
                                onChange={formik.handleChange}
                                value={formik.values.rccm}
                                onBlur={formik.handleBlur}
                                type="text" 
                                placeholder="Registre de commerce " 
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.rccm && formik.touched.rccm ? 'border-red-500': ''}`}
                                />
                            </div>
                    </div>
                    <div className='col-span-8'>
                        <label htmlFor="gerant" className="block text-gray-700 font-semibold mb-1 pl-2">Gérant/Référents</label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <FaUserPen className="w-5 h-5 text-gray-400 " />
                                </span>
                                <input 
                                autoComplete='off'
                                id="gerant" 
                                name='gerant'
                                onChange={formik.handleChange}
                                value={formik.values.gerant}
                                onBlur={formik.handleBlur}
                                type="text" 
                                placeholder="Prenom et Nom du Gerant ou Referant" 
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.gerant && formik.touched.gerant ? 'border-red-500': ''}`}
                                />
                            </div>
                    </div>
                    <div className='col-span-8'>
                        <label htmlFor="email" className="block text-gray-700 font-semibold mb-1 pl-2">Adresse électronique</label>
                        <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <FaEnvelope className="w-5 h-5 text-gray-400 " />
                            </span>
                            <input
                            autoComplete='off'
                            id="email"
                            name='email'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            type="email"
                            placeholder="Saisissez l'email"
                            className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.email && formik.touched.email ? 'border-red-500' : ''}`}
                            />
                        </div>
                    </div>
                    <div className='col-span-4'>
                        <label htmlFor="fixe" className="block text-gray-700 font-semibold mb-1 pl-2">Téléphone Fixe</label>
                            <div className="relative">
                                <PhoneInput
                                    country={'sn'}
                                    onlyCountries={['sn', 'gn','ml', 'ci']}
                                    masks={{ 
                                        sn: '-..-...-..-..',
                                        gn: '-..-...-...',
                                        ml: '-..-...-...',
                                        ci: '-..-..-..-..'
                                    }}
                                    autoComplete='off'
                                    id="fixe"
                                    name='fixe'
                                    value={formik.values.fixe}
                                    onChange={(value) => {
                                        formik.setFieldValue('fixe', value);
                                    }}
                                    onBlur={() => {
                                        formik.handleBlur('fixe');
                                        setIsFocused(false);
                                    }}
                                    placeholder=" Numero du Fix"
                                    inputStyle={{
                                        'fontFamily':'serif',
                                        'width': '100%',
                                        'height': '2.5rem',
                                        'color': '#444',
                                        ...(isFocused && {
                                            outline: 'none',
                                            boxShadow: '0 0 0 2px #3b82f6',
                                            borderColor: 'transparent'
                                        
                                        })
                                    }}
                                    onFocus={() => setIsFocused(true)}
                                />
                            </div>
                    </div>
                    <div className='col-span-4'>
                        <label htmlFor="mobile" className="block text-gray-700 font-semibold mb-1 pl-2">Téléphone Mobile</label>
                            <div className="relative">
                                <PhoneInput
                                    country={'sn'}
                                    onlyCountries={['sn', 'gn','ml', 'ci']}
                                    masks={{ 
                                        sn: '-..-...-..-..',
                                        gn: '-..-...-...',
                                        ml: '-..-...-...',
                                        ci: '-..-..-..-..'
                                    }}
                                    id="mobile"
                                    name='mobile'
                                    value={formik.values.mobile}
                                    onChange={(value) => {
                                        formik.setFieldValue('mobile', value);
                                    }}
                                    onBlur={() => {
                                        formik.handleBlur('mobile');
                                        setIsFocused1(false);
                                    }}
                                    placeholder=" Numero mobile"
                                    inputStyle={{
                                        'fontFamily':'serif',
                                        'width': '100%',
                                        'height': '2.5rem',
                                        'color': '#444',
                                        ...(isFocused1 && {
                                            outline: 'none',
                                            boxShadow: '0 0 0 2px #3b82f6',
                                            borderColor: 'transparent'
                                        
                                        }),
                                        // ...(!formik.touched.mobileNumber && !formik.errors.fixnumber) ? { border: '#ef4444' } : null
                                    }}
                                    onFocus={() => setIsFocused1(true)}
                                />
                            </div>
                    </div>

                    <div className='col-span-8'>
                        <label htmlFor="ville" className="block text-gray-700 font-semibold mb-1 pl-2">Ville</label>
                        <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <FaCity  className="w-5 h-5 text-gray-400 " />
                            </span>
                            <select
                            autoComplete='off'
                            id="ville" 
                            name="ville"
                            onChange={formik.handleChange}
                            value={formik.values.ville}
                            onBlur={formik.handleBlur}
                            className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.ville && formik.touched.ville ? 'border-red-500': ''}`}
                            > 
                            <option value="" label="Selectionnez la Ville" />
                            {regions.map(option => (
                                <option key={option.id} value={option.nom}>{option.nom}</option>
                            ))}
                            </select> 
                        </div>
                    </div>

                    <div className='col-span-4'>
                        <label htmlFor="quartier" className="block text-gray-700 font-semibold mb-1 pl-2">Quartier</label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <GiVillage className="w-5 h-5 text-gray-400 " />
                                </span>
                                <input 
                                autoComplete='off'
                                id="quartier" 
                                name='quartier'
                                onChange={formik.handleChange}
                                value={formik.values.quartier}
                                onBlur={formik.handleBlur}
                                type="text" 
                                placeholder="Saisissez le quartier " 
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.quartier && formik.touched.quartier ? 'border-red-500': ''}`}
                                />
                            </div>
                    </div>

                    <div className='col-span-4'>
                        <label htmlFor="rue" className="block text-gray-700 font-semibold mb-1 pl-2">Rue</label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <FaStreetView className="w-5 h-5 text-gray-400 " />
                                </span>
                                <input 
                                autoComplete='off'
                                id="rue" 
                                name='rue'
                                onChange={formik.handleChange}
                                value={formik.values.rue}
                                onBlur={formik.handleBlur}
                                type="text" 
                                placeholder="Saisissez la rue " 
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.rue && formik.touched.rue ? 'border-red-500': ''}`}
                                />
                            </div>
                    </div>

                    <div className='col-span-8'>
                        <label htmlFor="adresse" className="block text-gray-700 font-semibold mb-1 pl-2">Adresse</label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <MdPlace className="w-5 h-5 text-gray-400 " />
                                </span>
                                <input 
                                autoComplete='off'
                                id="adresse" 
                                name='adresse'
                                onChange={formik.handleChange}
                                value={formik.values.adresse}
                                onBlur={formik.handleBlur}
                                type="text" 
                                placeholder="Saisissez l'adresse " 
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.adresse && formik.touched.adresse ? 'border-red-500': ''}`}
                                />
                            </div>
                    </div>
                </div>
            </div>
            <div className='flex  justify-center item-center mt-4'>
                <Button type='submit' size="md" disabled={isLoading}>
                    <span className="pr-2">
                        {
                            isLoading ? (<Spinner color="info" size="md" />) : (<FaCheckCircle size={18} />)
                        }
                    </span>
                        {
                            isLoading ? "En Cours d'enregistrement..." : 'Sauvegarde'
                        }
                </Button>
            </div>
        </form> 
  )
}

export default UpdateDistributeurs

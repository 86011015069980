import React, { useEffect, useState } from 'react'
import Sidebar from '../../template/sidebar/Sidebar';
import HomeNavbar from '../../template/navbar/HomeNavbar';
import { useProfile } from '../../UserDataStock/Profils/Profile';
import { motion } from "framer-motion";
import AdminUserBanner from '../../template/banner/AdminUserBanner';
import VendeursFacturesDatatable from '../../components/datatables/voyagesDatatable/VendeursFacturesDatatable';
import {GetAllContratsVoyageByPV } from '../../api/voyages/DevisEtContratsVoyage';

const FacturesVoyageList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [rowData, setRowData] = useState([]);
    const [isloading, setIsLoading] = useState(true);
    const { position, token, userDetails } = useProfile();
    const [numData , setNumData] = useState(0);
    const idpv = userDetails?.pointDeVenteId;
    const handleOpenClose = () => setIsOpen(!isOpen);

    useEffect(() => {
      const mesContrats = async () => {
        try {
          const donnes = await GetAllContratsVoyageByPV (token, position, idpv);
          setIsLoading(true)
          setNumData(donnes.length);
          const formattedData = donnes.map(data => ({
            id: data.contrat.ref,
            date: data.contrat.dateSouscription,
            policeNo: data.contrat.ref,
            souscripteur: {
              nom: `${data.subscriber.prenom} ${data.subscriber.nom}`,
              telephone: data.subscriber.telephone,
              email: data.subscriber.email
            },
            decomposition: {
              montantTotal: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.montantTotal, 0),
            },
            contrat: data.contrat.paymentMethod,
            pdv: data.pointDeVente.libelle              
          }));
          setRowData(formattedData);
        } catch (error) {
          console.error('Erreur Impossible de recuperer les contrats:', error);
        } finally {
          setIsLoading(false);
        }
      };
      mesContrats();
    }, [token, position, idpv]);

    const filteredData = rowData.filter((row) => {
      const lowerSearchText = searchText.toLowerCase();
      return (
        row.id?.toLowerCase().includes(lowerSearchText) ||
        row.date?.toLowerCase().includes(lowerSearchText) ||
        row.policeNo?.toLowerCase().includes(lowerSearchText) ||
        row.souscripteur?.nom?.toLowerCase().includes(lowerSearchText) ||
        row.souscripteur?.telephone?.toLowerCase().includes(lowerSearchText) ||
        row.souscripteur?.email?.toLowerCase().includes(lowerSearchText) ||
        row.pdv?.toLowerCase().includes(lowerSearchText)
      );
    });

  return (
    <div className='flex h-screen'>
      <Sidebar isOpen={isOpen}  onToggle={handleOpenClose}/> 
      <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
          <HomeNavbar isOpen={isOpen} />
          <main className="p-6 mt-16">
              <AdminUserBanner  /> 
              <div className='flex justify-between items-center mb-4'>
                <h2 className="text-xl font-bold text-white bg-purple-700 px-4 py-2 rounded-md">
                  Liste des Factures Voyage
                  <span className='ml-3'>({numData})</span>
                </h2>
                <div className='flex justify-end items-center mx-8 mb-4'>
                  <div className='relative w-64'>
                    <input 
                     type="text"
                     placeholder='Rechercher une facture...'
                     className="w-full px-4 py-2 rounded-lg border border-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    /> 
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                        </svg>
                    </div>
                  </div>
                </div>
              </div>
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
                exit={{ y: 100, opacity: 0 }}
                className='border rounded-lg'
              >
                <VendeursFacturesDatatable data={filteredData} chargement={isloading} /> 
              </motion.div>
           </main>
      </div>
    </div>
  )
}

export default FacturesVoyageList

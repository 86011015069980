import React, { useState } from 'react'
import { motion } from "framer-motion";
import { useFormik } from 'formik';
import { PlaceValidation } from '../../utils/FormValidation';
import { PiListNumbersBold } from "react-icons/pi";
import { Button, Spinner } from 'keep-react';
import { FaCheckCircle,  } from 'react-icons/fa';
import { ImPower } from "react-icons/im";
import { IoShieldCheckmarkSharp } from 'react-icons/io5';
import { BsHourglassSplit } from 'react-icons/bs';

const ViewExcellUpload = ({handleSave, data ,isLoading}) => {

   
      const validate = PlaceValidation;
      const formik = useFormik({ 
        initialValues: {
            Nbplace: "",
        },
        validate,
        onSubmit: handleSave
      })
      
      
  return (
    <>
        <div className='w-full'>
        <form className='w-full' encType="multipart/form-data" onSubmit={formik.handleSubmit} method='post'>
            <div className='grid grid-cols-8 px-14 py-6 space-y-4 row-auto'>
            <div className='col-span-4'>
                <label htmlFor="place" className="block text-gray-700 font-semibold mb-1 pl-2">Nombre de Place</label>
                <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <PiListNumbersBold className="w-5 h-5 text-gray-400" />
                </span>
                <input 
                    autoComplete='off'
                    id='Nbplace' 
                    name='Nbplace'
                    onChange={formik.handleChange}
                    value={formik.values.Nbplace}
                    onBlur={formik.handleBlur}
                    type="number"
                    placeholder="Saisissez le Nombre de Place" 
                    className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.Nbplace && formik.touched.Nbplace ? 'border-red-500': ''}`}
                />
                </div>
            </div>
            <div className='col-span-4'>
                <div className='flex justify-center item-center py-2'>
                <Button type='submit' size="md" disabled={isLoading}>
                    <span className="pr-2">
                    {
                        isLoading ? (<Spinner color="info" size="md" />) : (<FaCheckCircle size={18} />)
                    }
                    </span>
                    {
                    isLoading ? "En Cours d'enregistrement..." : 'Sauvegarde'
                    }
                </Button>
                </div>
            </div>
            {formik.touched.Nbplace && formik.errors.Nbplace ? (
                <motion.div
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -100, opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="italic text-md col-span-8 text-red-500"
                >
                {formik.errors.Nbplace}
                </motion.div>) : null
            }
            </div>
        </form>
        {
            data && data.length > 0 && (
            <motion.div className="overflow-x-auto">
                <div className="overflow-y-auto max-h-96">
                    <table
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="w-full bg-white"
                    >
                    <thead>
                        <tr className='bg-purple-700 text-white text-center'>
                            {
                            Object.keys(data[0]).map((key, index) => {
                                let header = data[0][key];
                                if (!isNaN(header)) {
                                header = `${header} mois`;
                                }
                                return (
                                <th className='font-bold text-xl py-4 px-6' key={key}>
                                    <div className="flex items-center justify-center">
                                    {header}
                                    {index === 0 && <IoShieldCheckmarkSharp className="ml-2" />}
                                    {index === 1 && <ImPower className="ml-2" />}
                                    {index === 2 && <BsHourglassSplit className="ml-2" />}
                                    {index === 3 && <BsHourglassSplit className="ml-2" />}
                                    {index === 4 && <BsHourglassSplit className="ml-2" />}
                                    {index === 5 && <BsHourglassSplit className="ml-2" />}
                                    </div>
                                </th>
                                );
                            })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => {
                            if (index > 0) { 
                                return (
                                <motion.tr
                                    key={index}
                                    whileHover={{ scale: 1.02 }}
                                    className="hover:bg-blue-200 cursor-pointer"
                                >
                                    {Object.values(row).map((value, index) => (
                                    <td className='py-2 px-4 border-b text-center' key={index}>{value}</td>
                                    ))}
                                </motion.tr>
                                );
                            }
                            return null; 
                        })}
                    </tbody>
                    </table>
                </div>
            </motion.div>
            )
        }
        </div>
    </>
  )
}

export default ViewExcellUpload


import { Timeline, TimelineContent, TimelineItem, TimelinePoint } from 'keep-react'
import React from 'react'
import { TbPlaneInflight } from "react-icons/tb";
import { FaCarSide } from "react-icons/fa";

const HistoriqueDesVentes = () => {
  return (
    <Timeline >
      <TimelineItem >
        <TimelinePoint  />
        <TimelineContent >
          <p className="text-body-5 font-normal leading-[1.4] text-metal-400 dark:text-metal-300">23 Juin 2024 à 12h : 38mn</p>
          <h1 className="font-medium text-purple-700 ">Vente Voyage</h1>
          <div className='flex space-x-2'>
            <div className='p-3 items-center justify-center rounded-full border'>
              <TbPlaneInflight size={20} className='text-purple-700 '/>   
            </div>
            <div >
              <h1 className="font-medium text-metal-900 dark:text-white">Moustapha Badji</h1>
              <p className="text-body-4 font-normal leading-[1.4] text-metal-700 ">Telephone: +221-77-777-58-96</p>
            </div>
          </div>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelinePoint />
        <TimelineContent>
          <p className="text-body-5 font-normal leading-[1.4] text-metal-400 dark:text-metal-300">23 Juin 2024 à 11h : 13mn</p>
          <h1 className="font-medium text-purple-700 ">Vente Automobile</h1>
          <div className='flex space-x-2'>
            <div className='p-3 items-center justify-center rounded-full border'>
              <FaCarSide size={20} className='text-purple-700 '/>   
            </div>
            <div >
              <h1 className="font-medium text-metal-900 dark:text-white">Juliette Ndiaye</h1>
              <p className="text-body-4 font-normal leading-[1.4] text-metal-700 ">Telephone: +221-77-47-36-16</p>
            </div>
          </div>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelinePoint />
        <TimelineContent>
          <p className="text-body-5 font-normal leading-[1.4] text-metal-400 dark:text-metal-300">23 Juin 2024 à 10h : 45mn</p>
          <h1 className="font-medium text-purple-700 ">Vente Automobile</h1>
          <div className='flex space-x-2'>
            <div className='p-3 items-center justify-center rounded-full border'>
              <FaCarSide size={20} className='text-purple-700 '/>   
            </div>
            <div >
              <h1 className="font-medium text-metal-900 dark:text-white">Babacar Diagne</h1>
              <p className="text-body-4 font-normal leading-[1.4] text-metal-700 ">Telephone: +221-76-477-25-46</p>
            </div>
          </div>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  )
}

export default HistoriqueDesVentes


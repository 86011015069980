
import React, { useEffect, useState } from 'react';
import { FiDownload, FiCreditCard } from 'react-icons/fi';
import dioko from "/src/images/Diokotech.png"
import Sidebar from '../../template/sidebar/Sidebar';
import HomeNavbar from '../../template/navbar/HomeNavbar';
import moment from 'moment';
import 'moment/locale/fr'; 
import AutoPaiement from '../../components/paiement/AutoPaiement';
const DevisAutomobile = () => {

    const [stepData, setStepData] = useState({});
    const [isOpen, setIsopen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleOpenClose = () => { 
        setIsopen(!isOpen);
    }

    const handleDrawer = () => { 
        setOpenDrawer(!openDrawer)
    }

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem('formData'));
        if (data) {
            setStepData(data);
        }
    }, []);

    const step1Data = stepData.step1Data || {};
    const step2Data = stepData.step2Data || {};
    const step3Data = stepData.step3Data || {}; 

    

    return (
       <div className='flex h-screen'>
         <Sidebar isOpen={isOpen} onToggle={handleOpenClose} /> 
         <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
            <HomeNavbar isOpen={isOpen} />
              <main className="p-6 mt-16">
                <AutoPaiement drawOpen={openDrawer} closeDrawer={handleDrawer} />
                <div className="max-w-4xl mx-auto bg-white shadow-2xl rounded-lg overflow-hidden">
                    {/* Header */}
                    <div className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white p-6 flex justify-between items-center">
                        <h1 className="text-3xl font-bold">DEVIS AUTOMOBILE</h1>
                        <div className="flex space-x-4">
                            <button className="bg-blue-700 hover:bg-blue-800 py-2 px-4 rounded-lg text-white flex items-center shadow-md transition transform hover:scale-105"
                            onClick={()=> window.print()}
                            >
                                <FiDownload className="mr-2" /> TÉLÉCHARGER
                            </button>
                            <button className="bg-green-500 hover:bg-green-600 py-2 px-4 rounded-lg text-white flex items-center shadow-md transition transform hover:scale-105"
                            onClick={handleDrawer}
                            >
                                <FiCreditCard className="mr-2" /> PAYER
                            </button>
                        </div>
                    </div>

                    {/* Quote Information */}
                    <div className="p-8 space-y-6">
                        {/* Subscriber and Quittance Info */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="p-6 border rounded-lg shadow-md bg-gray-50">
                                <h2 className="text-lg font-semibold mb-4 text-blue-700">Souscripteur</h2>
                                <p><strong>Point de vente:</strong> Diokotech</p>
                                <p><strong>Nom client:</strong> {step2Data.prenom +" "+ step2Data.nom}</p>
                                <p><strong>Adresse:</strong> {step2Data.adresse}</p>
                                <p><strong>Téléphone:</strong> {step2Data.telephone_mobile}</p>
                                <p><strong>Email:</strong> {step2Data.adresse_email}</p>
                            </div>
                            <div className="p-6 border rounded-lg shadow-md bg-gray-50">
                                <h2 className="text-lg font-semibold mb-4 text-blue-700">Quittance</h2>
                                <p><strong>Assuré:</strong> {step2Data.prenom +" "+ step2Data.nom}</p>
                                <p><strong>Adresse assuré:</strong> {step2Data.adresse}</p>
                                <p><strong>Date d'émission:</strong> {moment().format('DD/MM/YYYY')}</p>
                                <p><strong>Date d'effet:</strong> {moment(step1Data.date_effet).locale('fr').format('DD/MM/YYYY')}</p>
                                <p><strong>Date d'expiration:</strong > <strong className=' text-red-500'>{moment(step1Data.date_fin).locale('fr').format('DD/MM/YYYY')}</strong></p>
                            </div>
                        </div>

                        {/* Vehicle Information */}
                        <div className="p-6 border rounded-lg shadow-md bg-gray-50">
                            <h2 className="text-lg font-semibold mb-4 text-blue-700">Véhicule</h2>
                            <div className="grid grid-cols-2 gap-4">
                                <p><strong>Catégorie:</strong> {step1Data.categorie}</p>
                                <p><strong>Immatriculation:</strong> {step1Data.matricule}</p>
                                <p><strong>Marque:</strong> {step1Data.marque_du_vehicule}</p>
                                <p><strong>Modèle:</strong> {step1Data.modele_du_vehicule}</p>
                                <p><strong>Énergie:</strong> {step1Data.energie}</p>
                                <p><strong>Puissance fiscale:</strong> {step1Data.puissance_fiscal}</p>
                                <p><strong>Places assises:</strong> {step1Data.nombre_de_place}</p>
                            </div>
                        </div>

                        {/* Premium Breakdown */}
                        <div className="p-6 border rounded-lg shadow-md bg-gray-50">
                            <h2 className="text-lg font-semibold mb-4 text-blue-700">Décomposition de la prime</h2>
                            <div className="grid grid-cols-2 gap-4 text-gray-700">
                                <p><strong>Prime nette:</strong> {step1Data.prime_nette} fcfa</p>
                                <p><strong>Accessoires:</strong> {step1Data.accessoire} fcfa</p>
                                <p><strong>FGA:</strong> {step1Data.FGA} fcfa</p>
                                <p><strong>Taxes:</strong> {step1Data.taxes} fcfa</p>
                                <p><strong>Prime TTC:</strong> {step1Data.prime_TTC} fcfa</p>
                                <p><strong>Frais de service:</strong> {step1Data.commission} fcfa</p>
                                <p className="text-xl font-bold mt-6 text-red-600">Total à payer:</p>
                                <p className="text-xl font-bold mt-6 text-red-600">{step1Data.montant_total} fcfa</p>
                            </div>
                            <div className='flex justify-between'>
                                
                            </div>
                            
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="bg-gray-100 p-6 flex justify-center border-t">
                        <img src={dioko} alt="Logo" className="h-20 " />
                    </div>
                </div>
             </main>
         </div>
       </div>
    );
}

export default DevisAutomobile

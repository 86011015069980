import axios from "axios";


export async function NewDevisVoyage(ref, token, position){ 
    try{
        const response = await axios({ 
            method: 'POST',
            url: `${import.meta.env.VITE_BACKLOG}/voyage/contrats`,
            headers:{
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: ref 
        });
        return response;
    } catch (error) { 
        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

export async function ModifContratVoyage(ref, token, position, data){
    try{
        const response = await axios({
            method: 'PUT',
            url: `${import.meta.env.VITE_BACKLOG}/voyage/contrats/${ref}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: data 
        });
        return response;
    } catch (error){
        if (error.response) {
            return error.response;
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log("Erreur", error.message);
        }
        console.log(error.config);
        return error;
    }
}

export async function NewDevisVoyageGn(ref, token, position){ 
    try{
        const response = await axios({ 
            method: 'POST',
            url: `${import.meta.env.VITE_GUINEE}/voyage/contrats`,
            headers:{
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`,
            },
            data: ref 
        });
        return response;
    } catch (error) { 
        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

export async function GetAllDevisVoyageByPV(token, position, pointDeVenteId) {
    try {
        const response = await axios({
            method: 'GET',
            url: `${import.meta.env.VITE_BACKLOG}/voyage/contrats/devis`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'pointDeVenteId': `${pointDeVenteId}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log("Erreur", error.message);
        }
        console.log(error.config);
        return error;
    }
}

export async function GetAllDevisVoyageByDB(token, position, distributeur) {
    try {
        const response = await axios({
            method: 'GET',
            url: `${import.meta.env.VITE_BACKLOG}/voyage/contrats/devis`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'distributeurId': `${distributeur}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log("Erreur", error.message);
        }
        console.log(error.config);
        return error;
    }
}

export async function GetAllContratsVoyageByPV(token, position, pointDeVenteId) {
    try {
        const response = await axios({
            method: 'GET',
            url: `${import.meta.env.VITE_BACKLOG}/voyage/contrats`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'pointDeVenteId': `${pointDeVenteId}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log("Erreur", error.message);
        }
        console.log(error.config);
        return error;
    }
}

export async function GetAllContratsVoyageByDB(token, position, distributeurId) {
    try {
        const response = await axios({
            method: 'GET',
            url: `${import.meta.env.VITE_BACKLOG}/voyage/contrats`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'distributeurId': `${distributeurId}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log("Erreur", error.message);
        }
        console.log(error.config);
        return error;
    }
}


export async function GetOneVoyageContrat(ref,token, position){ 
    try{ 
        const response = await axios({
            method: 'GET',
            url: `${import.meta.env.VITE_BACKLOG}/contrats/${ref}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        });
        return response.data
    } catch (error){ 
        if (error.response) {
            return error.response;
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log("Erreur", error.message);
        }
        console.log(error.config);
        return error;
    }
}
import axios from "axios";

//// Ajout d'un nouveau Assureru /////////////
export async function NewAssureur(data, token, position){ 
    try{
        const response = await axios({ 
            method: 'post',
            url: `${import.meta.env.VITE_BACKLOG}/assurances`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: data
        })
        return response;
    } catch(error){
        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

//ajout d'un assureur en guinee ///
export async function NewAssureurGN(data, token, position){ 
    try{
        const response = await axios({ 
            method: 'post',
            url: `${import.meta.env.VITE_GUINEE}/assurances`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: data
        })
        return response;
    } catch(error){
        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

// recuperer tout les assurerurs
export async function GetAllAssureur(token, position) { 
    try{ 
        const response = await axios({ 
            method: 'get',
            url: `${import.meta.env.VITE_BACKLOG}/Assurances`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        })
       
        return response.data;
    } catch (error) { 
        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

export async function GetAssurforSubscribe(token, position) { 
    try{ 
        const response = await axios({ 
            method: 'get',
            url: `${import.meta.env.VITE_BACKLOG}/Assurances`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                // 'countryCode': `${position?.location?.country_code3 || ''}`
                'countryCode': "GUI"
            }
        })
       
        return response.data;
    } catch (error) { 
        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

////// supression d'un assureru ///////

export async function DeleteAssureur (id, token, position) { 
    try{
        const response = await axios({
            method: 'delete',
            url: `${import.meta.env.VITE_BACKLOG}/assurances/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        })
        return response;

    } catch(error) { 

        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}


/////// mise a jour d'un assureur //////

export async function UpAssureur (id,modification, token, position){ 

    try{
        const response = await axios({ 
            method: 'put',
            url: `${import.meta.env.VITE_BACKLOG}/assurances/${id}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: modification
        })
        return response;
    } catch(error) {

        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}


////// recuperer un assureur /////////

export async function GetSingleAssureur(id, token, position) { 
    try{
        const response = await axios({
            method: 'get',
            url: `${import.meta.env.VITE_BACKLOG}/assurances/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        })
        return response.data;
    } catch(error) {

        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}


import React, { useEffect, useState } from 'react'
import Sidebar from '../../../template/sidebar/Sidebar';
import HomeNavbar from '../../../template/navbar/HomeNavbar';
import AdminUserBanner from '../../../template/banner/AdminUserBanner';
import { motion } from "framer-motion";
import { FaShop } from 'react-icons/fa6';
import PointsDeVentesDatatable from '../../../components/datatables/ressources/pointsdeventes/PointsDeVentesDatatable';
import MyModal from '../../../components/dialog/MyModal';
import AddPointsDeVente from '../../../components/forms/pointsdeventes/AddPointsDeVente';
import { getAllDistributeurs } from '../../../api/distributeurs/Distributeurs';
import { getAllTypePointsOfSells } from '../../../api/pointsdeventes/TypedePointsDeVente';
import { AddPointDeVente, GetAllPointVentes } from '../../../api/pointsdeventes/PointsDeVente';
import { failNotification, succesNotification } from '../../../components/notifications/Notifications';
import { useParams } from 'react-router-dom';
import { useProfile } from '../../../UserDataStock/Profils/Profile';


const PointsDeVentesList = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [pointModal , setPointModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chargement, setChargement] = useState(true);
    const [pointModalData , setPointModalData] = useState([]);
    const [typePoint , setTypePoint] = useState([]);
    const [lesDistributeurs, setLesDistributeurs] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPoints, setFilteredPoints] = useState([]);
    const idS = useParams();
    const {position, token, userDetails } = useProfile();
    
    
    useEffect(() => {
        setFilteredPoints(pointModalData);
      }, [pointModalData]);

    useEffect(() => {
        const fetchTypes = async () => {
            try {
                const lestypes = await getAllTypePointsOfSells(token, position);
                setTypePoint(lestypes);
                setIsLoading(false); 
            } catch (error) {
                console.error('Erreur Impossible de recuperer les roles:', error);
                
            }
        };
        fetchTypes();
    }, [])
    const filterPoints = (query) => {
        const filtered = pointModalData.filter((point) =>
          point.libelle.toLowerCase().includes(query.toLowerCase()) ||
          point.ville.toLowerCase().includes(query.toLowerCase()) ||
          point.email.toLowerCase().includes(query.toLowerCase()) ||
          point.mobile.includes(query) ||
          point.fixe.includes(query)
        );
        setFilteredPoints(filtered);
    };

    useEffect(() => {
        const fetchDistrib = async () => {
            try {
                const lesdistrib = await getAllDistributeurs(token, position);
                setLesDistributeurs(lesdistrib);
                setIsLoading(false); 
            } catch (error) {
                console.error('Erreur Impossible de recuperer les distributeurs:', error);
                
            }
        };
        fetchDistrib();
    }, [])

    //juste pour le test de maintenant
    

    async function PointVentes() { 
        try{ 
            let response = await GetAllPointVentes(idS.id, token, position);
            setChargement(true)
            setPointModalData(response);
        }catch(error){ 
            console.log(error)
        } finally {
            setChargement(false)
        }
    }

    useEffect(() => { 
        PointVentes();
    },[])


    const FormData = (data) => { 
        setIsLoading(true)
        var addPointSeller={
            libelle: data?.libelle,
            type: data?.type,
            adresse: data?.adresse,
            email: data?.email,
            fixe: data?.fixe, 
            mobile: data?.mobile,
            rccm: data?.rccm,
            ville: data?.ville,
            rue: data?.rue,
            quartier: data?.quartier,
            latitude: data?.latitude,
            longitude: data?.longitude, 
            distributeurId: data?.distributeurId
        }
        AddPointDeVente(addPointSeller, token, position)
        .then(function(result){ 
            console.log(result);
            if(result && result.status === 201){ 
                succesNotification(result.data.message);
                setPointModal(false);
                PointVentes();
            } else if (result) { 
                failNotification(result.data.message);
            } else {
                failNotification("Une erreur inconnue s'est produite.");
            }
        })
        .catch(function(error){ 
            console.log(error)
        })
        .finally(function(){ 
            setIsLoading(false)
        });
    }

    const handleOpenClose = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div className='flex h-screen'>
        <Sidebar isOpen={isOpen}  onToggle={handleOpenClose}/> 
        <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
            <HomeNavbar isOpen={isOpen} />
                <main className="p-6 mt-16">
                    <AdminUserBanner content={'rien a dire pour le moment '} /> 

                    <MyModal 
                     isOpen={pointModal}
                     closeModal={()=>setPointModal(false)}
                     content={
                        <AddPointsDeVente handleRegistration={FormData} isLoading={isLoading} pointVente={typePoint} distrib={idS.id} /> 
                     }
                    /> 
                    <div className='flex justify-end '>
                        <div className='flex space-x-2 py-2 px-4 mr-5 bg-purple-300 text-white items-center rounded-lg cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700 hover:text-white'
                        onClick={()=> setPointModal(true)}
                        >
                            <FaShop  className='w-9 h-8'/> 
                        </div>
                    </div>

                    <div className=' bg-white justify-center rounded-xl pb-4 mt-6 row-auto'>
                        <div className='flex w-full rounded-lg justify-between m-4 p-5 mt-6  '>
                            <div className='font-semibold text-lg text-purple-600 ml-2 underline'>
                                La Liste des Points de Ventes  
                            </div>
                        </div>
                        {/* Ajout du champ de recherche */}
                            <div className='flex justify-end items-center mx-8 mb-4'>
                                <div className='relative w-64'>
                                <input
                                    type="text"
                                    placeholder="Rechercher un point de vente..."
                                    className="w-full px-4 py-2 rounded-lg border border-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
                                    value={searchQuery}
                                    onChange={(e) => {
                                    setSearchQuery(e.target.value);
                                    filterPoints(e.target.value);
                                    }}
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                </div>
                            </div>
                            <motion.div
                            initial={{ y: 50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }} 
                            exit={{ y: 100, opacity: 0 }}
                            className=' p-8 border rounded-lg mx-8'
                            >
                                <PointsDeVentesDatatable data={filteredPoints} chargement={chargement} /> 
                            </motion.div>
                    </div>
                </main>
        </div>
  </div>
  )
}

export default PointsDeVentesList;


import axios from "axios"

export async function GetPaysDest(token, position){ 
    try{ 
        const response = await axios({ 
            method: 'GET',
            url: `${import.meta.env.VITE_BACKLOG}/countries`,
            headers:{ 
                'Content-Type': 'application/json',
                'Accept': '*/*' ,
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        })
        return response.data;
    } catch(error){ 

        if(error.response){
            return(error.response)
        } else if (error.request){
            console.log(error.request)
        } else{
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}
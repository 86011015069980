import { useSelector } from "react-redux";
import { createSelector } from "reselect";

// Création d'un sélecteur optimisé
const selectUserProfile = createSelector(
  (state) => state.user.userDetails,
  (state) => state.user.token,
  (state) => state.user.position,
  (userDetails, token, position) => ({ userDetails, token, position })
);

export const useProfile = () => {
  return useSelector(selectUserProfile);
};

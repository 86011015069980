import React, { useEffect, useState } from 'react'
import Sidebar from '../../template/sidebar/Sidebar';
import HomeNavbar from '../../template/navbar/HomeNavbar';
import AdminUserBanner from '../../template/banner/AdminUserBanner';
import MyModal from '../../components/dialog/MyModal';
import { BsPersonFillAdd } from 'react-icons/bs';
import AddUtilisateur from '../../components/forms/admins/AddUtilisateur';
import { GetAllRole } from '../../api/admin/RolesUtilisateurs';
import { motion } from "framer-motion";
import { failNotification, succesNotification } from '../../components/notifications/Notifications';
import { AddUser, GetAllUsers } from '../../api/admin/Utlisateurs';
import UsersDatatables from '../../components/datatables/admin/UsersDatatables';
import { useProfile } from '../../UserDataStock/Profils/Profile';

const UtilisateursList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [userModal, setUserModal] = useState(false);
    // const [rolesModal, setRolesModal] = useState(false);
    const [lesRoles, setLesRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [activeOption , setActiveOption] = useState('Role');
    const [lesUtilisateurs, setLesUtilisateurs] = useState([]);
    const [refresh , setRefresh] = useState(false);
    const {position, token, userDetails } = useProfile();

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const roles = await GetAllRole();
                setLesRoles(roles);
                setIsLoading(false); 
            } catch (error) {
                console.error('Erreur Impossible de recuperer les roles:', error);
                
            }
        };
        fetchRoles();
    }, [])

    

    //// recuperation des utilisateurs ////
    async function Utilisateurs() { 
      try{ 
        let response  = await GetAllUsers(token, position);
        setLesUtilisateurs(response);
      }catch(error){ 
        console.log(error);
      }
    }

    useEffect(() => {
      Utilisateurs();
      if(refresh > 0){ 
        Utilisateurs();
      }
    }, [refresh]);


    // actualise apres suppression 
    // useEffect(() => { 
    //   if(refresh > 0){ 
    //     Utilisateurs();
    //   }
    // },[refresh])


  // enregistrement d'un utilisateur //
   const formData = (data) =>{ 
      setIsLoading(true)
      var addUser={ 
        nom: data?.nom,
        prenom: data?.prenom,
        email: data?.email,
        username: data?.username,
        mobile: data?.mobile,
        role: data?.role,
        image: data?.image
      }

      // alert(JSON.stringify(addUser))
      AddUser(addUser, token, position)
      .then(function(result){ 
        if(result.status === 201){ 
          succesNotification(result.data.message);
          setUserModal(false);
          Utilisateurs();
        } else { 
          failNotification(result.data.message);
        }
      })
      .catch(function(error){ 
        console.log(error)
      })
      .finally(function(){ 
        setIsLoading(false);
      })

  }


  

   
  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(()=>{ 
  //   if(refresh > 0){ 
  //     Roles();
  //   }
  // }, [refresh])

  

  return (
    <div className='flex h-screen'>
        <Sidebar isOpen={isOpen} onToggle={handleOpenClose}/> 
        <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
        <HomeNavbar isOpen={isOpen} />
          <main className="p-6 mt-16">
              <AdminUserBanner  /> 
              
              {/* le modale de l'utilisateur */}
              <MyModal
               isOpen={userModal}
               closeModal={()=>setUserModal(false)}
               content={<AddUtilisateur data={lesRoles} handleRegistration={formData} isLoading={isLoading}  /> }
              />

              {/* le modale du role  */}
              {/* <MyModal 
                isOpen={rolesModal}
                closeModal={() => setRolesModal(false)}
                content={<AddRoles handleRegistration={formData} isLoading={isLoading}  />}
              />  */}

               {/* ajouter assureur  */}
              <div className='flex justify-end '>
                <div className='flex space-x-2 py-2 px-4 mr-5 bg-purple-300 text-white items-center rounded-lg cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700 hover:text-white'
                  // onClick={ activeOption  === 'Role' ? ()=>setRolesModal(true) : ()=>setUserModal(true)}
                  onClick={()=>setUserModal(true)}
                  >
                    {/* <BsFillPlusCircleFill className='w-8 h-7' /> */}
                    {/* <h1 className='text-lg font-semibold'>
                      Ajouter Un Utilisateur
                      </h1> */}
                    <BsPersonFillAdd  className='w-10 h-8'/> 
                </div>
              </div>

              <div className=' bg-white justify-center rounded-xl pb-4 mt-6 row-auto'>
                <div className='flex w-full rounded-lg justify-between m-4 p-5 mt-6  '>
                  <div className='font-semibold text-lg text-purple-600 ml-2 underline'>
                    La Liste des Utilisateurs 
                  </div>
                  {/* <div className='flex space-x-2'>
                      <Button size='sm' variant={activeOption === 'Role' ? '' : 'outline'} onClick={() => setActiveOption('Role')}>
                          Roles
                      </Button>
                      <Button size='sm' variant={activeOption === 'Utilisateur' ? '' : 'outline'} onClick={() => setActiveOption('Utilisateur')}>
                          Utilisateurs
                      </Button>
                  </div> */}
                </div>
                  <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }} 
                  exit={{ y: 100, opacity: 0 }}
                  className=' p-8 border rounded-lg mx-8'
                  >
                    <UsersDatatables data={lesUtilisateurs} confirmation={setRefresh}  />
                  </motion.div>
              </div>
           </main>
      </div>
    </div>
  )
}

export default UtilisateursList

import { useFormik } from 'formik';
import { Drawer, DrawerContent } from 'keep-react';
import Lottie from 'lottie-react';
import loader from '/src/images/loader.json';
import React from 'react'
import { AiFillCloseCircle } from 'react-icons/ai';
import { BiCalculator, BiCategory } from 'react-icons/bi';
import { BsHourglassSplit } from 'react-icons/bs';
import { FaCoins } from 'react-icons/fa';
import { FcSettings } from 'react-icons/fc';
import { IoIosFlash } from 'react-icons/io';
import { MdLocalGasStation, MdOutlineAirlineSeatReclineNormal } from 'react-icons/md';

const CalculatriceTarifAuto = ({drawOpen, closeDrawer}) => {


    const formik = useFormik({ 
        initialValues: { 
            categories: "",
            nbplace: "",
            energie: "",
            puissance: "",
            duree: ""
        }
    })

    const categoriesOptions = [
        { id: '1', label: 'CAT 1 (Promenade et Affaire)' },
        { id: '2', label: 'CAT 2 (TPC)' },
        { id: '3', label: 'CAT 3 (TPM)' },
        { id: '4', label: 'CAT 4 (TPV)' },
        { id: '5', label: 'CAT 6 (Garagiste)' },
        { id: '6', label: 'CAT 7 (Vehicule Auto-Ecole)' },
        { id: '7', label: 'CAT 8 (Location sans Chauffeur)' },
        { id: '8', label: 'Flotte TPV' }
      ];

  return (
    <Drawer isOpen={drawOpen} position='right' >
        <DrawerContent className='w-3/12 border'>
            <AiFillCloseCircle 
                className='m-5 text-gray-500 cursor-pointer text-3xl'
                onClick={closeDrawer}
            />
            <div className='bg-purple-600 mx-8 rounded-lg flex justify-center items-center text-white'>
                <BiCalculator size={25}  /> 
                <h1 className=' text-lg  font-semibold p-5'>Simulations des Tarifs Automobiles</h1>
            </div>
            <div className='border mx-8 my-4 p-4 rounded-lg'>
                <div className='flex space-x-2'>
                    <FcSettings size={25} />
                    <h1 className='font-semibold'>Parametres</h1>
                </div>
                <form>
                    <div className='grid grid-cols-8 gap-4 py-5 row-auto'>
                        <div className='col-span-8'>
                            <label htmlFor="categories" className="block text-gray-700 font-semibold mb-1 pl-2">Catégorie</label>
                                <div className='relative'>
                                    <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                                        <BiCategory className="w-5 h-5 text-gray-400 " />
                                    </span>
                                    <select
                                        autoComplete='off'
                                        id="categorie"
                                        name='categorie'
                                        onChange={formik.handleChange}
                                        value={formik.values.categorie}
                                        onBlur={formik.handleBlur}
                                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.categorie && formik.touched.categorie ? 'border-red-500' : ''}`}
                                        >
                                        <option value="" label="Sélectionner la catégorie du véhicule" />
                                        {categoriesOptions.map(option => (
                                            <option key={option.id} value={option.id}>{option.label}</option>
                                        ))}
                                    </select>
                                </div>
                        </div>
                        <div className='col-span-4'>
                            <label htmlFor="nombre_de_place" className="block text-gray-700 font-semibold mb-1 pl-2">Nombre de places</label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <MdOutlineAirlineSeatReclineNormal  className="w-5 h-5 text-gray-400 " />
                                </span>
                                <select
                                autoComplete='off'
                                id="nombre_de_place" 
                                name="nombre_de_place"
                                onChange={formik.handleChange}
                                value={formik.values.nombre_de_place}
                                onBlur={formik.handleBlur}
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.libelle && formik.touched.libelle ? 'border-red-500': ''}`}
                                > 
                                <option value="" label="Sélectionner le nombre de place du véhicule" />
                                {/* {nombreDePlacesOptions.map(option => (
                                    <option key={option.id} value={option.id}>{option.label}</option>
                                ))} */}
                                </select>
                            </div>
                        </div>
                        <div className='col-span-4'>
                            <label htmlFor="energie" className="block text-gray-700 font-semibold mb-1 pl-2">Energies</label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <MdLocalGasStation className="w-5 h-5 text-gray-400 " />
                                </span>
                                <select
                                autoComplete='off'
                                id="energie" 
                                name="energie"
                                onChange={formik.handleChange}
                                value={formik.values.energie}
                                onBlur={formik.handleBlur}
                                type=""
                                placeholder="" 
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.libelle && formik.touched.libelle ? 'border-red-500': ''}`}
                                >
                                <option value="" label="Sélectionner le type de consommation du véhicule" />
                                {/* {energiesOptions.map(option => (
                                    <option key={option.id} value={option.id}>{option.label}</option>
                                ))} */}
                                </select>
                            </div>
                        </div>
                        <div className='col-span-4'>
                            <label htmlFor="puissance_fiscal" className="block text-gray-700 font-semibold mb-1 pl-2">Puissance fiscale</label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <IoIosFlash  className="w-5 h-5 text-gray-400 " />
                                </span>
                                <select
                                autoComplete='off'
                                id="puissance_fiscal" 
                                name="puissance_fiscal"
                                onChange={formik.handleChange}
                                value={formik.values.puissance_fiscal}
                                onBlur={formik.handleBlur}
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.libelle && formik.touched.libelle ? 'border-red-500': ''}`}
                                >
                                <option value="" label="Sélectionner le nombre de chevaux du véhicule" />
                                {/* {puissancesFiscalesOptions.map(option => (
                                    <option key={option.id} value={option.id}>{option.label}</option>
                                ))} */}
                                </select>
                            </div>
                        </div>
                        <div className='col-span-4'>
                            <label htmlFor="duree_du_contrat" className="block text-gray-700 font-semibold mb-1 pl-2">Durée (en mois)</label>
                            <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <BsHourglassSplit className="w-5 h-5 text-gray-400" />
                            </span>
                            <select
                                autoComplete='off'
                                id="duree_du_contrat"
                                name="duree_du_contrat"
                                onChange={formik.handleChange}
                                value={formik.values.duree_du_contrat}
                                onBlur={formik.handleBlur}
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.libelle && formik.touched.libelle ? 'border-red-500' : ''}`}
                            >
                                <option value="" label="Sélectionner la durée" />
                                {/* Ajoute les options de durée ici */}
                                <option value="1" label="1 mois" />
                                <option value="3" label="3 mois" />
                                <option value="6" label="6 mois" />
                                <option value="12" label="12 mois" />
                                {/* Ajoute d'autres options si nécessaire */}
                            </select>
                            </div>
                        </div>
                    </div>
                </form>
                
                <div className='flex space-x-2 mt-4'>
                    <FaCoins size={20} className='text-teal-600' /> 
                    <h1 className='font-semibold'>Montant</h1>
                </div>
                <div className='border rounded-md mt-5'>
                    <div>
                        <Lottie 
                            animationData={loader}
                            loop={true}
                            autoPlay={true}
                            className='w-full h-full' 
                        />
                    </div>
                    
                    {/* <div className='space-y-2 mb-3'>
                        <div className='flex justify-between items-center px-4'>
                            <div className='font-semibold text-teal-600'>Prime Nette :</div>
                            <div>0</div>
                        </div>
                        <div className='flex justify-between items-center px-4'>
                            <div className='font-semibold text-teal-600'>FGA :</div>
                            <div>0</div>
                        </div>
                        <div className='flex justify-between items-center px-4'>
                            <div className='font-semibold text-teal-600'>Taxes :</div>
                            <div>0</div>
                        </div>
                        <div className='flex justify-between items-center px-4'>
                            <div className='font-semibold text-teal-600'>Prime TTC :</div>
                            <div>0</div>
                        </div>
                    </div> */}
                </div>

            </div>
    
        </DrawerContent>
    </Drawer>
  )
}

export default CalculatriceTarifAuto

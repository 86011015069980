import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaFacebook, FaBars, FaTimes } from 'react-icons/fa';
import ylogo from '/src/images/yesha.png';
import { Button } from 'keep-react';

const Navbar = ({ onOpenModal }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const [showModal, setShowModal] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowScrollTop(scrollPosition > 300);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = 0;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    if (showModal) {
      setShowModal(false);
    }
  };

  const navLinks = [
    { label: 'Compagnie', icon: FaFacebook, sectionId: 'compagnie' },
    { label: 'Assurance Voyage', icon: FaFacebook, sectionId: 'voyage' },
    { label: 'Assurance Automobile', icon: FaFacebook, sectionId: 'automobile' },
  ];

  const handleBarsIconClick = () => {
    toggleModal();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <nav className="bg-purple-800 mb-2 rounded-b-2xl">
        <div className="container mx-auto flex justify-between items-center py-6 px-4 md:px-24">
          <div>
            <img src={ylogo} alt="" className="w-48 h-auto" />
          </div>
          <div className="hidden md:flex gap-8 items-center">
            <ul className="flex gap-8 items-center text-center cursor-pointer">
              {navLinks.map((link, index) => (
                <li 
                  key={index} 
                  className="text-white text-lg hover:text-purple-300 transition-colors"
                  onClick={() => scrollToSection(link.sectionId)}
                >
                  {link.label}
                </li>
              ))}
            </ul>
          </div>
          <Button 
            color="primary" 
            className='bg-white hover:text-white border-white' 
            variant="outline" 
            onClick={onOpenModal}
          >
            <p className="">Se Connecter</p>
          </Button>

          <div className="md:hidden flex items-center gap-6">
            <FaBars 
              onClick={handleBarsIconClick} 
              className="text-white cursor-pointer"
            />
          </div>
        </div>
        
        {showModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="fixed inset-0 flex justify-center items-center z-10 opacity-5"
          >
            <div className="absolute inset-0 bg-purple-800" />
            <FaTimes
              className="absolute top-6 right-4 text-white cursor-pointer"
              onClick={toggleModal}
              style={{ fontSize: '16px' }}
            />
            <div className="relative bg-purple-800 w-full">
              <div className="flex flex-col gap-8 items-center justify-center h-full">
                {navLinks.map((link, index) => (
                  <span 
                    key={index} 
                    className="text-white font-light text-2xl cursor-pointer hover:text-purple-300 transition-colors"
                    onClick={() => scrollToSection(link.sectionId)}
                  >
                    {link.label}
                  </span>
                ))}
                <Button 
                  color="primary" 
                  variant="outline" 
                  onClick={onOpenModal}
                >
                  <p className="">Se Connecter</p>
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </nav>

      {showScrollTop && (
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-4 rounded-full bg-purple-800 bg-opacity-50 hover:bg-opacity-80 text-white transition-all duration-300 z-50"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M5 10l7-7m0 0l7 7m-7-7v18" 
            />
          </svg>
        </motion.button>
      )}
    </>
  );
};

export default Navbar;
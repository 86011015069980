import React, { useEffect, useState } from 'react'
import Sidebar from '../../../template/sidebar/Sidebar'
import HomeNavbar from '../../../template/navbar/HomeNavbar'
import AdminUserBanner from '../../../template/banner/AdminUserBanner';
import { motion } from 'framer-motion';
import MyModal from '../../../components/dialog/MyModal';
import distro from "/src/images/distribution1.png";
import AussureurSkeleton from '../../../components/loading/AussureurSkeleton';
import OptionsDistributeur from '../../../components/ressources/OptionsDistributeur';
import AddDistributeurs from '../../../components/forms/distributeurs/AddDistributeurs';
import { TbBinaryTree, TbBinaryTree2 } from 'react-icons/tb';
import { GetAllTypeActivity } from '../../../api/distributeurs/TypeActivites';
import { getAllDistributeurs, NewDistributeur } from '../../../api/distributeurs/Distributeurs';
import { failNotification, succesNotification } from '../../../components/notifications/Notifications';
import FormatNumero from '../../../utils/FormatNumero';
import { useProfile } from '../../../UserDataStock/Profils/Profile';
import DistributeurSkeleton from '../../../components/loading/DistributeurSkeleton';

const DistributeurList = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [distribModal, setDistribModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chargement, setChargement] = useState(true);
    const [lesDistributeurs, setLesDistributeurs] = useState([]);
    const [typeActivites, setTypeActivites] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const {position, token, userDetails } = useProfile();


    const formData = (data) =>{ 
      setIsLoading(true)
      var addDistributeur={ 
          raison: data?.raison,
          rccm: data?.rccm,
          adresse: data?.adresse,
          email: data?.email,
          mobile: data?.mobile,
          gerant: data?.gerant,
          fixe: data?.fixe,
          ville: data?.ville,
          rue: data?.rue,
          quartier: data?.quartier,
          activiteId: data?.activiteId
      }
      NewDistributeur(addDistributeur, token, position)
      .then(function(result){ 
        console.log(result);
        if(result.status === 201){ 
          succesNotification(result.data.message);
          setDistribModal(false);
          Distributeurs();
        } else { 
          failNotification(result.data.message);
        }
      })
      .catch(function(error){ 
        console.log(error);
      })
      .finally(function(){ 
        setIsLoading(false);
      })
    }

    useEffect(() => { 
      const fetchTypeActivity = async () => { 
          try {
            const activity = await GetAllTypeActivity(token, position) ;
            setTypeActivites(activity);
            setIsLoading(false); 
        } catch (error) {
            console.error('Erreur Impossible de recuperer les roles:', error);
        } 
      };
      fetchTypeActivity();
    }, [])

    //// recuperation des distributeurs ////
    async function Distributeurs() {
      try {
        let response = await getAllDistributeurs(token, position)
        setChargement(true)
        setLesDistributeurs(response);
      } catch (err) {
        console.log(err);
      } finally { 
        setChargement(false);
      }
    }

    useEffect(() => { 
      Distributeurs();
    },[]);




    const handleOpenClose = () => {
      setIsOpen(!isOpen);
    };

  return (
    <div className='flex h-screen'>
      <Sidebar isOpen={isOpen}  onToggle={handleOpenClose}/> 
      <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
        <HomeNavbar isOpen={isOpen} />
            <main className="p-6 mt-16">
              <AdminUserBanner content={"nothing right now"} /> 

              <MyModal 
                isOpen={distribModal}
                closeModal={()=> setDistribModal(false)}
                content={
                  <AddDistributeurs handleRegistration={formData} isLoading={isLoading}  data={typeActivites} />
                }
              /> 

              <div className='flex justify-end '>
                  <div className='flex space-x-2 py-2 px-4 mr-5 bg-purple-300 text-white items-center rounded-lg cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700 hover:text-white'
                  onClick={()=> setDistribModal(true)}
                  >
                      <TbBinaryTree2   className='w-9 h-8'/> 
                  </div>
              </div>

               {/* le nombre de distributeur */}
              <div className='flex justify-start items-center space-x-3'>
                <h1 className='text-2xl p-2 underline font-semibold'>Distributeurs</h1>
                <div className='bg-purple-600 px-2 rounded-md text-white '>
                  {lesDistributeurs.length}
                </div>
              </div>

              {/* la liste des distributeurs */}
             <div className='grid grid-cols-12 gap-5'>
             { 
                chargement ? (
                  <>
                    <DistributeurSkeleton /> 
                  </>
                ) : (
                  <>
                    { 
                      lesDistributeurs.map((as, index) => (
                        <motion.div
                          key={as.id}
                          className="col-span-6 lg:col-span-3 py-4 border rounded-2xl shadow-md px-4 bg-white"
                          initial={{ opacity: 0, x: '-100%', scale: 0.5 }}
                          animate={{ opacity: 1, x: 0, scale: 1 }}
                          exit={{ opacity: 0, x: '-100%', scale: 0.5 }}
                          transition={{ duration: 0.5, ease: 'easeIn', delay: index * 0.1 }}
                        >
                          {/* le bouton menu  */}
                          <OptionsDistributeur active={as} confirmation={setRefresh} act={typeActivites} />

                          {/* logo & name distributeur */}
                            <div className='flex justify-between'>
                              <div className='flex'>
                                <div className='w-24 h-24 rounded-md border shadow-md bg-white'>
                                  <img src={distro} className='p-2 object-cover' alt="image" />
                                  {/* <TbBinaryTree2  className='w-full h-full text-purple-600'/>  */}
                                </div>
                                <div className='p-4'>
                                  <div className='flex justify-start items-baseline space-x-2'>
                                    <h1 className='text-xl font-semibold'>{as.raison_social}</h1> 
                                  </div>
                                  <div className='flex space-x-2'>
                                    <h1 className='font-semibold text-teal-600'>{as.Activite.libelle}</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                           {/* les details du distributeur */}

                           <div className='py-4'>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Référent: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'>{as.referent_nom}</h1>
                                </div>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Code: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'>{as.code}</h1>
                                </div>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Téléphone mobile: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'><FormatNumero numero={as.tel_mobile} /></h1>
                                </div>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Téléphone fixe: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'><FormatNumero numero={as.tel_fixe} /></h1>
                                </div>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Email: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'>{as.email}</h1>
                                </div>
                           </div>
                           <hr /> 
                           <div className='py-6'>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Points de vente: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'>15</h1>
                                </div>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Produits: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'>2</h1>
                                </div>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Quantité: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'>2 255</h1>
                                </div>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Montant: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'>75 452 256</h1>
                                </div>
                            </div>
                        </motion.div>
                      ))
                    }
                  </>
                  
                )
              }
             </div>

            
           </main>
      </div>
    </div>
  )
}

export default DistributeurList

import React from 'react';
import { useNavigate } from 'react-router-dom';
import animationData from '/src/images/denied.json';
import Lottie from 'lottie-react';

const AccessDenied = () => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate('/Accueil');
    };

    return (
        <div className="relative flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-screen h-screen">
                <Lottie 
                    animationData={animationData}
                    loop={true}
                    autoPlay={true}
                    className="w-full h-full"
                />
                <div className="absolute bottom-0 w-full text-center p-4 bg-gray-100 bg-opacity-75">
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">Accès Refusé</h3>
                    <p className="text-lg font-bold  text-purple-600 mb-8">Désolé, vous n'avez pas l'autorisation d'accéder à cette ressource.</p>
                    <button 
                        className="bg-blue-500 text-white px-4 py-2 rounded" 
                        onClick={handleGoBack}
                    >
                        Retour à l'accueil
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AccessDenied;

import React, { useEffect, useState }  from 'react';
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FaPlane, FaUser, FaRegMoneyBillAlt, FaCheckCircle } from 'react-icons/fa';
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Spinner } from "keep-react";
import moment from "moment";
import { useProfile } from "../../../UserDataStock/Profils/Profile";
import { NewDevisVoyage, NewDevisVoyageGn } from "../../../api/voyages/DevisEtContratsVoyage";
import { succesNotification, failNotification } from "../../notifications/Notifications";
import FormatNumero from "../../../utils/FormatNumero";
import FormatMontant from "../../../utils/FormatMontant";
import afrique from "/src/images/afrique.svg";
import europe from "/src/images/schengen.svg";
import monde from "/src/images/monde.svg";
import inconnu from "/src/images/travel.png";
import passport from "/src/images/passport.png";

const Step3 = ({onPrev, isLastStep}) => {
    const navigate = useNavigate();
    const { position, token, userDetails } = useProfile();
    const [isloading, setIsloading] = useState(false);
    const [zone, setZone] = useState(0);
    const [devis, setDevis] = useState("");
    const [stepData, setStepData] = useState(() => {
        return JSON.parse(sessionStorage.getItem('formVoyage')) || {};
    });


    const paysActuel = position.location.country_name;
    // const paysActuel = "Guinée";

    const detailsParVoyageur = stepData?.step1Data?.selectedTarification?.formules?.[0]?.detailsParVoyageur || [];
    // const detailsParVoyageur = stepData?.step1Data?.selectedTarification?.formules?.[0]?.voyageurs || []; pour la guinnee

    // Fonction pour sauvegarder l'état de la session avant la navigation
    const saveSessionBeforeNavigation = () => {
        const currentSession = JSON.parse(sessionStorage.getItem('formVoyage'));
        if (currentSession) {
            sessionStorage.setItem('tempVoyageData', JSON.stringify(currentSession));
            sessionStorage.removeItem('formVoyage'); // Nettoyage de formVoyage après sauvegarde
        }
    };

    useEffect(() => {
        const handleStorageChange = () => {
            const data = JSON.parse(sessionStorage.getItem('formVoyage'));
            if (data) {
                setStepData(data);
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    // if (!stepData?.step1Data || !stepData?.step2Data) {
    //     return <div>Chargement...</div>;
    // }

    const {
        destination = '',
        duree = '',
        dateDepart = '',
        dateRetour = '',
        selectedTarification = { formules: [{ formule: {}, montantTotal: 0 }] }
    } = stepData.step1Data;

    const {
        souscripteur = {},
        voyagers = [],
    } = stepData.step2Data;

   

    const calculateAge = (birthDate) => {
        return moment().diff(moment(birthDate), 'years');
    };
    const formulaNameToPic = {
        "Schengen": europe,
        "Monde Entier": monde,
        "Afrique": afrique,
    };
    const pic = formulaNameToPic[selectedTarification?.formules?.[0]?.formule?.name] || formulaNameToPic[selectedTarification?.formules?.[0]?.formule] || inconnu;

    useEffect(() => {
        if(selectedTarification?.formules?.[0]?.formule === "Afrique"){ 
            setZone(3);
        } else if (selectedTarification?.formules?.[0]?.formule === "Schengen") {
            setZone(4);
        } else if (selectedTarification?.formules?.[0]?.formule === "Mondial Basic" || 
                   selectedTarification?.formules?.[0]?.formule === "Mondial Plus") {
            setZone(1);
        } else if (selectedTarification?.formules?.[0]?.formule === "Europe") {
            setZone(2);
        } else {
            setZone(0);
        }
    }, [selectedTarification?.formules?.[0]?.formule]);

    useEffect(() => {
        if (position?.location?.country_code3 === "GIN") {
            setDevis("GNF");
          } else {
            setDevis("XOF");
        }
    }, [position]);

    
    const formData = (stepData) => {
        
        setIsloading(true);
        const formVoyage = {
            voyage: {
                dateDepart: stepData.step1Data.dateDepart || "",
                dateRetour: stepData.step1Data.dateRetour || "",
                departId: stepData.step1Data.departId || 0,
                destinationId: stepData.step1Data.destinationId || 0,
                voyageurs: stepData.step2Data.voyagers.map(voyageur => {
                    const age = calculateAge(voyageur.dateNaissance);
                    const matchingDetail = detailsParVoyageur.find(detail => detail.age === age);
                    return {
                        isSubscriber: voyageur.isSubscriber || false,
                        nom: voyageur.nom || "",
                        prenom: voyageur.prenom || "",
                        genre: voyageur.genre || null,
                        dateNaissance: voyageur.dateNaissance || "",
                        tel: voyageur.telephone || "",
                        adresse: voyageur.adresse || "",
                        email: voyageur.email || "",
                        primeTTC: matchingDetail ? matchingDetail.primeTTC : 0, 
                        // primeTTC: matchingDetail ? matchingDetail.tarif : 0, pour la guinnee
                        passport: voyageur.passeport || "",
                        dateDelivrance: voyageur.delivrancePasseport  || "",
                        dateExp: voyageur.expirationPasseport || ""
                    };
                })
            },
            contrat: {
                duree: stepData.step1Data.duree || 0,
                pointDeVenteId: userDetails.pointDeVenteId || "",
                dateDebut: stepData.step1Data.dateDepart || "",
                assureurId: stepData.step1Data.selectedTarification.assurance.id || "",
                formuleId: stepData.step1Data.selectedTarification.formules?.[0]?.formule?.id || 0
                // formuleId: zone
            },
            details: {
                primeNette: stepData.step1Data.selectedTarification?.formules?.[0]?.primeNetteTotal || 0,
                coutPolice: stepData.step1Data.selectedTarification?.formules?.[0]?.policeTotal || 0,
                taxes: stepData.step1Data.selectedTarification?.formules?.[0]?.taxesTotal || 0,
                primeTTC: stepData.step1Data.selectedTarification?.formules?.[0]?.primeTTCTotal || 0,
                prixConseille: stepData.step1Data.selectedTarification?.formules?.[0]?.prixConseilleTotal || 0,
                montantTotal: stepData.step1Data.selectedTarification?.formules?.[0]?.montantTotal || 0
            },
            souscripteur: {
                type: stepData.step2Data.souscripteur.type || "",
                genre: stepData.step2Data.souscripteur.genre || null,
                nom: stepData.step2Data.souscripteur.nom || "",
                prenom: stepData.step2Data.souscripteur.prenom || "",
                raison: stepData.step2Data.souscripteur.raison_social || "",
                profession: stepData.step2Data.souscripteur.domaine || "",
                domaine: stepData.step2Data.souscripteur.domaine || "",
                adresse: stepData.step2Data.souscripteur.adresse || "",
                tel: stepData.step2Data.souscripteur.telephone || "",
                email: stepData.step2Data.souscripteur.email || ""
            }
        };

        // alert(JSON.stringify(formVoyage))
        if(position?.location?.country_code3 === "GIN"){
            // formVoyage.details.primeTTC = 0,
            // formuleId: zone,

            NewDevisVoyageGn(formVoyage, token, position)
                .then(function(result) {
                    console.log(result)
                    if (result.status === 201) {
                        succesNotification(result.data.message);
                        // Sauvegarder la session avant la navigation
                        saveSessionBeforeNavigation();
                        navigate(`/Voyages/Devis/${result.data.ref}`);
                    } else {
                        failNotification(result.data.message);
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    failNotification("Une erreur est survenue");
                })
                .finally(function() {
                    setIsloading(false);
                })

        } else { 
            
            NewDevisVoyage(formVoyage, token, position)
                .then(function(result) {
                    if (result.status === 201) {
                        succesNotification(result.data.message);
                        // Sauvegarder la session avant la navigation
                        saveSessionBeforeNavigation();
                        navigate(`/Voyages/Devis/${result.data.ref}`);
                    } else {
                        failNotification(result.data.message);
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    failNotification("Une erreur est survenue");
                })
                .finally(function() {
                    setIsloading(false);
            });
        }
    };

    const handleSave = () => {
        formData(stepData);
    };
    
 

  return (
    <div className="max-w-5xl mx-auto bg-white rounded-lg border">
        <h2 className="text-3xl p-6 font-bold text-blue-600 rounded-t-lg flex items-center bg-purple-600">
           <span className="text-white">Résumé du voyage</span>
        </h2>
      
      <div className="p-6">

        <motion.div
            className="mb-6 border-b pb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <h3 className="text-2xl font-bold flex items-center mb-2 text-purple-600">
            <FaPlane className="mr-2 text-blue-500" /> Les Informations du Voyage
            </h3>
            <motion.div
             className="flex flex-row justify-around text-lg p-4 bg-gray-50 rounded-lg shadow-md border border-gray-200"
             whileHover={{ scale: 1.02 }}
             >
                <div className="flex flex-col justify-center items-center">
                    <p className="text-gray-600 font-bold">📍 Pays de Résidence </p>
                    <span className="font-semibold mx-2 text-purple-600">{paysActuel}</span>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <p className="text-gray-600 font-bold">🌍 Destination </p>
                    <span className="font-semibold mx-2 text-purple-600">{destination}</span>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <p className="text-gray-600 font-bold">📅 Durée </p>
                    <span className="font-semibold mx-2 text-purple-600">{duree} days</span>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <p className="text-gray-600 font-bold">🛫 Date de Départ </p>
                    <span className="font-semibold mx-2 text-purple-600">{moment(dateDepart).format('DD/MM/YYYY')}</span>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <p className="text-gray-600 font-bold">🛬 Date de Retour </p>
                    <span className="font-semibold mx-2 text-purple-600">{moment(dateRetour).format('DD/MM/YYYY')}</span>
                </div>
                
            </motion.div>
        </motion.div>

        {/* Voyageurs assurés */}
        <motion.div
            className="mb-6 border-b pb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
        >
            <h3 className="text-2xl font-bold text-purple-600 flex items-stretch">
            <FaUser className="mr-2 text-blue-500" /> Les Informations des Voyageurs
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            {voyagers.map((voyager, index) => (
                <motion.div
                key={index}
                className="p-4 bg-gray-50 rounded-lg shadow-md border text-lg border-gray-200"
                whileHover={{ scale: 1.02 }}
                >
                    <div className="flex flex-col justify-start">
                        <div className="flex flex-row items-center space-x-2">
                            <p className="text-gray-600 font-bold">👤 Assuré {index+1}: </p>
                            <p className="font-bold text-purple-600">{voyager.prenom} {voyager.nom}</p>
                        </div>
                        <div className="flex flex-row items-center space-x-2">
                            <p className="text-gray-600 font-bold ">📅 Date de naissance: </p>
                            <p className="text-purple-600 font-bold">{moment(voyager.dateNaissance).format('DD/MM/YYYY')}</p>
                        </div>
                        <div className="flex flex-row items-center space-x-2">
                            <p className="text-gray-600 font-bold ">📱 Telephone: </p>
                            <p className="text-purple-600 font-bold"><FormatNumero numero={voyager.telephone}/> </p>
                        </div>
                        <div className="flex flex-row items-center space-x-2">
                            <p className="text-gray-600 font-bold ">📍 Adresse: </p>
                            <p className="text-purple-600 font-bold">{voyager.adresse}</p>
                        </div>
                        <div className="flex flex-row items-center space-x-2">
                            <p className="text-gray-600 font-bold ">📧 Mail: </p>
                            <p className="text-purple-600 font-bold">{voyager.email}</p>
                        </div>
                        <div className="flex flex-row items-center space-x-2">
                            <img src={passport} className="w-6 h-6" alt="passport" />
                            <p className="text-gray-600 font-bold "> Passport: </p>
                            <p className="text-purple-600 font-bold">{voyager.passeport}</p>
                        </div>
                        <div className="flex flex-row items-center space-x-2">
                            <p className="text-gray-600 font-bold ">📅 Date de délivrance du passeport: </p>
                            <p className="text-purple-600 font-bold">{moment(voyager.delivrancePasseport).format('DD/MM/YYYY')}</p>
                        </div>
                        <div className="flex flex-row items-center space-x-2">
                            <p className="text-gray-600 font-bold ">📅 Date d’expiration du passeport: </p>
                            <p className="text-red-600 font-bold">{moment(voyager.expirationPasseport).format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                </motion.div>
            ))}
            </div>
        </motion.div>

        {/* Résumé du plan d'assurance */}
        <motion.div
            className="mb-6 border-b pb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
        >
            <h3 className="text-2xl font-bold text-purple-600 flex items-center">
            <FaRegMoneyBillAlt className="mr-2 text-green-500" /> Les Informations du Tarif
            </h3>
            <motion.div
             className="bg-gray-100 border flex justify-around p-4 rounded-lg shadow-md mt-4"
             whileHover={{ scale: 1.02 }}
             >

                <div>
                    <div className="flex flex-row justify-start items-center">
                        <div className="flex flex-col ml-10 items-center mb-2">
                            <h3 className="flex justify-center items-center text-xl font-bold text-indigo-700 mb-4 underline underline-offset-4">Formule définie</h3>
                            <div className="w-36 h-36 rounded-md bg-white p-1 shadow-md border-[0.5px]">
                                <img src={pic} className="w-full h-full object-cover" alt="logo" />
                            </div>
                            <span className="mt-2 text-lg font-ConcertOne text-purple-600">
                                {selectedTarification?.formules?.[0]?.formule?.name || selectedTarification.formules?.[0].formule} 
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-row items-center space-x-2 text-lg">
                        <p className="text-gray-600 font-bold">Description: </p>
                        <span className="font-semibold mx-2 text-purple-600">{selectedTarification?.formules?.[0]?.formule?.description || 'N/A'}</span>
                    </div>
                </div>

                <div className="flex flex-col justify-center items-center">
                    <h3 className="flex justify-center items-center text-xl font-bold text-indigo-700 mb-4 underline underline-offset-4">Décomposition de la prime</h3>
                    <div className="bg-white shadow-md rounded-xl border p-5  items-center">

                        <div className="flex justify-between items-center space-x-7">
                            <span className="text-gray-800 font-DMSerifText">Prime Nette: </span>
                            <span className="font-semibold text-green-600">
                                <FormatMontant montant={selectedTarification?.formules?.[0]?.primeNetteTotal || 'N/A'} /> 
                                <span className="text-xs ml-1">{devis}</span>
                            </span>
                        </div>

                        <div className="flex justify-between items-center space-x-7">
                            <span className="text-gray-800 font-DMSerifText">Coût de police: </span>
                            <span className="font-semibold text-green-600">
                                <FormatMontant montant={selectedTarification?.formules?.[0]?.policeTotal || 'N/A'} /> 
                                <span className="text-xs ml-1">{devis}</span>
                            </span>
                        </div>

                        <div className="flex justify-between items-center space-x-7">
                            <span className="text-gray-800 font-DMSerifText">Taxes: </span>
                            <span className="font-semibold text-green-600">
                                <FormatMontant montant={selectedTarification?.formules?.[0]?.taxesTotal || 'N/A'} /> 
                                <span className="text-xs ml-1">{devis}</span>
                            </span>
                        </div>

                        <div className="flex justify-between items-center space-x-7">
                            <span className="text-gray-800 font-DMSerifText">Frais de service: </span>
                            <span className="font-semibold text-green-600">
                                <FormatMontant montant={selectedTarification?.formules?.[0]?.prixConseilleTotal || 'N/A'} /> 
                                <span className="text-xs ml-1">{devis}</span>
                            </span>
                        </div>

                        <div className="flex justify-between items-center space-x-7">
                            <span className="text-gray-800 font-DMSerifText">Prime TTC: </span>
                            <span className="font-semibold text-green-600">
                                <FormatMontant montant={selectedTarification?.formules?.[0]?.primeTTCTotal || 'N/A'} /> 
                                <span className="text-xs ml-1">{devis}</span>
                            </span>
                        </div>

                        <div className="flex justify-between items-center space-x-7">
                            <span className="text-gray-800 font-DMSerifText">Commission: </span>
                            <span className="font-semibold text-green-600">
                                {/* <FormatMontant montant={selectedTarification?.formules?.[0]?.commission || 'N/A'} />  */}
                                <FormatMontant montant={0} /> 
                                <span className="text-xs ml-1">{devis}</span>
                            </span>
                        </div>

                        <div className="flex justify-between font-bold text-red-600 text-lg mt-4">
                            <span>Total:</span>
                            <span>
                                <FormatMontant montant={selectedTarification?.formules?.[0]?.montantTotal || 'N/A'} /> 
                                <span className="text-xs ml-1">{devis}</span>
                            </span>
                        </div>

                    </div>
                </div>
            
            </motion.div>
        </motion.div>

        {/* Détails du souscripteur */}
        <motion.div
            className="mb-6"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
        >
            <h3 className="text-2xl font-bold text-purple-600 flex items-stretch">
            <FaUser className="mr-2 text-blue-500" /> Les Informations du Souscripteur
            </h3>
            <motion.div
                className="p-4 bg-blue-50 rounded-lg shadow-md border border-blue-200 mt-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                whileHover={{ scale: 1.02 }}
            >
                <div className="flex flex-col justify-start ">

                    <div className="flex flex-row items-center space-x-2 text-lg">
                        <p className="text-gray-600 font-bold ">🤵🏻‍♂️ Souscripteur: </p>
                        <p className="text-purple-600 font-bold">{souscripteur.prenom} {souscripteur.nom}</p>
                    </div>

                    <div className="flex flex-row items-center space-x-2 text-lg">
                        <p className="text-gray-600 font-bold">📅 Date de naissance: </p>
                        <p className="text-purple-600 font-bold">
                            {souscripteur.dateNaissance && moment(souscripteur.dateNaissance).isValid() 
                            ? moment(souscripteur.dateNaissance).format('DD/MM/YYYY')
                            : ''
                            }
                        </p>
                    </div>

                    <div className="flex flex-row items-center space-x-2 text-lg">
                        <p className="text-gray-600 font-bold ">📱 Telephone: </p>
                        <p className="text-purple-600 font-bold"><FormatNumero numero={souscripteur.telephone}/> </p>
                    </div>

                    <div className="flex flex-row items-center space-x-2 text-lg">
                        <p className="text-gray-600 font-bold ">📧 Mail: </p>
                        <p className="text-purple-600 font-bold">{souscripteur.email}</p>
                    </div>

                    <div className="flex flex-row items-center space-x-2 text-lg">
                        <p className="text-gray-600 font-bold ">📍 Adresse: </p>
                        <p className="text-purple-600 font-bold">{souscripteur.adresse}</p>
                    </div>

                    <div className="flex flex-row items-center space-x-2 text-lg">
                        <p className="text-gray-600 font-bold ">💼 Profession: </p>
                        <p className="text-purple-600 font-bold">{souscripteur.domaine}</p>
                    </div>

                    <div className="flex flex-row items-center space-x-2 text-lg">
                        <p className="text-gray-600 font-bold ">🤝 Raison Social: </p>
                        <p className="text-purple-600 font-bold">{souscripteur.raison_social}</p>
                    </div>

                </div>
            </motion.div>
        </motion.div>

      </div>
      {/* Boutons de navigation */}
      <div className="flex justify-between mt-8 p-5">
            <button 
                onClick={onPrev} 
                className="flex items-center space-x-3 bg-blue-100 text-blue-500 py-2 px-4 rounded transform transition duration-300 hover:scale-110 hover:shadow-md"
            >
                <IoIosArrowDropleftCircle size={20} />
                <span>Précédent</span>
            </button>
            <button 
                disabled={isloading}
                onClick={handleSave}
                className="flex space-x-3 items-center bg-blue-500 text-white py-2 px-4 rounded transform transition duration-300 hover:scale-110 hover:shadow-md"
            >
                <span>
                   <span className="flex items-center">
                        {
                            isloading ? (<Spinner color="info" size="md"  />) : (<FaCheckCircle size={18}  />)
                        }
                        <p className="pl-2">{
                        isloading ? "En Cours d'enregistrement..." : 'Enregistrer'
                        }</p>
                    </span>
                </span>
            </button>
      </div>
      
    </div>
  );
};

export default Step3;
import axios from "axios";

export async function AddVendeur(data, token, position) { 
    try{ 
        const response = await axios({ 
            method: 'POST',
            url: `${import.meta.env.VITE_BACKLOG}/users`,
            headers: { 
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: data
        })
        return response;

    } catch (error) { 
        if(error.response){ 
            return (error.response)
        } else if (error.request) { 
            console.log(error.request)
        } else { 
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}


export async function GetAllSellers(token, position){ 
    try{
        const response = await axios({
            method: 'GET',
            url: `${import.meta.env.VITE_BACKLOG}/users/sellers`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        })
        return response.data;
    }catch (error){ 

        if(error.response){ 
            return (error.response)
        } else if (error.request) { 
            console.log(error.request)
        } else { 
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}

export async function GetAllSellerByPointVenteId(id, token, position){ 
    try{ 
        const response = await axios({ 
            method: 'GET',
            url: `${import.meta.env.VITE_BACKLOG}/users/${id}/point-de-vente`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            }
        })
        return response.data;

    } catch(error) { 
        if(error.response){ 
            return (error.response)
        } else if (error.request) { 
            console.log(error.request)
        } else { 
            console.log("Erreur", error.message)
        }
        console.log(error.config)
        return error;
    }
}
import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { RiEdit2Fill } from 'react-icons/ri'
import { MdDelete } from 'react-icons/md'
// import { IoIosLock, IoIosUnlock } from 'react-icons/io';
import { HiMiniLockClosed, HiMiniLockOpen } from "react-icons/hi2";
import FormatNumero from '../../../../utils/FormatNumero';
import ChoiceDialog from '../../../dialog/ChoiceDialog';
import MyModal from '../../../dialog/MyModal';
import { motion } from 'framer-motion'
import { DeleteUser, UpdateUser } from '../../../../api/admin/Utlisateurs';
import { failNotification, succesNotification } from '../../../notifications/Notifications';




const VendeursDatatables = ({ data, confirmation, chargement }) => {

  const [updateDialog, setUpdateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blockDialog, setBlockDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
 

  
  const deleteOneUser = (id) => {
    DeleteUser(id)
      .then(function(result) {
        if (result.status === 200) {
          succesNotification(result.message);
          confirmation((prevStatus) => prevStatus + 1);
        } else {
          failNotification(result.message);
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => {
        setDeleteDialog(false); 
      });
  };

  // modification d'un assurereur ///
  const formDataUpdate = (data) =>{
  
    setIsLoading(true)
      const idS = data?.id;
      var updateUser={
      nom: data.nom,
      prenom: data.prenom,
      email: data.email,
      username: data.username,
      mobile: data.mobile,
      role: data.role,
      image: data.image
      }
      UpdateUser(idS , updateUser, token, position)
      .then(function(result){
        if(result.status === 200){
          console.log(result);
          succesNotification(result.data.message);
          setUserModal(false);
          Utilisateurs();
        } else {
          failNotification(result.data.message);
        }
      })
      .catch(function(error){
        console.log(error)
      })
      .finally(function(){
        setIsLoading(false);
      })
  }



  const openBlockDialog = (user) => {
    setSelectedUser(user);
    setBlockDialog(true);
  };



  const openDeleteDialog = (user) => { 
    setSelectedUser(user);
    setDeleteDialog(true);
  }

  const openUpdateDialog = (user) => { 
    setSelectedUser(user);
    setUpdateDialog(true);
  }

  const ActionComponent = params => {
    const { id, username, mobile, email, image,  prenom, nom, isLocked} = params.data;  
    
    return (
      <div className='flex text-center justify-center items-center space-x-3'>
        <button className='p-2 rounded-lg border bg-fuchsia-100 hover:bg-fuchsia-200 hover:text-white' 
            onClick={() => openBlockDialog({ id, prenom, nom, isLocked})}>
            {isLocked ? <HiMiniLockClosed size={20} className='text-purple-600' /> : <HiMiniLockOpen size={20} className='text-purple-600' />}
        </button> 
        <button className='p-2 rounded-lg border bg-fuchsia-100 hover:bg-fuchsia-200 hover:text-white' 
            onClick={() => openUpdateDialog({id, username, mobile, email, image,  prenom, nom})}>
         <RiEdit2Fill className='text-purple-600' size={20} />
        </button>
        <button className='p-2 rounded-lg border bg-fuchsia-100 hover:bg-fuchsia-200 hover:text-white' 
            onClick={() => openDeleteDialog({id, prenom, nom })}>
         <MdDelete className='text-purple-600' size={20} />
        </button>
      </div>
    );
  };

  // le style des differents cellules 

  const IdentifiantCellRender = ({value}) => {
    return (
      <div className='flex justify-center items-center'>
        <span className='font-semibold text-purple-500'>{value}</span>
      </div>
    )
  }

  const ImageCellRender = ({ value }) => {
    return (
      <div className="flex justify-center items-center">
        <img
          src={value}
          alt="Profil"
          className="w-16 h-16 rounded-full object-center border shadow-sm"
        />
      </div>
    );
  }

  const PrenomCellRender = ({ value }) => {
    return ( 
      <div className='flex justify-center items-center'>
        <span className='font-semibold text-purple-500'>{value}</span>
      </div>
    )
  }

  const NomCellRender = ({ value }) => {
    return ( 
      <div className='flex justify-center items-center'>
        <span className='font-semibold text-purple-500'>{value}</span>
      </div>
    )
  }

  const TelephoneCellRender = ({value}) => {
    return (
      <div className='flex justify-center items-center'>
        <span className='font-semibold text-purple-500'><FormatNumero numero={value}  /> </span>
      </div>
    )
  }

  const RoleCellRender = ({value}) => {

    return (
      <div className='flex justify-center items-center'>
        <span className='font-semibold text-purple-500'>{value}</span>
      </div>
    )
  }

  const StatusCellRender = ({ value }) => {
    return (
      <div className="flex justify-center items-center">
        <span
          className={`flex items-center px-3 py-1 rounded-full border text-sm font-medium ${
            value === false
              ? 'bg-teal-200 text-teal-800 border-teal-300'
              : 'bg-red-100 text-red-600 border-red-300'
          }`}
        >
          <span
            className={`w-2.5 h-2.5 rounded-full mr-2 ${
              value === false
                ? 'bg-teal-600'
                : 'bg-red-600'
            }`}
          ></span>
          {value === false ? 'Actif' : 'Bloqué'}
        </span>
      </div>
    );
  };

  // fin de style //

  const header = [
    {
      field: "Profil",
      valueGetter: params => params.data.image,
      cellRenderer: ImageCellRender,
      checkboxSelection: true,
      cellClass: 'flex justify-center items-center',
    },
    { field: "Prénom",
      valueGetter: params => params.data.prenom,
      cellRenderer: PrenomCellRender,
      cellClass: 'flex justify-center items-center',
    },
    { field: "Nom",
      valueGetter: params => params.data.nom,
      cellRenderer: NomCellRender,
      cellClass: 'flex justify-center items-center',
    },
    { field: "Téléphone", 
      valueGetter: params => params.data.mobile,
      cellRenderer: TelephoneCellRender,
      cellClass: 'flex justify-center items-center',
    },
    { field: "Identifiant",
      valueGetter: params => params.data.username,
      cellRenderer: IdentifiantCellRender,
      cellClass: 'flex justify-center items-center',
    },
    { field: "Status",
      valueGetter: params => params.data.isLocked,
      cellRenderer: StatusCellRender,
      cellClass: 'flex justify-center items-center',
    },
    { field: "Rôle",
      valueGetter: params => params.data.Role.libelle,
      cellRenderer: RoleCellRender,
      cellClass: 'flex justify-center items-center',
    },
    {
      field: "Action",
      cellRenderer: ActionComponent, 
      cellRendererParams: {
        id: 'Identifiant', 
      },
      cellClass: 'flex justify-center items-center',
    },
  ];

  return (
    <div className="ag-theme-quartz" style={{ height: 500 }}>

      {/* bloquer des utilisateurs */}
    
      <ChoiceDialog
        isOpen={blockDialog}
        closeModal={() => setBlockDialog(false)}
        content={
          <div className='p-8 flex-col justify-center'>
            <div className='flex justify-center space-x-2  items-baseline text-red-600'>
              {
                selectedUser?.isLocked ? <HiMiniLockOpen size={20} /> : <HiMiniLockClosed size={20} /> 
              }
              <h2 className='text-2xl font-semibold'>{selectedUser?.isLocked ? "Déblocage": "Blocage"} d'un Utilisateur</h2>
            </div>
            {selectedUser && (
              <div className='flex-col justify-center items-center space-y-3 '>
                <div className='text-lg text-center mt-4'>Voulez-vous vraiment {selectedUser?.isLocked ? "Débloquer": "Bloquer"} </div>
                <div className='text-xl font-semibold text-purple-600 text-center'>{selectedUser.prenom} {selectedUser.nom}</div>
                <div className="flex justify-center items-center  space-x-8">
                  <button
                    className="bg-red-500 text-white font-bold mt-5 py-2 px-4 rounded-lg shadow hover:bg-red-600 transition"
                    onClick={''}
                  >
                    Confirmer
                  </button>
                  <button
                    className="bg-gray-300 text-white font-bold mt-5 py-2 px-4 rounded-lg shadow hover:bg-gray-400 transition"
                    onClick={()=>setBlockDialog(false)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            )}
          </div>
        }
      />

      {/* Supprimer un Utilisateur  */}
      <ChoiceDialog 
        isOpen={deleteDialog}
        closeModal={() => setDeleteDialog(false)}
        content={<motion.div
          className="fixed inset-0 flex items-center justify-center"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className='relative bg-white rounded-3xl p-6 shadow-lg w-11/12 max-w-lg'>
            <div className="flex flex-col items-center">
            <MdDelete size={40} className="text-red-500" />
            <h1 className="text-red-500 font-bold text-2xl mt-4 mb-2">
              Suppression d'un Utilisateur
            </h1>
            <div className="text-center mb-4">
              <div>Attention cette action est irréversible.<br/>Voulez-vous vraiment supprimer </div>
              <span className="text-lg font-bold block mt-1">{selectedUser?.prenom} {selectedUser?.nom}</span>
            </div>
            <div className="flex space-x-4 mt-4">
              <button
                className="bg-red-500 text-white font-bold py-2 px-4 rounded-lg shadow hover:bg-red-600 transition"
                onClick={()=> deleteOneUser(selectedUser?.id)}
              >
                Confirmer
              </button>
              <button
                className="bg-gray-300 text-white font-bold py-2 px-4 rounded-lg shadow hover:bg-gray-400 transition"
                onClick={() => setDeleteDialog(false)}
              >
                Annuler
              </button>
            </div>
          </div>
          </div>
        </motion.div>}
      /> 

      {/* Modifier un utilisateur */}
      <MyModal
        isOpen={updateDialog}
        closeModal={() => setUpdateDialog(false)}
        content={ 'hello'}
        // content={<UpdateUtilisateur handleUpdate={formDataUpdate} id={selectedUser?.id} email={selectedUser?.email} image={selectedUser?.image} prenom={selectedUser?.prenom} nom={selectedUser?.nom} username={selectedUser?.username} mobile={selectedUser?.mobile}  isLoading={isLoading} data={lesRoles} /> }
      />

      <AgGridReact 
        rowData={data}
        columnDefs={header}
        rowHeight={80}
        rowSelection={'multiple'}
        getRowClass={() => 'fade-in'} 
        animateRows={true}
        loading={chargement}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Chargement des données...</span>'
          }
          overlayNoRowsTemplate={
            '<span class="ag-overlay-no-rows-center">Aucune donnée disponible</span>'
          }
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 20, 30]}
      />
    </div>
  )
}

export default VendeursDatatables; 

import React, { useEffect, useRef, useState } from 'react'
import { UtilisateurValidation } from '../../../utils/FormValidation';
import { useFormik } from 'formik';
import { RiImageAddFill } from 'react-icons/ri';
import { motion } from 'framer-motion';
import { FaUserPen } from 'react-icons/fa6';
import { FaCheckCircle, FaEnvelope, FaUserPlus, FaUserShield } from 'react-icons/fa';
import { Button, Spinner } from 'keep-react';
import PhoneInput from 'react-phone-input-2';

const UpdateUtilisateur = ({handleUpdate, isLoading, id, nom, prenom, username, mobile, image, email, data}) => {

    const [imagePreview, setImagePreview] = useState(null);
    const fileInputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [role, setRole] = useState(data)

    const validate = UtilisateurValidation;

    const formik = useFormik({ 
        initialValues: { 

            username: '',
            mobile: '',
            email: '',
            prenom: '',
            nom: '',
            image: '',
            role: ''
        },
        validate,
        onSubmit: handleUpdate
    })

    const handleImageChange = (event) =>{ 
        const file = event.target.files[0];
        if(file){ 
            formik.setFieldValue('image', file);
            const reader = new FileReader();
            reader.onloadend = () => { 
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleClickImageUpload = () => { 
        fileInputRef.current.click();
    }

    useEffect(() => {
        formik.setFieldValue('username', username);
        formik.setFieldValue('prenom', prenom);
        formik.setFieldValue('nom', nom);
        formik.setFieldValue('mobile', mobile);
        formik.setFieldValue('email', email);
        formik.setFieldValue('image', image);
    }, [username, prenom, nom, mobile, email, image]);

  return (
    <form onSubmit={formik.handleSubmit} method='post'  encType="multipart/form-data" className='w-full'>
      {/* le logo */}
      <div className='flex items-center justify-center mt-2 mb-4'>
        <div>
            <div className='ml-32 cursor-pointer bottom-5 shadow-md'>
                <span onClick={handleClickImageUpload}>
                    <RiImageAddFill className="hover:text-purple-800" size={25} />
                </span>
            </div>
            {imagePreview ? (
                <div className='w-32 h-32 rounded-md shadow-md bg-white border-[0.5px]'>
                    <img  src={imagePreview} alt='preview' className='p-2 object-cover items-center'/> 
                </div>
             ) : (
                <div className='w-32 h-32 rounded-md shadow-md bg-white border-[0.5px]'>
                    <img  src={image} alt='Preview' className='p-2 object-cover items-center'/> 
                </div>
            )}
        </div>
        <input type='file' accept='image/*' id='image' style={{ display: "none"}} ref={fileInputRef} onChange={handleImageChange}/> 
      </div>
      <div className="italic text-md text-center text-red-500">{formik.touched.image && formik.errors.image ?
         (<motion.div 
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -100, opacity: 0 }}
            transition={{ duration: 0.5 }}
        >{formik.errors.image}</motion.div>) : null}
        </div>
        <hr /> 
        <div className='grid grid-cols-8 gap-4 px-14 py-2 row-auto'>

            <div className='col-span-4'>
                <label htmlFor="prenom" className="block text-gray-700 font-semibold mb-1 pl-2">Prenom</label>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FaUserPen className="w-5 h-5 text-gray-400 " />
                        </span>
                        <input 
                        autoComplete='off'
                        id="prenom" 
                        name='prenom'
                        onChange={formik.handleChange}
                        value={formik.values.prenom}
                        onBlur={formik.handleBlur}
                        type="text" 
                        placeholder="Saisissez le Prenom " 
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.prenom && formik.touched.prenom ? 'border-red-500': ''}`}
                        />
                    </div>
            </div>

            <div className='col-span-4'>
                <label htmlFor="nom" className="block text-gray-700 font-semibold mb-1 pl-2">Nom</label>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FaUserPen className="w-5 h-5 text-gray-400 " />
                        </span>
                        <input 
                        autoComplete='off'
                        id="nom" 
                        name='nom'
                        onChange={formik.handleChange}
                        value={formik.values.nom}
                        onBlur={formik.handleBlur}
                        type="text" 
                        placeholder="Saisissez le Nom " 
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.nom && formik.touched.nom ? 'border-red-500': ''}`}
                        />
                    </div>
            </div>

            {formik.touched.prenom && formik.errors.prenom ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-4 text-red-500"
                >{formik.errors.prenom}
                </motion.div>) : null
            }

            {formik.touched.nom && formik.errors.nom ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-4 text-red-500"
                >{formik.errors.nom}
                </motion.div>) : null
            }

            <div className='col-span-8'>
                <label htmlFor="email" className="block text-gray-700 font-semibold mb-1 pl-2">Adresse électronique</label>
                <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FaEnvelope className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input 
                    autoComplete='off'
                    id="email" 
                    name='email'
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    type="email" 
                    placeholder="Saisissez l'email" 
                    className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.email && formik.touched.email ? 'border-red-500': ''}`}
                    />
                </div>
            </div>

            {formik.touched.email && formik.errors.email ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-8 text-red-500"
                >{formik.errors.email}
                </motion.div>) : null
            }

            <div className='col-span-8'>
                <label htmlFor="mobile" className="block text-gray-700 font-semibold mb-1 pl-2">Téléphone Mobile</label>
                    <div className="relative">
                        <PhoneInput
                            country={'sn'}
                            onlyCountries={['sn', 'gn','ml', 'ci']}
                            masks={{ 
                                sn: '-..-...-..-..',
                                gn: '-..-...-...',
                                ml: '-..-...-...',
                                ci: '-..-..-..-..'
                                }}
                            autoComplete='off'
                            id="mobile"
                            name='mobile'
                            value={formik.values.mobile}
                            onChange={(value) => {
                                formik.setFieldValue('mobile', value);
                            }}
                            onBlur={() => {
                                formik.handleBlur('mobile');
                                setIsFocused(false);
                            }}
                            placeholder=" Numero Mobile"
                            inputStyle={{
                                'fontFamily':'serif',
                                'width': '100%',
                                'height': '2.7rem',
                                'color': '#444',
                                ...(isFocused && {
                                    outline: 'none',
                                    boxShadow: '0 0 0 2px #3b82f6',
                                    borderColor: 'transparent'
                                
                                })
                            }}
                            onFocus={() => setIsFocused(true)}
                        />
                    </div>
            </div>

            {formik.touched.mobile && formik.errors.mobile ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-8 text-red-500"
                >{formik.errors.mobile}
                </motion.div>) : null
            }

            <div className='col-span-8'>
                <label htmlFor="username" className="block text-gray-700 font-semibold mb-1 pl-2">Login</label>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FaUserPlus className="w-5 h-5 text-gray-400 " />
                        </span>
                        <input 
                        readOnly
                        autoComplete='off'
                        id="username" 
                        name='username'
                        onChange={formik.handleChange}
                        value={formik.values.username}
                        onBlur={formik.handleBlur}
                        type="text" 
                        placeholder="Saisissez l'identifiant " 
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full bg-gray-200  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.username && formik.touched.username ? 'border-red-500': ''}`}
                        />
                    </div>
            </div>
            {formik.touched.username && formik.errors.username ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-8 text-red-500"
                >{formik.errors.username}
                </motion.div>) : null
            }
            <div className='col-span-8'>
                <label htmlFor="role" className="block text-gray-700 font-semibold mb-1 pl-2">Roles</label>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FaUserShield className="w-5 h-5 text-gray-400 " />
                        </span>
                        <select 
                        autoComplete='off'
                        id="role" 
                        name='role'
                        onChange={formik.handleChange}
                        value={formik.values.role}
                        onBlur={formik.handleBlur}
                        placeholder="Selectionnez un role " 
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.role && formik.touched.role ? 'border-red-500': ''}`}
                        >
                            <option value="" label="Sélectionner un Role" />
                            {role.map(option =>(
                                <option key={option.id} value={option.libelle}>{option.libelle}</option>
                            ))}
                        </select>
                    </div>
            </div>
            {formik.touched.role && formik.errors.role ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-8 text-red-500"
                >{formik.errors.role }
                </motion.div>) : null
            }
            </div>
            <div className='flex  justify-center item-center mt-4'>
                <Button type='submit' size="md" disabled={isLoading}>
                    <span className="pr-2">
                        {
                            isLoading ? (<Spinner color="info" size="md" />) : (<FaCheckCircle size={18} />)
                        }
                    </span>
                        {
                            isLoading ? "En Cours de modification ..." : 'Modifier'
                        }
                </Button>
            </div>
  
    </form>
  )
}

export default UpdateUtilisateur

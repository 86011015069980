import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../template/sidebar/Sidebar';
import HomeNavbar from '../../template/navbar/HomeNavbar';
import AdminUserBanner from '../../template/banner/AdminUserBanner';
import SouscriptionsVoyage from '../../components/forms/voyages/SouscriptionsVoyage';
import { motion } from 'framer-motion';

const SouscriptionVoyageList = () => {
    const [isOpen, setIsOpen] = useState(false);
    
    const bannerRef = useRef(null);
    const handleOpenClose = () => {
      setIsOpen(!isOpen);
    };
    
    
    const stepMessages = {
      step1: (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className='font-bold text-xl text-blue-600 underline underline-offset-2'>
            Bienvenue dans le formulaire de souscription pour une assurance voyage.
          </h1>
          <span className='text-lg text-gray-700'>
            Veuillez remplir tous les champs du formulaire que l'on vous présentera sur chaque étape du parcours :
          </span>
          <motion.ul className='list-disc list-inside mt-2'>
            <li className='text-lg text-gray-800 font-bold underline underline-offset-2'>Etape 1 : Les informations du voyage</li>
            <div>
              À ce niveau, vous devez sélectionner le pays de destination, indiquer le nombre de voyageurs, puis choisir la date de départ et la date de retour. La durée du séjour sera alors automatiquement calculée. En revanche, si vous ne connaissez pas la date de retour, vous pouvez cocher "Non" à « Date de retour connue », puis renseigner la durée de séjour. Ensuite, indiquez la date de naissance des assurés (voyageurs) en prenant en compte les éventuelles erreurs dans les champs, comme la limite d'âge des voyageurs. Si tous les champs sont remplis, vous verrez, sur votre droite, la liste des assureurs disponibles. Choisissez-en un, vérifiez les montants, puis cliquez sur "Suivant"
            </div>
          </motion.ul>
        </motion.div>
      ),
      step2: (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className='font-bold text-xl text-blue-600 underline underline-offset-2'>
            Bienvenue dans le formulaire de souscription pour une assurance voyage.
          </h1>
          <span className='text-lg text-gray-700'>
            Veuillez remplir tous les champs du formulaire que l'on vous présentera sur chaque étape du parcours :
          </span>
          <motion.ul className='list-disc list-inside mt-2'>
            <li className='text-lg text-gray-800 font-bold'>Etape 2 : Les informations des voyageurs et souscripteurs</li>
            <div>
             Veuillez renseigner les informations personnelles de chaque voyageur ainsi que du souscripteur. Ces informations sont essentielles pour établir le contrat d'assurance. Si l’assurée (voyageur) est la personne qui souscrit dans ce cas vous pouvez cliquer sur le bouton « Souscripteur » qui se trouve du côté droit du formulaire, après avoir remplie le formulaire de l’assure. Si vous avez plusieurs assures vous pouvez cliquer sur la flèche basse qui est a cote de Assure 1 ou bien de faire un scrolle sur le formulaire afin de voir les autres champs.
            </div>
          </motion.ul>
        </motion.div>
      ),
      step3: (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className='font-bold text-xl text-blue-600 underline underline-offset-2'>
            Bienvenue dans le formulaire de souscription pour une assurance voyage.
          </h1>
          <span className='text-lg text-gray-700'>
            Veuillez remplir tous les champs du formulaire que l'on vous présentera sur chaque étape du parcours :
          </span>
          <motion.ul className='list-disc list-inside mt-2'>
            <li className='text-lg text-gray-800 font-bold underline underline-offset-2'>Etape 3 : Le récapitulatif des données saisies</li>
            <div>
             Vérifiez attentivement toutes les informations saisies avant la validation finale de votre souscription. Si vous avez commis des erreurs vous pouvez toujours cliquer sur « Précèdent » les donnes seront dans leurs  champs respectifs.
            </div>
          </motion.ul>
        </motion.div>
      )
    };

    const [currentStep, setCurrentStep] = useState(0);
    useEffect(() => {
      if (bannerRef.current) {
          bannerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  }, [currentStep]);
    

    return (
      <div className='flex h-screen bg-gray-100'>
          <Sidebar isOpen={isOpen} onToggle={handleOpenClose} />
          <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
              <HomeNavbar isOpen={isOpen} ref={bannerRef} />
              <main className='p-6 mt-16'>
                  <div >
                      <AdminUserBanner content={stepMessages[`step${currentStep + 1}`]} />
                  </div>
                  <SouscriptionsVoyage 
                      stepMessages={stepMessages} 
                      setCurrentStep={setCurrentStep} 
                  />
              </main>
          </div>
      </div>
    );
};

export default SouscriptionVoyageList;
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const VoyageSessionCleanup = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const cleanupVoyageSession = () => {
            sessionStorage.removeItem('formVoyage');
        };

        const isValidPath = location.pathname.toLowerCase().includes('/voyage/souscription');

        if (!isValidPath && initialized) {
            cleanupVoyageSession();
        }

        if (!initialized) {
            setInitialized(true);
        }

        return () => {
            if (!isValidPath) {
                cleanupVoyageSession();
            }
        };
    }, [location.pathname, initialized]);
};

export const DevisSessionCleanup = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const cleanupDevisSession = () => {
            sessionStorage.removeItem('tempVoyageData');
        };

        const isValidPath = location.pathname.toLowerCase().includes('/voyages/devis');

        if (!isValidPath && initialized) {
            cleanupDevisSession();
        }

        if (!initialized) {
            setInitialized(true);
        }

        return () => {
            if (!isValidPath) {
                cleanupDevisSession();
            }
        };
    }, [location.pathname, initialized]);
};
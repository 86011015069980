import { Popover, PopoverContent, PopoverTrigger } from 'keep-react';
import React, { useState } from 'react'
import { motion } from "framer-motion";
import { RxDotsHorizontal } from 'react-icons/rx';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { FcConferenceCall, FcFullTrash, FcShop } from 'react-icons/fc';
import MyModal from '../dialog/MyModal';
import UpdateDistributeurs from '../forms/distributeurs/UpdateDistributeurs';

const OptionsDistributeur = ({active, confirmation, act}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [Selected, setSelected] = useState(active);
    const [activity, setActivity] = useState(act);
    const [updateModal, setUpdateModale] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const pointvente = () => {navigate(`${location.pathname}/${Selected.id}/PointsDeVentes`)}

    const formUpdate = (data) =>{ 
      const ids = data.id;
      setIsLoading(true)
      var updateDistrib={ 
          raison: data?.raison,
          rccm: data?.rccm,
          adresse: data?.adresse,
          email: data?.email,
          mobile: data?.mobile,
          gerant: data?.gerant,
          fixe: data?.fixe,
          ville: data?.ville,
          rue: data?.rue,
          quartier: data?.quartier,
          activiteId: data?.activiteId
      }
      alert(JSON.stringify(updateDistrib))
    }

  return (
    <>
    {/* update dialog */}
    <MyModal 
      isOpen={updateModal}
      closeModal={()=> setUpdateModale(false)}
      content={<UpdateDistributeurs formdata={Selected} isLoading={isLoading} data={activity} handleUpdate={formUpdate}/>}
    /> 

      <div className='flex justify-between p-1'>
        <div></div>
        <Popover placement='left-start'>
          <PopoverTrigger
            className='p-2  rounded-md cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700  hover:text-white '>
            <RxDotsHorizontal   />
            <PopoverContent 
             className='max-w-xs z-20 rounded-lg shadow-md border bg-[#faf0e6]'>
              <motion.div 
               initial={{ opacity: 0, y: -20 }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: 0.5 }}
               className='flex-col justify-center p-2 space-y-2'
              >
                <div className='flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7'
                  onClick={()=> setUpdateModale(true)}
                  >
                  <div className='flex items-center space-x-2 '>
                    <FaEdit className='text-green-500' size={19} /> 
                    <button>Modifier</button>
                  </div>
                    <div></div>
                </div>

                <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7 '
                onClick={pointvente}
                >
                    <div className='flex items-center space-x-2  '>
                        <FcShop size={23}/> 
                    <button>Points de Ventes</button>
                    </div>
                    <div className='bg-purple-700 rounded-lg border border-white'>
                        <span className='text-white p-1 '>12</span>
                    </div>
                </div>

                <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7 '>
                    <div className='flex items-center space-x-2  '>
                        <FcConferenceCall size={23} /> 
                    <button>Comptes</button>
                    </div>
                    <div className='bg-purple-700 rounded-lg border border-white'>
                        <span className='text-white p-1 '>01</span>
                    </div>
                </div>
                <hr /> 
                <div className=' flex justify-between hover:bg-purple-700 hover:text-white p-2 rounded-md cursor-pointer space-x-7'
                // onClick={''}
                >
                    <div className='flex items-center space-x-2 '>
                    <FcFullTrash size={23} /> 
                    <button>Supprimer</button>
                    </div>
                    <div></div>
                </div>

              </motion.div>

            </PopoverContent>

          </PopoverTrigger>

        </Popover>
      </div>
    </>
  )
}

export default OptionsDistributeur

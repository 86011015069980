
import React, { useState, useEffect, useRef } from 'react';
import { createColumnHelper,flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable} from '@tanstack/react-table';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { FaRegFilePdf, FaSort, FaSortUp, FaSortDown, FaEllipsisV } from 'react-icons/fa';
import { Button, Dropdown, DropdownAction, DropdownContent, DropdownItem, DropdownList } from 'keep-react';
import { NewPDFAttestationVoyage, NewPDFConditionVoyage } from '../../../api/pdf/AssureurAttestation';
import logopdf from "/src/images/pflogo.png";
import { useLocation, useNavigate } from 'react-router-dom';
import FormatNumero from '../../../utils/FormatNumero';

const VendeurContratDatatable = ({ data, chargement }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [rowSelection, setRowSelection] = useState({})
  const [tableHeight, setTableHeight] = useState('calc(100vh - 200px)');
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [sorting, setSorting] = useState([]);
 
  const [loading, setLoading] = useState({
    attestation: false,
    condition: false,
  });
  const navigate = useNavigate();
  const location = useLocation();

  

  useEffect(() => {
    const updateHeight = () => {
      const headerHeight = 64; 
      const paginationHeight = 76;
      const padding = 32; 
      const availableHeight = window.innerHeight - (headerHeight + paginationHeight + padding);
      setTableHeight(`${availableHeight}px`);
    };
  
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    if (Object.keys(rowSelection).length > 0) {
      setShowSelectionModal(true);
      const timer = setTimeout(() => {
        setShowSelectionModal(false);
      }, 2000); 
      return () => clearTimeout(timer);
    }
  }, [rowSelection]);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.display({
      id: 'select',
      header: ({ table }) => (
        <div className="flex justify-center items-center">
          <input
            type="checkbox"
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="flex justify-center items-center">
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
          />
        </div>
      ),
    }),
    columnHelper.accessor('date', {
      header: ({ column }) => (
        <div 
          className="cursor-pointer flex items-center justify-center gap-2"
          onClick={() => column.toggleSorting()}
        >
          <span>Date souscription</span>
          {column.getIsSorted() ? (
            column.getIsSorted() === "asc" ? (
              <FaSortUp className="inline" />
            ) : (
              <FaSortDown className="inline" />
            )
          ) : (
            <FaSort className="inline" />
          )}
        </div>
      ),
      cell: info => <div className="text-md text-center text-gray-500 font-medium">{info.getValue()}</div>
    }),
    columnHelper.accessor('policeNo', {
      header: ({ column }) => (
        <div 
          className="cursor-pointer flex items-center justify-center gap-2"
          onClick={() => column.toggleSorting()}
        >
          <span>Police N°</span>
          {column.getIsSorted() ? (
            column.getIsSorted() === "asc" ? (
              <FaSortUp className="inline" />
            ) : (
              <FaSortDown className="inline" />
            )
          ) : (
            <FaSort className="inline" />
          )}
        </div>
      ),
      cell: info => (
        <div className="text-md text-center text-gray-500 font-medium">
          {info.row.original.modifc 
            ? `${info.row.original.id}/${info.row.original.modifc}`
            : info.row.original.id
          }
        </div>
      )
    }),
    columnHelper.accessor('souscripteur', {
      header: ({ column }) => (
        <div 
          className="cursor-pointer flex items-center justify-center gap-2"
          onClick={() => column.toggleSorting()}
        >
          <span>Souscripteur</span>
          {column.getIsSorted() ? (
            column.getIsSorted() === "asc" ? (
              <FaSortUp className="inline" />
            ) : (
              <FaSortDown className="inline" />
            )
          ) : (
            <FaSort className="inline" />
          )}
        </div>
      ),
      cell: info => {
        const souscripteur = info.getValue();
        const social = souscripteur?.social?.trim();
        const nom = souscripteur?.nom?.trim();        
        const displayName = social || nom || 'Non défini';
        
        return (
          <div className='flex flex-col justify-center items-center'>
            <div className="text-purple-600 font-bold text-center underline underline-offset-1">
              {displayName}
            </div>
            <div className="text-sm font-medium text-gray-500">
              📞 : <FormatNumero numero={info.getValue().telephone} />
            </div>
            <div className="text-sm font-medium text-gray-500">
              ✉️ : {info.getValue().email}
            </div>
          </div>
        );
      }
    }),
    columnHelper.accessor('voyage', {
      header: ({ column }) => (
        <div 
          className="cursor-pointer flex items-center justify-center gap-2"
          onClick={() => column.toggleSorting()}
        >
          <span>Voyage</span>
          {column.getIsSorted() ? (
            column.getIsSorted() === "asc" ? (
              <FaSortUp className="inline" />
            ) : (
              <FaSortDown className="inline" />
            )
          ) : (
            <FaSort className="inline" />
          )}
        </div>
      ),
      cell: info => (
        <div className="flex flex-col justify-center items-center">
          
          <div className='font-bold text-gray-500  underline underline-offset-1 uppercase'>
            {info.row.original.formule.libelle}
          </div>
          <div className="flex space-x-3 items-center">
            <div className='font-bold text-sm text-gray-500'>Depart :</div>
            <div className='font-bold text-sm text-purple-600'>
              {new Date(info.getValue().depart).toLocaleDateString()}
            </div>
          </div>
          <div className="flex space-x-3 items-center">
            <div className='font-bold text-sm text-gray-500'>Retour :</div>
            <div className='font-bold text-sm text-purple-600'>
              {new Date(info.getValue().retour).toLocaleDateString()}
            </div>
          </div>
        </div>
      )
    }),
    columnHelper.accessor('decomposition', {
      header: ({ column }) => (
        <div 
          className="cursor-pointer flex items-center justify-center gap-2"
          onClick={() => column.toggleSorting()}
        >
          <span>Décomposition</span>
          {column.getIsSorted() ? (
            column.getIsSorted() === "asc" ? (
              <FaSortUp className="inline" />
            ) : (
              <FaSortDown className="inline" />
            )
          ) : (
            <FaSort className="inline" />
          )}
        </div>
      ),
      cell: info => (
        <div className="">
          <div className='flex justify-between items-center space-x-4'>
            <div className='text-sm font-bold text-gray-500'>Prime nette:</div>
            <div className='text-sm font-bold text-purple-600'>
              {info.getValue().primeNette.toLocaleString()}
            </div>
          </div>
          <div className='flex justify-between items-center space-x-4'>
            <div className='text-sm font-bold text-gray-500'>Accessoires:</div>
            <div className='text-sm font-bold text-purple-600'>
              {info.getValue().accessoires.toLocaleString()}
            </div>
          </div>
          <div className='flex justify-between items-center space-x-4'>
            <div className='text-sm font-bold text-gray-500'>Taxes:</div>
            <div className='text-sm font-bold text-purple-600'>
              {info.getValue().taxes.toLocaleString()}
            </div>
          </div>
          <div className='flex justify-between items-center space-x-4'>
            <div className='text-sm font-bold text-gray-500'>Prime TTC:</div>
            <div className='text-sm font-bold text-purple-600'>
              {info.getValue().primeTTC.toLocaleString()}
            </div>
          </div>
          <div className='flex justify-between items-center space-x-4'>
            <div className='text-sm font-bold text-red-500'>Montant Total:</div>
            <div className='text-sm font-bold text-red-500'>
              {info.getValue().montantTotal.toLocaleString()}
            </div>
          </div>
        </div>
      )
    }),
    columnHelper.accessor('assureur', {
      header: ({ column }) => (
        <div 
          className="cursor-pointer flex items-center justify-center gap-2"
          onClick={() => column.toggleSorting()}
        >
          <span>Assureur</span>
          {column.getIsSorted() ? (
            column.getIsSorted() === "asc" ? (
              <FaSortUp className="inline" />
            ) : (
              <FaSortDown className="inline" />
            )
          ) : (
            <FaSort className="inline" />
          )}
        </div>
      ),
      cell: info => (
        <div className='border rounded-full p-4 bg-gray-400'>
          <div className="font-medium text-white text-center">
            {info.getValue().nom}
          </div>
        </div>
      )
    }),

    columnHelper.accessor('pdv', {
      header: ({ column }) => (
        <div 
          className="cursor-pointer flex items-center justify-center gap-2"
          onClick={() => column.toggleSorting()}
        >
          <span>Point de Vente</span>
          {column.getIsSorted() ? (
            column.getIsSorted() === "asc" ? (
              <FaSortUp className="inline" />
            ) : (
              <FaSortDown className="inline" />
            )
          ) : (
            <FaSort className="inline" />
          )}
        </div>
      ),
      cell: info => (
        <div className='flex flex-col'>
          <div className="font-medium text-gray-500 text-center">
            {info.getValue()}
          </div>
          <div className='flex justify-center items-center space-x-4'>
            <div className="text-sm font-bold text-gray-500">vendeur:</div>
            <div className='text-sm font-bold text-purple-500'>
              {info.row.original.createdByUser.vendeur}
            </div>
          </div>
        </div>
      )
    }),
    
    columnHelper.accessor('actions', {
      header: 'Actions',
      cell: info => {
        // Helper pour vérifier si un loader est actif
        const isLoading = loading.attestation || loading.condition;
        
        return (
          <div className='flex justify-center items-center'>
            <Dropdown>
              <DropdownAction asChild>
                <Button 
                    size="sm" 
                    className="p-2 rounded-lg bg-blue-100 hover:bg-blue-200 transition-colors"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500" />
                    ) : (
                      <FaEllipsisV className="text-blue-600" size={18} />
                    )}
               </Button>
              </DropdownAction>
              
              <DropdownContent>
                <DropdownList>
                  <DropdownItem className="hover:bg-transparent">
                    <Dropdown placement="right-start">
                      <DropdownAction className="bg-white py-2 px-4 hover:bg-blue-300 hover:text-white">
                        <div className='flex justify-between items-start space-x-3'>
                          <FaRegFilePdf className="w-5 h-5 text-gray-600" />
                          <span className="text-gray-600 font-medium w-full">Livrables</span>
                        </div>
                      </DropdownAction>
                      
                      <DropdownContent>
                        <DropdownList>
                          <DropdownItem
                  onClick={async () => {
                    setLoading(prev => ({ ...prev, attestation: true }));
                    try {
                      await handleOpenAttestationPdf(info.row.original.id);
                    } finally {
                      setLoading(prev => ({ ...prev, attestation: false }));
                    }
                  }}
                  disabled={isLoading}
                >
                  <div className="flex items-center gap-2">
                    <img src={logopdf} alt="PDF icon" className="w-6 h-8" />
                    <span className="text-gray-600 text-sm">Attestations</span>
                  </div>
                          </DropdownItem>

                          <DropdownItem
                            onClick={async () => {
                              setLoading(prev => ({ ...prev, condition: true }));
                              try {
                                await handleOpenConditionPdf(info.row.original.id);
                              } finally {
                                setLoading(prev => ({ ...prev, condition: false }));
                              }
                            }}
                            disabled={isLoading}
                          >
                            <div className="flex items-center gap-2">
                              <img src={logopdf} alt="PDF icon" className="w-6 h-8" />
                              <span className="text-gray-600 text-sm">Conditions</span>
                            </div>
                          </DropdownItem>
                        </DropdownList>
                      </DropdownContent>
                    </Dropdown>
                  </DropdownItem>
    
                  <DropdownItem 
                    className="hover:bg-transparent"
                    disabled={isLoading}
                  >
                    <div 
                      className="flex justify-between items-start space-x-3 py-2 px-4 text-left hover:bg-blue-300 hover:text-white rounded-md"
                      onClick={() => navigate(`${location.pathname}/Modifie/${info.row.original.id}`)}
                    >
                      <FaRegFilePdf className="w-5 h-5 text-gray-600" />
                      <span className="text-gray-600 text-sm font-medium">Modifier</span>
                    </div>
                  </DropdownItem>
                </DropdownList>
              </DropdownContent>
            </Dropdown>
          </div>
        );
      }
    })

  ];

  const closeModal = () => {
    setShowModal(false);
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      setPdfUrl(null);
    }
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      sorting,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const handleOpenAttestationPdf = async (id) => {
    setLoading(prev => ({ ...prev, attestation: true }));
    try {
      const result = await NewPDFAttestationVoyage({ id });
      if (result.status === 200) {
        const url = URL.createObjectURL(result.data);
        setPdfUrl(url);
        setShowModal(true);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du PDF", error);
    } finally {
      setLoading(prev => ({ ...prev, attestation: false }));
    }
  };
  
  const handleOpenConditionPdf = async (id) => {
    setLoading(prev => ({ ...prev, condition: true }));
    try {
      const result = await NewPDFConditionVoyage({ id });
      if (result.status === 200) {
        const url = URL.createObjectURL(result.data);
        setPdfUrl(url);
        setShowModal(true);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du PDF", error);
    } finally {
      setLoading(prev => ({ ...prev, condition: false }));
    }
  };

  useEffect(() => {
    console.log('Lignes sélectionnées:', 
      Object.keys(rowSelection).map(key => data[parseInt(key)])
    )
  }, [rowSelection])

  return (
    <div className="w-full h-full bg-white rounded-lg shadow flex flex-col mb-6">
      
      {/* Modal PDF */}
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <div className="bg-white w-3/4 h-3/4 rounded-lg shadow-lg overflow-hidden">
             <div className="flex justify-between items-center p-4 border-b">
               <h2 className="text-xl font-semibold">Aperçu du PDF</h2>
               <button onClick={closeModal} className="text-red-500 font-bold">
                 <span className='p-3 hover:bg-red-500 hover:text-white rounded-md border-red-500 border'>
                   Fermer
                 </span>
               </button>
             </div>
             <div className="p-4 h-full">
               {pdfUrl ? (
                 <iframe src={pdfUrl} title="PDF Preview" className="w-full h-full" />
               ) : (
                 <p>Chargement du PDF...</p>
               )}
             </div>
           </div>
        </motion.div>
      )}

      <div className="flex-1 overflow-auto min-h-[600px]" style={{ height: tableHeight }}>
        <table className="min-w-full divide-y divide-gray-200 ">
          <thead className="bg-gray-50 sticky top-0 z-10">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className="px-6 py-3 text-center text-md font-medium text-white bg-purple-600 uppercase tracking-wider"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {table.getRowModel().rows.length === 0 ? (
              <tr>
                <td 
                  colSpan={columns.length}
                  className="px-6 py-16 text-center"
                >
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="flex flex-col items-center justify-center text-gray-500"
                  >
                    <svg 
                      className="w-16 h-16 mb-4 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={1.5}
                        d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                      />
                    </svg>
                    <span className="text-xl font-medium">{chargement ? "Chargement des données ..." : "Aucune donnée n’est disponible pour l’instant"}</span>
                    {chargement && (
                      <div className="mt-4">
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
                      </div>
                    )}
                  </motion.div>
                </td>
              </tr>
            ) : (
              <AnimatePresence>
              {table.getRowModel().rows.map(row => (
                <motion.tr
                  key={row.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                  className="hover:bg-blue-50"
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className="px-6 py-4 whitespace-nowrap"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </motion.tr>
              ))}
            </AnimatePresence>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="py-3 flex items-center justify-between border-t border-gray-200 px-4">
        <div className="flex items-center space-x-4">
          <div className="flex space-x-2">
            <button
              className={`px-4 py-2 text-sm font-medium text-white rounded-md transition-colors ${
                !table.getCanPreviousPage()
                  ? 'bg-purple-300 cursor-not-allowed'
                  : 'bg-purple-500 hover:bg-purple-600 cursor-pointer'
              }`}
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Précédent
            </button>
            <button
              className={`px-4 py-2 text-sm font-medium text-white rounded-md transition-colors ${
                !table.getCanNextPage()
                  ? 'bg-purple-300 cursor-not-allowed'
                  : 'bg-purple-500 hover:bg-purple-600 cursor-pointer'
              }`}
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Suivant
            </button>
          </div>

          <span className="flex items-center gap-1 text-sm text-gray-700">
            <span>Page</span>
            <span className="font-medium text-purple-600">
              {table.getState().pagination.pageIndex + 1}
            </span>
            <span>sur</span>
            <span className="font-medium text-purple-600">
              {table.getPageCount()}
            </span>
          </span>
        </div>

        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-700">Afficher</span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={e => table.setPageSize(Number(e.target.value))}
            className="form-select rounded-md border-gray-300 text-sm"
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <span className="text-sm text-gray-700">lignes par page</span>
        </div>
      </div>
      {/* affiche le nombre de contrats selectionnes */}
      <AnimatePresence>
        {showSelectionModal && Object.keys(rowSelection).length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 50, scale: 0.3 }}
            transition={{
              duration: 0.3,
              ease: "easeInOut"
            }}
            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
          >
            <motion.div
              className="bg-purple-600 text-white px-6 py-3 rounded-lg shadow-lg flex items-center space-x-2"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
            >
              <motion.span
                key={Object.keys(rowSelection).length}
                initial={{ scale: 0.5 }}
                animate={{ 
                  scale: [1, 1.2, 1],
                  transition: { duration: 0.3 }
                }}
                className="font-bold text-2xl"
              >
                {Object.keys(rowSelection).length}
              </motion.span>
              <span className="text-lg">
                contrat{Object.keys(rowSelection).length > 1 ? 's' : ''} sélectionné{Object.keys(rowSelection).length > 1 ? 's' : ''}
              </span>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default VendeurContratDatatable;

import axios from "axios";

export async function NewVoyagePaiementEspece(ref, token, position) {
    try {
        const response = await axios({
            method: 'PUT', 
            url: `${import.meta.env.VITE_BACKLOG}/contrats/${ref}`, 
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `${token}`,
                'countryCode': `${position?.location?.country_code3 || ''}`
            },
            data: {
                payMethod: "espece" 
            }
        });
        return response;
    } catch (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            console.log("Erreur de requête", error.request);
        } else {
            console.log("Erreur", error.message);
        }
        console.log(error.config);
        return error;
    }
}
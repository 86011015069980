import React, { useEffect } from 'react';
import { Modal } from 'keep-react';
import { motion } from 'framer-motion';
import { AiFillCloseCircle } from 'react-icons/ai';


const ChoiceDialog = ({ content, isOpen, closeModal,}) => {

    
    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            closeModal();
          }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

  return (
    <Modal isOpen={isOpen}>
      <motion.div
        className="fixed inset-0 flex items-center justify-center"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="relative bg-white rounded-3xl p-6 shadow-lg w-auto max-w-full max-h-[80vh] overflow-auto">
          <div className="flex justify-end">
            <AiFillCloseCircle
              className="text-gray-500 cursor-pointer text-2xl"
              onClick={closeModal}
            />
          </div>
          {content}
        </div>
      </motion.div>
    </Modal>
  )
}

export default ChoiceDialog


// import { createSlice } from '@reduxjs/toolkit';
// import { encryptData, decryptData } from '../../utils/Encryption';


// const initialState = {
//   userDetails: null,
//   token: null,
//   position: null
// };

// // Vérifiez si des données cryptées existent déjà dans localStorage
// const encryptedUserDetails = localStorage.getItem('userDetails');
// const encryptedToken = localStorage.getItem('token');
// const encryptedPosition = localStorage.getItem('position')

// if (encryptedUserDetails && encryptedToken && encryptedPosition) {
//   initialState.userDetails = decryptData(encryptedUserDetails);
//   initialState.token = decryptData(encryptedToken);
//   initialState.position = decryptData(encryptedPosition);
// }

// const userSlice = createSlice({
//   name: 'user',
//   initialState,
//   reducers: {
//     setUserDetails: (state, action) => {
//       state.userDetails = action.payload;
//       const encryptedUserDetails = encryptData(action.payload);
//       localStorage.setItem('userDetails', encryptedUserDetails);
//     },
//     setToken: (state, action) => {
//       state.token = action.payload;
//       const encryptedToken = encryptData(action.payload);
//       localStorage.setItem('token', encryptedToken);
//     },
//     setPosition: (state, action) => {
//       state.position = action.payload;
//       const encryptedPosition = encryptData(action.payload);
//       localStorage.setItem('position', encryptedPosition);
//     },
//     clearUser: (state) => {
//       state.userDetails = null;
//       state.token = null;
//       state.position = null;
//       localStorage.removeItem('userDetails');
//       localStorage.removeItem('token');
//       localStorage.removeItem('position');
//     },
//   },
// });

// export const { setUserDetails, setToken, clearUser, setPosition } = userSlice.actions;

// export default userSlice.reducer;


import { createSlice } from '@reduxjs/toolkit';

// État initial
const initialState = {
  userDetails: null,
  token: null,
  position: null
};

// Vérifiez si des données existent déjà dans localStorage
const storedUserDetails = localStorage.getItem('userDetails');
const storedToken = localStorage.getItem('token');
const storedPosition = localStorage.getItem('position');

if (storedUserDetails && storedToken && storedPosition) {
  initialState.userDetails = JSON.parse(storedUserDetails);
  initialState.token = JSON.parse(storedToken);
  initialState.position = JSON.parse(storedPosition);
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      localStorage.setItem('userDetails', JSON.stringify(action.payload));
    },
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('token', JSON.stringify(action.payload));
    },
    setPosition: (state, action) => {
      state.position = action.payload;
      localStorage.setItem('position', JSON.stringify(action.payload));
    },
    clearUser: (state) => {
      state.userDetails = null;
      state.token = null;
      state.position = null;
      localStorage.removeItem('userDetails');
      localStorage.removeItem('token');
      localStorage.removeItem('position');
    },
  },
});

export const { setUserDetails, setToken, clearUser, setPosition } = userSlice.actions;

export default userSlice.reducer;



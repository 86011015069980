import React, { useRef, useState, useEffect } from 'react';
import { FiArrowUpRight } from "react-icons/fi";
import { motion, useScroll, useTransform } from "framer-motion";
import mvideo from '/videos/meeting.mp4';
import tvideo from '/videos/voyage.mp4';
import cvideo from '/videos/auto.mp4';
import pvideo from '/videos/partenaire.mp4';
import ivideo from '/videos/immobilier.mp4';

const IMG_PADDING = 12;

const TextParalaxContent = ({ mediaUrl, isVideo, subheading, heading, children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isVideo) {
      const video = document.createElement('video');
      video.src = mediaUrl;
      video.onloadeddata = () => setIsLoaded(true);
    } else {
      const img = new Image();
      img.src = mediaUrl;
      img.onload = () => setIsLoaded(true);
    }
  }, [mediaUrl, isVideo]);

  return (
    <div style={{ paddingLeft: IMG_PADDING, paddingRight: IMG_PADDING }}>
      <div className='relative h-[150vh]'>
        {!isLoaded && <div className="loading-spinner">Loading...</div>}
        {isLoaded && <StickyMedia mediaUrl={mediaUrl} isVideo={isVideo} />}
        <OverlayContent heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyMedia = ({ mediaUrl, isVideo }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"]
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [0.8, 0]);

  return (
    <motion.div
      style={{
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className='sticky z-0 overflow-hidden rounded-3xl'
    >
      {isVideo ? (
        <video
          src={mediaUrl}
          autoPlay
          loop
          muted
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      ) : (
        <div
          style={{
            backgroundImage: `url(${mediaUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
          }}
        />
      )}
      <motion.div
        style={{
          opacity,
        }}
        className='absolute inset-0 bg-neutral-950/80'
      />
    </motion.div>
  );
};

export const Content = ({ title, content }) => {
  return (
    <div className='mx-auto grid max-w-5xl grid-flow-col-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12'>
      <h2 className='col-span-1 text-3xl font-bold md:col-span-4'>
        {title}
      </h2>
      <div className='col-span-1 md:col-span-8'>
        <p className='mb-4 text-xl text-neutral-600 md:text-2xl'>
          {content}
        </p>
        
        <a
            href="https://diokotech.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block w-full rounded bg-purple-800 px-9 py-4 text-xl text-white transition-colors hover:bg-purple-500 md:w-fit"
          >
            En savoir plus <FiArrowUpRight className="inline" />
        </a>
      </div>
    </div>
  );
};

export const OverlayContent = ({ heading, subheading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"]
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      ref={targetRef}
      style={{
        y,
        opacity
      }}
      className='absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white'
    >
      <p className='text-center text-xl font-bold md:text-7xl'>{heading}</p>
      <p className='mb-2 text-center text-xl md:mb-4 md:text-3xl'>{subheading}</p>
    </motion.div>
  );
};

const Information = () => {
  return (
    <div>
      <div id="compagnie">
        <TextParalaxContent
          
          mediaUrl={mvideo}
          isVideo={true}
          heading={'DIOKOTECH'}
          subheading={'Bénéficiez de nos services innovants et pertinents répondants à vos besoins du quotidien.'}
        >
          <Content title={'Qui Somme Nous ?'}
          content={
            <div>
            <h1 > DIOKOTECH est une société qui se veut innovante du point de vue de ses offres et qui propose trois catégories de produits et services, à savoir :</h1>
                <p className=' pl-6 mt-2 mb-2 '>
                  • Des formules d'incubation pour l'accélération de la croissance des startups. La société propose également en location, par jour ou heure, de salles entièrement équipées et climatisées pour le confort de vos réunions, ateliers et séminaires, à des tarifs compétitifs ;
                </p>
                <p className=' pl-6 mt-2 mb-2 '>
                  • La distribution en réseau et en agence de produits et services via des plateformes digitales. Pour ce faire, la société a mis à la disposition de ses partenaires des solutions digitales innovantes qui permettent de réaliser des opérations commerciales, financières ou d'assurances de façon très fluide, sécurisée et organisée en conformité avec les différentes réglementations applicables dans leurs domaines d'activités ;
                </p>
                <p className='pl-6 mt-2 mb-2 '>
                  • Et la fourniture de solutions de e-commerce ou autres solutions digitales.
                </p>
              <h1>Basée au Sénégal, DIOKOTECH est également présente en Côte d'Ivoire.</h1>
            </div>
          }
          />
        </TextParalaxContent>
      </div>
      
      <div id="voyage">
        <TextParalaxContent
          
          mediaUrl={tvideo}
          isVideo={true}
          heading='Voyage'
          subheading={'Assurez-vous chez nous pour un voyage paisible en tout tranquillité.'}
        >
          <Content title={'Assurance Voyage'}
          content={
            <div>
              L’assurance voyage permet à tout voyageur qui quitte le territoire sénégalais de se couvrir contre les risques qui pourraient survenir durant le séjour à l’étranger : accidents, maladies, décès, pertes de bagages, etc.
              Cette assurance est obligatoire pour l’obtention d’un visa, dans le cadre d’un voyage dans un ou plusieurs pays de l’Espace Schengen.
              Nous vous proposons plusieurs formules de couverture. Vous pouvez faire le choix de rajouter une garantie Covid-19 à votre couverture d’assurance voyage.
            </div>
          }
            />
        </TextParalaxContent>
      </div>
      
      <div id="automobile">
        <TextParalaxContent
          
          mediaUrl={cvideo}
          isVideo={true}
          heading='Automobile'
          subheading={'Avec nous à vos côtés, oubliez les tracas de la route et roulez en toute sécurité.'}
        >
          <Content title={'Assurance Automobile'}
          content={
            <div>
              L’assurance automobile a pour objectif premier de garantir le conducteur d’un véhicule automobile contre les conséquences des dommages matériels ou corporels causés par son véhicule à des tiers (responsabilité civile). La garantie responsabilité civile constitue le minimum obligatoire pour l’assurance automobile qui peut aussi inclure, selon les modalités de votre contrat, des garanties complémentaires facultatives couvrant par exemple les dommages matériels pour le véhicule assuré et les dommages corporels du conducteur et des passagers.
            </div>
          }
          />
        </TextParalaxContent>
      </div>
      

      {/* <TextParalaxContent
        mediaUrl={ivideo}
        isVideo={true}
        heading='Habitation'
        subheading={'Donnez Nous Une Chance de Sécuriser votre Demeure et toute votre famille pour une vie épanouis.'}
      >
        <Content title={'Assurance Habitation'} />
      </TextParalaxContent>

      <TextParalaxContent
        mediaUrl={pvideo}
        isVideo={true}
        heading='Partenariats'
        subheading={'Vous Pouvez Devenir un de nos nombreux partenaires...'}
      >
        <Content title={'Nos Partenaires'} />
      </TextParalaxContent> */}
    </div>
  );
};

export default Information;
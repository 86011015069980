import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { ImManWoman } from "react-icons/im";
import { HiSwitchVertical } from "react-icons/hi";
import { FaPerson, FaUserPen, FaEnvelope } from 'react-icons/fa6';
import { MdWork } from "react-icons/md";
import PhoneInput from 'react-phone-input-2';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';
import { motion } from 'framer-motion';
import { GiPositionMarker } from 'react-icons/gi';
import { AssureFormValidation } from '../../../utils/FormValidation'; // Assurez-vous que cette fonction est importée

const FormStep3 = ({ onNext, onPrev }) => {

  const [isFocused, setIsFocused] = useState(false);
  const [type, setType] = useState("");
  const formik = useFormik({ 
    initialValues: { 
      genre: "",
      nom: "",
      prenom: "",
      activite: "", 
      telephone_mobile: "",
      adresse_email: "",
      raison_social: "",
      adresse: "",
      verif: "",
      type: "",
    },
    validate: AssureFormValidation,
    onSubmit: (values) => { 
      const saveData = JSON.parse(sessionStorage.getItem('formData')) || {};
      saveData.step3Data = values;
      sessionStorage.setItem('formData', JSON.stringify(saveData));
      onNext(values);
    }
  });

  let savedData; 
  useEffect(() => {
    savedData = JSON.parse(sessionStorage.getItem('formData')); // Assign value to savedData
    if(savedData && savedData.step2Data.verif === '1'){
      formik.setValues({
        ...formik.values,
        genre: savedData.step2Data.genre,
        nom: savedData.step2Data.nom,
        prenom: savedData.step2Data.prenom,
        activite: savedData.step2Data.activite,
        telephone_mobile: savedData.step2Data.telephone_mobile,
        adresse_email: savedData.step2Data.adresse_email,
        raison_social: savedData.step2Data.raison_social,
        adresse: savedData.step2Data.adresse,
        verif: savedData.step2Data.verif,
        type: savedData.step2Data.type
      });
      setType(savedData.step2Data.type); 
      savedData.verif = '1';
    }
  }, [formik.setValues, savedData && savedData.step2Data.verif]);

  return (
    <div className='bg-white p-8 rounded-xl border shadow-md'>
      <h2 className="text-2xl mb-4 font-semibold text-purple-700">3. Informations du souscripteur</h2>
      <div className='flex space-x-16'>
        <form onSubmit={formik.handleSubmit} method='post' className='w-full' >
          <div className='grid grid-cols-8 gap-4 py-2 row-auto'>
            <div className='col-span-8'>
              <div className='flex justify-center items-center space-x-4 text-purple-700 py-2 '>
                <FaPerson size={22} />
                <span className='text-2xl font-semibold'>Les Informations de la Personne Présente</span>
              </div>
            </div>
            
            <div className='col-span-4'>
              <label htmlFor="Type de client" className="block text-gray-700 font-semibold mb-1 pl-2">Type de client</label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <HiSwitchVertical className="w-5 h-5 text-gray-400 " />
                </span>
                <select
                  onChange={(e) => {
                    setType(e.target.value);
                    formik.handleChange(e); 
                  }}
                  disabled={Boolean(savedData?.verif === '1')}
                  value={type}
                  className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.libelle && formik.touched.libelle ? 'border-red-500' : ''}`}
                >
                  <option value="" label="Sélectionner un type de client" />
                  <option value="1" label="Personne physique - (particulier)" />
                  <option value="2" label="Personne morale - (entreprise)" />
                </select>
              </div>
            </div>
            <div className='col-span-4'></div>
            {type === "1" && (
              <>
                <motion.div
                  className='col-span-4'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="genre" className="block text-gray-700 font-semibold mb-1 pl-2">Genre</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <ImManWoman className="w-5 h-5 text-gray-400 " />
                    </span>
                    <select
                      id="genre"
                      name="genre"
                      onChange={formik.handleChange}
                      value={formik.values.genre}
                      onBlur={formik.handleBlur}
                      disabled={Boolean(savedData?.verif === '1')}
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.genre && formik.touched.genre ? 'border-red-500' : ''}`}
                    >
                      <option value="" label="Sélectionner le genre" />
                      <option value="H" label="Monsieur" />
                      <option value="F" label="Madame" />
                    </select>
                  </div>
                  {formik.touched.genre && formik.errors.genre && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="italic text-md text-red-500"
                    >
                      {formik.errors.genre}
                    </motion.div>
                  )}
                </motion.div>
                <motion.div 
                className='col-span-4'
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -50, opacity: 0 }}
                transition={{ duration: 0.5 }}
                ></motion.div>
                <motion.div
                  className='col-span-4'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="prenom" className="block text-gray-700 font-semibold mb-1 pl-2">Prénom</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <FaUserPen className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input
                      autoComplete='off'
                      id="prenom"
                      name='prenom'
                      onChange={formik.handleChange}
                      value={formik.values.prenom}
                      onBlur={formik.handleBlur}
                      type="text"
                      disabled={Boolean(savedData?.verif === '1')}
                      placeholder="Saisissez le prénom"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.prenom && formik.touched.prenom ? 'border-red-500' : ''}`}
                    />
                  </div>
                  {formik.touched.prenom && formik.errors.prenom && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="italic text-md text-red-500"
                    >
                      {formik.errors.prenom}
                    </motion.div>
                  )}
                </motion.div>
                <motion.div
                  className='col-span-4'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="nom" className="block text-gray-700 font-semibold mb-1 pl-2">Nom</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <FaUserPen className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input
                      autoComplete='off'
                      id="nom"
                      name='nom'
                      onChange={formik.handleChange}
                      value={formik.values.nom}
                      onBlur={formik.handleBlur}
                      type="text"
                      disabled={Boolean(savedData?.verif === '1')} 
                      placeholder="Saisissez le nom"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.nom && formik.touched.nom ? 'border-red-500' : ''}`}
                    />
                  </div>
                  {formik.touched.nom && formik.errors.nom && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="italic text-md text-red-500"
                    >
                      {formik.errors.nom}
                    </motion.div>
                  )}
                </motion.div>
                <motion.div
                  className='col-span-8'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="activite" className="block text-gray-700 font-semibold mb-1 pl-2">Profession</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <MdWork className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input
                      autoComplete='off'
                      id="activite"
                      name="activite"
                      onChange={formik.handleChange}
                      value={formik.values.activite}
                      onBlur={formik.handleBlur}
                      type="text"
                      disabled={Boolean(savedData?.verif === '1')} 
                      placeholder="Saisissez le métier"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.activite && formik.touched.activite ? 'border-red-500' : ''}`}
                    />
                  </div>
                  {formik.touched.activite && formik.errors.activite && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="italic text-md text-red-500"
                    >
                      {formik.errors.activite}
                    </motion.div>
                  )}
                </motion.div>
              </>
            )}
            {type === "2" && (
              <>
                <motion.div
                  className='col-span-4'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="raison_social" className="block text-gray-700 font-semibold mb-1 pl-2">Raison Sociale</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <FaUserPen className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input
                      autoComplete='off'
                      id="raison_social"
                      name='raison_social'
                      onChange={formik.handleChange}
                      value={formik.values.raison_social}
                      onBlur={formik.handleBlur}
                      type="text"
                      disabled={Boolean(savedData?.verif === '1')} 
                      placeholder="Saisissez la Raison Sociale"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.raison_social && formik.touched.raison_social ? 'border-red-500' : ''}`}
                    />
                  </div>
                  {formik.touched.raison_social && formik.errors.raison_social && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="italic text-md text-red-500"
                    >
                      {formik.errors.raison_social}
                    </motion.div>
                  )}
                </motion.div>
                <motion.div
                  className='col-span-4'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="activite" className="block text-gray-700 font-semibold mb-1 pl-2">Activité</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <MdWork className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input
                      autoComplete='off'
                      id="activite"
                      name="activite"
                      onChange={formik.handleChange}
                      value={formik.values.activite}
                      onBlur={formik.handleBlur}
                      type="text"
                      disabled={Boolean(savedData?.verif === '1')} 
                      placeholder="Saisissez l'activité"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.activite && formik.touched.activite ? 'border-red-500' : ''}`}
                    />
                  </div>
                  {formik.touched.activite && formik.errors.activite && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="italic text-md text-red-500"
                    >
                      {formik.errors.activite}
                    </motion.div>
                  )}
                </motion.div>
              </>
            )}

            <div className='col-span-4'>
              <label htmlFor="telephone_mobile" className="block text-gray-700 font-semibold mb-1 pl-2">Téléphone Mobile</label>
              <div className="relative">
                <PhoneInput
                  country={'sn'}
                  onlyCountries={['sn', 'gn','ml', 'ci']}
                  masks={{ 
                    sn: '-..-...-..-..',
                    gn: '-..-...-...',
                    ml: '-..-...-...',
                    ci: '-..-..-..-..'
                  }}
                  autoComplete='off'
                  id="telephone_mobile"
                  name='telephone_mobile'
                  value={formik.values.telephone_mobile}
                  onChange={(value) => {
                    formik.setFieldValue('telephone_mobile', value);
                  }}
                  onBlur={() => {
                    formik.handleBlur('telephone_mobile');
                    setIsFocused(false);
                  }}
                  onFocus={() => setIsFocused(true)}
                  disabled={Boolean(savedData?.verif === '1')} 
                  placeholder="Numéro du mobile"
                  inputStyle={{
                    'fontFamily': 'serif',
                    'width': '100%',
                    'height': '2.5rem',
                    'color': '#444',
                    ...(isFocused && {
                      outline: 'none',
                      boxShadow: '0 0 0 2px #3b82f6',
                      borderColor: 'transparent'
                    })
                  }}
                />
              </div>
              {formik.touched.telephone_mobile && formik.errors.telephone_mobile && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="italic text-md text-red-500"
                >
                  {formik.errors.telephone_mobile}
                </motion.div>
              )}
            </div>
            <div className='col-span-4'>
              <label htmlFor="adresse_email" className="block text-gray-700 font-semibold mb-1 pl-2">Adresse électronique</label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <FaEnvelope className="w-5 h-5 text-gray-400 " />
                </span>
                <input
                  autoComplete='off'
                  id="adresse_email"
                  name='adresse_email'
                  onChange={formik.handleChange}
                  value={formik.values.adresse_email}
                  onBlur={formik.handleBlur}
                  type="email"
                  disabled={Boolean(savedData?.verif === '1')} 
                  placeholder="Saisissez l'email"
                  className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.adresse_email && formik.touched.adresse_email ? 'border-red-500' : ''}`}
                />
              </div>
              {formik.touched.adresse_email && formik.errors.adresse_email && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="italic text-md text-red-500"
                >
                 {formik.errors.adresse_email}
                </motion.div>
              )}
            </div>
            <div className='col-span-8'>
              <label htmlFor="adresse" className="block text-gray-700 font-semibold mb-1 pl-2">Adresse</label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <GiPositionMarker className="w-5 h-5 text-gray-400 " />
                </span>
                <input
                  autoComplete='off'
                  id="adresse"
                  name='adresse'
                  onChange={formik.handleChange}
                  value={formik.values.adresse}
                  onBlur={formik.handleBlur}
                  type="text"
                  disabled={Boolean(savedData?.verif === '1')} 
                  placeholder="Saisissez l'adresse"
                  className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.adresse && formik.touched.adresse ? 'border-red-500' : ''}`}
                />
              </div>
              {formik.touched.adresse && formik.errors.adresse && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="italic text-md text-red-500"
                >
                  {formik.errors.adresse}
                </motion.div>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="flex justify-between mt-8">
        <button onClick={onPrev} className="flex items-center space-x-3 bg-blue-100 text-blue-500 py-2 px-4 rounded transform transition duration-300 hover:scale-110">
          <IoIosArrowDropleftCircle size={20} />
          <span>Précédent</span>
        </button>
        <button 
          type="button" 
          onClick={formik.handleSubmit} 
          disabled={!formik.isValid || !type} 
          className={`flex space-x-3 items-center bg-blue-500 text-white py-2 px-4 rounded transform transition duration-300 hover:scale-110 ${
            (!formik.isValid || !type) ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          <span>Suivant</span>
          <IoIosArrowDroprightCircle size={20} />
        </button>
      </div>
    </div>
  );
};

export default FormStep3;






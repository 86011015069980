import { useState } from 'react';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import piclogin from "/src/images/password.json"
import { Button, Spinner } from 'keep-react';
import { FaCheck, FaTimes } from 'react-icons/fa';

const SetPasswordForm = ({ handleSetPassword, isLoading }) => {
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    number: false
  });

  const checkPasswordCriteria = (password) => {
    if (!password) {
      setPasswordCriteria({
        length: false,
        uppercase: false,
        number: false
      });
      return;
    }
  
    setPasswordCriteria({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      number: /[0-9]/.test(password)
    });
  };

  const getPasswordStrength = () => {
    if (!formik.values.password) {
      return '';
    }
    
    const criteriaCount = Object.values(passwordCriteria).filter(Boolean).length;
    if (criteriaCount === 3) return 'Fort';
    if (criteriaCount >= 2) return 'Moyen';
    return 'Faible';
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: (values) => {
      const errors = {};
      checkPasswordCriteria(values.password);

      if (!values.password) {
        errors.password = 'Mot de passe requis';
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = 'Confirmation du mot de passe requise';
      } else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Les mots de passe ne correspondent pas';
      }

      return errors;
    },
    onSubmit: handleSetPassword
  });

  const passwordStrength = getPasswordStrength();

  const CriteriaItem = ({ met, text }) => (
    <div className="flex items-center space-x-2 text-sm">
      {met ? (
        <FaCheck className="text-green-500" />
      ) : (
        <FaTimes className="text-red-500" />
      )}
      <span className={met ? "text-green-500" : "text-gray-500"}>{text}</span>
    </div>
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      className="flex flex-col justify-center items-center bg-purple-800 bg-opacity-25"
    >
      <div className="flex items-center justify-center h-screen w-full px-5 sm:px-0">
        <div className="flex bg-white rounded-lg shadow-lg border overflow-hidden max-w-sm lg:max-w-4xl w-full">
          <div className="hidden md:block lg:w-1/2 bg-cover bg-blue-700">
            <Lottie animationData={piclogin} loop={true} autoPlay={true} className="w-full h-full" />
          </div>
          <form onSubmit={formik.handleSubmit} className="w-full p-8 lg:w-1/2">
            <p className="text-2xl text-gray-600 mb-6 text-center">Définir un mot de passe</p>

            {/* Password criteria checklist */}
            <div className="mb-6 p-4 bg-gray-50 rounded-lg">
              <p className="font-semibold mb-3">Critères du mot de passe :</p>
              <div className="space-y-2">
                <CriteriaItem met={passwordCriteria.length} text="Au moins 8 caractères" />
                <CriteriaItem met={passwordCriteria.uppercase} text="Au moins une majuscule" />
                <CriteriaItem met={passwordCriteria.number} text="Au moins un chiffre" />
              </div>
              <div className="mt-3">
                <div className="w-full h-2 bg-gray-200 rounded-full">
                  <motion.div
                    className={`h-2 rounded-full ${
                      !formik.values.password ? 'bg-gray-200' :
                      passwordStrength === 'Fort' ? 'bg-green-500' :
                      passwordStrength === 'Moyen' ? 'bg-yellow-500' :
                      'bg-red-500'
                    }`}
                    initial={{ width: 0 }}
                    animate={{
                      width: !formik.values.password ? '0%' :
                             passwordStrength === 'Fort' ? '100%' :
                             passwordStrength === 'Moyen' ? '66%' :
                             passwordStrength === 'Faible' ? '33%' : '0%'
                    }}
                    transition={{ duration: 0.5 }}
                  />
                </div>
                <p className="text-gray-600 mt-2">
                  Force du mot de passe : <strong>{formik.values.password ? passwordStrength : 'Non défini'}</strong>
                </p>
              </div>
            </div>

            {/* Password input */}
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700 text-lg font-bold mb-2">
                Mot de Passe
              </label>
              <input
                className={`text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:outline-2 focus:outline-blue-700 ${
                  formik.errors.password && formik.touched.password ? 'border-red-500' : ''
                }`}
                type="password"
                id="password"
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.password}</p>
              )}
            </div>

            {/* Confirm password input */}
            <div className="mb-6">
              <label htmlFor="confirmPassword" className="block text-gray-700 text-lg font-bold mb-2">
                Confirmez le Mot de Passe
              </label>
              <input
                className={`text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:outline-2 focus:outline-blue-700 ${
                  formik.errors.confirmPassword && formik.touched.confirmPassword ? 'border-red-500' : ''
                }`}
                type="password"
                id="confirmPassword"
                {...formik.getFieldProps('confirmPassword')}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.confirmPassword}</p>
              )}
            </div>

            {/* Submit button */}
            <div className="flex w-full justify-center item-center">
              <Button 
                type='submit' 
                size='md'
                disabled={!formik.isValid || !formik.dirty || isLoading || formik.isSubmitting}
              >
                <span className="pr-2">
                  {isLoading && <Spinner color="info" size="md" />}
                </span>
                {isLoading ? "En Cours d'enregistrement..." : 'Enregistrer'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default SetPasswordForm;
// import React, { useEffect, useState } from 'react';
// import { FiDownload, FiCreditCard } from 'react-icons/fi';
// import Sidebar from '../../template/sidebar/Sidebar';
// import HomeNavbar from '../../template/navbar/HomeNavbar';
// import moment from 'moment';
// import 'moment/locale/fr';
// import dioko from "/src/images/Diokotech.png"
// import FormatNumero from '../../utils/FormatNumero';
// import FormatMontant from '../../utils/FormatMontant';
// import afrique  from "/src/images/afrique.svg";
// import europe from "/src/images/schengen.svg";
// import monde from "/src/images/monde.svg";
// import inconnu from "/src/images/travel.png";
// import { NewPDFDevisVoyage } from '../../api/pdf/DevisFacturePdf';
// import { motion } from 'framer-motion';
// import { useParams } from 'react-router-dom';
// import VoyagePaiement from '../../components/paiement/VoyagePaiement';
// import { useProfile } from '../../UserDataStock/Profils/Profile';
// import { DevisSessionCleanup } from '../../hooks/CleanFormSession';



// const DevisVoyage = () => {

//     const [stepData, setStepData] = useState({});
//     const [isOpen, setIsOpen] = useState(false);
//     const [openDrawer, setOpenDrawer] = useState(false);
//     const [loading, setLoading] = useState(false); // État de chargement
//     const [pdfUrl, setPdfUrl] = useState(null); // URL Blob du PDF
//     const [devis, setDevis] = useState(""); // Devise par défaut
//     const [showModal, setShowModal] = useState(false); // État de la modale
//     const idDevis = useParams();
//     const {position, token, userDetails } = useProfile();

//     useEffect(() => {
//         if (position?.location?.country_code3 === "GIN") {
//             setDevis("GNF");
//           } else {
//             setDevis("XOF");
//         }
//     }, [position]);

//     // Fonction de chargement du PDF
//     const openPdfInModal = () => {
//         setLoading(true);
//         NewPDFDevisVoyage(idDevis, token, position)
//             .then((result) => {
//                 if (result.status === 200) {
//                     const url = URL.createObjectURL(result.data);
//                     setPdfUrl(url); 
//                     setShowModal(true); 
//                 } else {
//                     console.error("Erreur lors de la récupération du PDF");
//                 }
//             })
//             .catch((error) => {
//                 console.error("Erreur lors du chargement du PDF :", error);
//             })
//             .finally(() => {
//                 setLoading(false);
//             });
//     };

//     const closeModal = () => {
//         setShowModal(false);
//         if (pdfUrl) {
//             URL.revokeObjectURL(pdfUrl); // Libère la mémoire de l'URL Blob
//             setPdfUrl(null);
//         }
//     };

//     const handleOpenClose = () => { 
//         setIsOpen(!isOpen);
//     }

//     const handleDrawer = () => { 
//         setOpenDrawer(!openDrawer);
//     }

//     // Chargement des données depuis sessionStorage lors de la première exécution
//     useEffect(() => {
//         const data = JSON.parse(sessionStorage.getItem('tempVoyageData'));
//         if (data) {
//             setStepData(data);
//         }
//     }, []);
    
    
//     // Ajoutez le hook de nettoyage
    

//     const { 
//       destination,  duree, dateDepart, dateRetour, nbVoyager, 
//       selectedTarification = {} 
//     } = stepData.step1Data || {};
//     const { voyagers = []} = stepData.step2Data || {};
//     const souscripteur = stepData.step2Data?.souscripteur || {};

//     const { assurance = {}, formules = [] } = selectedTarification;
//     const formule = formules[0] || {};

//     const formulaNameToPic = {
//         "Schengen": europe,
//         "Monde Entier": monde,
//         "Afrique": afrique,
//     };

//     const getFormuleName = () => {
//         if (!selectedTarification?.formules?.length) return null;
//         return selectedTarification.formules[0]?.formule?.name || selectedTarification.formules[0]?.formule || null;
//     };
    
//     const pic = formulaNameToPic[getFormuleName()] || inconnu;

//     DevisSessionCleanup();
   

//     return (
//         <div className='flex h-screen'>
//             <Sidebar isOpen={isOpen} onToggle={handleOpenClose} /> 
//             <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
//                 <HomeNavbar isOpen={isOpen} />
//                 <main className="p-6 mt-16">
//                     <VoyagePaiement drawOpen={openDrawer} closeDrawer={handleDrawer} idref={idDevis.id}/>
//                     <div className="max-w-4xl mx-auto bg-white shadow-2xl rounded-lg overflow-hidden">
//                         {/* Header */}
//                         <div className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white p-6 flex justify-between items-center"> 
//                             <div className='flex flex-col justify-center'>
//                                 <h1 className="text-3xl font-bold">DEVIS VOYAGE</h1>
//                                 <div className='flex flex-row space-x-4 items-center text-lg'>
//                                     <span>No: </span>
//                                     <span >{idDevis.id}</span>
//                                 </div>
//                             </div>
                            
//                             <div className="flex space-x-4">
//                                 {/* Bouton pour afficher le PDF dans une modale */}
//                                 <button 
//                                     className="bg-blue-700 hover:bg-blue-800 py-2 px-4 rounded-lg text-white flex items-center shadow-md transition transform hover:scale-105"
//                                     onClick={openPdfInModal}
//                                     disabled={loading}
//                                 >
//                                     <FiDownload className="mr-2" /> {loading ? "Chargement..." : "AFFICHER LE PDF"}
//                                 </button>

//                                 <button className="bg-green-500 hover:bg-green-600 py-2 px-4 rounded-lg text-white flex items-center shadow-md transition transform hover:scale-105"
//                                     onClick={handleDrawer}
//                                 >
//                                     <FiCreditCard className="mr-2" /> PAYER
//                                 </button>
//                             </div>
//                         </div>

//                         {/* Informations de voyage */}
//                         <div className="p-8 space-y-6">
//                             {/* Destination et Détails du Voyage */}
//                             <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                                 <div className="p-6 border rounded-lg shadow-md bg-gray-50">
//                                     <h2 className="text-lg font-semibold mb-4 text-blue-700">Informations du Voyage</h2>
//                                     <p><strong>Pays de Résidence:</strong> Senegal </p>
//                                     <p><strong>Destination:</strong> {destination}</p>
//                                     <p><strong>Date de départ:</strong> {moment(dateDepart).format('DD/MM/YYYY')}</p>
//                                     <p><strong>Date de retour:</strong> {moment(dateRetour).format('DD/MM/YYYY')}</p>
//                                     <p><strong>Durée:</strong> {duree} jours</p>
//                                     <p><strong>Nombre de voyageurs:</strong> {nbVoyager}</p>
//                                 </div>

//                                 {/* Détails du souscripteur */}
//                                 <div className="p-6 border rounded-lg shadow-md bg-gray-50">
//                                     <h2 className="text-lg font-semibold mb-4 text-blue-700">Informations du Souscripteur</h2>
//                                     <p>
//                                         <strong>Souscripteur:</strong>{' '}
//                                         {(!souscripteur.prenom?.trim() && !souscripteur.nom?.trim()) 
//                                             ? souscripteur.raison_social 
//                                             : `${souscripteur.prenom || ''} ${souscripteur.nom || ''}`.trim()
//                                         }
//                                     </p>
//                                     <p>
//                                     <strong>Date de Naissance: </strong> 
//                                     {souscripteur.dateNaissance && moment(souscripteur.dateNaissance).isValid() 
//                                         ? moment(souscripteur.dateNaissance).format('DD/MM/YYYY')
//                                         : ""
//                                     }
//                                     </p>
//                                     <p><strong>Email:</strong> {souscripteur.email}</p>
//                                     <p><strong>Telephone:</strong> <FormatNumero numero={souscripteur.telephone} /> </p>
//                                     <p><strong>Profession:</strong> {souscripteur.domaine}</p>
                                    
//                                 </div>
//                             </div>

//                             {/* Voyageur(s) Information */}
//                             <div className="p-6 border rounded-lg shadow-md bg-gray-50">
//                                 <h2 className="text-lg font-semibold mb-4 text-blue-700">Voyageur(s)</h2>
//                                 {voyagers.map((voyager, index) => (
//                                     <div key={index} className="p-4 mb-4 border-b border-gray-200">
//                                         <p><strong>Assuré: </strong> {voyager.prenom}  {voyager.nom} </p>
//                                         <p><strong>Date de Naissance: </strong> {moment(voyager.dateNaissance).format('DD/MM/YYYY')}</p>
//                                         <p><strong>Adresse Mail: </strong> {voyager.email}</p>
//                                         <p><strong>Telephone: </strong><FormatNumero numero={voyager.telephone} /></p>
//                                         <p><strong>Numero Passport: </strong>{voyager.passeport} </p>
//                                         <p><strong>Date de délivrance du passeport:</strong> {moment(voyager.delivrancePasseport).format('DD/MM/YYYY')}</p>
//                                         <p><strong>Date d'expiration du Passeport: </strong><span className='text-red-500'>{moment(voyager.expirationPasseport).format('DD/MM/YYYY')}</span></p>
//                                     </div>
//                                 ))}
//                             </div>

//                             {/* les informations de l'assureur */}
//                             <div className="p-6 border rounded-lg shadow-md bg-gray-50 space-x-4">
//                                 <h2 className="text-lg font-semibold mb-4 text-blue-700">Informations de l'Assureur</h2>
//                                 <div className='flex justify-between items-center '>
//                                   <div className='flex justify-center  space-x-4'>
//                                       <div className='h-20 w-20 p-1 border mt-2'>
//                                         <img src={assurance.logo} alt="Logo Assureur" className='object-cover' />
//                                       </div>
                                      
//                                       <div>
//                                           <p><strong>Nom de l'assureur:</strong> {assurance.libelle}</p>
//                                           <p><strong>Description:</strong> {assurance.description}</p>
//                                           <p><strong>Email:</strong> {assurance.email}</p>
//                                           <p><strong>Téléphone:</strong> <FormatNumero numero={assurance.tel_mobile} /></p>
//                                       </div>
//                                   </div>
//                                   <div className="flex flex-col mr-16 items-center mb-2">
//                                       <h3 className="flex justify-center items-center text-xl font-bold text-indigo-700 mb-4 underline underline-offset-4">Formule</h3>
//                                       <div className="w-20 h-20 rounded-md bg-white p-1 shadow-md border-[0.5px]">
//                                           <img src={pic} className="w-full h-full object-cover" alt="logo" />
//                                       </div>
//                                       <h3 className="flex justify-center items-center text-xl font-bold text-indigo-700 mb-4 underline underline-offset-4">
//                                           {formule.formule || formule.formule?.name}
//                                       </h3>
//                                   </div>
//                                 </div>
//                             </div>

//                             {/* Décomposition de la Prime */}
//                             <div className="p-6 border rounded-lg shadow-md bg-gray-50">
//                                 <h2 className="text-lg font-semibold mb-4 text-blue-700">Décomposition de la Prime</h2>
//                                 <div className="grid grid-cols-2 gap-4 text-gray-700">
//                                     <p><strong>Prime nette:</strong> <FormatMontant montant={formule.primeNetteTotal} /> {devis}</p>
//                                     <p><strong>Taxes:</strong> <FormatMontant montant={formule.taxesTotal} />  {devis}</p>
//                                     <p><strong>Coût de police:</strong> <FormatMontant montant={formule.policeTotal} />  {devis}</p>
//                                     <p><strong>Frais de service:</strong> {formule.prixConseilleTotal} {devis}</p>
//                                     <p><strong>Prime TTC:</strong> <FormatMontant  montant={formule.primeTTCTotal} /> {devis}</p>
//                                     {/* <p><strong>Commission:</strong> <FormatMontant  montant={formule.commission} />  fcfa</p> */}
//                                     <p><strong>Commission:</strong> <FormatMontant  montant={0} />  {devis}</p>
//                                     <p className="text-xl font-bold mt-6 text-red-600">Total à payer:</p>
//                                     <p className="text-xl font-bold mt-6 text-red-600"><FormatMontant  montant={formule.montantTotal}/>  {devis}</p>
//                                 </div>
//                             </div>
//                         </div>

//                         {/* Footer */}
//                         <div className="bg-gray-100 p-6 flex justify-center border-t">
//                             <img src={dioko} alt="Logo" className="h-20" />
//                         </div>
//                     </div>
//                 </main>
//                 {/* Modale pour afficher le PDF */}
//                 {showModal && (
//                     <motion.div 
//                         initial={{ opacity: 0 }}
//                         animate={{ opacity: 1 }}
//                         exit={{ opacity: 0 }}
//                         transition={{ duration: 0.5 }}
//                         className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
//                     >
//                         <div className="bg-white w-3/4 h-3/4 rounded-lg shadow-lg overflow-hidden">
//                             <div className="flex justify-between items-center p-4 border-b">
//                                 <h2 className="text-xl font-semibold">Aperçu du PDF</h2>
//                                 <button onClick={closeModal} className="text-red-500 font-bold">
//                                     <span className='p-3 hover:bg-red-500 hover:text-white rounded-md border-red-500 border'>Fermer</span>
//                                 </button>
//                             </div>
//                             <div className="p-4 h-full">
//                                 {pdfUrl ? (
//                                     <iframe 
//                                         src={pdfUrl}
//                                         title="PDF Preview"
//                                         className="w-full h-full"
//                                     />
//                                 ) : (
//                                     <p>Chargement du PDF...</p>
//                                 )}
//                             </div>
//                         </div>
//                     </motion.div>
//                 )}
//             </div>
//         </div>
//     );
// }

// export default DevisVoyage;

import React, { useEffect, useState } from 'react';
import { FiDownload, FiCreditCard } from 'react-icons/fi';
import Sidebar from '../../template/sidebar/Sidebar';
import HomeNavbar from '../../template/navbar/HomeNavbar';
import moment from 'moment';
import 'moment/locale/fr';
import dioko from "/src/images/Diokotech.png"
import FormatNumero from '../../utils/FormatNumero';
import FormatMontant from '../../utils/FormatMontant';
import afrique  from "/src/images/afrique.svg";
import europe from "/src/images/schengen.svg";
import monde from "/src/images/monde.svg";
import inconnu from "/src/images/travel.png";
import { NewPDFDevisVoyage } from '../../api/pdf/DevisFacturePdf';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import VoyagePaiement from '../../components/paiement/VoyagePaiement';
import { useProfile } from '../../UserDataStock/Profils/Profile';
import { DevisSessionCleanup } from '../../hooks/CleanFormSession';



const DevisVoyage = () => {

    const [stepData, setStepData] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loading, setLoading] = useState(false); // État de chargement
    const [pdfUrl, setPdfUrl] = useState(null); // URL Blob du PDF
    const [showModal, setShowModal] = useState(false); // État de la modale
    const idDevis = useParams();
    const {position, token, userDetails } = useProfile();

    // Fonction de chargement du PDF
    const openPdfInModal = () => {
        setLoading(true);
        NewPDFDevisVoyage(idDevis, token, position)
            .then((result) => {
                if (result.status === 200) {
                    const url = URL.createObjectURL(result.data);
                    setPdfUrl(url); 
                    setShowModal(true); 
                } else {
                    console.error("Erreur lors de la récupération du PDF");
                }
            })
            .catch((error) => {
                console.error("Erreur lors du chargement du PDF :", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeModal = () => {
        setShowModal(false);
        if (pdfUrl) {
            URL.revokeObjectURL(pdfUrl); // Libère la mémoire de l'URL Blob
            setPdfUrl(null);
        }
    };

    const handleOpenClose = () => { 
        setIsOpen(!isOpen);
    }

    const handleDrawer = () => { 
        setOpenDrawer(!openDrawer);
    }

    // Chargement des données depuis sessionStorage lors de la première exécution
    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem('tempVoyageData'));
        if (data) {
            setStepData(data);
        }
    }, []);
    
    
    // Ajoutez le hook de nettoyage
    

    const { 
      destination,  duree, dateDepart, dateRetour, nbVoyager, 
      selectedTarification = {} 
    } = stepData.step1Data || {};
    const { voyagers = []} = stepData.step2Data || {};
    const souscripteur = stepData.step2Data?.souscripteur || {};

    const { assurance = {}, formules = [] } = selectedTarification;
    const formule = formules[0] || {};

    const formulaNameToPic = {
        "Schengen": europe,
        "Monde Entier": monde,
        "Afrique": afrique,
    };

    const getFormuleName = () => {
        if (!selectedTarification?.formules?.length) return null;
        return selectedTarification.formules[0]?.formule?.name;
    };
    
    const pic = formulaNameToPic[getFormuleName()] || inconnu;

    DevisSessionCleanup();
   

    return (
        <div className='flex h-screen'>
            <Sidebar isOpen={isOpen} onToggle={handleOpenClose} /> 
            <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
                <HomeNavbar isOpen={isOpen} />
                <main className="p-6 mt-16">
                    <VoyagePaiement drawOpen={openDrawer} closeDrawer={handleDrawer} idref={idDevis.id}/>
                    <div className="max-w-4xl mx-auto bg-white shadow-2xl rounded-lg overflow-hidden">
                        {/* Header */}
                        <div className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white p-6 flex justify-between items-center"> 
                            <div className='flex flex-col justify-center'>
                                <h1 className="text-3xl font-bold">DEVIS VOYAGE</h1>
                                <div className='flex flex-row space-x-4 items-center text-lg'>
                                    <span>No: </span>
                                    <span >{idDevis.id}</span>
                                </div>
                            </div>
                            
                            <div className="flex space-x-4">
                                {/* Bouton pour afficher le PDF dans une modale */}
                                <button 
                                    className="bg-blue-700 hover:bg-blue-800 py-2 px-4 rounded-lg text-white flex items-center shadow-md transition transform hover:scale-105"
                                    onClick={openPdfInModal}
                                    disabled={loading}
                                >
                                    <FiDownload className="mr-2" /> {loading ? "Chargement..." : "AFFICHER LE PDF"}
                                </button>

                                <button className="bg-green-500 hover:bg-green-600 py-2 px-4 rounded-lg text-white flex items-center shadow-md transition transform hover:scale-105"
                                    onClick={handleDrawer}
                                >
                                    <FiCreditCard className="mr-2" /> PAYER
                                </button>
                            </div>
                        </div>

                        {/* Informations de voyage */}
                        <div className="p-8 space-y-6">
                            {/* Destination et Détails du Voyage */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="p-6 border rounded-lg shadow-md bg-gray-50">
                                    <h2 className="text-lg font-semibold mb-4 text-blue-700">Informations du Voyage</h2>
                                    <p><strong>Pays de Résidence:</strong> Senegal </p>
                                    <p><strong>Destination:</strong> {destination}</p>
                                    <p><strong>Date de départ:</strong> {moment(dateDepart).format('DD/MM/YYYY')}</p>
                                    <p><strong>Date de retour:</strong> {moment(dateRetour).format('DD/MM/YYYY')}</p>
                                    <p><strong>Durée:</strong> {duree} jours</p>
                                    <p><strong>Nombre de voyageurs:</strong> {nbVoyager}</p>
                                </div>

                                {/* Détails du souscripteur */}
                                <div className="p-6 border rounded-lg shadow-md bg-gray-50">
                                    <h2 className="text-lg font-semibold mb-4 text-blue-700">Informations du Souscripteur</h2>
                                    <p>
                                        <strong>Souscripteur:</strong>{' '}
                                        {(!souscripteur.prenom?.trim() && !souscripteur.nom?.trim()) 
                                            ? souscripteur.raison_social 
                                            : `${souscripteur.prenom || ''} ${souscripteur.nom || ''}`.trim()
                                        }
                                    </p>
                                    <p>
                                    <strong>Date de Naissance: </strong> 
                                    {souscripteur.dateNaissance && moment(souscripteur.dateNaissance).isValid() 
                                        ? moment(souscripteur.dateNaissance).format('DD/MM/YYYY')
                                        : ""
                                    }
                                    </p>
                                    <p><strong>Email:</strong> {souscripteur.email}</p>
                                    <p><strong>Telephone:</strong> <FormatNumero numero={souscripteur.telephone} /> </p>
                                    <p><strong>Profession:</strong> {souscripteur.domaine}</p>
                                    
                                </div>
                            </div>

                            {/* Voyageur(s) Information */}
                            <div className="p-6 border rounded-lg shadow-md bg-gray-50">
                                <h2 className="text-lg font-semibold mb-4 text-blue-700">Voyageur(s)</h2>
                                {voyagers.map((voyager, index) => (
                                    <div key={index} className="p-4 mb-4 border-b border-gray-200">
                                        <p><strong>Assuré: </strong> {voyager.prenom}  {voyager.nom} </p>
                                        <p><strong>Date de Naissance: </strong> {moment(voyager.dateNaissance).format('DD/MM/YYYY')}</p>
                                        <p><strong>Adresse Mail: </strong> {voyager.email}</p>
                                        <p><strong>Telephone: </strong><FormatNumero numero={voyager.telephone} /></p>
                                        <p><strong>Numero Passport: </strong>{voyager.passeport} </p>
                                        <p><strong>Date de délivrance du passeport:</strong> {moment(voyager.delivrancePasseport).format('DD/MM/YYYY')}</p>
                                        <p><strong>Date d'expiration du Passeport: </strong><span className='text-red-500'>{moment(voyager.expirationPasseport).format('DD/MM/YYYY')}</span></p>
                                    </div>
                                ))}
                            </div>

                            {/* les informations de l'assureur */}
                            <div className="p-6 border rounded-lg shadow-md bg-gray-50 space-x-4">
                                <h2 className="text-lg font-semibold mb-4 text-blue-700">Informations de l'Assureur</h2>
                                <div className='flex justify-between items-center '>
                                  <div className='flex justify-center  space-x-4'>
                                      <div className='h-20 w-20 p-1 border mt-2'>
                                        <img src={assurance.logo} alt="Logo Assureur" className='object-cover' />
                                      </div>
                                      
                                      <div>
                                          <p><strong>Nom de l'assureur:</strong> {assurance.libelle}</p>
                                          <p><strong>Description:</strong> {assurance.description}</p>
                                          <p><strong>Email:</strong> {assurance.email}</p>
                                          <p><strong>Téléphone:</strong> <FormatNumero numero={assurance.tel_mobile} /></p>
                                      </div>
                                  </div>
                                  <div className="flex flex-col mr-16 items-center mb-2">
                                      <h3 className="flex justify-center items-center text-xl font-bold text-indigo-700 mb-4 underline underline-offset-4">Formule</h3>
                                      <div className="w-20 h-20 rounded-md bg-white p-1 shadow-md border-[0.5px]">
                                          <img src={pic} className="w-full h-full object-cover" alt="logo" />
                                      </div>
                                      <h3 className="flex justify-center items-center text-xl font-bold text-indigo-700 mb-4 underline underline-offset-4">
                                          {formule.formule && formule.formule.name}
                                      </h3>
                                  </div>
                                </div>
                            </div>

                            {/* Décomposition de la Prime */}
                            <div className="p-6 border rounded-lg shadow-md bg-gray-50">
                                <h2 className="text-lg font-semibold mb-4 text-blue-700">Décomposition de la Prime</h2>
                                <div className="grid grid-cols-2 gap-4 text-gray-700">
                                    <p><strong>Prime nette:</strong> <FormatMontant montant={formule.primeNetteTotal} /> fcfa</p>
                                    <p><strong>Taxes:</strong> <FormatMontant montant={formule.taxesTotal} />  fcfa</p>
                                    <p><strong>Coût de police:</strong> <FormatMontant montant={formule.policeTotal} />  fcfa</p>
                                    <p><strong>Frais de service:</strong> {formule.prixConseilleTotal} fcfa</p>
                                    <p><strong>Prime TTC:</strong> <FormatMontant  montant={formule.primeTTCTotal} /> fcfa</p>
                                    {/* <p><strong>Commission:</strong> <FormatMontant  montant={formule.commission} />  fcfa</p> */}
                                    <p><strong>Commission:</strong> <FormatMontant  montant={0} />  fcfa</p>
                                    <p className="text-xl font-bold mt-6 text-red-600">Total à payer:</p>
                                    <p className="text-xl font-bold mt-6 text-red-600"><FormatMontant  montant={formule.montantTotal}/>  fcfa</p>
                                </div>
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="bg-gray-100 p-6 flex justify-center border-t">
                            <img src={dioko} alt="Logo" className="h-20" />
                        </div>
                    </div>
                </main>
                {/* Modale pour afficher le PDF */}
                {showModal && (
                    <motion.div 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                    >
                        <div className="bg-white w-3/4 h-3/4 rounded-lg shadow-lg overflow-hidden">
                            <div className="flex justify-between items-center p-4 border-b">
                                <h2 className="text-xl font-semibold">Aperçu du PDF</h2>
                                <button onClick={closeModal} className="text-red-500 font-bold">
                                    <span className='p-3 hover:bg-red-500 hover:text-white rounded-md border-red-500 border'>Fermer</span>
                                </button>
                            </div>
                            <div className="p-4 h-full">
                                {pdfUrl ? (
                                    <iframe 
                                        src={pdfUrl}
                                        title="PDF Preview"
                                        className="w-full h-full"
                                    />
                                ) : (
                                    <p>Chargement du PDF...</p>
                                )}
                            </div>
                        </div>
                    </motion.div>
                )}
            </div>
        </div>
    );
}

export default DevisVoyage;

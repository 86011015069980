import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { HiChevronRight, HiChevronDown } from 'react-icons/hi';

const SidebarLink = ({ children, name, submenu, isOpen, onToggle, isActive, isNavOpen }) => {
  const handleClick = () => {
    if (!isNavOpen && name !== "Dashboard") {
      onToggle(name);
    } else if (name === "Dashboard") {
      onToggle();
    } else {
      onToggle(name);
    }
  };

  return (
    <div>
      <a
        onClick={handleClick}
        className={`flex p-2 rounded-md cursor-pointer stroke-[0.75] ${
          isActive 
            ? 'bg-neutral-700/70 text-white' 
            : 'hover:stroke-neutral-100 stroke-neutral-100 text-neutral-100 hover:text-neutral-100'
        } place-items-center space-x-2.5 gap-3 hover:bg-neutral-700 transition-colors duration-100 ${
          !isNavOpen ? 'justify-center' : ''
        }`}
      >
        {children}
        {isNavOpen && (
          <div className='flex overflow-clip justify-between w-full items-center space-x-1'>
            <p className='text-inherit font-DMSerifText overflow-clip whitespace-nowrap tracking-wide'>
              {name}
            </p>
            {name !== "Dashboard" && (
              isOpen ? (
                <HiChevronDown className='stroke-inherit stroke-[0.75] min-w-8 w-8' />
              ) : (
                <HiChevronRight className='stroke-inherit stroke-[0.75] min-w-8 w-8' />
              )
            )}
          </div>
        )}
      </a>
      
      <AnimatePresence>
        {isOpen && isNavOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="pl-6"
          >
            {submenu && submenu.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ delay: index * 0.1 }}
              >
                {item}
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SidebarLink;
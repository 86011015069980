
import React, { useState, useRef } from 'react';
import { FaBuilding } from 'react-icons/fa';
import DropdownPortal from '../dropdown/DropdownPortal';


const SelectDistributeurs = ({ distributeurs, onSelect, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDistributeur, setSelectedDistributeur] = useState(null);
  const selectRef = useRef(null);
  const searchInputRef = useRef(null);

  const handleOptionClick = (distributeur) => {
    setSelectedDistributeur(distributeur);
    onSelect(distributeur); // Envoyer au parent
    setIsOpen(false);
    setSearchTerm('');
  };

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      const currentScroll = window.scrollY;
      setTimeout(() => {
        searchInputRef.current?.focus();
        window.scrollTo({
          top: currentScroll,
          behavior: 'instant'
        });
      }, 0);
    }
  };

  return (
    <div className='w-full'>
      {/* <label className="block text-gray-700 font-semibold mb-1 pl-2">
        Sélectionner un Distributeur
      </label> */}
      <div className="relative" ref={selectRef}>
        <div
          className={`pl-10 pr-4 py-2 border ${
            isOpen ? 'border-purple-500' : 'border-gray-300'
          } rounded-lg w-full text-gray-700 cursor-pointer`}
          onClick={handleDropdownClick}
        >
          <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
            <FaBuilding className={`w-5 h-5 ${isLoading ? 'text-gray-300' : 'text-gray-400'}`} />
          </span>
          {isLoading ? "En cours de chargement..." : selectedDistributeur ? selectedDistributeur.raison_social : "Choisir un distributeur"}
        </div>

        <DropdownPortal isOpen={isOpen} anchorRef={selectRef}>
          <div className="bg-white border border-gray-300 rounded-lg shadow-lg">
            <input
              type="text"
              placeholder={isLoading ? "Veuillez Patienter..." : "Rechercher un distributeur..."}
              className="pl-3 pr-4 py-2 border-b w-full"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              ref={searchInputRef}
            />
            <div className="max-h-60 overflow-y-auto">
              {Array.isArray(distributeurs) && distributeurs
                .filter(dist => 
                  dist.raison_social.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(searchTerm.toLowerCase())
                )
                .map(dist => (
                  <div
                    key={dist.id}
                    onClick={() => handleOptionClick(dist)}
                    className={`px-4 py-2 cursor-pointer hover:bg-purple-500 hover:text-white`}
                  >
                    <div>{dist.raison_social}</div>
                    <div className="text-sm text-gray-500">{dist.Activite.libelle}</div>
                  </div>
                ))}
            </div>
          </div>
        </DropdownPortal>
      </div>
    </div>
  );
};

export default SelectDistributeurs;
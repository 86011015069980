import React, { useEffect, useState } from 'react'
import Sidebar from '../../../template/sidebar/Sidebar';
import HomeNavbar from '../../../template/navbar/HomeNavbar';
import AdminUserBanner from '../../../template/banner/AdminUserBanner';
import { motion } from "framer-motion";
import { FaUserPlus } from 'react-icons/fa';
import VendeursDatatables from '../../../components/datatables/ressources/vendeurs/VendeursDatatables';
import MyModal from '../../../components/dialog/MyModal';
import AddVendeurs from '../../../components/forms/vendeurs/AddVendeurs';
import { GetAllRole } from '../../../api/admin/RolesUtilisateurs';
import { failNotification, succesNotification } from '../../../components/notifications/Notifications';
import { AddUser } from '../../../api/admin/Utlisateurs';
import { useProfile } from '../../../UserDataStock/Profils/Profile';
import { useParams, useLocation } from 'react-router-dom';
import { GetAllSellerByPointVenteId } from '../../../api/vendeurs/Vendeurs';

const VendeursList = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [userModal, setUserModal] = useState(false);

  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chargement, setChargement] = useState(true);
  const [lesVendeurs, setLesVendeurs] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredVendeurs, setFilteredVendeurs] = useState([]);
  const {position, token, userDetails } = useProfile();

  const getDistributeurId = (pathname) => {
    const regex = /Distributeurs\/([^/]+)\/PointsDeVentes/;
    const match = pathname.match(regex);
    return match ? match[1] : null;
  };

  const location = useLocation();
  const idDistributeur = getDistributeurId(location.pathname);
  const idPointdeVente  = useParams().id;

  const GetVendeurs = async () => { 
      try{ 
        const vendeurs = await GetAllSellerByPointVenteId(idPointdeVente, token, position, idPointdeVente); 
        setChargement(true);
        setLesVendeurs(vendeurs);
        setIsLoading(false);
      } catch(error){ 
        console.error('Erreur lors de récupération des vendeurs:', error);
      } finally {
        setChargement(false);
      }
  };

  useEffect(() => {
    GetVendeurs();
  }, []);
  
  useEffect(() => {
    setFilteredVendeurs(lesVendeurs);
  }, [lesVendeurs]);

  useEffect(() => { 
    const recRoles = async () => { 
      try{ 
        const roles = await GetAllRole(token, position); 
        setRoles(roles);
        setIsLoading(false);
      } catch(error){ 
        console.error('Erreur lors de récupération des rôles:', error);
      }
    };
    recRoles();
  },[])

  const filterVendeurs = (query) => {
    const filtered = lesVendeurs.filter((vendeur) =>
      vendeur.nom.toLowerCase().includes(query.toLowerCase()) ||
      vendeur.prenom.toLowerCase().includes(query.toLowerCase()) ||
      vendeur.username.toLowerCase().includes(query.toLowerCase()) ||
      vendeur.mobile.includes(query)
    );
    setFilteredVendeurs(filtered);
  };

  useEffect(() => {
    if (refresh) {
      GetVendeurs();
      setRefresh(false);
    }
  }, [refresh]);

  

  const formData = (data) => { 
    setIsLoading(true);
    const role = roles?.find(roleItem => roleItem.libelle === "Vendeur")?.id || '';
    const vendeur = {
      nom: data?.nom,
      prenom: data?.prenom,
      email: data?.email,
      username: data?.username,
      mobile: data?.mobile,
      role: role,
      image: data?.image,
      pointDeVenteId: idPointdeVente, 
      distributeurId: idDistributeur
    }

    AddUser(vendeur, token, position)
    .then(function(result){
      if(result.status === 201){
        succesNotification(result.data.message);
        setUserModal(false);
        setRefresh(true);
      } else {
        failNotification(result.data.message);
      }
    })
    .catch(function(error){
      console.log(error)
    })
    .finally(function(){
      setIsLoading(false);
    })
  }

  const handleOpenClose = () => {
   setIsOpen(!isOpen);
  };

  return (
  <div className='flex h-screen'>
    <Sidebar isOpen={isOpen}  onToggle={handleOpenClose}/> 
    <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
    <HomeNavbar isOpen={isOpen} />
      <main className="p-6 mt-16">
        <AdminUserBanner content={"rien a dire pour le moment"}/>
        
        <MyModal 
          isOpen={userModal}
          closeModal={() => setUserModal(false)}
          content={
            <AddVendeurs handleRegistration={formData} isLoading={isLoading} /> 
          }
        />

        <div className='flex justify-end'>
          <div className='flex space-x-2 py-2 px-4 mr-5 bg-purple-300 text-white items-center rounded-lg cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700 hover:text-white'
            onClick={() => setUserModal(true)}>
            <FaUserPlus className='w-9 h-8'/> 
          </div>
        </div>

        <div className='bg-white justify-center rounded-xl pb-4 mt-6 row-auto'>
          <div className='flex w-full rounded-lg justify-between m-4 p-5 mt-6'>
            <div className='font-semibold text-lg text-purple-600 ml-2 underline'>
              La Liste des Vendeurs
            </div>
          </div>

          <div className='flex justify-end items-center mx-8 mb-4'>
            <div className='relative w-64'>
              <input
                type="text"
                placeholder="Rechercher un vendeur..."
                className="w-full px-4 py-2 rounded-lg border border-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  filterVendeurs(e.target.value);
                }}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
          </div>

          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }} 
            exit={{ y: 100, opacity: 0 }}
            className='p-8 border rounded-lg mx-8'
          >
            <VendeursDatatables data={filteredVendeurs} confirmation={setRefresh} chargement={chargement}  /> 
          </motion.div>
        </div>
      </main>
      </div>
  </div>
  )
}

export default VendeursList

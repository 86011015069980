import React from 'react';
import { motion } from 'framer-motion';
import { useFormik } from 'formik';
import piclogin from "/src/images/hello.json"
import { Button, Spinner } from 'keep-react';
import Lottie from 'lottie-react';


const LoginForm = ({handleLogin, check}) => {
  

  const formik = useFormik({ 
    initialValues: { 
      email: '',
      password: '',
    },
    onSubmit: handleLogin
  })


  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      className="flex flex-col justify-center items-center  bg-purple-800 bg-opacity-25 " 
    >
      
      <div className="flex items-center justify-center h-screen w-full px-5 sm:px-0">
      <div className="flex bg-white rounded-lg shadow-lg border overflow-hidden max-w-sm lg:max-w-4xl w-full">
        <div
          className="hidden md:block lg:w-1/2 bg-cover bg-blue-700"
        >
          {/* <img src={piclogin}  className="object-cover h-full" alt="login" /> */}
          <Lottie 
            animationData={piclogin}
            loop={true}
            autoPlay={true}
            className='w-full h-full'
          /> 
        </div>
        <form onSubmit={formik.handleSubmit} method='post' className="w-full p-8 lg:w-1/2">
          <p className="text-2xl text-gray-600 mt-5 mb-10 text-center">Bienvenue sur Yesha</p>
          <div className="  mt-4">
            
            <label htmlFor='email' className="block text-gray-700 text-lg font-bold mb-2">
              Email
            </label>
            <input
              className="text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:outline-2 focus:outline-blue-700"
              type="text"
              name='email'
              required
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.errors.email && <p style={{ color: 'red' }}>{formik.errors.email}</p> }
          </div>
          <div className="mt-4 flex flex-col justify-between">
            <div className="flex justify-between">
              <label htmlFor='password' className="block text-gray-700 text-lg font-bold mb-2">
                Mots de Passe
              </label>
            </div>
            <input
              className="text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:outline-2 focus:outline-blue-700"
              type="password"
              name='password'
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {formik.errors.password && <p style={{ color: 'red' }}>{formik.errors.password}</p> }
            <a
              href="#"
              className="text-md text-gray-600 hover:text-gray-900 text-end w-full mt-5"
            >
              Mots de passe oublié ?
            </a>
          </div>
          <div className="pt-14 mb-4">
            
            <div className='flex w-full justify-center item-center py-2'>
                <Button type='submit' size="md" disabled={check}>
                    <span className="pr-2">
                      {
                          check ? (<Spinner color="info" size="md" />) : ''
                      }
                    </span>
                      {
                          check ? "En Cours de verification..." : 'Se Connecter'
                      }
                </Button>
            </div>
          </div>
        </form>
      </div>
      </div>
    </motion.div>
  );
};

export default LoginForm;
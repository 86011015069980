import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useState} from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
import 'moment/locale/fr';
import { motion } from 'framer-motion';
import FormatNumero from '../../../utils/FormatNumero';
import { FaMoneyBillWave, FaRegFilePdf } from 'react-icons/fa';
import { NewPDFDevisVoyage } from '../../../api/pdf/DevisFacturePdf';
import VoyagePaiement from '../../paiement/VoyagePaiement';

const AdminDevisDatatable = ({data, chargement}) => {

  const [isFirstMount, setIsFirstMount] = useState(true);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [idref, setIdref] = useState(null);

  moment.locale('fr');
  // Fonction de formatage des dates
  const formatDate = (dateString, format = 'DD MMMM YYYY') => {
    if (!dateString) return 'N/A';
    return moment(dateString).isValid() ? moment(dateString).format(format) : 'Date invalide';
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFirstMount(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const getRowClass = useCallback(() => {
    return isFirstMount ? 'fade-in' : '';
  }, [isFirstMount]);

  const Loader = () => (
    <div className="flex items-center justify-center">
      <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );

  const closeModal = () => {
    setShowModal(false);
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      setPdfUrl(null);
    }
  };

  const handleDrawer = () => { 
    setOpenDrawer(!openDrawer);
}



  // Style pour les cellules
  const cellStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Centre horizontalement
    textAlign: 'center', // Texte centré (au cas où)
    height: '100%',
    padding: '0.5rem',
  };

  const ActionRenderer = (params) => {
    const rowId = params.data.id;
    const handleDevis = () => { 
      setLoading(true);
      NewPDFDevisVoyage({id: rowId})
       .then((result) => { 
          if (result.status === 200) {
            const url = URL.createObjectURL(result.data);
            setPdfUrl(url);
            setShowModal(true);
          } else {
            console.error("Erreur lors de la récupération du PDF");
          }
       })
       .catch((error) => {
          console.error("Erreur lors du chargement du PDF :", error);
       })
       .finally(() => {
            setLoading(false);
       });
    };

    const handlePayement = () =>{ 
      handleDrawer();
      setIdref(rowId);
    }



    return(
      <div className='flex justify-normal items-center space-x-2'>
        <div className='relative'>
          <button 
          className="p-2 rounded-lg bg-blue-100 hover:bg-blue-200 transition-colors"
          onClick={handlePayement}
          >
            <FaMoneyBillWave className="text-blue-600" size={18} />
          </button>
        </div>
        <div>
          <button
          onClick={handleDevis}
          className="p-2 rounded-lg bg-blue-100 hover:bg-blue-200 transition-colors"
          disabled={loading}
          >
            {loading ? (
            <Loader />
          ) : (
            <FaRegFilePdf className="text-blue-600" size={18} />
          )}
          </button>
        </div>
      </div>
    )
  }

  

  // Définition des colonnes
  const columnDefs = useMemo(() => [
    { 
      headerName: "Date souscription", 
      field: "date",
      cellRenderer: (params) => (
        <div className="font-medium">
          {params.value}
        </div>
      ),
      flex: 1
    },
    { 
      headerName: "Formule", 
      field: "formule",
      cellRenderer: (params) => (
        <div className="px-3 py-1 bg-purple-100 text-purple-700 rounded-full">
          {params.value}
        </div>
      ),
      flex: 1 
    },
    {
      headerName: "Client",
      field: "client",
      cellRenderer: (params) => (
        <div className="flex flex-col items-center">
          <div className="font-semibold text-center underline underline-offset-1">{params.data.client.nom}</div>
          <div className="text-sm text-gray-500 mb-2">
            <div className="flex items-center gap-2">
              <span>📞</span> 
              <FormatNumero numero={params.data.client.telephone} />
            </div>
            <div className="flex items-center gap-2">
              <span>✉️</span>
              {params.data.client.email}
            </div>
          </div>
        </div>
      ),
      flex: 2
    },
    { 
      headerName: "Période",
      children: [
        {
          headerName: "Départ",
          field: "dateDepart",
          cellRenderer: (params) => (
            <div className="text-green-600 font-medium">
              {formatDate(params.value, 'DD/MM/YYYY')}
            </div>
          ),
          flex: 1
        },
        {
          headerName: "Retour", 
          field: "dateRetour",
          cellRenderer: (params) => (
            <div className="text-red-600 font-medium">
              {formatDate(params.value, 'DD/MM/YYYY')}
            </div>
          ),
          flex: 1
        }
      ]
    },
    { 
      headerName: "Prime TTC", 
      field: "primeTTC",
      cellRenderer: (params) => (
        <div className="font-bold text-purple-600">
          {new Intl.NumberFormat('fr-FR').format(params.value)} FCFA
        </div>
      ),
      flex: 1
    },
    { 
      headerName: "Assureur",
      field: "assureur",
      cellRenderer: (params) => (
        <div className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full">
          {params.value}
        </div>
      ),
      flex: 1
    },
    {
      headerName: "Action",
      field: "action",
      width: 120,
      cellRenderer: ActionRenderer,
      cellRendererParams: {
        popup: true, 
      }
    }
  ], []);

  const mobileColumnDefs = [
    { 
      headerName: "Infos",
      field: "client",
      cellRenderer: (params) => (
        <div className="flex flex-col p-2">
          <div className="font-semibold">{params.data.client.nom}</div>
          <div className="text-sm">{params.data.formule}</div>
          <div className="text-sm">Départ: {formatDate(params.data.dateDepart)}</div>
          <div className="font-bold text-purple-600">{new Intl.NumberFormat('fr-FR').format(params.data.primeTTC)} FCFA</div>
          <div className='text-sm'><FormatNumero numero={params.data.client.telephone} /></div>
        </div>
      ),
      flex: 1
    },
    {
      headerName: "Actions",
      field: "action",
      cellRenderer: ActionRenderer,
      width: 100
    }
  ];
  
    const tabletColumnDefs = [
      { 
        headerName: "Client & Formule",
        field: "client",
        cellRenderer: (params) => (
          <div className="flex flex-col">
            <div className="font-semibold">{params.data.client.nom}</div>
            <div className="text-sm">{params.data.formule}</div>
          </div>
        ),
        flex: 2
      },
      {
        headerName: "Période",
        cellRenderer: (params) => (
          <div className="flex flex-col">
            <div>Du: {formatDate(params.data.dateDepart)}</div>
            <div>Au: {formatDate(params.data.dateRetour)}</div>
          </div>
        ),
        flex: 1
      },
      { 
        headerName: "Prime",
        field: "primeTTC",
        cellRenderer: (params) => (
          <div className="font-bold text-purple-600">
            {new Intl.NumberFormat('fr-FR').format(params.value)} FCFA
          </div>
        ),
        flex: 1
      },
      {
        headerName: "Actions",
        field: "action",
        cellRenderer: ActionRenderer,
        width: 120
      }
    ];
  
  const desktopColumnDefs = columnDefs;

  const getGridConfig = () => {
    const width = window.innerWidth;
    if (width <= 640) { // mobile
      return {
        columnDefs: mobileColumnDefs,
        rowHeight: 120
      };
    } else if (width <= 1024) { // tablet
      return {
        columnDefs: tabletColumnDefs,
        rowHeight: 100
      };
    }
    return {
      columnDefs: desktopColumnDefs,
      rowHeight: 80
    };
  };

  const [gridConfig, setGridConfig] = useState(getGridConfig());

  useEffect(() => {
    const handleResize = () => {
      setGridConfig(getGridConfig());
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Configuration par défaut des colonnes
  const defaultColDef = useMemo(() => ({
    sortable: true,
    // filter: true,
    resizable: true,
    cellStyle: cellStyle,
    headerClass: 'ag-header-center',
    suppressMovable: true,
    filterParams: {
      buttons: ['reset', 'apply'],
      closeOnApply: true
    }
  }), []);



  return (
    <div className="w-full h-full p-4">
      <VoyagePaiement drawOpen={openDrawer} closeDrawer={handleDrawer} idref={idref} />
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <div className="bg-white w-3/4 h-3/4 rounded-lg shadow-lg overflow-hidden">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-semibold">Aperçu du PDF</h2>
              <button onClick={closeModal} className="text-red-500 font-bold">
                <span className='p-3 hover:bg-red-500 hover:text-white rounded-md border-red-500 border'>
                  Fermer
                </span>
              </button>
            </div>
            <div className="p-4 h-full">
              {pdfUrl ? (
                <iframe src={pdfUrl} title="PDF Preview" className="w-full h-full" />
              ) : (
                <p>Chargement du PDF...</p>
              )}
            </div>
          </div>
        </motion.div>
      )}
      <div 
        className="ag-theme-alpine w-full h-[660px]" 
        
      >
        <AgGridReact
          rowData={data}
          columnDefs={gridConfig.columnDefs}
          defaultColDef={defaultColDef}
          rowHeight={gridConfig.rowHeight}
          suppressColumnVirtualisation={true}
          headerHeight={50}
          pagination={true}
          paginationPageSize={10}
          animateRows={true}
          rowClass="hover:bg-gray-50"
          getRowClass={getRowClass}
          loading={chargement}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Chargement des données...</span>'
          }
          overlayNoRowsTemplate={
            '<span class="ag-overlay-no-rows-center">Aucune donnée disponible</span>'
          }
        />
      </div>
    </div>
  );
};

export default AdminDevisDatatable;

import React, { useState } from 'react';
import Sidebar from '../../template/sidebar/Sidebar';
import HomeNavbar from '../../template/navbar/HomeNavbar';
import AdminUserBanner from '../../template/banner/AdminUserBanner';
import VendeursCharts from '../../components/vendeurs/dashboard/VendeursCharts';
import { GiReceiveMoney } from "react-icons/gi";
import HistoriqueDesVentes from '../../components/vendeurs/dashboard/HistoriqueDesVentes';
import { FcClock } from "react-icons/fc";
import { motion, AnimatePresence } from 'framer-motion';
import EtatsDesVentes from '../../components/vendeurs/dashboard/EtatsDesVentes';
import { Button } from 'keep-react';
import  anima from '/src/images/error_404.json';
import ContratAutoDatatable from '../../components/vendeurs/dashboard/ContratAutoDatatable';
import ContratMotoDatatable from '../../components/vendeurs/dashboard/ContratMotoDatatable';
import ContratRetraitDatatable from '../../components/vendeurs/dashboard/ContratRetraitDatatable';
import Lottie from 'lottie-react';

const VendeurHomeList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeOption, setActiveOption] = useState('Auto');

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebar isOpen={isOpen} onToggle={handleOpenClose} />
      <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
        <HomeNavbar isOpen={isOpen} />
        <main className="p-6 mt-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            
            {/* <div className="flex flex-col justify-center w-full h-full items-center pb-5">
            <h1 className='flex justify-normal items-center font-bold text-2xl  text-purple-700 pb-5'>
              Veuillez patienter le site est en cours de maintenance...
            </h1>
              <Lottie 
               animationData={anima}
                loop={true}
                autoPlay={true}
                className='w-screen h-screen'
              /> 
            </div> */}
            <AdminUserBanner />
            
            {/* Section des graphiques et statistiques */}
            <div className="flex flex-col gap-5 lg:flex-row pt-5">
              <motion.div 
                // whileHover={{ scale: 1.01 }}
                className="basis-4/5 rounded-xl border bg-white shadow-md p-4 transition-all duration-300"
              >
                <h1 className='font-bold pb-4 underline text-lg text-purple-700'>
                  La Charte de mes Ventes de l'Année
                </h1>
                <VendeursCharts />
              </motion.div>

              <div className="flex basis-1/5 flex-col gap-y-5">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="rounded-xl border bg-purple-700 p-5 transition-all duration-300 hover:shadow-lg"
                >
                  <div className='flex justify-center space-x-5 items-baseline pb-2'>
                    <GiReceiveMoney size={45} className='text-white' />
                    <span className='text-white font-bold text-2xl'>145.000 fcfa</span>
                  </div>
                  <h1 className='text-white font text-center underline'>Commission totale</h1>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="rounded-xl border shadow-md p-5 bg-white transition-all duration-300"
                >
                  <div className='flex justify-start items-stretch space-x-3'>
                    <FcClock size={25} />
                    <h1 className='font-bold pb-4 underline text-lg text-purple-700'>
                      Historique des Ventes de la Journée
                    </h1>
                  </div>
                  <div className='h-[200px] p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-purple-500 scrollbar-track-purple-100'>
                    <HistoriqueDesVentes />
                  </div>
                </motion.div>
              </div>
            </div>

            {/* Section des états des ventes */}
            <div className="flex flex-wrap gap-5 lg:flex-nowrap pt-10">
              <EtatsDesVentes />
            </div>

            {/* Section des contrats à renouveler */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='basis-full rounded-xl border mt-7 p-4 bg-white shadow-md'
            >
              <div className='flex justify-between items-baseline'>
                <h1 className='font-bold pb-4 underline text-lg text-purple-700'>
                  La liste des Contrats à Renouveler
                </h1>
                <div className='flex space-x-2'>
                  {['Auto', 'Retrait', 'Moto'].map((option) => (
                    <Button
                      key={option}
                      size='sm'
                      variant={activeOption === option ? '' : 'outline'}
                      onClick={() => setActiveOption(option)}
                      className="transform transition-all duration-300 hover:scale-105"
                    >
                      {option}
                    </Button>
                  ))}
                </div>
              </div>

              <div className='p-4'>
                <AnimatePresence mode="wait">
                  {activeOption === 'Auto' && (
                    <motion.div
                      key="auto"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <ContratAutoDatatable />
                    </motion.div>
                  )}

                  {activeOption === 'Retrait' && (
                    <motion.div
                      key="retrait"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <ContratRetraitDatatable />
                    </motion.div>
                  )}

                  {activeOption === 'Moto' && (
                    <motion.div
                      key="moto"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <ContratMotoDatatable />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
          </motion.div>
        </main>
      </div>
    </div>
  );
};

export default VendeurHomeList;
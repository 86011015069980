import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import Home from "./pages/home/Home";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css"; 
import 'react-phone-input-2/lib/style.css';
import DistributeurList from "./layout/ressources/distributeurs/DistributeurList";
import ManageursList from "./layout/ressources/manageurs/ManageursList";
import VendeursList from "./layout/ressources/vendeurs/VendeursList";
import PointsDeVentesList from "./layout/ressources/pointsdeventes/PointsDeVentesList";
import CommerciauxList from "./layout/ressources/commmerciaux/CommerciauxList";
import UtilisateursList from "./layout/admin/UtilisateursList";
import SouscriptionAutoList from "./layout/automobiles/SouscriptionAutoList";
import ContratsAutoList from "./layout/automobiles/ContratsAutoList";
import DevisAutoList from "./layout/automobiles/DevisAutoList";
import FacturesAutoList from "./layout/automobiles/FacturesAutoList";
import ContratsMotoList from "./layout/motocyclettes/ContratsMotoList";
import DevisMotoList from "./layout/motocyclettes/DevisMotoList";
import SouscriptionMotoList from "./layout/motocyclettes/SouscriptionMotoList";
import FacturesMotoList from "./layout/motocyclettes/FacturesMotoList";
import SouscriptionVoyageList from "./layout/voyages/SouscriptionVoyageList";
import ContratsVoyageList from "./layout/voyages/ContratsVoyageList";
import DevisVoyageList from "./layout/voyages/DevisVoyageList";
import FacturesVoyageList from "./layout/voyages/FacturesVoyageList";
import AssureursList from "./layout/ressources/assureurs/AssureursList";
import TarifsAutomobileList from "./layout/ressources/assureurs/TarifsAutomobileList";
import TarifsMotocycletteList from "./layout/ressources/assureurs/TarifsMotocycletteList";
import TarifsVoyageList from "./layout/ressources/assureurs/TarifsVoyageList";
import NouveauAutoList from "./layout/automobiles/NouveauAutoList";
import RenouvelleAutoList from "./layout/automobiles/RenouvelleAutoList";
import { AnimatePresence } from "framer-motion";
import DevisAutomobile from "./layout/automobiles/DevisAutomobile";
import DevisVoyage from "./layout/voyages/DevisVoyage";
import ContratVoyage from "./layout/voyages/ContratVoyage";
import ServerError from "./template/errors/ServerError";
import ProtectedRoute from "./pages/auth/ProtectedRoute";
import ScrollToTop from "./template/banner/ScrollToTop";
import ContratsVoyageModifie from "./layout/voyages/ContratsVoyageModifie";

function App() {
  return (
    <>
      <AnimatePresence mode="wait">
        <BrowserRouter>
        <ScrollToTop /> 
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="Accueil" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <Home />
              </ProtectedRoute>
            } />

            {/* Voyage */}
            <Route path="/Voyages/Souscriptions" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <SouscriptionVoyageList />
              </ProtectedRoute>
            } />
            <Route path="/Voyages/Contrats" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <ContratsVoyageList />
              </ProtectedRoute>
            } />
            <Route path="/Voyages/Contrats/:id" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <ContratVoyage />
              </ProtectedRoute>
            } />
            <Route path="/Voyages/Contrats/Modifie/:id" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <ContratsVoyageModifie/>
              </ProtectedRoute>
            } />
            <Route path="/Voyages/Devis" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <DevisVoyageList />
              </ProtectedRoute>
            } />
            <Route path="/Voyages/Devis/:id" element={
              <ProtectedRoute allowedRoles={['Admin', 'Manager','Vendeur']}>
                <DevisVoyage />
              </ProtectedRoute>
            } />
            <Route path="/Voyages/Factures" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <FacturesVoyageList />
              </ProtectedRoute>
            } />

            {/* Automobile */}
            <Route path="/Automobiles/Souscriptions" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <SouscriptionAutoList />
              </ProtectedRoute>
            } />
            <Route path="/Automobiles/Souscriptions/Nouveau" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <NouveauAutoList />
              </ProtectedRoute>
            } />
            <Route path="/Automobiles/Souscriptions/Renouvellement" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <RenouvelleAutoList />
              </ProtectedRoute>
            } />
            <Route path="/Automobiles/Contrats" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <ContratsAutoList />
              </ProtectedRoute>
            } />
            <Route path="/Automobiles/Devis" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <DevisAutoList />
              </ProtectedRoute>
            } />
            <Route path="/Automobiles/Devis/:id" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <DevisAutomobile />
              </ProtectedRoute>
            } />
            <Route path="/Automobiles/Factures" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <FacturesAutoList />
              </ProtectedRoute>
            } />

            {/* Motocyclettes */}
            <Route path="/Motocyclettes/Souscriptions" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <SouscriptionMotoList />
              </ProtectedRoute>
            } />
            <Route path="/Motocyclettes/Contrats" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <ContratsMotoList />
              </ProtectedRoute>
            } />
            <Route path="/Motocyclettes/Devis" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <DevisMotoList />
              </ProtectedRoute>
            } />
            <Route path="/Motocyclettes/Factures" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager','Vendeur']}>
                <FacturesMotoList />
              </ProtectedRoute>
            } />

            {/* Administrateur */}
            <Route path="/Administrateurs/Utilisateurs" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin']}>
                <UtilisateursList />
              </ProtectedRoute>
            } />

            {/* Ressources */}
            <Route path="/Ressources/Assureurs" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin']}>
                <AssureursList />
              </ProtectedRoute>
            } />
            <Route path="/Ressources/Assureurs/Tarif_Voyage/:id" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin']}>
                <TarifsVoyageList />
              </ProtectedRoute>
            } />
            <Route path="/Ressources/Assureurs/Tarifs_Motocyclette/:id" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin']}>
                <TarifsMotocycletteList />
              </ProtectedRoute>
            } />
            <Route path="/Ressources/Assureurs/Tarifs_Automobile/:id" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin']}>
                <TarifsAutomobileList />
              </ProtectedRoute>
            } />
            <Route path="/Ressources/Distributeurs" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager']}>
                <DistributeurList />
              </ProtectedRoute>
            } />
            <Route path="/Ressources/Distributeurs/:id/PointsDeVentes" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager']}>
                <PointsDeVentesList />
              </ProtectedRoute>
            } />
            <Route path="/Ressources/Distributeurs/:id/PointsDeVentes/:id/Vendeurs" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager']}>
                <VendeursList />
              </ProtectedRoute>
            } />
            <Route path="/Ressources/Manageurs" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin']}>
                <ManageursList />
              </ProtectedRoute>
            } />
            <Route path="/Ressources/PointsDeVentes/:id" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager']}>
                <PointsDeVentesList />
              </ProtectedRoute>
            } />
            <Route path="/Ressources/Commerciaux" element={
              <ProtectedRoute allowedRoles={['Super Admin','Admin', 'Manager']}>
                <CommerciauxList />
              </ProtectedRoute>
            } />

            <Route path="*" element={<ServerError />} />
          </Routes>
        </BrowserRouter>
      </AnimatePresence>
    </>
  );
}

export default App;
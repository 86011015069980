import React, { useState } from 'react'
import Sidebar from '../../template/sidebar/Sidebar'
import HomeNavbar from '../../template/navbar/HomeNavbar'
import AdminUserBanner from '../../template/banner/AdminUserBanner'

const RenouvelleAutoList = () => {

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenClose = () => { 
    setIsOpen(!isOpen);
  }
  return (
    <div className='flex h-screen'>
        <Sidebar isOpen={isOpen} onToggle={handleOpenClose} /> 
          <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
            <HomeNavbar isOpen={isOpen} />
              <main className="p-6 mt-16">
                <AdminUserBanner />
                  <h1>Binevenue a la page de renouvellement </h1>
              </main>
          </div>
    </div>
  )
}

export default RenouvelleAutoList

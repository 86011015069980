import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { FaCheckCircle, FaCity, FaCompass, FaEnvelope, FaMapMarkerAlt, FaPen, FaSearch, FaStreetView } from 'react-icons/fa';
import { TbWorldLatitude, TbWorldLongitude } from 'react-icons/tb';
import { GiArchiveRegister } from 'react-icons/gi';
import { HiMiniBuildingOffice2 } from 'react-icons/hi2';
import { Button, Spinner } from 'keep-react'
import PhoneInput from 'react-phone-input-2';
import GoogleMapReact from 'google-map-react';
import { SiGooglestreetview } from 'react-icons/si';
import { Pays } from '../../../utils/City';
import { generateUniqueEmail } from '../../../utils/Extras';
import { useProfile } from '../../../UserDataStock/Profils/Profile';


const AnyReactComponent = ({ text }) => <div>{text}</div>;

const AddPointsDeVente = ({handleRegistration, isLoading, pointVente, distrib}) => {

    const [isFocused, setIsFocused] = useState(false);
    const [isFocused1, setIsFocused1] = useState(false);
    const [search, setSearch] = useState('');
    const [mapCenter, setMapCenter] = useState({ lat: '', lng: '' });
    const [mapZoom, setMapZoom] = useState(13);
    const [typedata, setTypeData] = useState(pointVente);
    const [lesDis, setLesDis] = useState(distrib);
    const {position, token, userDetails } = useProfile();
    const [code, setCode] = useState('');

    
    useEffect(() => {
        if(!position?.location) {
            setCode('sn'); 
            return;
        }
        
        if(position.location.country_code3 === "SEN"){
            setCode('sn');
        } else if(position.location.country_code3 === "GIN"){
            setCode('gn');
        } else if(position.location.country_code3 === "MLI"){ 
            setCode('mli');
        } else if(position.location.country_code3 === "GNF"){
            setCode('gnf');
        } else {
            setCode('sn'); 
        }
    }, [position?.location]);
    
    // Ajoutez une vérification avant d'accéder à Pays[code]
    const regions = code ? Pays[code]?.regions || [] : [];

    
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setMapCenter({ lat: latitude, lng: longitude });
                    formik.setFieldValue('latitude', latitude);
                    formik.setFieldValue('longitude', longitude);
                },
                (error) => {
                    console.error("Erreur de géolocalisation : ", error);
                }
            );
        } else {
            console.error("La géolocalisation n'est pas prise en charge par ce navigateur.");
        }
    }, []);


    const formik = useFormik({

        initialValues: {
            libelle: "",
            type: "",
            adresse: "",
            email: "",
            fixe: "",
            mobile: "",
            rccm: "",
            ville: "",
            rue: "",
            quartier: "",
            latitude: "",
            longitude: "",
            distributeurId: lesDis
        },
        onSubmit: handleRegistration
    });

    const handleGenerateEmail = () => {
        const generatedEmail = generateUniqueEmail();
        formik.setFieldValue('email', generatedEmail); 
    };

    const handleSearch = () => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: search }, (results, status) => {
            if (status === "OK" && results[0]) {
                const { lat, lng } = results[0].geometry.location;
                setMapCenter({ lat: lat(), lng: lng() });
                formik.setFieldValue('latitude', lat());
                formik.setFieldValue('longitude', lng());
            } else {
                console.error("Erreur de géocodage: ", status);
            }
        });
    };

    const handleMapClick = ({ lat, lng }) => {
        formik.setFieldValue('latitude', lat);
        formik.setFieldValue('longitude', lng);
        setMapCenter({ lat, lng });
    };
   
  return (
   
    <form onSubmit={formik.handleSubmit} method='post' className='w-full'>
        <div className='h-[700px] overflow-y-auto'>
            <h1 className='flex justify-center text-2xl text-center font-semibold text-purple-600 underline mb-2 '>Les informations nécessaires  </h1>
            <div className='grid grid-cols-8 gap-4 p-5 py-2 row-auto'>

                <div className='col-span-8'>
                        <label htmlFor="libelle" className="block text-gray-700 font-semibold mb-1 pl-2">Raison sociale</label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <FaPen className="w-5 h-5 text-gray-400 " />
                                </span>
                                <input 
                                autoComplete='off'
                                id="libelle" 
                                name='libelle'
                                onChange={formik.handleChange}
                                value={formik.values.libelle}
                                onBlur={formik.handleBlur}
                                type="text" 
                                placeholder="Nom commercial" 
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.libelle && formik.touched.libelle ? 'border-red-500': ''}`}
                                />
                            </div>
                </div>
                    {formik.touched.libelle && formik.errors.libelle ? (
                        <motion.div
                            initial={{ x: -50, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            exit={{ x: -100, opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="italic text-md col-span-4 text-red-500"
                        >{formik.errors.libelle}
                        </motion.div>) : null
                    }
                <div className='col-span-4'>
                        <label htmlFor="rccm" className="block text-gray-700 font-semibold mb-1 pl-2">Registre de Commerce </label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <GiArchiveRegister className="w-5 h-5 text-gray-400 " />
                                </span>
                                <input 
                                autoComplete='off'
                                id="rccm" 
                                name='rccm'
                                onChange={formik.handleChange}
                                value={formik.values.rccm}
                                onBlur={formik.handleBlur}
                                type="text" 
                                placeholder="Registre de commerce " 
                                className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.libelle && formik.touched.libelle ? 'border-red-500': ''}`}
                                />
                            </div>
                </div>
                    {formik.touched.rccm && formik.errors.rccm ? (
                        <motion.div
                            initial={{ x: -50, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            exit={{ x: -100, opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="italic text-md col-span-4 text-red-500"
                        >{formik.errors.rccm}
                        </motion.div>) : null
                    }
                <div className='col-span-4'>
                    <label htmlFor="type" className="block text-gray-700 font-semibold mb-1 pl-2">Type de Point de Vente</label>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <HiMiniBuildingOffice2  className="w-5 h-5 text-gray-400 " />
                        </span>
                        <select
                        autoComplete='off'
                        id="type" 
                        name="type"
                        onChange={formik.handleChange}
                        value={formik.values.type}
                        onBlur={formik.handleBlur}
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.distributeurId && formik.touched.distributeurId ? 'border-red-500': ''}`}
                        > 
                        <option value="" label="Type de point de vente" />
                        {typedata.map(option => (
                            <option key={option.id} value={option.id}>{option.libelle}</option>
                        ))}
                        </select>
                    </div>
                </div>
                <div className='col-span-6'>
                    <label htmlFor="email" className="block text-gray-700 font-semibold mb-1 pl-2">Adresse électronique</label>
                    <div className="relative">
                        <span 
                            className="absolute inset-y-0 left-0 flex items-center pl-3 cursor-pointer"
                            onClick={handleGenerateEmail} 
                        >
                            <FaEnvelope className="w-5 h-5 text-gray-400" />
                        </span>
                        <input 
                            autoComplete='off'
                            id="email" 
                            name='email'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            type="email" 
                            placeholder="Saisissez l'email" 
                            className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.email && formik.touched.email ? 'border-red-500' : ''}`}
                        />
                    </div>
                </div>
                <div className='col-span-2'></div>
                <div className='col-span-4'>
                    <label htmlFor="fixe" className="block text-gray-700 font-semibold mb-1 pl-2">Téléphone Fixe</label>
                        <div className="relative">
                            <PhoneInput
                                country={'sn'}
                                onlyCountries={['sn', 'gn','ml', 'ci']}
                                masks={{ 
                                    sn: '-..-...-..-..',
                                    gn: '-..-...-...',
                                    ml: '-..-...-...',
                                    ci: '-..-..-..-..'
                                }}
                                autoComplete='off'
                                id="fixe"
                                name='fixe'
                                value={formik.values.fixe}
                                onChange={(value) => {
                                    formik.setFieldValue('fixe', value);
                                }}
                                onBlur={() => {
                                    formik.handleBlur('fixe');
                                    setIsFocused(false);
                                }}
                                placeholder=" Numero du Fix"
                                inputStyle={{
                                    'fontFamily':'serif',
                                    'width': '100%',
                                    'height': '2.5rem',
                                    'color': '#444',
                                    ...(isFocused && {
                                        outline: 'none',
                                        boxShadow: '0 0 0 2px #3b82f6',
                                        borderColor: 'transparent'
                                    
                                    })
                                }}
                                onFocus={() => setIsFocused(true)}
                            />
                        </div>
                </div>
            
                <div className='col-span-4'>
                    <label htmlFor="mobile" className="block text-gray-700 font-semibold mb-1 pl-2">Téléphone Mobile</label>
                    
                        <div className="relative">
                            <PhoneInput
                                country={'sn'}
                                onlyCountries={['sn', 'gn','ml', 'ci']}
                                masks={{ 
                                    sn: '-..-...-..-..',
                                    gn: '-..-...-...',
                                    ml: '-..-...-...',
                                    ci: '-..-..-..-..'
                                }}
                                id="mobile"
                                name='mobile'
                                value={formik.values.mobile}
                                onChange={(value) => {
                                    formik.setFieldValue('mobile', value);
                                }}
                                onBlur={() => {
                                    formik.handleBlur('mobile');
                                    setIsFocused1(false);
                                }}
                                placeholder=" Numero mobile"
                                inputStyle={{
                                    'fontFamily':'serif',
                                    'width': '100%',
                                    'height': '2.5rem',
                                    'color': '#444',
                                    ...(isFocused1 && {
                                        outline: 'none',
                                        boxShadow: '0 0 0 2px #3b82f6',
                                        borderColor: 'transparent'
                                    
                                    }),
                                    // ...(!formik.touched.mobileNumber && !formik.errors.fixnumber) ? { border: '#ef4444' } : null
                                }}
                                onFocus={() => setIsFocused1(true)}
                            
                            />
                        </div>
                </div>
                
                <div className='col-span-4'>
                    <label htmlFor="ville" className="block text-gray-700 font-semibold mb-1 pl-2">Ville</label>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FaCity  className="w-5 h-5 text-gray-400 " />
                        </span>
                        <select
                        autoComplete='off'
                        id="ville" 
                        name="ville"
                        onChange={formik.handleChange}
                        value={formik.values.ville}
                        onBlur={formik.handleBlur}
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.distributeurId && formik.touched.distributeurId ? 'border-red-500': ''}`}
                        > 
                        <option value="" label="Selectionnez la Ville" />
                        {regions.map(option => (
                            <option key={option.id} value={option.nom}>{option.nom}</option>
                        ))}
                        </select> 
                    </div>
                </div>

                <div className='col-span-4'>
                    <label htmlFor="rue" className="block text-gray-700 font-semibold mb-1 pl-2">Quartier</label>
                        <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <SiGooglestreetview className="w-5 h-5 text-gray-400 " />
                            </span>
                            <input 
                            autoComplete='off'
                            id="quartier" 
                            name='quartier'
                            onChange={formik.handleChange}
                            value={formik.values.quartier}
                            onBlur={formik.handleBlur}
                            type="text" 
                            placeholder="Saisissez le quartier " 
                            className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.quartier && formik.touched.quartier ? 'border-red-500': ''}`}
                            />
                        </div>
                </div>

                <div className='col-span-8'>
                    <label htmlFor="rue" className="block text-gray-700 font-semibold mb-1 pl-2">Rue</label>
                        <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <FaStreetView className="w-5 h-5 text-gray-400 " />
                            </span>
                            <input 
                            autoComplete='off'
                            id="rue" 
                            name='rue'
                            onChange={formik.handleChange}
                            value={formik.values.rue}
                            onBlur={formik.handleBlur}
                            type="text" 
                            placeholder="Saisissez la rue " 
                            className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.rue && formik.touched.rue ? 'border-red-500': ''}`}
                            />
                        </div>
                </div>

                <div className='col-span-6'>
                    <label htmlFor="rue" className="block text-gray-700 font-semibold mb-1 pl-2">Rechercher une adresse</label>
                        <div className='relative'>
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <FaCompass className="w-5 h-5 text-gray-400 " />
                            </span>
                            <input 
                            autoComplete='off'
                            id='search'
                            name='search'
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                            type='text'
                            placeholder="Rechercher un Emplacement"
                            className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                            />
                        </div>
                </div>
                <div className='col-span-2'>
                    <Button type='button' className='justify-center items-baseline mt-[26px]' onClick={handleSearch}>
                        <FaSearch className="w-5 h-5 text-white" />
                    </Button>
                </div>

                <div className='col-span-4'>
                    <label htmlFor="latitude" className="block text-gray-700 font-semibold mb-1 pl-2">Latitude</label>
                        <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <TbWorldLatitude className="w-5 h-5 text-gray-400 " />
                            </span>
                            <input 
                            autoComplete='off'
                            readOnly
                            id="latitude" 
                            name='latitude'
                            onChange={formik.handleChange}
                            value={formik.values.latitude}
                            onBlur={formik.handleBlur}
                            type="number" 
                            placeholder="latitude actuel " 
                            className={`pl-10 pr-4 py-2 border bg-gray-100 border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.quartier && formik.touched.quartier ? 'border-red-500': ''}`}
                            />
                        </div>
                </div>
                <div className='col-span-4'>
                    <label htmlFor="longitude" className="block text-gray-700 font-semibold mb-1 pl-2">Longitude</label>
                        <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <TbWorldLongitude className="w-5 h-5 text-gray-400 " />
                            </span>
                            <input 
                            autoComplete='off'
                            readOnly
                            id="longitude" 
                            name='longitude'
                            onChange={formik.handleChange}
                            value={formik.values.longitude}
                            onBlur={formik.handleBlur}
                            type="number" 
                            placeholder="longitude actuel " 
                            className={`pl-10 pr-4 py-2 border bg-gray-100  border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.quartier && formik.touched.quartier ? 'border-red-500': ''}`}
                            />
                        </div>
                </div>
                <div className='col-span-8 w-full h-[350px]'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyDbSHcfjumboXTYELqdhTLPimhQj1nFtmw" }}
                    center={[mapCenter.lat, mapCenter.lng]}
                    zoom={mapZoom}
                    onClick={handleMapClick}
                    >
                    <AnyReactComponent
                        lat={mapCenter.lat}
                        lng={mapCenter.lng}
                        text={<FaMapMarkerAlt size={30} color="red" />}
                    />
                </GoogleMapReact>
                </div>
        
            </div>
            <div className='flex  justify-center item-center py-4'>
                <Button type='submit' size="md" disabled={isLoading}>
                    <span className="pr-2">
                        {
                            isLoading ? (<Spinner color="info" size="md" />) : (<FaCheckCircle size={18} />)
                        }
                    </span>
                    {
                        isLoading ? "En Cours d'enregistrement..." : 'Sauvegarde'
                    }
                </Button>
            </div>
        </div>
    </form>
  )
}

export default AddPointsDeVente

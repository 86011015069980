import React from 'react'
import { FaCarSide, FaMotorcycle, FaBoxesStacked, FaPlane, FaHandsHoldingChild } from 'react-icons/fa6'; 
import { RiCoinsFill } from "react-icons/ri";
import { GiCoins } from "react-icons/gi";

const EtatsDesVentes = () => {
  return (
    <>
        <div className="basis-full rounded-xl border bg-blue-400 shadow-md p-6 cursor-pointer ">
              <div className='flex justify-between items-center'>
                <div className='space-y-1' >
                  <h1 className='font-bold text-xl text-white'>Ventes Auto</h1>
                  <div className='flex justify-start items-center space-x-1'>
                    <RiCoinsFill className='text-white' size={35}/>
                    <h1 className=' text-2xl text-white'>452.126 fcfa</h1>
                  </div>
                </div>
                <div className='p-2 border rounded-md bg-white'>
                  <FaCarSide  size={30} className='text-blue-400'/> 
                </div>
              </div>
              <hr className='mt-3 h-2 w-full'/>
              <div className='flex justify-center items-start space-x-4 mt-2 '>
                <FaBoxesStacked className='text-white' size={35} /> 
                <h1 className='text-white font-bold text-3xl'>124</h1>
              </div>
        </div>

        <div className="basis-full rounded-xl border bg-green-400 shadow-md p-6 cursor-pointer ">
              <div className='flex justify-between items-center'>
                <div className='space-y-1' >
                  <h1 className='font-bold text-xl text-white'>Ventes Voyages</h1>
                  <div className='flex justify-start items-center space-x-1'>
                    <RiCoinsFill className='text-white' size={35}/>
                    <h1 className=' text-2xl text-white'>1.452.126 fcfa</h1>
                  </div>
                </div>
                <div className='p-2 border rounded-md bg-white'>
                  <FaPlane  size={30} className='text-green-400'/> 
                </div>
              </div>
              <hr className='mt-3 h-2 w-full'/>
              <div className='flex justify-center items-start space-x-4 mt-2 '>
                <FaBoxesStacked className='text-white' size={35} /> 
                <h1 className='text-white font-bold text-3xl'>270</h1>
              </div>
        </div>

        <div className="basis-full rounded-xl border bg-metal-400 shadow-md p-6 cursor-pointer ">
            <div className='flex justify-between items-center'>
            <div className='space-y-1' >
                <h1 className='font-bold text-xl text-white'>Ventes Moto</h1>
                <div className='flex justify-start items-center space-x-1'>
                <RiCoinsFill className='text-white' size={35}/>
                <h1 className=' text-2xl text-white'>652.126 fcfa</h1>
                </div>
            </div>
            <div className='p-2 border rounded-md bg-white'>
                <FaMotorcycle  size={30} className='text-metal-400'/> 
            </div>
            </div>
            <hr className='mt-3 h-2 w-full'/>
            <div className='flex justify-center items-start space-x-4 mt-2 '>
            <FaBoxesStacked className='text-white' size={35} /> 
            <h1 className='text-white font-bold text-3xl'>182</h1>
            </div>
        </div>

        <div className="basis-full rounded-xl border bg-amber-400 shadow-md p-6 cursor-pointer ">
            <div className='flex justify-between items-center'>
            <div className='space-y-1' >
                <h1 className='font-bold text-xl text-white'>Ventes Retraite</h1>
                <div className='flex justify-start items-center space-x-1'>
                <RiCoinsFill className='text-white' size={35}/>
                <h1 className=' text-2xl text-white'>0 fcfa</h1>
                </div>
            </div>
            <div className='p-2 border rounded-md bg-white'>
                <FaHandsHoldingChild  size={30} className='text-amber-400'/> 
            </div>
            </div>
            <hr className='mt-3 h-2 w-full'/>
            <div className='flex justify-center items-start space-x-4 mt-2 '>
            <FaBoxesStacked className='text-white' size={35} /> 
            <h1 className='text-white font-bold text-3xl'>0</h1>
            </div>
        </div>

        <div className="basis-full rounded-xl border bg-purple-700 shadow-md p-6 cursor-pointer ">
            <div className='flex justify-between items-center'>
            <div className='space-y-1' >
                <h1 className='font-bold text-xl text-white'>Totale des Ventes</h1>
                <div className='flex justify-start items-center space-x-1'>
                <RiCoinsFill className='text-white' size={35}/>
                <h1 className=' text-2xl text-white'>2.556.378 fcfa</h1>
                </div>
            </div>
            <div className='p-2 border rounded-md bg-white'>
                <GiCoins  size={30} className='text-purple-700'/> 
            </div>
            </div>
            <hr className='mt-3 h-2 w-full'/>
            <div className='flex justify-center items-start space-x-4 mt-2 '>
            <FaBoxesStacked className='text-white' size={35} /> 
            <h1 className='text-white font-bold text-3xl'>412</h1>
            </div>
        </div>
    </>
  )
}

export default EtatsDesVentes

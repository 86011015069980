import React, { useRef, useState, useEffect } from 'react';
import Sidebar from '../../../template/sidebar/Sidebar';
import HomeNavbar from '../../../template/navbar/HomeNavbar';
import AdminUserBanner from '../../../template/banner/AdminUserBanner';
import { FaFileDownload, FaGlobeAfrica, FaUpload } from 'react-icons/fa';
import * as XLSX from 'xlsx/xlsx.mjs';
import MyModal from '../../../components/dialog/MyModal';
import { motion, AnimatePresence } from 'framer-motion';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Spinner } from 'keep-react';
import { useParams } from 'react-router-dom';
import { NewTarifVoyage, NewTarifVoyageFormule, NewTarifVoyageFormuleGn } from '../../../api/ressources/TarisAssurances';
import { failNotification, succesNotification } from '../../../components/notifications/Notifications';
import { useProfile } from '../../../UserDataStock/Profils/Profile';

const TarifsVoyageList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [modale, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState({});
  const [currentSheet, setCurrentSheet] = useState('');
  const [name, setName] = useState('');
  const [zone, setZone] = useState('');
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const assurId = useParams();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const {position, token, userDetails } = useProfile();

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  const zoneCouverture = [
    { id: 1, label: 'Monde entier' },
    { id: 2, label: 'Europe' },
    { id: 3, label: 'Afrique' },
    { id: 4, label: 'Schengen' },
    { id: 5, label: 'Moyen Orient' },
    { id: 6, label: 'USA - Canada' },
  ];

  const pays = [
    { id: 1, label: 'Senegal', flag: 'SEN' },
    { id: 2, label: 'Côte d\'Ivoire', flag: 'CIV' },
    { id: 3, label: 'Guinée Conakry', flag: 'GUI' },
    { id: 4, label: 'Mali', flag: 'MLI' },
  ]



  useEffect(() => {
    const bothSheetsExist = data["Formule"] && data["PrixConseille"];
    const isZoneAndNameFilled = name.trim() !== '' && zone.trim() !== '';
    // Désactive le bouton si les deux feuilles existent mais les champs ne sont pas remplis
    setIsButtonDisabled(bothSheetsExist ? !isZoneAndNameFilled : false);
  }, [data, name, zone]);

  const formdata = () => {
    if (!selectedFile) {
      failNotification("Veuillez sélectionner un fichier avant de soumettre.");
      return;
    }

    setIsLoading(true);
    const bothSheetsExist = data["Formule"] && data["PrixConseille"];

    if (bothSheetsExist) {
      const addTarifWith = {
        formule: {
          name: name,
          zones: [zone],
          assuranceId: assurId.id
        },
        file: selectedFile
      };

      NewTarifVoyageFormule(addTarifWith, token, position)
        .then((result) => {
          if (result.status === 201) {
            succesNotification(result.data.message);
            setModal(false);
          } else {
            failNotification(result.data.message);
          }
        })
        .catch(() => {
          failNotification("Une erreur s'est produite lors de l'envoi des données.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (selectedCountry === 'GUI'){
      const addTarifGuinee = { 
        assuranceId: assurId.id,
        file: selectedFile
      }

      NewTarifVoyageFormuleGn(addTarifGuinee, token)
        .then((result) => { 
          console.log(result)
          if (result.status === 200) {
            succesNotification(result.data);
            setModal(false);
          } else {
            failNotification(result.data);
          }
        })
        .catch(() => { 
          failNotification("Une erreur s'est produite lors de l'envoi des données.");
        })
        .finally(() => { 
          setIsLoading(false);
        })
      
    } else {
      const addTarif = {
        assuranceId: assurId.id,
        file: selectedFile
      };

      NewTarifVoyage(addTarif, token, position)
        .then((result) => {
          if (result.status === 201) {
            succesNotification(result.data.message);
            setModal(false);
          } else {
            failNotification(result.data.message);
          }
        })
        .catch(() => {
          failNotification("Une erreur s'est produite lors de l'envoi des données.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleSaveToDatabase = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetsData = {};
        workbook.SheetNames.forEach(sheetName => {
          const worksheet = workbook.Sheets[sheetName];
          sheetsData[sheetName] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        });
        setData(sheetsData);
        setCurrentSheet(workbook.SheetNames[0]);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleExcellFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleSaveToDatabase(file);
      setSelectedFile(file);
      setModal(true);
      fileInputRef.current.value = null;
    }
  };

  const handlExcelLoad = () => {
    fileInputRef.current.click();
  };

  const handleSheetChange = (sheetName) => {
    setCurrentSheet(sheetName);
  };

  const tabTransition = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 },
    transition: { duration: 0.3 }
  };

  const renderFields = () => {
    const bothSheetsExist = data["Formule"] && data["PrixConseille"];
    if (!bothSheetsExist) return null;

    return (
      <div className='flex justify-around items-center'>
        <div>
          <label className="font-semibold">Nom de la formule:</label>
          <input 
            type="text" 
            placeholder="Saisissez le nom " 
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label className="font-semibold mb-4">Zone de couverture:</label>
          <select
            autoComplete='off'
            id="zone"
            name="zone"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            onChange={(e) => setZone(e.target.value)}
          >
            <option value="">Choisir une zone</option>
            {zoneCouverture.map((option) => (
              <option key={option.id} value={option.label}>{option.label}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const renderTable = (sheetData) => {
    const columns = sheetData[0].map((col, index) => ({
      headerName: col,
      field: index.toString(),
    }));

    const rowData = sheetData.slice(1).map((row) => {
      const rowData = {};
      row.forEach((cell, index) => {
        rowData[index.toString()] = cell;
      });
      return rowData;
    });

    return (
      <div className="ag-theme-alpine w-full h-full">
        <AgGridReact
          columnDefs={columns}
          rowData={rowData}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
            flex: 1,
          }}
          domLayout='autoHeight'
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    );
  };

  return (
    <div className='flex h-screen'>
      <Sidebar isOpen={isOpen} onToggle={handleOpenClose} />
      <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
        <HomeNavbar isOpen={isOpen} />
        <main className="p-6 mt-16">
          <AdminUserBanner />

          <MyModal
            content={
              <div className="flex flex-col h-[70vh] w-full space-y-4">
                <div className="flex space-x-2 mb-3">
                  {Object.keys(data).map(sheetName => (
                    <button
                      key={sheetName}
                      onClick={() => handleSheetChange(sheetName)}
                      className={`py-2 px-4 rounded-lg font-semibold transition-colors duration-300 ${
                        currentSheet === sheetName
                          ? 'bg-purple-700 text-white shadow-lg'
                          : 'bg-purple-300 text-black hover:bg-purple-400'
                      }`}
                    >
                      {sheetName}
                    </button>
                  ))}
                </div>
                
                {renderFields()}

                <div className="bg-gray-100 p-6 rounded-lg shadow flex-1 overflow-auto">
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={currentSheet}
                      variants={tabTransition}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      className="font-mono text-sm w-full h-full"
                    >
                      {data[currentSheet] && renderTable(data[currentSheet])}
                    </motion.div>
                  </AnimatePresence>
                </div>

                <div className='flex justify-center items-center'>
                   <Button 
                      type='button' 
                      size="md" 
                      disabled={isButtonDisabled || isLoading} 
                      onClick={formdata}
                    >
                      <span className="pr-2">
                        {isLoading ? (<Spinner color="info" size="md" />) : ''}
                      </span>
                      {isLoading ? "En Cours d'enregistrement..." : 'Enregistrer'}
                    </Button>
                </div>
              </div>
            }
            isOpen={modale}
            closeModal={() => setModal(false)}
          />

          <div className='flex justify-end space-x-2 pb-4 item-center'>
            <div className='relative'>
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <FaGlobeAfrica className="w-5 h-5 text-gray-400" />
              </span>
              <select
                id='country'
                name='country'
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
                value={selectedCountry}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg h-10 pl-10 pr-4 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-transparent"
              >
                <option value="">Sélectionner un pays</option>
                {pays.map((option) => (
                  <option key={option.id} value={option.flag}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <AnimatePresence>
              {selectedCountry && (
                <motion.div 
                  className="flex pl-4"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <motion.div 
                    className='flex py-2 px-4 mr-5 bg-purple-300 text-white items-center rounded-lg cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700 hover:text-white'
                    whileHover={{ scale: 1.1 }}
                    onClick={() => alert('Hello Everyone')}
                  >
                    <FaFileDownload className='w-8 h-7' />
                  </motion.div>

                  <motion.div 
                    className='flex py-2 px-4 mr-5 bg-purple-300 text-white items-center rounded-lg cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700 hover:text-white'
                    whileHover={{ scale: 1.1 }}
                    onClick={handlExcelLoad}
                  >
                    <FaUpload className='w-8 h-7' />
                    <input
                      type='file'
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={handleExcellFileUpload}
                    />
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

        </main>
      </div>
    </div>
  );
}

export default TarifsVoyageList;

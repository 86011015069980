import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { IoMdAirplane } from "react-icons/io";
import { FaUserShield } from 'react-icons/fa';
import { BsFillClipboard2CheckFill } from 'react-icons/bs';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { VoyageSessionCleanup } from '../../../hooks/CleanFormSession';

const steps = [
    { component: Step1, icon: <IoMdAirplane size={30} />, label: '1. Voyage' },
    { component: Step2, icon: <FaUserShield size={30} />, label: '2. Assurés' },
    { component: Step3, icon: <BsFillClipboard2CheckFill size={30} />, label: '3 Résumé' },
];

const SouscriptionsVoyage = ({ stepMessages, setCurrentStep }) => {
    VoyageSessionCleanup();
    
    const [currentStep, setCurrentStepState] = useState(0);
    const [formVoyage, setFormVoyage] = useState(() => {
        const savedData = sessionStorage.getItem('formVoyage');
        return savedData ? JSON.parse(savedData) : {
            step1Data: {},
            step2Data: {},
            step3Data: {}
        };
    });

    useEffect(() => {
        if (Object.keys(formVoyage).length > 0) {
            sessionStorage.setItem('formVoyage', JSON.stringify(formVoyage));
        }
    }, [formVoyage]);

    const handleNext = (data) => {
        const newFormData = {
            ...formVoyage,
            [`step${currentStep + 1}Data`]: data
        };
        setFormVoyage(newFormData);
        setCurrentStepState(currentStep + 1);
        setCurrentStep(currentStep + 1);
    };

    const handlePrev = () => {
        setCurrentStepState(currentStep - 1);
        setCurrentStep(currentStep - 1);
    };

    const CurrentComponent = steps[currentStep].component;

    return (
        <div className='w-11/12 mx-auto bg-white p-14 rounded-3xl'>
            <ProgressBar currentStep={currentStep} />
            <motion.div
                key={currentStep}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
            >
                <CurrentComponent 
                    onNext={handleNext}
                    onPrev={handlePrev}
                    data={formVoyage[`step${currentStep + 1}Data`]}  
                    isLastStep={currentStep === steps.length - 1}
                />
            </motion.div>
        </div>
    );
};

const ProgressBar = ({ currentStep }) => {
    const progress = (currentStep / (steps.length - 1)) * 100;
    
    return (
        <div className="mb-8">
            <div className="relative flex justify-between items-center mt-4">
                {steps.map((step, index) => (
                    <div key={index} className="flex flex-col items-center relative z-10">
                        <motion.div
                            className={`flex items-center justify-center w-20 h-20 rounded-full p-2 transition-transform duration-500 
                                ${index <= currentStep ? 'bg-purple-600 text-white scale-110' : 'bg-gray-300 text-gray-600'}`}
                        >
                            <div className="z-10 flex items-center justify-center w-full h-full">
                                {step.icon}
                            </div>
                        </motion.div>
                        <span className={`mt-4 font-semibold text-lg ${index <= currentStep ? 'text-purple-600' : 'text-gray-500'}`}>
                            {step.label}
                        </span>
                    </div>
                ))}
                <motion.div
                    className="absolute top-1/3 left-[5px] h-2 bg-purple-600"
                    style={{ width: `${progress}%`, transform: 'translateY(-50%)' }}
                    initial={{ width: 0 }}
                    animate={{ width: `${progress}%` }}
                    transition={{ duration: 0.5 }}
                />
            </div>
        </div>
    );
};

export default SouscriptionsVoyage;
import { toast } from "keep-react"


export const succesNotification = (message) =>{ 
return toast.success(message,{ 
        richColors: true,
        invert: true,

    })
}

export const failNotification = (message) => { 
    return toast.error(message,{
        richColors: true,
        invert: true,
    })
}
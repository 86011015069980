// import React, { useEffect, useState, useRef } from 'react';
// import { motion, useAnimationControls } from 'framer-motion';
// import { TfiLayoutGrid2 } from "react-icons/tfi";
// import { IoSettingsSharp } from "react-icons/io5";
// import SidebarLink from '../sidebar/SidebarLink';
// import { AnimatePresence } from "framer-motion";
// import { submenus } from '../../utils/SubmenuLink';
// import { FaCarSide, FaPlane} from "react-icons/fa";
// import { SiBuffer } from "react-icons/si";
// import { FaHandsHoldingChild, FaMotorcycle } from 'react-icons/fa6';
// import { useNavigate } from "react-router-dom";
// import logo from "/src/images/yesha.png";
// import SousCategoriesNavigation from '../navigation/SousCategoriesNavigation';
// import { useProfile } from '../../UserDataStock/Profils/Profile';

// const containerVariants = {
//   close: {
//     width: '5rem',
//     transition: {
//       type: "spring",
//       damping: 15,
//       duration: 0.5
//     },
//   },
//   open: {
//     width: '16rem',
//     transition: {
//       type: "spring",
//       damping: 15,
//       duration: 0.5
//     }
//   }
// };

// const svgVariant = {
//   close: {
//     rotate: 360,
//   },
//   open: {
//     rotate: 180,
//   }
// };

// const Sidebar = ({ isOpen, onToggle }) => {
//   const [openSubmenu, setOpenSubmenu] = useState(null);
//   const [activeLink, setActiveLink] = useState(null);
//   const [showSousCategories, setShowSousCategories] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const containerControl = useAnimationControls();
//   const svgControl = useAnimationControls();
//   const navigate = useNavigate();
//   const sidebarRef = useRef(null);
//   const { position, token, userDetails } = useProfile();

//   const isMenuAllowed = (menuName) => {
//     if (userDetails?.role === "Vendeur") {
//       const allowedMenus  =  ["Dashboard", "Automobile", "Voyage", "Motocyclette", "Retraite", "Ressources", "Configurations"];
//       return allowedMenus.includes(menuName);
//     } else{
//       const allowedMenus  =  ["Dashboard", "Automobile", "Voyage", "Motocyclette", "Retraite", "Ressources", "Configurations"];
//       return allowedMenus.includes(menuName);
//     }
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (showSousCategories && 
//           !sidebarRef.current?.contains(event.target) && 
//           !event.target.closest('.sous-categories-nav')) {
//         setShowSousCategories(false);
//         setOpenSubmenu(null);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [showSousCategories]);

//   useEffect(() => {
//     if (isOpen) {
//       containerControl.start('open');
//       svgControl.start('open');
//       setShowSousCategories(false);
//       setOpenSubmenu(null);
//     } else {
//       containerControl.start('close');
//       svgControl.start('close');
//       setOpenSubmenu(null);
//     }
//   }, [isOpen]);

//   const handleSubmenuToggle = (name) => {
//     if (!isOpen) {
//       if (openSubmenu === name) {
//         setShowSousCategories(false);
//         setOpenSubmenu(null);
//         setActiveLink(null);
//       } else {
//         setShowSousCategories(false);
//         setTimeout(() => {
//           setActiveLink(name);
//           setOpenSubmenu(name);
//           setShowSousCategories(true);
//         }, 300);
//       }
//     } else {
//       if (openSubmenu === name) {
//         setOpenSubmenu(null);
//       } else {
//         setOpenSubmenu(name);
//         setActiveLink(name);
//       }
//       setShowSousCategories(false);
//     }
//   };

//   const filteredSubmenuItems = (items) => {
//     return items.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
//   };

//   const getSubmenuItems = (category) => {
//     const items = submenus[category] || [];
//     return items.filter(item => 
//       item.name.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//   };

//   return (
//     <>
//      <motion.nav
//         ref={sidebarRef}
//         variants={containerVariants}
//         animate={containerControl}
//         initial="close"
//         className='bg-[#8203b1] flex flex-col z-50 gap-20 p-5 fixed top-0 left-0 leading-snug h-full shadow shadow-neutral-600 overflow-hidden'
//       >
//         {/* Logo et bouton toggle */}
//         <div className='flex flex-row w-full justify-between place-items-center'>
//           {isOpen ? (
//             <img src={logo} alt="Logo" className="w-48 h-14" />
//           ) : (
//             <div className='w-10 h-10 bg-gradient-to-br' />
//           )}
//           <button className='p-1 rounded-full flex' onClick={onToggle}>
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               fill='none'
//               viewBox='0 0 24 24'
//               strokeWidth={1}
//               stroke='currentColor'
//               className='w-8 h-8 stroke-neutral-200'
//             >
//               <motion.path
//                 variants={svgVariant}
//                 animate={svgControl}
//                 d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
//                 transition={{
//                   duration: 0.5,
//                   ease: 'easeIn'
//                 }}
//               />
//             </svg>
//           </button>
//         </div>

//         <div className='flex flex-col gap-3'>
//           {/* Dashboard - toujours visible */}
//           <SidebarLink 
//             name="Dashboard"  
//             onToggle={() => {
//               navigate('/Accueil');
//               setShowSousCategories(false);
//             }}
//             isActive={activeLink === "Accueil"} 
//             isNavOpen={isOpen}
//           >
//             <TfiLayoutGrid2 size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />
//           </SidebarLink>

//           {/* Voyage */}
//           {isMenuAllowed("Voyage") && (
//             <SidebarLink 
//               name="Voyage" 
//               isOpen={openSubmenu === "Voyage"} 
//               onToggle={() => handleSubmenuToggle("Voyage")} 
//               isActive={activeLink === "Voyage"} 
//               submenu={filteredSubmenuItems(submenus.Voyage).map((item, index) => (
//                 <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
//                   <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
//                     <div className='text-white'>{item.icon}</div>
//                     <div className='text-white'>{item.name}</div>
//                   </div>
//                 </div>
//               ))} 
//               isNavOpen={isOpen}
//             >
//               <FaPlane size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />
//             </SidebarLink>
//           )}

//           {/* Automobile */}
//           {isMenuAllowed("Automobile") && (
//             <SidebarLink 
//               name="Automobile" 
//               isOpen={openSubmenu === "Automobile"} 
//               onToggle={() => handleSubmenuToggle("Automobile")} 
//               isActive={activeLink === "Automobile"} 
//               submenu={filteredSubmenuItems(submenus.Automobile).map((item, index) => (
//                 <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
//                   <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
//                     <div className='text-white'>{item.icon}</div>
//                     <div className='text-white'>{item.name}</div>
//                   </div>
//                 </div>
//               ))} 
//               isNavOpen={isOpen}
//             >
//               <FaCarSide size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />
//             </SidebarLink>
//           )}

//           {/* Motocyclette */}
//           {isMenuAllowed("Motocyclette") && (
//             <SidebarLink 
//               name="Motocyclette" 
//               isOpen={openSubmenu === "Motocyclette"} 
//               onToggle={() => handleSubmenuToggle("Motocyclette")} 
//               isActive={activeLink === "Motocyclette"} 
//               submenu={filteredSubmenuItems(submenus.Moto).map((item, index) => (
//                 <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
//                   <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
//                     <div className='text-white'>{item.icon}</div>
//                     <div className='text-white'>{item.name}</div>
//                   </div>
//                 </div>
//               ))} 
//               isNavOpen={isOpen}
//             >
//               <FaMotorcycle size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />
//             </SidebarLink>
//           )}

//           {/* Retraite */}
//           {isMenuAllowed("Retraite") && (
//             <SidebarLink 
//               name="Retraite" 
//               isOpen={openSubmenu === "Retraite"} 
//               onToggle={() => handleSubmenuToggle("Retraite")} 
//               isActive={activeLink === "Retraite"} 
//               submenu={filteredSubmenuItems(submenus.Retrait).map((item, index) => (
//                 <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
//                   <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
//                     <div className='text-white'>{item.icon}</div>
//                     <div className='text-white'>{item.name}</div>
//                   </div>
//                 </div>
//               ))} 
//               isNavOpen={isOpen}
//             >
//               <FaHandsHoldingChild size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />
//             </SidebarLink>
//           )}

//           {/* Ressources - visible uniquement pour les non-vendeurs */}
//           {!userDetails?.role === "Vendeur" && (
//             <SidebarLink 
//               name="Ressources" 
//               isOpen={openSubmenu === "Ressources"} 
//               onToggle={() => handleSubmenuToggle("Ressources")} 
//               isActive={activeLink === "Ressources"} 
//               submenu={filteredSubmenuItems(submenus.Ressources).map((item, index) => (
//                 <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
//                   <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
//                     <div className='text-white'>{item.icon}</div>
//                     <div className='text-white'>{item.name}</div>
//                   </div>
//                 </div>
//               ))} 
//               isNavOpen={isOpen}
//             >
//               <SiBuffer size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />
//             </SidebarLink>
//           )}

//           {/* Configurations - visible uniquement pour les non-vendeurs */}
//           {!userDetails?.role === "Vendeur" && (
//             <SidebarLink 
//               name="Configurations" 
//               isOpen={openSubmenu === "Configurations"} 
//               onToggle={() => handleSubmenuToggle("Configurations")} 
//               isActive={activeLink === "Configurations"} 
//               submenu={filteredSubmenuItems(submenus.Configurations).map((item, index) => (
//                 <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
//                   <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
//                     <div className='text-white'>{item.icon}</div>
//                     <div className='text-white'>{item.name}</div>
//                   </div>
//                 </div>
//               ))} 
//               isNavOpen={isOpen}
//             >
//               <IoSettingsSharp size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />
//             </SidebarLink>
//           )}
//         </div>
//       </motion.nav>

//       <AnimatePresence>
//         {showSousCategories && !isOpen && (
//           <SousCategoriesNavigation
//             selectedCategories={activeLink}
//             setSelectedCategories={() => {
//               setShowSousCategories(false);
//               setOpenSubmenu(null);
//             }}
//             searchTerm={searchTerm}
//             setSearchTerm={setSearchTerm}
//             submenuItems={getSubmenuItems(activeLink)}
//             isOpen={isOpen}
//           />
//         )}
//       </AnimatePresence>
//     </>
//   );
// };

// export default Sidebar;

import React, { useEffect, useState, useRef } from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import { TfiLayoutGrid2 } from "react-icons/tfi";
import { IoSettingsSharp } from "react-icons/io5";
import SidebarLink from '../sidebar/SidebarLink';
import { AnimatePresence } from "framer-motion";
import { submenus } from '../../utils/SubmenuLink';
import { Tooltip } from "keep-react";
import { FaCarSide, FaPlane} from "react-icons/fa";
import { SiBuffer } from "react-icons/si";
import { FaHandsHoldingChild, FaMotorcycle } from 'react-icons/fa6';
import { useNavigate } from "react-router-dom";
import logo from "/src/images/yesha.png";
import SousCategoriesNavigation from '../navigation/SousCategoriesNavigation';
import { useProfile } from '../../UserDataStock/Profils/Profile';

const Sidebar = ({ isOpen, onToggle }) => {
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [activeLink, setActiveLink] = useState(null);
  const [showSousCategories, setShowSousCategories] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const containerControl = useAnimationControls();
  const svgControl = useAnimationControls();
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const { position, token, userDetails } = useProfile();

  // Fonction pour vérifier si un menu est autorisé pour le rôle actuel
  const isMenuAllowed = (menuName) => {
    if (!userDetails?.role || userDetails === null || userDetails === undefined || Object.keys(userDetails).length === 0 ) {
      return false; // Si userDetails.role n'existe pas ou est null/undefined, ne rien envoyer
    }
    if (userDetails.role === "Vendeur") {
      const allowedMenus = ["Dashboard", "Automobile", "Motocyclette", "Retraite", "Voyage"];
      return allowedMenus.includes(menuName);
    }
    return true; // Pour tous les autres rôles, afficher tous les liens
  };

  const containerVariants = {
    close: {
      width: '5rem',
      transition: {
        type: "spring",
        damping: 15,
        duration: 0.5
      },
    },
    open: {
      width: '16rem',
      transition: {
        type: "spring",
        damping: 15,
        duration: 0.5
      }
    }
  };
  
  const svgVariant = {
    close: {
      rotate: 360,
    },
    open: {
      rotate: 180,
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showSousCategories && 
          !sidebarRef.current?.contains(event.target) && 
          !event.target.closest('.sous-categories-nav')) {
        setShowSousCategories(false);
        setOpenSubmenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSousCategories]);

  useEffect(() => {
    if (isOpen) {
      containerControl.start('open');
      svgControl.start('open');
      setShowSousCategories(false);
      setOpenSubmenu(null);
    } else {
      containerControl.start('close');
      svgControl.start('close');
      setOpenSubmenu(null);
    }
  }, [isOpen]);

  const handleSubmenuToggle = (name) => {
    if (!isOpen) {
      if (openSubmenu === name) {
        setShowSousCategories(false);
        setOpenSubmenu(null);
        setActiveLink(null);
      } else {
        setShowSousCategories(false);
        setTimeout(() => {
          setActiveLink(name);
          setOpenSubmenu(name);
          setShowSousCategories(true);
        }, 300);
      }
    } else {
      if (openSubmenu === name) {
        setOpenSubmenu(null);
      } else {
        setOpenSubmenu(name);
        setActiveLink(name);
      }
      setShowSousCategories(false);
    }
  };

  const renderSidebarLink = (name, icon, submenuItems = null, linkPath = null) => {
    const linkContent = (
      <SidebarLink 
        name={name}
        isOpen={openSubmenu === name}
        onToggle={() => {
          if (linkPath) {
            navigate(linkPath);
            setShowSousCategories(false);
          } else {
            handleSubmenuToggle(name);
          }
        }}
        isActive={activeLink === name}
        submenu={submenuItems}
        isNavOpen={isOpen}
      >
        {icon}
      </SidebarLink>
    );
  
    return !isOpen ? (
      <Tooltip
        content={name}
        placement="right"
        className="bg-gray-900 text-white px-2 py-1 rounded text-sm"
      >
        <div>{linkContent}</div>
      </Tooltip>
    ) : linkContent;
  };

  const filteredSubmenuItems = (items) => {
    return items.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const getSubmenuItems = (category) => {
    const items = submenus[category] || [];
    return items.filter(item => 
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  return (
    <>
      <motion.nav
        ref={sidebarRef}
        variants={containerVariants}
        animate={containerControl}
        initial="close"
        className='bg-[#8203b1] flex flex-col z-50 gap-20 p-5 fixed top-0 left-0 leading-snug h-full shadow shadow-neutral-600 overflow-hidden'
      >
        {/* Logo et bouton toggle */}
        <div className='flex flex-row w-full justify-between place-items-center'>
          {isOpen ? (
            <img src={logo} alt="Logo" className="w-48 h-14" />
          ) : (
            <div className='w-10 h-10 bg-gradient-to-br' />
          )}
          <button className='p-1 rounded-full flex' onClick={onToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1}
              stroke='currentColor'
              className='w-8 h-8 stroke-neutral-200'
            >
              <motion.path
                variants={svgVariant}
                animate={svgControl}
                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                transition={{
                  duration: 0.5,
                  ease: 'easeIn'
                }}
              />
            </svg>
          </button>
        </div>

        <div className='flex flex-col gap-3'>
          {isMenuAllowed("Dashboard") && renderSidebarLink(
            "Dashboard",
            <TfiLayoutGrid2 size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />,
            null,
            '/Accueil'
          )}

          {isMenuAllowed("Voyage") && renderSidebarLink(
            "Voyage",
            <FaPlane size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />,
            filteredSubmenuItems(submenus.Voyage).map((item, index) => (
              <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
                <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
                  <div className='text-white'>{item.icon}</div>
                  <div className='text-white'>{item.name}</div>
                </div>
              </div>
            ))
          )}

          {isMenuAllowed("Automobile") && renderSidebarLink(
            "Automobile",
            <FaCarSide size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />,
            filteredSubmenuItems(submenus.Automobile).map((item, index) => (
              <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
                <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
                  <div className='text-white'>{item.icon}</div>
                  <div className='text-white'>{item.name}</div>
                </div>
              </div>
            ))
          )}

          {isMenuAllowed("Motocyclette") && renderSidebarLink(
            "Motocyclette",
            <FaMotorcycle size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />,
            filteredSubmenuItems(submenus.Moto).map((item, index) => (
              <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
                <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
                  <div className='text-white'>{item.icon}</div>
                  <div className='text-white'>{item.name}</div>
                </div>
              </div>
            ))
          )}

          {isMenuAllowed("Retraite") && renderSidebarLink(
            "Retraite",
            <FaHandsHoldingChild size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />,
            filteredSubmenuItems(submenus.Retrait).map((item, index) => (
              <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
                <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
                  <div className='text-white'>{item.icon}</div>
                  <div className='text-white'>{item.name}</div>
                </div>
              </div>
            ))
          )}

          {isMenuAllowed("Ressources") && renderSidebarLink(
            "Ressources",
            <SiBuffer size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />,
            filteredSubmenuItems(submenus.Ressources).map((item, index) => (
              <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
                <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
                  <div className='text-white'>{item.icon}</div>
                  <div className='text-white'>{item.name}</div>
                </div>
              </div>
            ))
          )}

          {isMenuAllowed("Configurations") && renderSidebarLink(
            "Configurations",
            <IoSettingsSharp size={22} className='stroke-inherit stroke-[0.75] min-w-8 w-8' />,
            filteredSubmenuItems(submenus.Configurations).map((item, index) => (
              <div key={index} className='mt-2 p-2 rounded-md gap-4 ml-2 cursor-pointer hover:bg-neutral-700' onClick={()=>navigate(item.link)}>
                <div className='flex items-center gap-2 hover:translate-x-6 transition-transform duration-300'>
                  <div className='text-white'>{item.icon}</div>
                  <div className='text-white'>{item.name}</div>
                </div>
              </div>
            ))
          )}
        </div>
      </motion.nav>

      <AnimatePresence>
        {showSousCategories && !isOpen && (
          <SousCategoriesNavigation
            selectedCategories={activeLink}
            setSelectedCategories={() => {
              setShowSousCategories(false);
              setOpenSubmenu(null);
            }}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            submenuItems={getSubmenuItems(activeLink)}
            isOpen={isOpen}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidebar;
import { useFormik } from 'formik'
import React from 'react'
import { motion } from 'framer-motion'; 
import { FcSearch } from "react-icons/fc";
import { MatriculeValidation } from '../../../utils/FormValidation'
import { Button, Spinner } from 'keep-react'
import { GoUnverified } from "react-icons/go";

const CheckForm = ({handleCheck, isLoading}) => {

        const validate = MatriculeValidation;
        const formik = useFormik({ 
            initialValues: {
                matricule: ''
            },
            validate,
            onSubmit: handleCheck
        })
  return (
    <form className='w-full ' method='post' onSubmit={formik.handleSubmit}>
        <div className='grid grid-cols-8 gap-4 py-8 justify-center items-center row-auto'>
            <div className='col-span-4'>
                <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FcSearch className="w-5 h-5 " />
                    </span>
                    <input 
                        autoComplete='off'
                        autoCapitalize='on'
                        id="matricule" 
                        name='matricule'
                        onChange={formik.handleChange}
                        value={formik.values.matricule}
                        onBlur={formik.handleBlur}
                        type="text" 
                        placeholder="Saisissez le matricule" 
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.matricule && formik.touched.matricule ? 'border-red-500': ''}`}
                    />
                </div>
            </div>
            <div className='col-span-4'>
                <div className='flex justify-start py-2'>
                    <Button disabled={isLoading}   type='submit' size='sm' className={` ${isLoading ? 'bg-teal-600 hover:bg-slate-600 text-white  ' : 'bg-teal-600 hover:bg-slate-600 text-white'}`} >
                        <span className="pr-2">
                            {
                                isLoading ? (<Spinner color="success" size="md" />) : (<GoUnverified size={18} />)
                            }
                        </span>
                            {
                                isLoading ? "En Cours de Verification..." : 'Vérifier'
                            }
                    </Button>
                </div>
            </div>
            {formik.errors.matricule && formik.errors.matricule ? (
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md col-span-8 text-red-500"
                >{ formik.errors.matricule }
                </motion.div>) : null
            }
        </div>
    </form>
  )
}

export default CheckForm

import React from 'react'

const ContratRetraitDatatable = () => {
  return (
    <div>
        <h1>Retrait</h1>
    </div>
  )
}

export default ContratRetraitDatatable

import React, { useEffect } from 'react';
import { Modal } from 'keep-react';
import { motion } from 'framer-motion';
import { AiFillCloseCircle } from 'react-icons/ai';

const MyModal = ({ isOpen, closeModal, content }) => {

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            closeModal();
          }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    
  return (
    <Modal isOpen={isOpen}>
      <motion.div
       initial={{ opacity: 0, scale: 0.8 }}
       animate={{ opacity: 1, scale: 1 }}
       transition={{ duration: 0.5 }}
       className='w-screen'
      >
        <AiFillCloseCircle
          className="absolute top-10 right-10 text-white cursor-pointer text-[30px]"
          onClick={closeModal}
        />
        <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }} 
            className='flex justify-center items-center bg-white rounded-3xl  lg:w-5/12 md:w-8/12 sm:w-11/12 mx-auto p-4'>
            {/* Place ton form ou autre infos ici */}
            {content}
        </motion.div>
      </motion.div>
    </Modal>
  );
};

export default MyModal;
const FormatMontant = ({ montant }) => {
    if (montant === undefined || montant === null) {
        return "0"; 
    }

    let strNombre = montant.toString();

    let milliers = strNombre.slice(0, -3); 
    let centiemes = strNombre.slice(-3);

    return milliers + " " + centiemes;
}

export default FormatMontant;
import { useFormik } from 'formik';
import React from 'react';
import { ImManWoman } from "react-icons/im";
import { HiSwitchVertical } from "react-icons/hi";
import { FaPerson, FaUserPen, FaEnvelope } from 'react-icons/fa6';
import { MdWork } from "react-icons/md";
import PhoneInput from 'react-phone-input-2';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';
import { motion } from 'framer-motion';
import { GiPositionMarker } from 'react-icons/gi';
import { AssureFormValidation } from '../../../utils/FormValidation';

const FormStep2 = ({ onNext, onPrev, data }) => {

  const formik = useFormik({ 
    initialValues: { 
      type: "", 
      genre: "",
      nom: "",
      prenom: "",
      activite: "", 
      telephone_mobile: "",
      adresse_email: "",
      raison_social: "",
      adresse: "",
      verif: "",
    },
    validate: AssureFormValidation,
    onSubmit: (values) => { 
      const saveData = JSON.parse(sessionStorage.getItem('formData')) || {};
      saveData.step2Data = values;
    
      if (values.verif === '1') { 
        saveData.step3Data = {
          ...values, 
        };
      }
    
      sessionStorage.setItem('formData', JSON.stringify(saveData));
      onNext(values);
    }
  });

  const responseOptions = [
    { id: '1', label: 'Oui' },
    { id: '2', label: 'Non' },
  ];

  return (
    <div className='bg-white p-8 rounded-xl border shadow-md'>
      <h2 className="text-2xl mb-4 font-semibold text-purple-700">2. Informations de l'assuré</h2>
      <div className='flex space-x-16'>
        <form onSubmit={formik.handleSubmit} method='post' className='w-full' >
          <div className='grid grid-cols-8 gap-4 py-2 row-auto'>
            <div className='col-span-8'>
              <div className='flex justify-center items-center space-x-4 text-purple-700 py-2 '>
                <FaPerson size={22} />
                <span className='text-2xl font-semibold'>Les Informations de l'individus </span>
              </div>
            </div>
            <div className='col-span-4'>
              <label htmlFor="verif" className="block text-gray-700 font-semibold mb-1 pl-2">
                L'assuré est-il le souscripteur ?
              </label>
              <div className="relative">
                {responseOptions.map(option => (
                  <div key={option.id} className="flex items-center mb-2 ml-5">
                    <input
                      id={`verif-${option.id}`}
                      name="verif"
                      type="radio"
                      value={option.id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.verif === option.id}
                      className={`mr-2 w-6 h-6 border cursor-pointer border-gray-300 rounded text-blue-600 bg-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.verif && formik.touched.verif ? 'border-red-500' : ''}`}
                    />
                    <label htmlFor={`verif-${option.id}`} className="text-gray-700">{option.label}</label>
                  </div>
                ))}
              </div>
              <div className="h-6">
                {formik.touched.verif && formik.errors.verif && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md text-red-500"
                  >
                    {formik.errors.verif}
                  </motion.div>
                )}
              </div>
            </div>
            <div className='col-span-4'>
              <label htmlFor="type" className="block text-gray-700 font-semibold mb-1 pl-2">Type de client</label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <HiSwitchVertical className="w-5 h-5 text-gray-400 " />
                </span>
                <select
                  id="type"
                  name="type"
                  onChange={formik.handleChange}
                  value={formik.values.type}
                  onBlur={formik.handleBlur}
                  className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.type && formik.touched.type ? 'border-red-500' : ''}`}
                >
                  <option value="" label="Sélectionner un type de client" />
                  <option value="1" label="Personne physique - (particulier)" />
                  <option value="2" label="Personne morale - (entreprise)" />
                </select>
              </div>
              {formik.touched.type && formik.errors.type && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="italic text-md text-red-500"
                >
                  {formik.errors.type}
                </motion.div>
              )}
            </div>
            
            {formik.values.type === "1" && (
              <>
                <motion.div
                  className='col-span-4'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="genre" className="block text-gray-700 font-semibold mb-1 pl-2">Genre</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <ImManWoman className="w-5 h-5 text-gray-400 " />
                    </span>
                    <select
                      id="genre"
                      name="genre"
                      onChange={formik.handleChange}
                      value={formik.values.genre}
                      onBlur={formik.handleBlur}
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.genre && formik.touched.genre ? 'border-red-500' : ''}`}
                    >
                      <option value="" label="Sélectionner le genre" />
                      <option value="H" label="Monsieur" />
                      <option value="F" label="Madame" />
                    </select>
                  </div>
                  {formik.touched.genre && formik.errors.genre && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.genre}
                      </motion.div>
                    )}
                </motion.div>
                <div className='col-span-4'></div>
                
                <motion.div
                  className='col-span-4'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="prenom" className="block text-gray-700 font-semibold mb-1 pl-2">Prenom</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <FaUserPen className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input
                      autoComplete='off'
                      id="prenom"
                      name='prenom'
                      onChange={formik.handleChange}
                      value={formik.values.prenom}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder="Saisissez le Prenom "
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.prenom && formik.touched.prenom ? 'border-red-500' : ''}`}
                    />
                  </div>
                  {formik.touched.prenom && formik.errors.prenom && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.prenom}
                      </motion.div>
                    )}
                </motion.div>
                
                <motion.div
                  className='col-span-4'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="nom" className="block text-gray-700 font-semibold mb-1 pl-2">Nom</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <FaUserPen className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input
                      autoComplete='off'
                      id="nom"
                      name='nom'
                      onChange={formik.handleChange}
                      value={formik.values.nom}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder="Saisissez le Nom"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.nom && formik.touched.nom ? 'border-red-500' : ''}`}
                    />
                  </div>
                  {formik.touched.nom && formik.errors.nom && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.nom}
                      </motion.div>
                    )}
                </motion.div>
                
                <motion.div
                  className='col-span-8'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="activite" className="block text-gray-700 font-semibold mb-1 pl-2">Activités</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <MdWork className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input
                      autoComplete='off'
                      id="activite"
                      name="activite"
                      onChange={formik.handleChange}
                      value={formik.values.activite}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder="Saisissez l'activité "
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.activite && formik.touched.activite ? 'border-red-500' : ''}`}
                    />
                  </div>
                  {formik.touched.activite && formik.errors.activite && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.activite}
                      </motion.div>
                    )}
                </motion.div>
              </>
            )}
            
            {formik.values.type === "2" && (
              <>
                <motion.div
                  className='col-span-4'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="raison_social" className="block text-gray-700 font-semibold mb-1 pl-2">Raison Sociale</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <FaUserPen className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input
                      autoComplete='off'
                      id="raison_social"
                      name='raison_social'
                      onChange={formik.handleChange}
                      value={formik.values.raison_social}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder="Saisissez la Raison Sociale"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.raison_social && formik.touched.raison_social ? 'border-red-500' : ''}`}
                    />
                  </div>
                  {formik.touched.raison_social && formik.errors.raison_social && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.raison_social}
                      </motion.div>
                    )}
                </motion.div>
                
                <motion.div
                  className='col-span-4'
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor="activite" className="block text-gray-700 font-semibold mb-1 pl-2">Activité</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <MdWork className="w-5 h-5 text-gray-400 " />
                    </span>
                    <input
                      autoComplete='off'
                      id="activite"
                      name="activite"
                      onChange={formik.handleChange}
                      value={formik.values.activite}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder="Saisissez l'activité"
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.activite && formik.touched.activite ? 'border-red-500' : ''}`}
                    />
                  </div>
                  {formik.touched.activite && formik.errors.activite && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.activite}
                      </motion.div>
                    )}
                </motion.div>
              </>
            )}

            <div className='col-span-4'>
              <label htmlFor="telephone_mobile" className="block text-gray-700 font-semibold mb-1 pl-2">Téléphone Mobile</label>
              <div className="relative">
                <PhoneInput
                  country={'sn'}
                  onlyCountries={['sn', 'gn','ml', 'ci']}
                  masks={{ 
                     sn: '-..-...-..-..',
                     gn: '-..-...-...',
                     ml: '-..-...-...',
                     ci: '-..-..-..-..'
                     }}
                  autoComplete='off'
                  id="telephone_mobile"
                  name='telephone_mobile'
                  value={formik.values.telephone_mobile}
                  onChange={(value) => {
                    formik.setFieldValue('telephone_mobile', value);
                  }}
                  onBlur={() => {
                    formik.handleBlur('telephone_mobile');
                  }}
                  placeholder=" Numero du mobile"
                  inputStyle={{
                    'fontFamily': 'serif',
                    'width': '100%',
                    'height': '2.5rem',
                    'color': '#444',
                  }}
                />
              </div>
              {formik.touched.telephone_mobile && formik.errors.telephone_mobile && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md text-red-500"
                  >
                    {formik.errors.telephone_mobile}
                  </motion.div>
              )}
            </div>
            <div className='col-span-4'>
              <label htmlFor="adresse_email" className="block text-gray-700 font-semibold mb-1 pl-2">Adresse électronique</label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <FaEnvelope className="w-5 h-5 text-gray-400 " />
                </span>
                <input
                  autoComplete='off'
                  id="adresse_email"
                  name='adresse_email'
                  onChange={formik.handleChange}
                  value={formik.values.adresse_email}
                  onBlur={formik.handleBlur}
                  type="email"
                  placeholder="Saisissez l'email"
                  className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.adresse_email && formik.touched.adresse_email ? 'border-red-500' : ''}`}
                />
              </div>
              {formik.touched.adresse_email && formik.errors.adresse_email && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="italic text-md text-red-500"
                  >
                    {formik.errors.adresse_email}
                  </motion.div>
              )}
            </div>
            <div className='col-span-8'>
              <label htmlFor="adresse" className="block text-gray-700 font-semibold mb-1 pl-2">Adresse</label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <GiPositionMarker className="w-5 h-5 text-gray-400 " />
                </span>
                <input
                  autoComplete='off'
                  id="adresse"
                  name='adresse'
                  onChange={formik.handleChange}
                  value={formik.values.adresse}
                  onBlur={formik.handleBlur}
                  type="text"
                  placeholder="Saisissez l'adresse"
                  className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.adresse && formik.touched.adresse ? 'border-red-500' : ''}`}
                />
              </div>
              {formik.touched.adresse && formik.errors.adresse && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="italic text-md text-red-500"
                >
                  {formik.errors.adresse}
                </motion.div>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="flex justify-between mt-8">
        <button onClick={onPrev} className=" flex items-center space-x-3 bg-blue-100 text-blue-500 py-2 px-4 rounded transform transition duration-300 hover:scale-110">
          <IoIosArrowDropleftCircle size={20} />
          <span>Précédent</span>
        </button>
        <button 
          type='button' 
          onClick={formik.handleSubmit} 
          disabled={!formik.isValid || !formik.dirty} // Désactivation en fonction de la validité et si le formulaire a été modifié
          className={`flex space-x-3 items-center py-2 px-4 rounded transform transition duration-300 hover:scale-110 ${(!formik.isValid || !formik.dirty) ? 'bg-blue-300 text-white cursor-not-allowed' : 'bg-blue-500 text-white'}`}>
          <span>Suivant</span>
          <IoIosArrowDroprightCircle size={20} />
        </button>
      </div>
    </div>
  );
}

export default FormStep2;

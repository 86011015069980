
import React, { useEffect, useRef, useState  } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useFormik } from 'formik'
import { BsPassportFill, BsFillPersonFill, BsBuildingsFill } from 'react-icons/bs'
import { FaCalendarDay, FaEnvelope } from 'react-icons/fa'
import { ImManWoman } from 'react-icons/im'
import { FaUserPen, FaUserTie } from 'react-icons/fa6'
import PhoneInput from 'react-phone-input-2'
import { TbSwitchHorizontal } from "react-icons/tb";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io'
import { MdDeleteForever, MdDeleteSweep, MdPlace, MdWork } from "react-icons/md";
import { HiMiniCheckBadge } from "react-icons/hi2";
import { BiSolidChevronDownCircle, BiSolidChevronUpCircle } from "react-icons/bi";
import afrique  from "/src/images/afrique.svg";
import europe from "/src/images/schengen.svg";
import monde from "/src/images/monde.svg";
import inconnu from "/src/images/travel.png";
import { createRoot } from 'react-dom/client';
import { generateUniqueEmail } from '../../../utils/Extras'
import Datepicker from "react-tailwindcss-datepicker";
import { VoyageSusbcriber, VoyageurValidation } from '../../../utils/FormValidation'
import FormatMontant from '../../../utils/FormatMontant'
import moment from 'moment'
import { useProfile } from '../../../UserDataStock/Profils/Profile'


const Step2 = ({onNext, onPrev}) => {

    const [isFocused, setIsFocused] = useState(false);
    const [selectedSubscriber, setSelectedSubscriber] = useState(null);
    const saveData = JSON.parse(sessionStorage.getItem('formVoyage')) || {};
    const savedData = JSON.parse(sessionStorage.getItem('formVoyage'))?.step2Data || {};
    const [devis, setDevis] = useState("");
    const { position} = useProfile();


    

    const paysActuel = position.location.country_name;
    // const paysActuel = "Guinée";

    useEffect(() => {
      if(position?.location?.country_code3 === "GIN"){
        setDevis("GNF")
      }else{
        setDevis("XOF")
      }
    }, []);
    

    // Fonction pour valider si un formulaire est bien rempli
    const isFormValid = (voyager) => {
      const requiredFields = [
        'prenom', 'nom', 'genre', 'dateNaissance', 'telephone', 
        'email', 'passeport', 'expirationPasseport', 'adresse', 
        'delivrancePasseport'
      ];
      return requiredFields.every(field => voyager[field]);
    };
    

    const initialVoyagers = (saveData.step1Data?.voyagers || []).map((voyager, index) => ({
      isSubscriber: savedData.voyagers?.[index]?.isSubscriber || '',
      nom: savedData.voyagers?.[index]?.nom || '',
      prenom: savedData.voyagers?.[index]?.prenom || '',
      genre: savedData.voyagers?.[index]?.genre || '',
      dateNaissance: saveData.step1Data.voyagers[index].dateNaissance || '', // Récupération depuis step1Data
      telephone: savedData.voyagers?.[index]?.telephone || '',
      email: savedData.voyagers?.[index]?.email || '',
      passeport: savedData.voyagers?.[index]?.passeport || '',
      expirationPasseport: savedData.voyagers?.[index]?.expirationPasseport || '',
      adresse: savedData.voyagers?.[index]?.adresse || '',
      delivrancePasseport: savedData.voyagers?.[index]?.delivrancePasseport || '',
    }));
  
    const formikSusbcriber = useFormik({
      initialValues: {
        type: savedData.souscripteur?.type || '',
        nom: savedData.souscripteur?.nom || '',
        prenom: savedData.souscripteur?.prenom || '',
        genre: savedData.souscripteur?.genre || '',
        dateNaissance: savedData.souscripteur?.dateNaissance || '',
        telephone: savedData.souscripteur?.telephone || '',
        email: savedData.souscripteur?.email || '',
        adresse: savedData.souscripteur?.adresse || '',
        raison_social: savedData.souscripteur?.raison_social || '',
        domaine: savedData.souscripteur?.domaine || '',
      },
      validate: VoyageSusbcriber,
      onSubmit: (values) => {
        // Sauvegarder dans sessionStorage
        const existingData = JSON.parse(sessionStorage.getItem('formVoyage')) || {};
        const newData = {
          ...existingData,
          step2Data: {
            souscripteur: values,
            voyagers: formik.values.voyagers
          }
        };
        sessionStorage.setItem('formVoyage', JSON.stringify(newData));
        onNext(newData.step2Data);
      }
    });

    const handleTypeChange = (e) => {
      const newType = e.target.value;
      
      // Mettre à jour le type
      formikSusbcriber.setFieldValue('type', newType);
    
      // Si le type change vers "Entreprise", vider les champs du particulier
      if (newType === 'Entreprise') {
        formikSusbcriber.setFieldValue('genre', '');
        formikSusbcriber.setFieldValue('prenom', '');
        formikSusbcriber.setFieldValue('nom', '');
        formikSusbcriber.setFieldValue('dateNaissance', '');
        setDateValue({
          startDate: null,
          endDate: null
        });
      }
      
      // Si le type change vers "Particulier", vider le champ raison_social
      if (newType === 'Particulier') {
        formikSusbcriber.setFieldValue('raison_social', '');
      }
    
      // Si le type est vide, vider tous les champs
      if (newType === '') {
        formikSusbcriber.setFieldValue('genre', '');
        formikSusbcriber.setFieldValue('prenom', '');
        formikSusbcriber.setFieldValue('nom', '');
        formikSusbcriber.setFieldValue('dateNaissance', '');
        formikSusbcriber.setFieldValue('raison_social', '');
        setDateValue({
          startDate: null,
          endDate: null
        });
      }
    };

    const [dateValue, setDateValue] = useState({
      startDate: formikSusbcriber.values.dateNaissance || null,
      endDate: formikSusbcriber.values.dateNaissance || null
    });
    
  
    const formik = useFormik({
      initialValues: {
        voyagers: initialVoyagers,
      },
      validate: VoyageurValidation,
      onSubmit: (values) => {
        // La soumission est gérée par handleSubmitAll
      }
    });

    const [expirationDates, setExpirationDates] = useState(
      formik.values.voyagers.map(v => ({
        startDate: v.expirationPasseport || null,
        endDate: v.expirationPasseport || null
      }))
    );


    const isAllFormsValid = () => {
      // Vérifie si tous les voyageurs ont leurs champs requis remplis
      const areAllVoyagersValid = formik.values.voyagers.every(voyager => {
        return isFormValid(voyager);
      });
    
      // Vérifie si les champs du souscripteur sont remplis en fonction du type
      const isSouscripteurValid = () => {
        const commonFields = ['type', 'telephone', 'email', 'adresse', 'domaine'];
        const particulierFields = ['genre', 'prenom', 'nom', 'dateNaissance'];
        const entrepriseFields = ['raison_social'];
    
        const fieldsToCheck = [
          ...commonFields,
          ...(formikSusbcriber.values.type === 'Particulier' ? particulierFields : entrepriseFields)
        ];
    
        return fieldsToCheck.every(field => {
          const value = formikSusbcriber.values[field];
          return value && value.trim() !== '';
        });
      };
    
      // Vérifie si des données sont déjà sauvegardées dans le sessionStorage
      const savedData = JSON.parse(sessionStorage.getItem('formVoyage'))?.step2Data;
      const hasExistingData = !!savedData;
    
      return (
        formik.isValid &&
        formikSusbcriber.isValid &&
        areAllVoyagersValid &&
        isSouscripteurValid() &&
        // Ne vérifie dirty que s'il n'y a pas de données sauvegardées
        (hasExistingData || (formik.dirty && formikSusbcriber.dirty))
      );
    };

    const handleSubmitAll = () => {
      if (isAllFormsValid()) {
        formikSusbcriber.handleSubmit();
      }
    };


    const handleGenerateEmail = (index) => {
      const generatedEmail = generateUniqueEmail(); // Simule la génération d'un email unique
      formik.setFieldValue(`voyagers.${index}.email`, generatedEmail);
    };
    

    const GenerateEmailSouscripteur = () => {
      const generatedEmails = generateUniqueEmail();
      formikSusbcriber.setFieldValue('email', generatedEmails); 
    };

    const handleRemoveVoyager = (index) => {
      if (window.confirm("Êtes-vous sûr de vouloir supprimer ce voyageur ?")) {
        const updatedVoyagers = formik.values.voyagers.filter((_, i) => i !== index);
        formik.setFieldValue('voyagers', updatedVoyagers);
      }
    };

    const handleUnselectSouscripteur = () => {
      // Créer un composant d'alerte animé
      const AlertComponent = () => (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              root.unmount();
              document.body.removeChild(alertContainer);
            }
          }}
        >
          <motion.div
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", damping: 15, stiffness: 200 }}
            className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4"
          >
            <div className="text-center">
              <motion.div 
                className="flex justify-center mb-4"
                initial={{ rotate: -180, scale: 0 }}
                animate={{ rotate: 0, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <MdDeleteForever className="text-red-500 text-4xl" />
              </motion.div>
              <motion.h3 
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="text-lg font-semibold text-gray-900 mb-2"
              >
                Suppression du souscripteur actuel
              </motion.h3>
              <motion.p 
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-gray-600 mb-6"
              >
                Les données de l’assuré(e) devenue souscripteur ont été retiré avec succès 
              </motion.p>
              <motion.button
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-colors"
                onClick={() => document.body.removeChild(document.getElementById('alert-container'))}
              >
                OK
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      );
    
      // Créer un conteneur pour l'alerte
      const alertContainer = document.createElement('div');
      alertContainer.id = 'alert-container';
      document.body.appendChild(alertContainer);
    
      // Créer une racine React et rendre le composant
      const root = createRoot(alertContainer);
      root.render(<AlertComponent />);
    
      // Mettre à jour le gestionnaire de clic du bouton OK
      const handleClick = () => {
        root.unmount();
        document.body.removeChild(alertContainer);
      };
    
      // Mettre à jour les voyageurs et réinitialiser le formulaire
      const updatedVoyagers = formik.values.voyagers.map(voyager => ({
        ...voyager,
        isSubscriber: false
      }));
      formik.setFieldValue('voyagers', updatedVoyagers);
      setSelectedSubscriber(null);
      formikSusbcriber.resetForm();
      setDateValue({
        startDate: null,
        endDate: null
      });
    };


    const handleSelectSouscripteur = (index) => {
        const selectedVoyager = formik.values.voyagers[index];
        setSelectedSubscriber(index);

        // Mettre à jour isSubscriber pour tous les voyageurs
      const updatedVoyagers = formik.values.voyagers.map((voyager, i) => ({
        ...voyager,
        isSubscriber: i === index // true pour le voyageur sélectionné, false pour les autres
      }));
      formik.setFieldValue('voyagers', updatedVoyagers);
      
      // Créer un composant d'alerte animé
      const AlertComponent = () => (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              root.unmount();
              document.body.removeChild(alertContainer);
            }
          }}
        >
          <motion.div
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", damping: 15, stiffness: 200 }}
            className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4"
          >
            <div className="text-center">
              <motion.div 
                className="flex justify-center mb-4"
                initial={{ rotate: -180, scale: 0 }}
                animate={{ rotate: 0, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <FaUserTie className="text-purple-500 text-4xl" />
              </motion.div>
              <motion.h3 
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="text-lg font-semibold text-gray-900 mb-2"
              >
                L’assuré est le Souscripteur
              </motion.h3>
              <motion.p 
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-gray-600 mb-6"
              >
                L'assuré <span className="font-bold text-purple-600">{selectedVoyager.prenom} {selectedVoyager.nom}</span> est considéré(e) comme étant le souscripteur 
              </motion.p>
              <motion.button
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-purple-500 text-white px-6 py-2 rounded-lg hover:bg-purple-600 transition-colors"
                onClick={() => document.body.removeChild(document.getElementById('alert-container'))}
              >
                OK
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      );
    
      // Créer un conteneur pour l'alerte
      const alertContainer = document.createElement('div');
      alertContainer.id = 'alert-container';
      document.body.appendChild(alertContainer);

      // Créer une racine React et rendre le composant
      const root = createRoot(alertContainer);
      root.render(<AlertComponent />);

      // Mettre à jour les valeurs du formulaire souscripteur
      formikSusbcriber.setValues({
        ...formikSusbcriber.values,
        prenom: selectedVoyager.prenom,
        nom: selectedVoyager.nom,
        type: 'Particulier',
        genre: selectedVoyager.genre,
        dateNaissance: selectedVoyager.dateNaissance,
        telephone: selectedVoyager.telephone,
        email: selectedVoyager.email,
        adresse: selectedVoyager.adresse, // Ajout de l'adresse
      });

      setDateValue({
          startDate: selectedVoyager.dateNaissance,
          endDate: selectedVoyager.dateNaissance
      });
    };

    const [delivranceDates, setDelivranceDates] = useState(
      formik.values.voyagers.map(v => ({
        startDate: v.delivrancePasseport || null,
        endDate: v.delivrancePasseport || null
      }))
    );
    
    // Ajoutez la fonction handleDelivranceChange
    const handleDelivranceChange = (newValue, index) => {
      const newDates = [...delivranceDates];
      newDates[index] = newValue;
      setDelivranceDates(newDates);
      
      formik.setFieldValue(
        `voyagers.${index}.delivrancePasseport`,
        newValue?.startDate ? moment(newValue.startDate).format('YYYY-MM-DD') : ''
      );
    };

    const handleDateChange = (newValue) => {
      setDateValue(newValue);
      formikSusbcriber.setFieldValue(
        'dateNaissance', 
        newValue?.startDate ? moment(newValue.startDate).format('YYYY-MM-DD') : ''
      );
    };

    const handleExpirationChange = (newValue, index) => {
      const newDates = [...expirationDates];
      newDates[index] = newValue;
      setExpirationDates(newDates);
      
      formik.setFieldValue(
        `voyagers.${index}.expirationPasseport`,
        newValue?.startDate ? moment(newValue.startDate).format('YYYY-MM-DD') : ''
      );
    };

    const getMinExpirationDate = () => {
      const departDate = moment(saveData.step1Data.dateDepart);
      const duree = parseInt(saveData.step1Data.duree);
      return departDate.add(duree, 'days').toDate();
    };

    const [isOpen, setIsOpen] = useState(formik.values.voyagers.map(() => true));

    const toggleFormVisibility = (index) => {
      const updatedIsOpen = [...isOpen];
      updatedIsOpen[index] = !updatedIsOpen[index];
      setIsOpen(updatedIsOpen);
    };

    const getFieldError = (field, index) => {
      return (
        formik.errors.voyagers &&
        formik.errors.voyagers[index] &&
        formik.errors.voyagers[index][field] &&
        formik.touched.voyagers &&
        formik.touched.voyagers[index] &&
        formik.touched.voyagers[index][field]
      );
    };

    const formulaNameToPic = {
        "Schengen": europe,
        "Monde Entier": monde,
        "Afrique": afrique,
    };
    const pic = formulaNameToPic[saveData.step1Data.selectedTarification.formules?.[0].formule.name] || formulaNameToPic[saveData.step1Data.selectedTarification.formules?.[0].formule] || inconnu;


    return (
      <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row md:space-x-6">
        <div className="md:w-2/3 lg:w-2/3">
          {/* Les formulaires */}
          <div className="bg-gray-50 p-6 shadow-lg rounded-lg" style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <div className="grid">
              {/* Formulaire pour chaque voyageur */}
              {formik.values.voyagers.map((voyager, index) => (
                
                <AnimatePresence key={index}>
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0, overflow: 'hidden' }}
                    transition={{ duration: 0.3 }}
                    className="bg-white p-6 shadow-lg rounded-lg col-span-2 border mb-6"
                  >
                    <div className='flex flex-row justify-between items-baseline'>

                      <div className=" flex flex-row  mb-4 text-purple-500  ">
                        <div className='flex flex-row space-x-2 text-purple-600 justify-center items-center underline underline-offset-2 bg-purple-100 rounded-md p-2 '>
                          <BsFillPersonFill size={18}  />
                          <span className='font-bold text-md'>Assuré {index + 1}</span>
                        </div>
                        
                        {/* Vérifie si le formulaire du voyageur est complet */}
                        <button onClick={() => toggleFormVisibility(index)} className="ml-4">
                          {isOpen[index] ? <BiSolidChevronDownCircle size={20} /> : <BiSolidChevronUpCircle size={20} />}
                        </button>
                      </div>

                      <div>
                          {isFormValid(formik.values.voyagers[index]) && (
                            <div className='flex flex-row mb-4 text-green-600 justify-center items-center bg-green-100 p-2 rounded-md'>
                              <HiMiniCheckBadge size={18} />
                              <span className='font-bold text-md'>Formulaire complète</span>
                            </div>
                          )}
                      </div>

                      {voyager.isSubscriber && (
                        <div className='flex flex-row space-x-2 text-green-600 justify-center items-center bg-green-100 rounded-md p-2'>
                          <FaUserTie size={18} />
                          <span className='font-bold text-md'>Souscripteur actuel</span>
                        </div>
                      )}
                      
                      {!voyager.isSubscriber && (
                        <div 
                          className={`flex flex-row space-x-2 text-purple-600 justify-center items-center bg-purple-100 rounded-md p-2 
                            ${isFormValid(formik.values.voyagers[index]) 
                              ? 'cursor-pointer hover:bg-purple-600 hover:text-white' 
                              : 'opacity-50 cursor-not-allowed'}`}
                          onClick={() => isFormValid(formik.values.voyagers[index]) && handleSelectSouscripteur(index)}
                        >
                          <FaUserTie size={18} />
                          <span className='font-bold text-md'>
                            {isFormValid(formik.values.voyagers[index]) 
                              ? 'Souscripteur' 
                              : 'Complétez le formulaire'}
                          </span>
                        </div>
                      )}

                    </div>

                    {isOpen[index] && (
                      <form onSubmit={formik.handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {/* Prénom */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <label htmlFor={`voyagers.${index}.prenom`} className="block text-gray-700 font-semibold mb-1 pl-2">
                            Prénom
                          </label>
                          <div className="relative">
                            <FaUserPen className="absolute left-3 top-3 text-gray-400" />
                            
                            <input
                              type="text"
                              name={`voyagers.${index}.prenom`}
                              autoComplete="off"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.voyagers[index].prenom}
                              placeholder="Prénom"
                              className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                formik.errors.voyagers &&
                                formik.errors.voyagers[index] &&
                                formik.errors.voyagers[index].prenom &&
                                formik.touched.voyagers &&
                                formik.touched.voyagers[index] &&
                                formik.touched.voyagers[index].prenom
                                  ? 'border-red-500'
                                  : ''
                              }`}
                            />
                          </div>
                          {getFieldError('prenom', index) && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.5 }}
                              className="italic text-md text-red-500 mt-1"
                            >
                              {formik.errors.voyagers[index].prenom}
                            </motion.div>
                          )}
                        </motion.div>

                        {/* Nom */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <label htmlFor={`voyagers.${index}.nom`} className="block text-gray-700 font-semibold mb-1 pl-2">
                            Nom
                          </label>
                          <div className="relative">
                            <FaUserPen className="absolute left-3 top-3 text-gray-400" />
                            <input
                              type="text"
                              name={`voyagers.${index}.nom`}
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.voyagers[index].nom}
                              placeholder="Nom de famille"
                              className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                formik.errors.voyagers &&
                                formik.errors.voyagers[index] &&
                                formik.errors.voyagers[index].nom &&
                                formik.touched.voyagers &&
                                formik.touched.voyagers[index] &&
                                formik.touched.voyagers[index].nom
                                  ? 'border-red-500'
                                  : ''
                              }`}
                            />
                          </div>
                          <div className="h-6">
                          {getFieldError('nom', index) && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="italic text-md text-red-500 mt-1"
                              >
                                {formik.errors.voyagers[index].nom}
                              </motion.div>
                            )}
                          </div>
                        </motion.div>

                        {/* Genre */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <label htmlFor={`voyagers.${index}.genre`} className="block text-gray-700 font-semibold mb-1 pl-2">Genre</label>
                          <div className="relative">
                            <ImManWoman className="absolute left-3 top-3 text-gray-400" />
                            <select
                              autoComplete="off"
                              name={`voyagers.${index}.genre`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.voyagers[index].genre}
                              className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                formik.errors.voyagers &&
                                formik.errors.voyagers[index] &&
                                formik.errors.voyagers[index].genre &&
                                formik.touched.voyagers &&
                                formik.touched.voyagers[index] &&
                                formik.touched.voyagers[index].genre
                                  ? 'border-red-500'
                                  : ''
                              }`}
                            >
                              <option value="">Sélectionnez</option>
                              <option value="Monsieur">Monsieur</option>
                              <option value="Madame">Madame</option>
                            </select>
                            
                          </div>
                          <div className="h-6">
                          {getFieldError('genre', index) && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.5 }}
                              className="italic text-md text-red-500 mt-1"
                            >
                              {formik.errors.voyagers[index].genre}
                            </motion.div>
                          )}
                          </div>
                          
                        </motion.div>

                        {/* Date de naissance */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <label htmlFor={`voyagers.${index}.dateNaissance`} className="block text-gray-700 font-semibold mb-1 pl-2">
                            Date de naissance
                          </label>
                          <div className="relative">
                            <FaCalendarDay className="absolute left-3 top-3 text-gray-400" />
                            <input
                              readOnly
                              type="date"
                              name={`voyagers.${index}.dateNaissance`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.voyagers[index].dateNaissance}
                              className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                formik.errors.voyagers &&
                                formik.errors.voyagers[index] &&
                                formik.errors.voyagers[index].dateNaissance &&
                                formik.touched.voyagers &&
                                formik.touched.voyagers[index] &&
                                formik.touched.voyagers[index].dateNaissance
                                  ? 'border-red-500'
                                  : ''
                              }`}
                            />
                          </div>
                          <div className="h-6">
                          {getFieldError('dateNaissance', index) && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.5 }}
                              className="italic text-md text-red-500 mt-1"
                            >
                              {formik.errors.voyagers[index].dateNaissance}
                            </motion.div>
                          )}
                          </div>
                        </motion.div>

                        {/* Adresse */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <label htmlFor={`voyagers.${index}.adresse`} className="block text-gray-700 font-semibold mb-1 pl-2">
                            Adresse
                          </label>
                          <div className="relative">
                            <MdPlace className="absolute left-3 top-3 text-gray-400" />
                            <input
                              type="text"
                              name={`voyagers.${index}.adresse`}
                              autoComplete="off"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.voyagers[index].adresse}
                              placeholder="Adresse"
                              className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                getFieldError('adresse', index) ? 'border-red-500' : ''
                              }`}
                            />
                          </div>
                          <div className="h-6">
                            {getFieldError('adresse', index) && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="italic text-md text-red-500 mt-1"
                              >
                                {formik.errors.voyagers[index].adresse}
                              </motion.div>
                            )}
                          </div>
                        </motion.div>

                        {/* Téléphone */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <label htmlFor={`voyagers.${index}.telephone`} className="block text-gray-700 font-semibold mb-1 pl-2">
                            Téléphone
                          </label>
                          <div className="relative">
                            <PhoneInput
                              country={'sn'}
                              autoComplete="off"
                              onlyCountries={['sn', 'gn', 'ml', 'ci']}
                              masks={{
                                sn: '-..-...-..-..',
                                gn: '-..-...-...',
                                ml: '-..-...-...',
                                ci: '-..-..-..-..',
                              }}
                              id={`voyagers.${index}.telephone`}
                              name={`voyagers.${index}.telephone`}
                              value={formik.values.voyagers[index].telephone}
                              onChange={(value) => formik.setFieldValue(`voyagers.${index}.telephone`, value)}
                                onBlur={() => {
                                  formik.handleBlur(`voyagers.${index}.telephone`);
                                  setIsFocused(false);
                                }}
                                inputStyle={{
                                  fontFamily: 'serif',
                                  width: '100%',
                                  height: '2.5rem',
                                  color: '#444',
                                  ...(isFocused && {
                                    outline: 'none',
                                    boxShadow: '0 0 0 2px #3b82f6',
                                    borderColor: 'transparent',
                                  }),
                                }}
                              />
                              <div className="h-6">
                                {getFieldError('telephone', index) && (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    className="italic text-md text-red-500 mt-1"
                                  >
                                    {formik.errors.voyagers[index].telephone}
                                  </motion.div>
                                )}
                              </div>
                            </div>
                        </motion.div>

                        {/* Email */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <label htmlFor={`voyagers.${index}.email`} className="block text-gray-700 font-semibold mb-1 pl-2">Email</label>
                          <div className="relative">
                            <span 
                                  className="absolute inset-y-0 left-0 flex items-center pl-3 cursor-pointer"
                                  onClick={() => handleGenerateEmail(index)} 
                              >
                                  <FaEnvelope className="w-5 h-5 text-gray-400" />
                              </span>
                            <input
                              type="email"
                              autoComplete="off"
                              name={`voyagers.${index}.email`}
                              onChange={formik.handleChange}
                              value={formik.values.voyagers[index].email}
                              onBlur={formik.handleBlur}
                              placeholder="Adresse email"
                              className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                formik.errors.voyagers &&
                                formik.errors.voyagers[index] &&
                                formik.errors.voyagers[index].email &&
                                formik.touched.voyagers &&
                                formik.touched.voyagers[index] &&
                                formik.touched.voyagers[index].email
                                  ? 'border-red-500'
                                  : ''
                              }`}
                            />
                          </div>
                          <div className="h-6">
                          {getFieldError('email', index) && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.5 }}
                              className="italic text-md text-red-500 mt-1"
                            >
                              {formik.errors.voyagers[index].email}
                            </motion.div>
                          )}
                          </div>
                        </motion.div>

                        {/* Passeport */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                            <label htmlFor={`voyagers.${index}.passeport`} className="block text-gray-700 font-semibold mb-1 pl-2">Passeport</label>
                            <div className="relative">
                                <BsPassportFill className="absolute left-3 top-3 text-gray-400" />
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name={`voyagers.${index}.passeport`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.voyagers[index].passeport}
                                    placeholder="Numéro de passeport"
                                    className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                      formik.errors.voyagers &&
                                      formik.errors.voyagers[index] &&
                                      formik.errors.voyagers[index].passeport &&
                                      formik.touched.voyagers &&
                                      formik.touched.voyagers[index] &&
                                      formik.touched.voyagers[index].passeport
                                        ? 'border-red-500'
                                        : ''
                                    }`}
                                />
                            </div>
                            <div className="h-6">
                            {getFieldError('passeport', index) && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="italic text-md text-red-500 mt-1"
                              >
                                {formik.errors.voyagers[index].passeport}
                              </motion.div>
                            )}
                            </div>
                        </motion.div>

                        {/* Date de délivrance du passeport */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <label htmlFor={`voyagers.${index}.delivrancePasseport`} className="block text-gray-700 font-semibold mb-1 pl-2">
                            Date de délivrance
                          </label>
                          <div className="relative">
                            <FaCalendarDay className="absolute left-3 top-3 text-gray-400 z-10" />
                            <Datepicker
                              useRange={false}
                              asSingle={true}
                              value={delivranceDates[index]}
                              onChange={(newValue) => handleDelivranceChange(newValue, index)}
                              inputClassName={`pl-10 pr-4 cursor-pointer py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                getFieldError('delivrancePasseport', index) ? 'border-red-500' : ''
                              }`}
                              displayFormat={"DD/MM/YYYY"}
                              placeholder="Date de délivrance du passeport"
                              maxDate={new Date()}
                              i18n={"fr"}
                              popoverDirection="up"
                              configs={{
                                zIndex: 30
                              }}
                            />
                          </div>
                          <div className="h-6">
                            {getFieldError('delivrancePasseport', index) && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="italic text-md text-red-500 mt-1"
                              >
                                {formik.errors.voyagers[index].delivrancePasseport}
                              </motion.div>
                            )}
                          </div>
                        </motion.div>
                        {/* Date d'expiration */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <label htmlFor={`voyagers.${index}.expirationPasseport`} className="block text-gray-700 font-semibold mb-1 pl-2">
                            Date d'expiration
                          </label>
                          <div className="relative">
                            <FaCalendarDay className="absolute left-3 top-3 text-gray-400 z-10" />
                            <Datepicker
                              useRange={false}
                              asSingle={true}
                              value={expirationDates[index]}
                              onChange={(newValue) => handleExpirationChange(newValue, index)}
                              inputClassName={`pl-10 pr-4 cursor-pointer py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                getFieldError('expirationPasseport', index) ? 'border-red-500' : ''
                              }`}
                              displayFormat={"DD/MM/YYYY"}
                              placeholder="Date d'expiration du passeport"
                              minDate={getMinExpirationDate()} // Définit la date minimale
                              i18n={"fr"}
                              popoverDirection="up" 
                              configs={{
                                zIndex: 30 
                              }}
                            />
                          </div>
                          <div className="h-6">
                            {getFieldError('expirationPasseport', index) && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="italic text-md text-red-500 mt-1"
                              >
                                {formik.errors.voyagers[index].expirationPasseport}
                              </motion.div>
                            )}
                          </div>
                        </motion.div>


                        {/* Supprimer un formulaire */}
                        {/* {formik.values.voyagers.length > 1 && (
                          <MdDeleteSweep
                            size={40}
                            className="p-1 bg-red-100 rounded-md cursor-pointer text-red-500 hover:text-white hover:bg-red-500"
                            onClick={() => handleRemoveVoyager(index)}
                          />
                        )} */}
                      </form>
                    )}
                    </motion.div>
                  </AnimatePresence>
                ))}
              </div>
            </div>
            <div className='mt-6 p-6 rounded-md border shadow-md'>

            <div className='bg-white p-4 shadow-lg col-span-2 rounded-lg border '>
            <div className='flex flex-row justify-between items-center  rounded-md p-2 mb-6'>
              <div className='flex flex-row space-x-2 bg-warning-400 p-2 rounded-md text-white justify-center items-center'>
                <FaUserTie size={18} />
                <span className='font-bold text-md'>Souscripteur</span>
              </div>
              {selectedSubscriber !== null && (
                <motion.button
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={handleUnselectSouscripteur}
                  className="flex items-center space-x-1 bg-red-500 text-white p-2 rounded-md hover:bg-red-600"
                >
                  <MdDeleteForever size={18} />
                  <span className="text-md">Enlever le souscripteur actuel</span>
                </motion.button>
              )}
            </div>
              <form onSubmit={formikSusbcriber.handleSubmit} className='grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 gap-4 '>

                  {/* Type de souscripteur */}
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className='col-span-6'
                  >
                    <label htmlFor="prenom" className="block text-gray-700 font-semibold mb-1 pl-2">
                    Type de souscripteur
                    </label>
                    <div className="relative">
                      <TbSwitchHorizontal  className="absolute left-3 top-3 text-gray-400" />
                      <select
                        id="type"
                        name="type"
                        onChange={handleTypeChange}  // Utiliser la nouvelle fonction ici
                        onBlur={formikSusbcriber.handleBlur}
                        value={formikSusbcriber.values.type}
                        disabled={selectedSubscriber !== null}
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 
                          ${selectedSubscriber !== null ? 'bg-gray-100 cursor-not-allowed' : 'focus:outline-none focus:ring-2 focus:ring-blue-500'} 
                          ${formikSusbcriber.errors.type && formikSusbcriber.touched.type ? 'border-red-500' : ''}`}
                      >
                        <option value="">Sélectionnez</option>
                        <option value="Particulier">Particulier</option>
                        <option value="Entreprise">Entreprise</option>
                      </select>
                              
                    </div>
                    <div className="h-6">
                      {formikSusbcriber.touched.type && formikSusbcriber.errors.type && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className="italic text-md text-red-500 mt-1"
                        >
                          {formikSusbcriber.errors.type}
                        </motion.div>
                      )}
                    </div>
                  </motion.div>

                  <div className='col-span-6'></div>

                  {/* Les champs genre, prenom, nom seront masqués si le type est "Entreprise" */}
                  <AnimatePresence>
                    {formikSusbcriber.values.type === 'Particulier' && (
                      <>
                        {/* Genre */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 10 }}
                          transition={{ duration: 0.3 }}
                          className='col-span-4'
                        >
                          <label htmlFor="genre" className="block text-gray-700 font-semibold mb-1 pl-2">Genre</label>
                          <div className="relative">
                            <ImManWoman className="absolute left-3 top-3 text-gray-400" />
                            <select
                              id="genre"
                              name="genre"
                              onChange={formikSusbcriber.handleChange}
                              onBlur={formikSusbcriber.handleBlur}
                              value={formikSusbcriber.values.genre}
                              disabled={selectedSubscriber !== null}
                              className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 
                                ${selectedSubscriber !== null ? 'bg-gray-100 cursor-not-allowed' : 'focus:outline-none focus:ring-2 focus:ring-blue-500'} 
                                ${formikSusbcriber.errors.genre && formikSusbcriber.touched.genre ? 'border-red-500' : ''}`}
                            >
                              <option value="">Sélectionnez</option>
                              <option value="Monsieur">Monsieur</option>
                              <option value="Madame">Madame</option>
                            </select>
                          </div>
                          <div className="h-6">
                            {formikSusbcriber.touched.genre && formikSusbcriber.errors.genre && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="italic text-md text-red-500 mt-1"
                              >
                                {formikSusbcriber.errors.genre}
                              </motion.div>
                            )}
                          </div>
                        </motion.div>

                        {/* Prénom */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 10 }}
                          transition={{ duration: 0.3 }}
                          className='col-span-4'
                          >
                          <label htmlFor="prenom" className="block text-gray-700 font-semibold mb-1 pl-2">Prénom</label>
                          <div className="relative">
                            <FaUserPen className="absolute left-3 top-3 text-gray-400" />
                            <input
                              type="text"
                              name="prenom"
                              readOnly={selectedSubscriber !== null}
                              onChange={formikSusbcriber.handleChange}
                              onBlur={formikSusbcriber.handleBlur}
                              value={formikSusbcriber.values.prenom}
                              placeholder="Prénom"
                              className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 
                                ${selectedSubscriber !== null ? 'bg-gray-100 cursor-not-allowed' : 'focus:outline-none focus:ring-2 focus:ring-blue-500'} 
                                ${formikSusbcriber.errors.prenom && formikSusbcriber.touched.prenom ? 'border-red-500' : ''}`}
                            />
                          </div>
                          <div className="h-6">
                            {formikSusbcriber.touched.prenom && formikSusbcriber.errors.prenom && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="italic text-md text-red-500 mt-1"
                              >
                                {formikSusbcriber.errors.prenom}
                              </motion.div>
                            )}
                          </div>
                        </motion.div>

                        {/* Nom */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 10 }}
                          transition={{ duration: 0.3 }}
                          className='col-span-4'
                        >
                          <label htmlFor="nom" className="block text-gray-700 font-semibold mb-1 pl-2">Nom</label>
                          <div className="relative">
                            <FaUserPen className="absolute left-3 top-3 text-gray-400" />
                            <input
                              type="text"
                              name="nom"
                              readOnly={selectedSubscriber !== null}
                              onChange={formikSusbcriber.handleChange}
                              onBlur={formikSusbcriber.handleBlur}
                              value={formikSusbcriber.values.nom}
                              placeholder="nom de famille"
                              className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 
                                ${selectedSubscriber !== null ? 'bg-gray-100 cursor-not-allowed' : 'focus:outline-none focus:ring-2 focus:ring-blue-500'} 
                                ${formikSusbcriber.errors.prenom && formikSusbcriber.touched.nom ? 'border-red-500' : ''}`}
                            />
                          </div>
                          <div className="h-6">
                            {formikSusbcriber.touched.nom && formikSusbcriber.errors.nom && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="italic text-md text-red-500 mt-1"
                              >
                                {formikSusbcriber.errors.nom}
                              </motion.div>
                            )}
                          </div>
                        </motion.div>

                        {/* Date de naissance */}
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                          className='col-span-6'
                        >
                          <label htmlFor="dateNaissance" className="block text-gray-700 font-semibold mb-1 pl-2">
                            Date de naissance
                          </label>
                          <div className="relative">
                            <FaCalendarDay className="absolute left-3 top-3 text-gray-400 z-10" />
                            <Datepicker
                              useRange={false}
                              asSingle={true}
                              value={dateValue}
                              onChange={handleDateChange}
                              disabled={selectedSubscriber !== null}
                              inputClassName={`pl-10 pr-4 cursor-pointer py-2 border border-gray-300 rounded-lg w-full text-gray-700 
                                ${selectedSubscriber !== null ? 'bg-gray-100 cursor-not-allowed' : 'focus:outline-none focus:ring-2 focus:ring-blue-500'} 
                                ${formikSusbcriber.errors.dateNaissance && formikSusbcriber.touched.dateNaissance ? 'border-red-500' : ''}`}
                              displayFormat={"DD/MM/YYYY"}
                              placeholder="Sélectionnez une date"
                              maxDate={new Date()}
                              i18n={"fr"}
                            />
                          </div>
                          <div className="h-6">
                            {formikSusbcriber.touched.dateNaissance && formikSusbcriber.errors.dateNaissance && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="italic text-md text-red-500 mt-1"
                              >
                                {formikSusbcriber.errors.dateNaissance}
                              </motion.div>
                            )}
                          </div>
                        </motion.div>

                      </>
                    )}
                  </AnimatePresence>

                  <AnimatePresence>
                    {formikSusbcriber.values.type === 'Entreprise' && (
                      <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className='col-span-12'
                    >
                      <label htmlFor='raison_social'className="block text-gray-700 font-semibold mb-1 pl-2">Raison Social</label>
                      <div className="relative">
                      <BsBuildingsFill className="absolute left-3 top-3 text-gray-400" />
                        <input
                          type="raison_social"
                          autoComplete="off"
                          id='raison_social'
                          name='raison_social'
                          onChange={formikSusbcriber.handleChange}
                          onBlur={formikSusbcriber.handleBlur}
                          value={formikSusbcriber.values.raison_social}
                          placeholder="Raison social"
                          className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formikSusbcriber.errors.raison_social && formikSusbcriber.touched.raison_social ? 'border-red-500' : ''}`}
                        />
                      </div>
                      <div className="h-6">
                        {formikSusbcriber.touched.raison_social && formikSusbcriber.errors.raison_social && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="italic text-md text-red-500 mt-1"
                          >
                            {formikSusbcriber.errors.raison_social}
                          </motion.div>
                        )}
                      </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  

                    {/* Telephone */}
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className='col-span-6'
                    >
                      <label htmlFor="telephone" className="block text-gray-700 font-semibold mb-1 pl-2">
                        Telephone
                      </label>
                      <div className="relative">
                      <PhoneInput
                        country={'sn'}
                        disabled={selectedSubscriber !== null}
                        autoComplete="off"
                        onlyCountries={['sn', 'gn', 'ml', 'ci']}
                        masks={{
                          sn: '-..-...-..-..',
                          gn: '-..-...-...',
                          ml: '-..-...-...',
                          ci: '-..-..-..-..',
                        }}
                        id="telephone"
                        name="telephone"
                        value={formikSusbcriber.values.telephone}
                        onChange={(value) => formikSusbcriber.setFieldValue('telephone', value)}
                        onBlur={() => {
                          formik.handleBlur('telephone');
                          setIsFocused(false);
                        }}
                        inputStyle={{
                          fontFamily: 'serif',
                          width: '100%',
                          height: '2.5rem',
                          color: '#444',
                          backgroundColor: selectedSubscriber !== null ? '#f3f4f6' : 'white',
                          cursor: selectedSubscriber !== null ? 'not-allowed' : 'text',
                          ...(isFocused && !selectedSubscriber && {
                            outline: 'none',
                            boxShadow: '0 0 0 2px #3b82f6',
                            borderColor: 'transparent',
                          }),
                        }}
                      />
                      </div>
                      
                    </motion.div>

                    {/* Email */}
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className={`${formikSusbcriber.values.type === 'Particulier' ? 'col-span-4' : 'col-span-6'}`}
                    >
                      <label htmlFor='email'className="block text-gray-700 font-semibold mb-1 pl-2">Email</label>
                      <div className="relative">
                        <span 
                              className="absolute inset-y-0 left-0 flex items-center pl-3 cursor-pointer"
                              onClick={GenerateEmailSouscripteur} 
                          >
                              <FaEnvelope className="w-5 h-5 text-gray-400" />
                          </span>
                        <input
                          type="email"
                          name="email"
                          readOnly={selectedSubscriber !== null}
                          onChange={formikSusbcriber.handleChange}
                          onBlur={formikSusbcriber.handleBlur}
                          value={formikSusbcriber.values.email}
                          placeholder="email"
                          className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 
                            ${selectedSubscriber !== null ? 'bg-gray-100 cursor-not-allowed' : 'focus:outline-none focus:ring-2 focus:ring-blue-500'} 
                            ${formikSusbcriber.errors.email && formikSusbcriber.touched.email ? 'border-red-500' : ''}`}
                        />
                      </div>
                      <div className="h-6">
                        {formikSusbcriber.touched.email && formikSusbcriber.errors.email && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="italic text-md text-red-500 mt-1"
                          >
                            {formikSusbcriber.errors.email}
                          </motion.div>
                        )}
                      </div>
                    </motion.div>

                    {/* Adresse */}
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className={`${formikSusbcriber.values.type === 'Particulier' ? 'col-span-4' : 'col-span-6'}`}
                    >
                      <label htmlFor='adresse'className="block text-gray-700 font-semibold mb-1 pl-2">Adresse</label>
                      <div className="relative">
                      <MdPlace className="absolute left-3 top-3 text-gray-400" />
                      <input
                        type="text"
                        autoComplete="off"
                        id='adresse'
                        name='adresse'
                        onChange={formikSusbcriber.handleChange}
                        onBlur={formikSusbcriber.handleBlur}
                        value={formikSusbcriber.values.adresse}
                        readOnly={selectedSubscriber !== null}
                        placeholder="Adresse"
                        className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 ${selectedSubscriber !== null ? 'bg-gray-100 cursor-not-allowed' : 'focus:outline-none focus:ring-2 focus:ring-blue-500'} ${formikSusbcriber.errors.adresse && formikSusbcriber.touched.adresse ? 'border-red-500' : ''}`}
                      />
                      </div>
                      <div className="h-6">
                        {formikSusbcriber.touched.adresse && formikSusbcriber.errors.adresse && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="italic text-md text-red-500 mt-1"
                          >
                            {formikSusbcriber.errors.adresse}
                          </motion.div>
                        )}
                      </div>
                    </motion.div>

                    {/* Domaine */}
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className={`${formikSusbcriber.values.type === 'Particulier' ? 'col-span-4' : 'col-span-6'}`}
                    >
                      <label htmlFor='domaine'className="block text-gray-700 font-semibold mb-1 pl-2">Profession</label>
                      <div className="relative">
                      <MdWork className="absolute left-3 top-3 text-gray-400" />
                        <input
                          type="text"
                          autoComplete="off"
                          id='domaine'
                          name='domaine'
                          onChange={formikSusbcriber.handleChange}
                          onBlur={formikSusbcriber.handleBlur}
                          value={formikSusbcriber.values.domaine}
                          placeholder="Profession"
                          className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full  text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formikSusbcriber.errors.domaine && formikSusbcriber.touched.domaine ? 'border-red-500' : ''}`}
                        />
                      </div>
                      <div className="h-6">
                        {formikSusbcriber.touched.domaine && formikSusbcriber.errors.domaine && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="italic text-md text-red-500 mt-1"
                          >
                            {formikSusbcriber.errors.domaine}
                          </motion.div>
                        )}
                      </div>
                    </motion.div>

              </form>
            </div>
              <div className="flex justify-between mt-8">
                  {/* Bouton Précédent */}
                  <button
                    onClick={onPrev}
                    className="flex items-center space-x-3 bg-blue-100 text-blue-500 py-2 px-4 rounded transform transition duration-300 hover:scale-110"
                  >
                    <IoIosArrowDropleftCircle size={20} />
                    <span>Précédent</span>
                  </button>

                  {/* Bouton Suivant */}
                  <button
                    type="button"
                    onClick={handleSubmitAll} 
                    disabled={!isAllFormsValid()} 
                    className={`flex space-x-3 items-center py-2 px-4 rounded transform transition duration-300 hover:scale-110 ${
                      !isAllFormsValid()
                        ? 'bg-blue-300 text-white cursor-not-allowed'
                        : 'bg-blue-500 text-white'
                    }`}
                  >
                    <span>Suivant</span>
                    <IoIosArrowDroprightCircle size={20} />
                  </button>
              </div>
            </div>

            
          </div>

          <div className='md:w-1/3 lg:w-1/3'>
              {/* la decomposition de la prime */}
              <div className='border rounded-md h-full bg-gradient-to-r from-purple-200 to-indigo-300 p-6 shadow-2xl'>
                  {/* Détails du voyage */}
                  <div className="bg-white p-6 shadow-lg rounded-lg mb-6">
                    <h3 className=" flex justify-center items-center text-xl font-bold text-indigo-700 mb-4 underline underline-offset-4">Détails du voyage</h3>
                    <ul>
                      <li className="flex items-center mb-2">
                        <span className="text-purple-600 text-2xl mr-2">👥</span>
                        <span className="text-dark-600 font-DMSerifText">Nombre de voyageur : <span className="font-semibold text-purple-700 ml-2">{saveData.step1Data.nbVoyager}</span></span>
                      </li>
                      <li className="flex items-center mb-2">
                        <span className="text-purple-600 text-2xl mr-2">📍</span>
                        <span className="text-dark-600 font-DMSerifText">Pays de résidence : <span className="font-semibold text-purple-700 ml-2">{paysActuel}</span></span>
                      </li>
                      <li className="flex items-center mb-2">
                        <span className="text-purple-600 text-2xl mr-2">🛫</span>
                        <span className="text-dark-600 font-DMSerifText">
                          Date de départ : 
                          <span className="font-semibold text-purple-700 ml-2">
                            {moment(saveData.step1Data.dateDepart).format('DD/MM/YYYY')}
                          </span>
                        </span>
                      </li>
                      <li className="flex items-center mb-2">
                        <span className="text-purple-600 text-2xl mr-2">🌍</span>
                        <span className="text-dark-600 font-DMSerifText">Destination : <span className="font-semibold text-purple-700 ml-2">{saveData.step1Data.destination}</span></span>
                      </li>
                      <li className="flex items-center mb-2">
                        <span className="text-purple-600 text-2xl mr-2">🛬</span>
                        <span className="text-dark-600 font-DMSerifText">
                          Date de retour : 
                          <span className="font-semibold text-purple-700 ml-2">
                            {moment(saveData.step1Data.dateRetour).format('DD/MM/YYYY')}
                          </span>
                        </span>
                      </li>
                      <li className="flex items-center mb-2">
                        <span className="text-purple-600 text-2xl mr-2">📅</span>
                        <span className="text-dark-600 font-DMSerifText">Durée : <span className="font-semibold text-purple-700 ml-2">{`${saveData.step1Data.duree} jours`} </span></span>
                      </li>
                    </ul>
                  </div>

                  <div className='bg-white p-6 shadow-lg rounded-lg mb-6'>
                    <h3 className="flex justify-center items-center text-xl font-bold text-indigo-700 mb-4 underline underline-offset-4">Formule</h3>
                    <div className='flex flex-col justify-center items-center' >
                      <div className='w-32 h-32 rounded-md shadow-md bg-white border-[0.5px]'>
                        <img src={pic} className='p-2 object-cover items-center'/>
                      </div>
                      <span className='mt-2 text-lg font-ConcertOne text-purple-600'>
                        {saveData.step1Data.selectedTarification.formules?.[0].formule.name || saveData.step1Data.selectedTarification.formules?.[0].formule}
                      </span>
                    </div>
                  </div>
        
                  {/* Montant à payer */}
                  <div className="bg-white p-6 shadow-lg rounded-lg">
                    <h3 className="flex justify-center items-center text-xl font-bold text-indigo-700 mb-4 underline underline-offset-4">Prime à payer</h3>
                    <ul>
                      <li className="flex justify-between">
                        <span className="text-gray-800 font-DMSerifText">Prime Nette: </span>
                        <span className="font-semibold text-green-500">
                          <FormatMontant montant={saveData.step1Data.selectedTarification.formules?.[0].primeNetteTotal || 0} />
                          <span className='text-xs ml-1'>{devis}</span>
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-gray-800 font-DMSerifText">Coût de police: </span>
                        <span className="font-semibold text-green-500">
                          <FormatMontant montant={saveData.step1Data.selectedTarification.formules?.[0].policeTotal || 0} />
                          <span className='text-xs ml-1'>{devis}</span>
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-gray-800 font-DMSerifText">Taxes: </span>
                        <span className="font-semibold text-green-500">
                          <FormatMontant montant={saveData.step1Data.selectedTarification.formules?.[0].taxesTotal || 0} />
                          <span className='text-xs ml-1'>{devis}</span>
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-gray-800 font-DMSerifText">Frais de service: </span>
                        <span className="font-semibold text-green-500">
                          <FormatMontant montant={saveData.step1Data.selectedTarification.formules?.[0].prixConseilleTotal || 0} />
                          <span className='text-xs ml-1'>{devis}</span>
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-gray-800 font-DMSerifText">Prime TTC: </span>
                        <span className="font-semibold text-green-500">
                          <FormatMontant montant={saveData.step1Data.selectedTarification.formules?.[0].primeTTCTotal || 0} />
                          <span className='text-xs ml-1'>{devis}</span>
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-gray-800 font-DMSerifText">Commission: </span>
                        <span className="font-semibold text-green-500">
                          {/* <FormatMontant montant={saveData.step1Data.selectedTarification.formules?.[0].commission || 0} /> */}
                          <FormatMontant montant={0} />
                          <span className='text-xs ml-1'>{devis}</span>
                        </span>
                      </li>
                      
                      <li className="flex justify-between font-bold text-red-600 text-lg mt-4 ">
                        <span >Total: </span>
                        <span >
                          <FormatMontant montant={saveData.step1Data.selectedTarification.formules?.[0].montantTotal || 0} />
                          <span className='text-xs ml-1'>{devis}</span>
                        </span>
                      </li>
                    </ul>
                  </div>
              </div>
          </div>
        </div>
  
      </div>
    );
  
}

export default Step2

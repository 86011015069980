import { AgGridReact, useGridFloatingFilter } from 'ag-grid-react';
import { useMemo } from 'react';
import { Data } from '../../../utils/faker';
import { Button } from 'keep-react'
import { FaCommentDots } from 'react-icons/fa6';




const ContratAutoDatatable = () => {

    const conditons = useMemo(()=>({ 
        'status-warning': params => params.data.status === 'expire bientot',
        'status-bad': params => params.data.status === 'expiree',
    }),[])

    const ActionComponent = params =>{

        return(
            <Button variant='outline' color='warning' onClick={()=>alert(params.data.id)} size='sm'>
                <FaCommentDots size={20} />
            </Button>
        )
    }

    const CustomCol = useMemo(()=>{ 
        return{ 
            flex: 1,
            filter: true,
            floatingFilter: true,
        }
    })

    const header = [
        { field: "client" ,valueGetter: params => params.data.prenom + ' ' + params.data.nom ,checkboxSelection: true },
        { field: "telephone" },
        { field: "voiture"},
        { field: "matricule"},
        { field: "montant", valueFormatter: params => params.value +' fcfa'},
        { field: "status"},
        { field: "expiration"},
        { field: "action", cellRenderer: ActionComponent}
    ]
    return (
        <div className="ag-theme-quartz" style={{ height: 500 }}>
            <AgGridReact 
                rowData={Data} 
                columnDefs={header}
                rowHeight={60}
                rowSelection={'multiple'}
                rowClassRules={conditons}
                defaultColDef={CustomCol}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={[10, 20, 30]}
            />
        </div>
    );
};

export default ContratAutoDatatable;